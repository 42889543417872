import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PUBSUB_TOPICS, useSubscriber } from '../../../../hooks';
import { USERS_ACTIONS } from '../../../../store/actions/reducer-actions/users';
import { Colors } from '../../../../styles/vars';
import appConfig from '../../../../utils/app-config';
import { connect } from '../../../../utils/redux';
import Input from '../../../elements/Input';
import {
  NotificationType,
  sendNotification,
} from '../../../elements/NotificationArea';
import Select from '../../../elements/Select';
import Paths from '../../../routes/paths';
import {
  civilityOptions,
  roleOptions,
  selectStyles,
  selectTheme,
} from '../admin-utils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: flex-start;

  input[type='submit'] {
    display: none;
    visibility: hidden;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 360px;
  margin-bottom: 20px;

  span {
    margin-bottom: 8px;

    font-size: 14px;
    line-height: 21px;
    color: ${Colors.white};
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    display: block;
    width: 100%;
    height: 40px;
    padding: 0 15px;

    background-color: ${Colors.primary};
    color: ${Colors.white};
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }

  &.select {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 18px;
      right: 15px;
      width: 8px;
      height: 8px;

      border-bottom: 1px solid white;
      border-right: 1px solid white;

      transform-origin: center;
      transform: rotate(45deg);

      pointer-events: none;
    }
  }
`;

const CreateUser = ({ for: clientID, createUser, currentUser }) => {
  const history = useHistory();

  const submit = useRef();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [civility, setCivility] = useState('');
  const [role, setRole] = useState('');

  useSubscriber(PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_REQUESTED, () => {
    const { current } = submit;

    if (current) {
      current.click();
    }
  });

  useSubscriber(PUBSUB_TOPICS.CREATE_USER_SUCCESS, () => {
    history.push(Paths.Admin({ resource: 'users', id: clientID }));
  });

  const handleSubmit = event => {
    event.preventDefault();

    const internalDomains = appConfig.get('settings.internalDomains');

    const reg = new RegExp(`@(${internalDomains.join('|')})$`);
    const matches = email.match(reg);

    if (clientID !== 'internal' || (clientID === 'internal' && matches)) {
      createUser({
        sUserIdentifier: email,
        sUserPassword: password,
        sCustomerCode: clientID === 'internal' ? '' : clientID,
        sUserFirstName: firstname,
        sUserLastName: lastname,
        kIDCivility: civility,
        kIDRole: role,
      });
    } else {
      const domainList = internalDomains.join(', ');

      const domainLabel =
        internalDomains.length > 1
          ? "L'adresse e-mail doit être dans l'un des domaines :"
          : "L'adresse e-mail doit être dans le domaine :";

      sendNotification(
        [domainLabel, domainList].join(' '),
        NotificationType.WARNING,
      );
    }

    return false;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label>
        <span>Prénom</span>
        <Input
          type="text"
          name="firstname"
          background={Colors.primary}
          color={Colors.white}
          onChange={setFirstname}
          square
          required
        />
      </Label>

      <Label>
        <span>Nom de famille</span>
        <Input
          type="text"
          name="lastname"
          background={Colors.primary}
          color={Colors.white}
          onChange={setLastname}
          square
          required
        />
      </Label>

      <Label>
        <span>E-mail</span>
        <Input
          type="email"
          name="email"
          background={Colors.primary}
          color={Colors.white}
          onChange={setEmail}
          square
          required
        />
      </Label>

      <Label>
        <span>Mot de passe</span>
        <Input
          type="password"
          name="password"
          background={Colors.primary}
          color={Colors.white}
          onChange={setPassword}
          square
          required
        />
      </Label>

      <Label>
        <span>Civilité</span>

        <Select
          placeholder="Choisir une civilité"
          options={civilityOptions}
          onChange={({ value }) => setCivility(value)}
          expand
          required
          theme={selectTheme}
          styles={selectStyles}
        />
      </Label>

      <Label className="select">
        <span>Rôle</span>

        <Select
          placeholder="Choisir un rôle"
          options={roleOptions(currentUser)}
          onChange={({ value }) => setRole(value)}
          expand
          required
          theme={selectTheme}
          styles={selectStyles}
        />
      </Label>

      <input type="submit" value="Hidden submit" ref={submit} />
    </Form>
  );
};

CreateUser.propTypes = {
  for: PropTypes.string.isRequired,
  createUser: PropTypes.func.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ConnectedCreateUser = connect(state => ({ ...state.users }), {
  ...USERS_ACTIONS,
})(CreateUser);

export default ConnectedCreateUser;
