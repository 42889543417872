import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import React from 'react';

import global from './global';

class AppConfig {
  #config;

  constructor() {
    this.acquireConfigData();
  }

  acquireConfigData() {
    this.#config = global.hxConfig || this.#config;

    if (this.#config && typeof this.#config === typeof '') {
      try {
        this.#config = JSON.parse(this.#config);
      } catch {
        this.#config = {};
      }

      global.hxConfig = this.#config;
    }

    return this.#config;
  }

  get(compoundKey, defaultValue = null) {
    const keys = compoundKey.split('.');

    let current = this.acquireConfigData();

    keys.forEach(key => {
      if (current && key in current) {
        current = current[key];
      } else {
        current = defaultValue;
      }
    });

    if (
      current === null &&
      global.env &&
      global.env.vitaeEnv === 'development'
    ) {
      current = `Missing config key: ${compoundKey}`;
    }

    return current;
  }

  mapSegments(compoundKey) {
    const content = this.get(compoundKey);

    if (content && isObject(content) && 'segments' in content) {
      const { segments } = content;

      if (Array.isArray(segments)) {
        return segments.map((segment, id) => (
          <p
            // eslint-disable-next-line react/no-array-index-key
            key={`segment-${id}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: segment }}
            className="segment"
          />
        ));
      }
    }

    return null;
  }

  mapArray(compoundKey, mapCallback) {
    const content = this.get(compoundKey);

    if (content && isArray(content)) {
      return content.map((element, id) => {
        return mapCallback(element, `mappedarray-${id}`);
      });
    }

    return null;
  }
}

export default new AppConfig();
