import PreloadProductsByCustomer from '../../../../workers/preload-products-by-customer.worker';
import { createEndpoint, Type } from '../../models/europrisme';
import {
  idbGetSingleProduct,
  idbPutProductsByCustomerRecords,
  idbSearchProducts,
} from '../../models/idb';
import {
  CANCEL_GET_DISCOUNT_PRODUCTS,
  CLEAR_PRODUCTS,
  GET_DISCOUNT_PRODUCTS_REJECTED,
  GET_DISCOUNT_PRODUCTS_REQUESTED,
  GET_DISCOUNT_PRODUCTS_RESOLVED,
  GET_PRODUCTS_REJECTED,
  GET_PRODUCTS_REQUESTED,
  GET_PRODUCTS_RESOLVED,
  GET_SINGLE_PRODUCT_PDF_REJECTED,
  GET_SINGLE_PRODUCT_PDF_REQUESTED,
  GET_SINGLE_PRODUCT_PDF_RESOLVED,
  GET_SINGLE_PRODUCT_REJECTED,
  GET_SINGLE_PRODUCT_REQUESTED,
  GET_SINGLE_PRODUCT_RESOLVED,
  SEARCH_PRODUCTS_REJECTED,
  SEARCH_PRODUCTS_REQUESTED,
  SEARCH_PRODUCTS_RESOLVED,
} from '../types';
import { cartUpdateItemData } from './cart';

// ===== FETCH

export const fetchProductsRequested = (fullParams = {}) => ({
  type: GET_PRODUCTS_REQUESTED,
  params: fullParams,
});

export const fetchProductsRejected = (error, fullParams = {}) => ({
  type: GET_PRODUCTS_REJECTED,
  payload: { error, params: fullParams },
});

export const fetchProductsResolved = (data, fullParams = {}) => ({
  type: GET_PRODUCTS_RESOLVED,
  payload: { data, params: fullParams },
});

export const fetchProductsEndpoint = createEndpoint(
  Type.GET,
  'findAndSortArticlesByCustomer',
  {
    sCustomerCode: '',
    sFamille: '',
    iPageSize: 15,
    iPageNum: 1,
    sSortField: '',
    sSortWay: '',
  },
  {
    request: fetchProductsRequested,
    resolve: fetchProductsResolved,
    reject: fetchProductsRejected,
  },
);

export const fetchProducts = args => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;

  if (currentUser) {
    const { customerID } = currentUser;

    return dispatch(
      fetchProductsEndpoint({
        ...args,
        sCustomerCode: customerID,
      }),
    );
  }

  return dispatch(fetchProductsEndpoint(args));
};

export const fetchProductsByCustomer = ({ port = 6174, baseURL }) => async (
  dispatch,
  getState,
) => {
  if (process.env.BUILD_TARGET === 'client') {
    const { users } = getState();
    const { currentUser } = users || {};
    const { customerID } = currentUser || {};

    if (currentUser) {
      return new Promise((resolve, reject) => {
        const worker = new PreloadProductsByCustomer();

        worker.onmessage = async ({ data }) => {
          if (data.type === 'PRELOAD_PRODUCTS_BY_CUSTOMER_RESOLVED') {
            if (data.success) {
              const { value: records } = data;

              if (records?.recordArticles) {
                await idbPutProductsByCustomerRecords(records.recordArticles);

                await dispatch(
                  fetchProductsResolved({
                    recordArticles: records.recordArticles,
                  }),
                );

                return resolve(records.recordArticles);
              }
            }

            dispatch(
              fetchProductsRejected(data.error, {
                // Include a fake request object to prenvent crash
                __request: { uuid: 'idb' },
              }),
            );

            return reject(data.error);
          }
        };

        dispatch(fetchProductsRequested());
        worker.postMessage({
          type: 'PRELOAD_PRODUCTS_BY_CUSTOMER',
          port,
          baseURL,
          customerID,
          location: JSON.stringify({ ...window.location }),
        });
      });
    }
  }
};

// ===== FETCH DISCOUNT

export const fetchDiscountProductsRequested = fullParams => ({
  type: GET_DISCOUNT_PRODUCTS_REQUESTED,
  params: fullParams,
});

export const fetchDiscountProductsRejected = (error, fullParams) => ({
  type: GET_DISCOUNT_PRODUCTS_REJECTED,
  payload: { error, params: fullParams },
});

export const fetchDiscountProductsResolved = (data, fullParams) => ({
  type: GET_DISCOUNT_PRODUCTS_RESOLVED,
  payload: { data, params: fullParams },
});

export const cancelFetchDiscountProducts = () => ({
  type: CANCEL_GET_DISCOUNT_PRODUCTS,
});

export const fetchDiscountProductsEndpoint = createEndpoint(
  Type.GET,
  'get2Highlighted',
  {
    sCustomerCode: '',
    sSortField: '',
    sSortWay: '',
  },
  {
    request: fetchDiscountProductsRequested,
    resolve: fetchDiscountProductsResolved,
    reject: fetchDiscountProductsRejected,
  },
);

export const fetchDiscountProducts = args => async dispatch => {
  await dispatch(cancelFetchDiscountProducts());
  return dispatch(fetchDiscountProductsEndpoint(args));
};

// ===== SEARCH

export const searchProductsRequested = fullParams => ({
  type: SEARCH_PRODUCTS_REQUESTED,
  params: fullParams,
});

export const searchProductsRejected = (error, fullParams) => ({
  type: SEARCH_PRODUCTS_REJECTED,
  payload: { error, params: fullParams },
});

export const searchProductsResolved = (data, fullParams) => ({
  type: SEARCH_PRODUCTS_RESOLVED,
  payload: { data, params: fullParams },
});

export const searchProductsEndpoint = createEndpoint(
  Type.GET,
  'searchAndSortArticles',
  {
    sSearch: '',
    iPageSize: 15,
    iPageNum: 1,
    sSortField: '',
    sSortWay: '',
  },
  {
    request: searchProductsRequested,
    resolve: searchProductsResolved,
    reject: searchProductsRejected,
  },
);

export const searchProductsWithCustomerEndpoint = createEndpoint(
  Type.GET,
  'searchAndSortArticlesByCustomer',
  {
    sSearch: '',
    sCustomerCode: '',
    iPageSize: 15,
    iPageNum: 1,
    sSortField: '',
    sSortWay: '',
  },
  {
    request: searchProductsRequested,
    resolve: searchProductsResolved,
    reject: searchProductsRejected,
  },
);

export const searchProducts = args => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;

  if (currentUser) {
    const { customerID } = currentUser;

    const idbProducts = await idbSearchProducts(args);

    if (idbProducts) {
      await dispatch(
        searchProductsRequested({
          ...args,
          // eslint-disable-next-line no-underscore-dangle
          __options: { ...(args.__options || {}), clear: true },
        }),
      );

      return dispatch(
        searchProductsResolved({ recordArticles: idbProducts }, args),
      );
    }

    return dispatch(
      searchProductsWithCustomerEndpoint({
        ...args,
        sCustomerCode: customerID,
      }),
    );
  }

  return dispatch(searchProductsEndpoint(args));
};

// ===== FETCH SINGLE

export const fetchSingleProductRequested = fullParams => ({
  type: GET_SINGLE_PRODUCT_REQUESTED,
  params: fullParams,
});

export const fetchSingleProductRejected = (error, fullParams) => ({
  type: GET_SINGLE_PRODUCT_REJECTED,
  payload: { error, params: fullParams },
});

export const fetchSingleProductResolved = (data, fullParams) => ({
  type: GET_SINGLE_PRODUCT_RESOLVED,
  payload: { data, params: fullParams },
});

export const fetchSingleProductEndpoint = createEndpoint(
  Type.GET,
  'getArticleByCustomerByQuantity',
  { sArticleCode: '', sCustomerCode: '', sQuantity: 0 },
  {
    request: fetchSingleProductRequested,
    resolve: fetchSingleProductResolved,
    reject: fetchSingleProductRejected,
  },
  data => {
    try {
      const { Success, recordArticles } = data;

      return Success !== 'FALSE' && recordArticles && recordArticles.length > 0;
    } catch (error) {
      return false;
    }
  },
);

export const fetchSingleProduct = args => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;

  if (currentUser) {
    const { customerID } = currentUser;

    const idbProduct = await idbGetSingleProduct(args?.sArticleCode);

    if (idbProduct) {
      await dispatch(
        fetchSingleProductResolved({ recordArticles: [idbProduct] }),
      );
    } else {
      await dispatch(
        fetchSingleProductEndpoint({
          ...args,
          sCustomerCode: customerID,
        }),
      );
    }

    const { products: productsState } = getState();
    const { products } = productsState;

    const { sArticleCode } = args;

    if (products[sArticleCode]) {
      return dispatch(
        cartUpdateItemData({ product: products[sArticleCode], skipSort: true }),
      );
    }

    return false;
  }

  return dispatch(fetchSingleProductEndpoint(args));
};

// ===== FETCH SINGLE PDF B64 DOCUMENT

export const fetchSingleProductPdfRequested = fullParams => ({
  type: GET_SINGLE_PRODUCT_PDF_REQUESTED,
  params: fullParams,
});

export const fetchSingleProductPdfRejected = (error, fullParams) => ({
  type: GET_SINGLE_PRODUCT_PDF_REJECTED,
  payload: { error, params: fullParams },
});

export const fetchSingleProductPdfResolved = (data, fullParams) => ({
  type: GET_SINGLE_PRODUCT_PDF_RESOLVED,
  payload: { data, params: fullParams },
});

export const fetchSingleProductPdfEndpoint = createEndpoint(
  Type.GET,
  'getPDFArticleB64',
  { sArticleCode: '' },
  {
    request: fetchSingleProductPdfRequested,
    resolve: fetchSingleProductPdfResolved,
    reject: fetchSingleProductPdfRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success !== 'FALSE';
    } catch (error) {
      return false;
    }
  },
);

export const fetchSingleProductPdf = args => async dispatch => {
  dispatch(fetchSingleProductPdfEndpoint({ ...args }));
};

// ===== CLEAR

export const clearProducts = () => ({ type: CLEAR_PRODUCTS });

export const PRODUCT_ACTIONS = {
  fetchProducts,
  fetchProductsByCustomer,
  fetchDiscountProducts,
  searchProducts,
  fetchSingleProduct,
  clearProducts,
  fetchSingleProductPdf,
};
