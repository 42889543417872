import PropTypes from 'prop-types';
import React from 'react';

const ArrowCornerUpLeft = ({ size, style }) => (
  <svg
    viewBox="0 0 24 24"
    width={size}
    height={size}
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={style}
  >
    <polyline points="9 14 4 9 9 4" />
    <path d="M20 20v-7a4 4 0 0 0-4-4H4" />
  </svg>
);

ArrowCornerUpLeft.propTypes = {
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

ArrowCornerUpLeft.defaultProps = {
  size: 12,
  style: {},
};

export default ArrowCornerUpLeft;
