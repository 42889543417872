import appConfig from '../../utils/app-config';
import Admin from './Admin';
import AdminIndex from './AdminIndex';
import Cart from './Cart';
import Contact from './Contact';
import Home from './Home';
import Login from './Login';
import NotFound from './NotFound';
import OrderConfirmation from './OrderConfirmation';
import OrdersList from './OrdersList';
import PendingOrdersList from './PendingOrdersList';
import PreordersList from './PreordersList';
import RequestPassword from './RequestPassword';
import ResetPassword from './ResetPassword';
import Shop from './Shop';
import SingleAisle from './SingleAisle';
import SingleFamily from './SingleFamily';
import SingleProduct from './SingleProduct';

const AlwaysAvailable = () => true;

export const Routes = [
  Login,
  RequestPassword,
  ResetPassword,

  Home,
  Contact,

  Shop,
  SingleProduct,
  SingleFamily,
  SingleAisle,

  Cart,
  OrderConfirmation,
  OrdersList,
  PreordersList,
  PendingOrdersList,

  AdminIndex,
  Admin,
];

export const PublicRoutes = [
  Login,
  RequestPassword,
  ResetPassword,
  Home,
  Contact,
];

export const PrivateRoutes = [
  { route: Shop, condition: AlwaysAvailable },
  { route: SingleProduct, condition: AlwaysAvailable },
  { route: SingleFamily, condition: AlwaysAvailable },
  { route: SingleAisle, condition: AlwaysAvailable },

  { route: Cart, condition: AlwaysAvailable },
  { route: OrderConfirmation, condition: AlwaysAvailable },
  { route: OrdersList, condition: AlwaysAvailable },
  { route: PreordersList, condition: AlwaysAvailable },
  {
    route: PendingOrdersList,
    condition: () => appConfig.get('settings.usePendingOrdersPage'),
  },
]
  .filter(({ condition }) => condition())
  .map(({ route }) => route);

export const AdminRoutes = [AdminIndex, Admin];

export const getScopedRoutes = currentUser => {
  if (!currentUser) return PublicRoutes;

  if (currentUser.admin) {
    return [...PublicRoutes, ...PrivateRoutes, ...AdminRoutes];
  }

  return [...PublicRoutes, ...PrivateRoutes];
};

export const getRoutes = currentUser => [
  ...getScopedRoutes(currentUser),
  NotFound,
];

export const BreadcrumbRoutes = [
  Home,
  Contact,

  Shop,
  SingleProduct,
  SingleAisle,
  SingleFamily,

  Cart,
  OrderConfirmation,
  OrdersList,
  PreordersList,
  PendingOrdersList,

  AdminIndex,
  Admin,
];
