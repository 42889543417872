import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArrowLeftIcon from '../../../assets/img/ArrowLeftIcon';
import heroHeader from '../../../assets/img/hero_header.jpg';
import { usePageTitle } from '../../hooks';
import { Colors, Sizing } from '../../styles/vars';
import Button from '../elements/Button';
import FadedImage from '../elements/FadedImage';
import HeroHeader from '../elements/HeroHeader';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  width: 100%;
  max-width: ${Sizing.wrapperWidth};
`;

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 4 / span 6;
  padding: 60px 40px;

  background-color: ${Colors.white};

  h1 {
    margin: 0;
    margin-bottom: 30px;
  }
`;

const NotFound = () => {
  usePageTitle('Page non trouvée');

  return (
    <>
      <HeroHeader showSearch={false} fullHeight>
        <FadedImage id="not-found" />

        <Contents>
          <NotFoundContainer>
            <PageTitle>Page non trouvée</PageTitle>
            <Button
              fromComponent={Link}
              to={Paths.Home()}
              background="white"
              hoverBackground={Colors.accent}
              hoverColor={Colors.black}
            >
              <ArrowLeftIcon />
              <span>Retour à l&apos;accueil</span>
            </Button>
          </NotFoundContainer>
        </Contents>
      </HeroHeader>
    </>
  );
};

export default {
  path: '',
  name: 'Not Found',
  component: NotFound,
};
