import { isPlainObject, without } from 'lodash';
import { publish } from 'pubsub-js';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import { PUBSUB_TOPICS } from '../../hooks';
import { API_BASE_URL } from '../../utils/api-base';
import appConfig from '../../utils/app-config';
import {
  CREATE_USER_REJECTED,
  CREATE_USER_REQUESTED,
  CREATE_USER_RESOLVED,
  DISABLE_USER_REJECTED,
  DISABLE_USER_REQUESTED,
  DISABLE_USER_RESOLVED,
  GET_SINGLE_USER_REJECTED,
  GET_SINGLE_USER_REQUESTED,
  GET_SINGLE_USER_RESOLVED,
  GET_USERS_REJECTED,
  GET_USERS_REQUESTED,
  GET_USERS_RESOLVED,
  LOGIN_USER_REJECTED,
  LOGIN_USER_REQUESTED,
  LOGIN_USER_RESOLVED,
  LOGOUT_USER,
  REQUEST_PASSWORD_REJECTED,
  REQUEST_PASSWORD_REQUESTED,
  REQUEST_PASSWORD_RESOLVED,
  RESET_PASSWORD_REJECTED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_RESOLVED,
  UPDATE_USER_REJECTED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';
import { Keys, storagePut } from '../models/localstorage';

export const ResetPasswordStatus = {
  IDLE: 'IDLE',
  REQUEST_PENDING: 'REQUEST_PENDING',
  REQUEST_RESOLVED: 'REQUEST_RESOLVED',
  RESET_PENDING: 'RESET_PENDING',
  RESET_RESOLVED: 'RESET_RESOLVED',
  ERROR: 'ERROR',
};

const initialState = {
  ...defaultState,
  currentUser: undefined,
  createStatus: Status.IDLE,
  updateStatus: Status.IDLE,
  deleteStatus: Status.IDLE,
  users: {},
  meta: {},
  passwordReset: {
    status: ResetPasswordStatus.IDLE,
    data: {},
    email: null,
    idEmailLog: null,
    error: null,
  },
  error: null,
};

const civilityToHumanReadable = id =>
  ['Monsieur', 'Madame', 'Mademoiselle', 'Maitre', 'Docteur'][id - 1];

export class User {
  constructor(data = {}) {
    this.data = data;
  }

  /**
   * Checks if the current user can use a given platform.
   *
   * @param {string} what Platform name to test
   * @returns boolean
   */
  canUse(what) {
    const prop = `${what}_use`.toLocaleLowerCase();

    const caseInsensitiveKey = Object.keys(this.data).find(
      key => key.toLocaleLowerCase() === prop,
    );

    if (caseInsensitiveKey) {
      return Number.parseInt(this.data[caseInsensitiveKey], 10) !== 0;
    }

    return false;
  }

  get canUseDHX() {
    return this.canUse('DHX');
  }

  get canUseOntex() {
    return this.canUse('Ontex');
  }

  get canUseHartmann() {
    return this.canUse('Hartmann');
  }

  get canUseDASRI() {
    return this.canUse('DASRI');
  }

  get canUseCartPreLocalization() {
    return this.canUse('CartPreLocalization');
  }

  get id() {
    return this.data.kIDUser;
  }

  get email() {
    return this.data.sUserIdentifier || '';
  }

  get name() {
    return {
      first: this.data.sUserFirstName,
      last: this.data.sUserLastName,
    };
  }

  get firstName() {
    return this.name.first;
  }

  get lastName() {
    return this.name.last;
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(v => v).join(' ');
  }

  get civilityID() {
    return Number.parseInt(this.data.kIDCivility, 10);
  }

  get customerID() {
    return this.data.sCustomerCode;
  }

  get customerName() {
    return this.data.sCustomerName;
  }

  get isTTC() {
    return this.data.isTTC;
  }

  // eslint-disable-next-line class-methods-use-this
  get priceMode() {
    return this.isTTC === '0' ? 'HT' : 'TTC';
  }

  get civility() {
    return civilityToHumanReadable(this.data.civilityID);
  }

  get isInternal() {
    const reg = new RegExp(
      `@(${appConfig.get('settings.internalDomains').join('|')})$`,
    );

    return !!this.email.match(reg);
  }

  get roleID() {
    return (
      Number.parseInt(this.data.kIDRole, 10) ||
      Number.parseInt(this.data.isAdmin, 10) ||
      0
    );
  }

  /**
   * 1: Administrator
   * 5: Administrator with DASRI access
   */
  get admin() {
    return [1, 5].includes(this.roleID);
  }

  /**
   * 2: Manager
   * 6: Manager with DASRI access
   */
  get manager() {
    return [2, 6].includes(this.roleID);
  }

  /**
   * 1: Administrator
   * 2: Manager
   * 5: Administrator with DASRI access
   * 6: Manager with DASRI access
   */
  get canPlaceOrders() {
    return [1, 2, 5, 6].includes(this.roleID);
  }

  get token() {
    return this.data.sUserToken;
  }

  get autologinURL() {
    return `${API_BASE_URL()}/?kIDUser|${this.id}&sUserToken|${this.token}`;
  }

  get initialData() {
    return this.data;
  }

  get json() {
    return { initialData: this.initialData };
  }
}

export const createAdaptorUser = data => new User(data);

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUESTED: {
      return { ...state, status: Status.LOADING, error: null };
    }

    case LOGIN_USER_RESOLVED: {
      const { success, message, user } = action.payload;
      const adaptorUser = createAdaptorUser(user);

      if (
        typeof window !== 'undefined' &&
        window?.env?.nodeEnv === 'development'
      ) {
        window.currentUser = adaptorUser;
      }

      if (success) {
        storagePut(Keys.CURRENT_USER, adaptorUser.json);
      }

      return {
        ...state,
        currentUser: adaptorUser,
        status: success ? Status.IDLE : Status.ERROR,
        error: message,
      };
    }

    case LOGIN_USER_REJECTED: {
      const { error } = action.payload;

      return { ...state, status: Status.ERROR, error };
    }

    case LOGOUT_USER: {
      storagePut(Keys.CURRENT_USER, null);

      return {
        ...state,
        currentUser: null,
        status: Status.IDLE,
        error: null,
      };
    }

    case REQUEST_PASSWORD_REQUESTED: {
      const { params } = action;
      const { sUserEmail } = params;

      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.REQUEST_PENDING,
          email: sUserEmail,
          error: null,
        },
      };
    }

    case REQUEST_PASSWORD_RESOLVED: {
      const { xmlst } = action.payload;
      const { sIDEmailLog } = xmlst;

      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.REQUEST_RESOLVED,
          data: action.payload,
          idEmailLog: sIDEmailLog,
          error: null,
        },
      };
    }

    case REQUEST_PASSWORD_REJECTED: {
      const { error } = action.payload;

      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.ERROR,
          error,
        },
      };
    }

    case RESET_PASSWORD_REQUESTED: {
      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.RESET_PENDING,
          error: null,
        },
      };
    }

    case RESET_PASSWORD_RESOLVED: {
      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.RESET_RESOLVED,
          data: action.payload,
          error: null,
        },
      };
    }

    case RESET_PASSWORD_REJECTED: {
      const { error } = action.payload;

      return {
        ...state,
        status: Status.IDLE,
        passwordReset: {
          ...state.passwordReset,
          status: ResetPasswordStatus.ERROR,
          error,
        },
      };
    }

    case GET_USERS_REQUESTED: {
      const { sCustomerCode } = action.payload;

      const customer = sCustomerCode || 'internal';

      return {
        ...state,
        status: Status.IDLE,
        users: {
          ...state.users,
          [customer]: {
            records: [],
            status: Status.LOADING,
          },
        },
      };
    }

    case GET_USERS_RESOLVED: {
      const { data, fullParams } = action.payload;
      const { sCustomerCode } = fullParams;
      const { xmlst } = data;
      const { Message, Records } = xmlst;

      const customer = sCustomerCode || 'internal';

      if (!Records) {
        sendNotification(Message);

        return {
          ...state,
          status: Status.IDLE,
          users: {
            ...state.users,
            [customer]: {
              records: [],
              status: Status.IDLE,
            },
          },
        };
      }

      const { Item } = Records;
      const previousUsers = (state.users[customer] || {}).records || [];

      const newRecords = (isPlainObject(Item) ? [Item] : Item).map(newUser => {
        const previousUser = previousUsers.find(
          u => u.kIDUser === newUser.kIDUser,
        );

        if (previousUser) {
          return { ...previousUser, ...newUser };
        }

        return newUser;
      });

      return {
        ...state,
        status: Status.IDLE,
        users: {
          ...state.users,
          [customer]: {
            records: newRecords,
            status: Status.IDLE,
          },
        },
      };
    }

    case GET_USERS_REJECTED: {
      return { ...state, status: Status.ERROR };
    }

    case GET_SINGLE_USER_REQUESTED: {
      const { sCustomerCode } = action.payload;

      const previousUsers = (state.users[sCustomerCode] || {}).records || [];

      return {
        ...state,
        status: Status.IDLE,
        users: {
          ...state.users,
          [sCustomerCode]: {
            records: previousUsers,
            status: Status.LOADING,
          },
        },
      };
    }

    case GET_SINGLE_USER_RESOLVED: {
      const { data, fullParams } = action.payload;
      const { sCustomerCode } = fullParams;
      const { xmlst } = data;
      const { Records } = xmlst;
      const { Item } = Records;

      const previousUsers = (state.users[sCustomerCode] || {}).records || [];
      const previousUser = previousUsers.find(u => u.kIDUser === Item.kIDUser);

      let newUsers = previousUsers;

      if (previousUser) {
        const newUser = { ...previousUser, ...Item };

        newUsers = without(newUsers, previousUser).push(newUser);
      } else {
        newUsers = previousUsers.push(Item);
      }

      return {
        ...state,
        status: Status.IDLE,
        users: {
          ...state.users,
          [sCustomerCode]: {
            records: [...newUsers],
            status: Status.IDLE,
          },
        },
      };
    }

    case GET_SINGLE_USER_REJECTED: {
      const { Message, fullParams } = action.payload;
      const { sCustomerCode } = fullParams;

      sendNotification(Message, NotificationType.ERROR);

      const previousUsers = (state.users[sCustomerCode] || {}).records || [];

      return {
        ...state,
        status: Status.IDLE,
        users: {
          ...state.users,
          [sCustomerCode]: {
            records: previousUsers,
            status: Status.IDLE,
          },
        },
      };
    }

    case CREATE_USER_REQUESTED: {
      return {
        ...state,
        status: Status.IDLE,
        createStatus: Status.LOADING,
      };
    }

    case CREATE_USER_RESOLVED: {
      const { data } = action.payload;
      const { xmlst } = data;
      const { Message } = xmlst;

      sendNotification(Message);
      publish(PUBSUB_TOPICS.CREATE_USER_SUCCESS);

      return {
        ...state,
        status: Status.IDLE,
        createStatus: Status.IDLE,
      };
    }

    case CREATE_USER_REJECTED: {
      const { error } = action.payload;
      const { originalData } = error;

      sendNotification(originalData.xmlst.Message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.IDLE,
        createStatus: Status.ERROR,
      };
    }

    case UPDATE_USER_REQUESTED: {
      return {
        ...state,
        status: Status.IDLE,
        updateStatus: Status.LOADING,
      };
    }

    case UPDATE_USER_RESOLVED: {
      const { data } = action.payload;
      const { xmlst } = data;
      const { Message } = xmlst;

      sendNotification(Message);
      publish(PUBSUB_TOPICS.UPDATE_USER_SUCCESS);

      return {
        ...state,
        status: Status.IDLE,
        updateStatus: Status.IDLE,
      };
    }

    case UPDATE_USER_REJECTED: {
      const { error } = action.payload;
      const { originalData } = error;

      sendNotification(originalData.xmlst.Message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.IDLE,
        updateStatus: Status.ERROR,
      };
    }

    case DISABLE_USER_REQUESTED: {
      return { ...state, deleteStatus: Status.LOADING, error: null };
    }

    case DISABLE_USER_REJECTED: {
      const { payload } = action;
      const { error } = payload;

      return { ...state, deleteStatus: Status.ERROR, error: error.message };
    }

    case DISABLE_USER_RESOLVED: {
      const { fullParams } = action.payload;
      const { sUserIdentifier } = fullParams;

      publish(PUBSUB_TOPICS.DISABLE_USER_RESOLVED, { ...fullParams });
      publish(PUBSUB_TOPICS.SHOW_GENERIC_CONFIRM, {
        title: 'Utilisateur désactivé',
        message: `L'utilisateur ${sUserIdentifier} a été désactivé avec succès.`,
        skipRejectButton: true,
        acceptButtonLabel: 'OK',
      });

      return { ...state, deleteStatus: Status.IDLE, error: null };
    }

    default:
      return state;
  }
};
