import PropTypes from 'prop-types';
import { parse } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { usePageTitle } from '../../../hooks';
import {
  AISLES_ACTIONS,
  FAMILIES_ACTIONS,
  PRODUCT_ACTIONS,
} from '../../../store/actions';
import { USERS_ACTIONS } from '../../../store/actions/reducer-actions/users';
import { Status } from '../../../store/models/europrisme';
import { Colors, Sizing } from '../../../styles/vars';
import { capitalize } from '../../../utils/misc';
import { connect } from '../../../utils/redux';
import Button from '../../elements/Button';
import ResourceImage from '../../elements/ResourceImage';
import SearchInput from '../../elements/SearchInput';
import Select from '../../elements/Select';
import Paths from '../../routes/paths';

const ResourceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  form {
    margin-bottom: ${Sizing.gutterWidth};
  }
`;

const ResourceListRoot = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  align-items: stretch;
  width: 100%;
  margin-top: 0;
  padding: 0;
  list-style: none;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    grid-column: 1 / span 3;

    opacity: 0.5;
  }
`;

const ResourceListItem = styled.li``;

const SingleResourceLink = styled(Link)`
  display: block;
  position: relative;
  height: 100%;
  padding: 15px;

  text-decoration: none;
  color: ${Colors.white};
  outline: none;
  background-color: ${Colors.white};

  overflow: hidden;

  .image-wrapper {
    display: block;
    position: relative;
    width: 100%;

    background-color: ${Colors.lightGrey};

    &::before {
      content: '';
      display: block;
      position: relative;
      padding-bottom: 75%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  span {
    position: relative;
    display: block;
    margin-top: 20px;
    color: ${Colors.black};

    &.missing {
      opacity: 0.5;
    }
  }
`;

const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${Sizing.gutterWidth};
`;

const SeeMoreButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const TYPE_MAP = {
  aisles: 'Rayon',
  families: 'Famille',
  products: 'Article',
  users: 'Utilisateur',
};

const ResourceList = ({ type, state, location, ...actions }) => {
  usePageTitle(`${TYPE_MAP[type]}s`);

  const history = useHistory();
  const search = parse(location.search.replace(/^\?/, '')).q;

  const [filters, setFilters] = useState({});

  const fetch = actions[`fetch${capitalize(type)}`];
  const fetchSearch = actions[`search${capitalize(type)}`];

  const resourceState = state[type] || {};
  const resources = Object.values(resourceState[type] || {});

  const shouldPaginate = type === 'products';

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;
  const totalPages = Math.round((resourceState.meta || {}).iTotalPage || 2);

  const pagedResources = useMemo(() => {
    if (shouldPaginate) {
      return resources
        .slice(0, (currentPage - 1) * perPage + perPage)
        .sort((a, b) => a.sLibelle.localeCompare(b.sLibelle));
    }

    return resources.sort((a, b) => a.sLibelle.localeCompare(b.sLibelle));
  }, [resources, shouldPaginate]);

  const handleSearch = value => {
    history.push(`${Paths.Admin({ resource: type })}/?q=${value}`);
  };

  const handleFiltersChange = ({
    label,
    'data-key': sSortField,
    'data-order': sSortWay,
  }) => {
    const nextFilter = { label, sSortField, sSortWay };

    setFilters(nextFilter);
    setCurrentPage(1);
    actions.clearProducts();
  };

  useEffect(() => {
    actions.clearProducts();
    setCurrentPage(1);
  }, [search]);

  const fetchResources = (options = {}) => {
    if (search && fetchSearch) {
      fetchSearch({
        sSearch: search,
        iPageSize: perPage,
        iPageNum: currentPage,

        ...filters,
        ...options,
      });
    } else {
      fetch({
        iPageSize: perPage,
        iPageNum: currentPage,

        ...filters,
        ...options,
      });
    }
  };

  useEffect(() => {
    setCurrentPage(1);

    if (type === 'products') {
      fetchResources({ clear: true });
    }
  }, [type]);

  useEffect(() => {
    fetchResources();
  }, [type, currentPage, search, filters]);

  const isLoading = resourceState.status === Status.LOADING;
  const options = [
    {
      value: 'default',
      label: 'défaut',
    },
    {
      value: 'asc',
      label: 'ordre alphabétique croissant',
      'data-key': 'Libelle',
      'data-order': 'ASC',
    },
    {
      value: 'desc',
      label: 'ordre alphabétique décroissant',
      'data-key': 'Libelle',
      'data-order': 'DESC',
    },
  ];

  const searchPlaceholder = () => {
    const entity = {
      products: 'un article',
    }[type];

    return `Rechercher ${entity}`;
  };

  return (
    <ResourceListWrapper>
      {['products'].includes(type) && (
        <FiltersRow>
          <SearchInput
            initialValue={search}
            onSearch={handleSearch}
            placeholder={searchPlaceholder()}
            style={{
              margin: 0,
              flexShrink: 1,
            }}
          />
          <Select options={options} onChange={handleFiltersChange}>
            Résultats triés par
          </Select>
        </FiltersRow>
      )}

      <ResourceListRoot>
        {isLoading ? (
          <span className="loading">Chargement...</span>
        ) : (
          pagedResources.map(({ sCode, sLibelle }) => (
            <ResourceListItem key={sCode}>
              <SingleResourceLink
                to={Paths.Admin({ resource: type, id: sCode })}
              >
                <div className="image-wrapper">
                  <ResourceImage type={TYPE_MAP[type]} code={sCode} />
                </div>
                <span className={sLibelle ? '' : 'missing'}>
                  {sLibelle || 'Libellé manquant'}
                </span>
              </SingleResourceLink>
            </ResourceListItem>
          ))
        )}
      </ResourceListRoot>

      {shouldPaginate && (
        <SeeMoreButtonContainer>
          {currentPage < totalPages && (
            <Button
              background={Colors.primary}
              color={Colors.white}
              disabled={isLoading}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {isLoading ? 'Chargement...' : 'Voir plus'}
            </Button>
          )}
        </SeeMoreButtonContainer>
      )}
    </ResourceListWrapper>
  );
};

ResourceList.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPE_MAP)).isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const ConnectedResourceList = connect(state => ({ state }), {
  ...AISLES_ACTIONS,
  ...FAMILIES_ACTIONS,
  ...PRODUCT_ACTIONS,
  ...USERS_ACTIONS,
})(withRouter(ResourceList));

export default ConnectedResourceList;
