import { createEndpoint, Type } from '../../models/europrisme';
import {
  CANCEL_SEARCH_20_CLIENTS,
  CLEAR_CLIENTS,
  SEARCH_20_CLIENTS_REJECTED,
  SEARCH_20_CLIENTS_REQUESTED,
  SEARCH_20_CLIENTS_RESOLVED,
} from '../types';

export const search20ClientsRequested = fullParams => ({
  type: SEARCH_20_CLIENTS_REQUESTED,
  params: fullParams,
});

export const search20ClientsRejected = (error, fullParams) => ({
  type: SEARCH_20_CLIENTS_REJECTED,
  payload: { error, params: fullParams },
});

export const search20ClientsResolved = (data, fullParams) => ({
  type: SEARCH_20_CLIENTS_RESOLVED,
  payload: { data, params: fullParams },
});

export const cancelSearch20Clients = () => ({
  type: CANCEL_SEARCH_20_CLIENTS,
});

export const search20ClientsEndpoint = createEndpoint(
  Type.GET,
  'searchAndSortClients',
  {
    sClientCode: '',
    sSearch: '',
    sSortField: '',
    sSortWay: 'ASC',
  },
  {
    request: search20ClientsRequested,
    resolve: search20ClientsResolved,
    reject: search20ClientsRejected,
  },
);

export const search20Clients = (...args) => async dispatch => {
  await dispatch(cancelSearch20Clients());

  return dispatch(search20ClientsEndpoint(...args));
};

export const clearClients = () => ({ type: CLEAR_CLIENTS });

export const CLIENT_ACTIONS = {
  search20Clients,
  clearClients,
};
