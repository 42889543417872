import { isArray } from 'lodash';
import { publish } from 'pubsub-js';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import { PUBSUB_TOPICS } from '../../hooks';
import DateFormat, { Formats } from '../../utils/date';
import {
  GET_PREORDERS_REJECTED,
  GET_PREORDERS_REQUESTED,
  GET_PREORDERS_RESOLVED,
  GET_SINGLE_PREORDER_REJECTED,
  GET_SINGLE_PREORDER_REQUESTED,
  GET_SINGLE_PREORDER_RESOLVED,
  PREORDER_DELETE_REJECTED,
  PREORDER_DELETE_REQUESTED,
  PREORDER_DELETE_RESOLVED,
  PREORDER_SEND_CART_REJECTED,
  PREORDER_SEND_CART_REQUESTED,
  PREORDER_SEND_CART_RESOLVED,
} from '../actions/types';
import { Status } from '../models/europrisme';

const initialState = {
  status: Status.IDLE,
  loaded: false,
  message: null,
  comment: '',
  preorderInfo: {},
  preorders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PREORDERS_REQUESTED: {
      return { ...state, loaded: false, status: Status.LOADING };
    }
    case GET_PREORDERS_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        loaded: true,
        status: Status.ERROR,
        message,
      };
    }
    case GET_PREORDERS_RESOLVED: {
      const { data } = action.payload;
      const { xmlst } = data;
      const { Item: recordsPreorders } = xmlst;

      let preordersList = [];
      if (recordsPreorders) {
        if (isArray(recordsPreorders)) {
          preordersList = recordsPreorders;
        } else {
          preordersList = [recordsPreorders];
        }
      }

      const preorders = preordersList.reduce(
        (
          acc,
          { kIDPreOrder, dCreationDate, sPreOrderTitle, sPreOrderComment },
        ) => {
          const date = DateFormat.parse(dCreationDate, Formats.PREORDERS_API);
          const formattedDate = DateFormat.format(date, Formats.DAY_MONTH_YEAR);

          acc[kIDPreOrder] = {
            code: kIDPreOrder,
            date,
            formattedDate,
            title: sPreOrderTitle,
            comment: sPreOrderComment,
            status: Status.IDLE,
          };

          return acc;
        },
        {},
      );

      return {
        ...state,
        loaded: true,
        status: Status.IDLE,
        preorders,
      };
    }
    case GET_SINGLE_PREORDER_REQUESTED: {
      const { params } = action.payload;
      const { sIDPreOrder } = params;

      const previousData = state.preorders[sIDPreOrder];

      if (!previousData) return state;

      const preorder = {
        ...previousData,
        status: Status.LOADING,
      };

      return {
        ...state,
        loaded: false,
        preorders: {
          ...state.preorders,
          [sIDPreOrder]: preorder,
        },
      };
    }
    case GET_SINGLE_PREORDER_REJECTED: {
      const { error, params } = action.payload;
      const { sIDPreOrder } = params;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      const previousData = state.preorders[sIDPreOrder];

      if (!previousData) return state;

      const preorder = {
        ...previousData,
        status: Status.ERROR,
        message,
      };

      return {
        ...state,
        loaded: true,
        preorders: {
          ...state.preorders,
          [sIDPreOrder]: preorder,
        },
      };
    }
    case GET_SINGLE_PREORDER_RESOLVED: {
      const { data, params } = action.payload;
      const { sIDPreOrder } = params;
      const { xmlst } = data;
      const { Success, Message, ...rawData } = xmlst;

      const previousData = state.preorders[sIDPreOrder] || {};

      const { Item, ...recordDataRest } = rawData;
      const recordData = {
        ...recordDataRest,
        records: isArray(Item) ? Item : [Item],
      };

      const preorder = {
        ...previousData,
        ...recordData,
        status: Status.IDLE,
      };

      return {
        ...state,
        loaded: true,
        preorders: {
          ...state.preorders,
          [sIDPreOrder]: preorder,
        },
      };
    }
    case PREORDER_SEND_CART_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }
    case PREORDER_SEND_CART_RESOLVED: {
      const { data } = action.payload;

      publish(PUBSUB_TOPICS.SEND_PREORDER_SUCCESS);

      return {
        ...state,
        status: Status.IDLE,
        preorderInfo: data,
      };
    }
    case PREORDER_SEND_CART_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.ERROR,
        message,
      };
    }
    case PREORDER_DELETE_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }
    case PREORDER_DELETE_RESOLVED: {
      const { params } = action.payload;
      const { sIDPreOrder } = params;

      return {
        ...state,
        preorders: Object.keys(state.preorders)
          .filter(key => key !== sIDPreOrder)
          .reduce((acc, key) => {
            acc[key] = state.preorders[key];

            return acc;
          }, {}),
        status: Status.IDLE,
      };
    }
    case PREORDER_DELETE_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.ERROR,
        message,
      };
    }
    default:
      return state;
  }
};
