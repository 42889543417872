import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IMAGES_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { connect } from '../../utils/redux';

const ResourceImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: white;
`;

const ResourceImage = ({
  category,
  type,
  code,
  style,
  loader,
  images,
  fetchImage,
}) => {
  const image = images[code] || {};

  useEffect(() => {
    if (!image.B64 && !image.status !== Status.IDLE) {
      fetchImage({
        sResourceCategory: category,
        sParentEntityType: type,
        sParentEntityValue: code,
      });
    }
  }, [code]);

  if (image.B64) {
    return (
      <ResourceImageContainer
        src={`data:image/png;base64,${image.B64}`}
        style={style}
      />
    );
  }

  return loader;
};

ResourceImage.propTypes = {
  category: PropTypes.oneOf(['IMG', 'DOC']),
  type: PropTypes.oneOf(['Rayon', 'Famille', 'Article']),
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  loader: PropTypes.node,
  images: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchImage: PropTypes.func.isRequired,
};

ResourceImage.defaultProps = {
  category: 'IMG',
  type: 'Article',
  style: {},
  loader: null,
};

export default connect(state => ({ ...state.images }), { ...IMAGES_ACTIONS })(
  ResourceImage,
);
