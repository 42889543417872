import PropTypes from 'prop-types';
import React from 'react';

import ImageLogoDASRI from '../../../assets/img/dasri_logo.png';
import ImageLogoHartmann from '../../../assets/img/hartmann_logo.png';
import ImageLogoOntex from '../../../assets/img/id_logo.png';
import appConfig from '../../utils/app-config';
import { shouldShowConfigurator } from '../../utils/misc';
import { HeaderButtonExternalConfigurator } from '../sections/Header';
import ExternalConfigurator from './ExternalConfigurator';

export const OntexConfigurator = {
  origin: 'ONTX',
  name: 'ontex',
  title: 'Partenaire iD',
  alt: 'iD Expert',
  icon: ImageLogoOntex,
  isOntex: true,
};

export const HartmannConfigurator = {
  origin: 'HRTM',
  name: 'hartmann',
  title: 'Hartmann',
  alt: 'Hartmann',
  icon: ImageLogoHartmann,
  isHartmann: true,
};

export const DASRIConfigurator = {
  origin: 'DASRI',
  name: 'dasri',
  title: 'DASRI',
  alt: 'DASRI',
  icon: ImageLogoDASRI,
  src: appConfig.get('settings.connections.dasri.baseUrl'),

  isDASRI: true,
  skipVirtualCart: true,
};

export const allConfigurators = {
  ontex: OntexConfigurator,
  hartmann: HartmannConfigurator,
  dasri: DASRIConfigurator,
};

export const ConfiguratorButton = ({
  origin,
  icon,
  alt,
  title,
  component,
  componentProps,
  ...rest
}) => {
  const ButtonComponent = component;

  return (
    <ExternalConfigurator
      fullscreen
      scrolling="yes"
      origin={origin}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <ButtonComponent
        href="#"
        className="with-icon"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...componentProps}
      >
        <img src={icon} alt={alt} />
        <span>{title}</span>
      </ButtonComponent>
    </ExternalConfigurator>
  );
};

ConfiguratorButton.propTypes = {
  origin: PropTypes.oneOf(
    Object.values(allConfigurators).map(config => config.origin),
  ).isRequired,

  icon: PropTypes.oneOf(
    Object.values(allConfigurators).map(config => config.icon),
  ).isRequired,

  alt: PropTypes.oneOf(
    Object.values(allConfigurators).map(config => config.alt),
  ).isRequired,

  title: PropTypes.oneOf(
    Object.values(allConfigurators).map(config => config.title),
  ).isRequired,

  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.objectOf(PropTypes.any),
};

ConfiguratorButton.defaultProps = {
  componentProps: {},
};

const renderAllConfigurators = (
  currentUser,
  component = HeaderButtonExternalConfigurator,
  componentProps = {},
) =>
  currentUser &&
  Object.values(allConfigurators).map(
    ({ origin, name, icon, alt, title, ...rest }) =>
      shouldShowConfigurator(name, currentUser) && (
        <ConfiguratorButton
          key={origin}
          origin={origin}
          icon={icon}
          alt={alt}
          title={title}
          component={component}
          componentProps={componentProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      ),
  );

export default renderAllConfigurators;
