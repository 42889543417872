import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import styled, { css } from 'styled-components';

import TrashIcon from '../../../assets/img/TrashIcon';
import { PRODUCT_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import BlockPlaceholder from './BlockPlaceholder';
import Button from './Button';
import ResourceImage from './ResourceImage';

const OrderSingleRowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  margin-bottom: 20px;
  padding: 10px 20px;

  background-color: ${Colors.white};

  overflow: hidden;

  ${props =>
    props.isMissing &&
    css`
      opacity: 0.75;
    `}

  .inner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .product-image {
      position: relative;
      width: 80px;
      height: 80px;
      margin-right: 20px;

      &.missing {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: ${Colors.lightGrey};

        &::before {
          content: '×';

          color: ${Colors.white};
          font-size: 40px;
          line-height: 0;

          user-select: none;
          pointer-events: none;
        }
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }

    .product-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex-grow: 1;

      .product-name {
        margin: 0;

        font-size: 16px;
        font-weight: 500;
        color: ${Colors.black};
        text-decoration: none;

        &.with-code {
          display: flex;
          flex-direction: column;

          p.notice {
            margin: 0;
            margin-bottom: 5px;

            color: ${Colors.primary};
            font-size: 12px;
          }

          span.code {
            color: ${Colors.darkGrey};
            font-weight: 400;
          }
        }
      }

      .product-quantity {
        margin-left: 5px;

        font-size: 16px;
        font-weight: normal;
        color: ${Colors.darkGrey};
      }

      .below-comment {
        margin-top: 5px;

        font-size: 12px;
        font-weight: normal;

        &.is-invalid {
          color: ${Colors.darkYellow};
        }
      }
    }

    &:hover,
    &:focus-within {
      button.delete-button {
        opacity: 1;
        transform: scale(1);

        pointer-events: initial;
      }
    }
  }
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 0;
  right: -6px;
  min-width: unset !important;
  padding: 3px 6px !important;

  font-size: 12px;

  cursor: pointer;
  pointer-events: none;
`;

const OrderSingleRow = ({
  productID,
  quantity,
  products,
  label,
  belowComment,
  fetchSingleProduct,

  deletable,
  onDelete,
}) => {
  const product = products[productID];

  const isDummy =
    belowComment &&
    belowComment.match(/^r[ée]f[ée]rence\s*fabricant\s*inconnue\s*:?\s*/i);

  useEffect(() => {
    if (!product) {
      fetchSingleProduct({ sArticleCode: productID, defaultName: label });
    }
  }, [productID]);

  if (!product || product.status === Status.LOADING) {
    return <BlockPlaceholder height={100} style={{ marginBottom: '20px' }} />;
  }

  if (product.status === Status.ERROR) {
    return (
      <OrderSingleRowWrapper isMissing>
        <div className="inner">
          <div className="product-image missing" />

          <div className="product-info">
            <div className="product-name with-code">
              <p className="notice">Article non-référencé</p>

              {label && <span className="name">{label}</span>}

              {productID && !`${productID}`.match(/^__INVALID\//) && (
                <span className="code">
                  Code article&nbsp;:&nbsp;{productID}
                </span>
              )}

              {/* TODO: Check this, this is showing an invalid reference while the comment can be anything */}
              {belowComment && (
                <span
                  className="below-comment"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(belowComment),
                  }}
                />
              )}
            </div>

            <span className="product-quantity">Quantité : {quantity}</span>
          </div>

          {deletable && (
            <DeleteButton
              className="delete-button"
              background="white"
              color={Colors.red}
              hoverBackground={Colors.red}
              hoverColor={Colors.white}
              onClick={onDelete}
            >
              <TrashIcon size={14} />
              <span>Supprimer</span>
            </DeleteButton>
          )}
        </div>
      </OrderSingleRowWrapper>
    );
  }

  return (
    <OrderSingleRowWrapper>
      <div className="inner">
        <div className="product-image">
          <ResourceImage
            code={productID}
            loader={
              <BlockPlaceholder height="100%" style={{ width: '100%' }} />
            }
          />
        </div>

        <div className="product-info">
          {isDummy ? (
            <div className="product-name with-code">
              <span className="name">{product.sLibelle}</span>
              <span className="code">Code article&nbsp;:&nbsp;{productID}</span>

              <span
                className="below-comment is-invalid"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(belowComment) }}
              />
            </div>
          ) : (
            <Link
              to={Paths.SingleProduct({ productID })}
              className="product-name with-code"
            >
              <span className="name">{product.sLibelle}</span>
              <span className="code">Code article&nbsp;:&nbsp;{productID}</span>

              {belowComment && (
                <span
                  className="below-comment"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(belowComment),
                  }}
                />
              )}
            </Link>
          )}

          <span className="product-quantity">Quantité : {quantity}</span>
        </div>

        {deletable && (
          <DeleteButton
            className="delete-button"
            background="white"
            color={Colors.red}
            hoverBackground={Colors.red}
            hoverColor={Colors.white}
            onClick={onDelete}
          >
            <TrashIcon size={14} />
            <span>Supprimer</span>
          </DeleteButton>
        )}
      </div>
    </OrderSingleRowWrapper>
  );
};

OrderSingleRow.propTypes = {
  productID: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.string,
  belowComment: PropTypes.string,

  products: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleProduct: PropTypes.func.isRequired,

  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
};

OrderSingleRow.defaultProps = {
  label: null,
  belowComment: null,
  deletable: false,
  onDelete: () => {},
};

export default connect(state => ({ ...state.products }), {
  ...PRODUCT_ACTIONS,
})(OrderSingleRow);
