import { isNull } from 'lodash';

export const Keys = {
  CART: 'vitae/cart',
  CURRENT_USER: 'vitae/current-user',
};

export const storagePut = (key, data) => {
  if (localStorage) {
    if (key === Keys.CURRENT_USER && !isNull(data)) {
      localStorage.setItem(
        key,
        JSON.stringify({ ...data, expires: Date.now() + 3 * 60 * 60 * 1000 }),
      );
    } else {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }
};

export const storageGet = (key, defaultValue = {}) => {
  if (!localStorage) throw new Error('localStorage not supported');

  try {
    const content = localStorage.getItem(key);

    if (content) {
      return JSON.parse(localStorage.getItem(key));
    }

    return defaultValue;
  } catch {
    return defaultValue;
  }
};
