import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';
import Breadcrumbs from './Breadcrumbs';

const SidebarContainer = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  height: 100%;
  padding: 0;
  padding-bottom: 30px;
  list-style: none;
  flex-grow: 0;
  flex-shrink: 1;

  grid-column-start: 1;
  grid-column-end: span 3;

  background-color: ${props => (props.isAdmin ? Colors.darkGrey : '#ffffff')};

  color: ${props => (props.isAdmin ? '#ffffff' : Colors.black)};

  z-index: 300;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    background-color: inherit;
    width: 100vw;
  }

  h3 {
    position: relative;
    width: calc(100% - ${Sizing.gutterWidth});
    margin-top: 130px;
    margin-bottom: 50px;

    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    color: ${props => (props.isAdmin ? Colors.white : Colors.primary)};

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      top: calc(100% + 35px);
      left: 0;
      background-color: ${props =>
        props.isAdmin ? Colors.white : Colors.black};
    }
  }

  ${props =>
    props.isAdmin &&
    css`
      &::before {
        top: -${Sizing.headerHeight};
        right: 0;
        height: calc(100% + ${Sizing.headerHeight});
      }

      h3 {
        margin-top: 30px;
      }
    `};

  ${props =>
    !props.isMobile &&
    css`
      @media all and (max-width: ${Sizing.mobileWidth}) {
        display: none;
      }
    `}

  ${props =>
    props.isMobile &&
    css`
      @media all and (min-width: ${Sizing.mobileWidth}) {
        display: none;
      }

      grid-column: 1 / span 12;
      min-height: unset;
      padding-bottom: 0;

      &::before {
        width: 100vh;
        left: -16px;

        z-index: -1;
      }

      h3 {
        margin-top: 16px;
        margin-bottom: 16px;
        white-space: nowrap;

        &::after {
          content: unset;
        }
      }

      [class*='DetailsContentWrapper'] {
        [class*='DetailsContent'] {
          align-items: center;

          [class*='SidebarEntryContainer'] {
            padding: 0;

            & + [class*='SidebarEntryContainer'] {
              margin-top: 12px;
            }

            &:last-child {
              margin-bottom: 20px;
            }

            [class*='SidebarEntryLink'] {
              min-height: unset;
              margin-left: 0;
              padding: 0;

              color: ${Colors.black};

              &::before {
                content: unset;
              }

              [class*='SidebarEntryPanel'] {
                display: none !important;
              }
            }
          }
        }
      }
    `}
`;

const Sidebar = ({ children, isAdmin, isMobile }) => {
  const classes = classNames({
    mobile: isMobile,
  });

  return (
    <SidebarContainer
      className={classes}
      isAdmin={isAdmin ? 1 : 0}
      isMobile={isMobile ? 1 : 0}
    >
      {!isAdmin && !isMobile && <Breadcrumbs />}

      {children}
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Sidebar.defaultProps = {
  isAdmin: false,
  isMobile: false,
};

export default Sidebar;
