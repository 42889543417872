import { isNull, isUndefined } from 'lodash';

export const createPath = basePath => (params = null) => {
  if (!params) return basePath;

  let intermediatePath = basePath;

  Object.entries(params).forEach(([param, value]) => {
    if (!isNull(value) && !isUndefined(value)) {
      const re = new RegExp(
        `(.*)(:${param})(\\([^)]*\\))??(\\?)??(?=\\/|$)(.*)?`,
      );

      intermediatePath = intermediatePath.replace(re, `$1${value}$5`);
    }
  });

  return intermediatePath.replace(/\/:[^/?]*?\?/g, '');
};

const Paths = {
  Login: createPath('/connexion'),
  RequestPassword: createPath('/request-password'),
  ResetPassword: createPath('/reset-password'),

  Home: createPath('/'),

  Contact: createPath('/contact'),

  Shop: createPath('/boutique'),
  SingleProduct: createPath('/boutique/articles/:productID'),
  SingleFamily: createPath('/boutique/:aisleID/:familyID/:page?'),
  SingleAisle: createPath('/boutique/:aisleID'),

  Cart: createPath('/panier'),
  OrderConfirmation: createPath('/commande/confirmation'),
  OrdersList: createPath('/commandes/:orderID?'),
  PendingOrdersList: createPath('/intentions-de-commande/:orderID?'),
  PreordersList: createPath('/precommandes/:preorderID?'),

  AdminIndex: createPath('/admin'),
  Admin: createPath('/admin/:resource/:id?/:userID?'),

  NotFound: createPath('/404'),
};

export default Paths;
