import PreloadSearchData from '../../../../workers/preload-search-data.worker';
import { idbPutSearchEngineRecords } from '../../models/idb';
import {
  SEARCH_DATA_LOAD_REJECTED,
  SEARCH_DATA_LOAD_REQUESTED,
  SEARCH_DATA_LOAD_RESOLVED,
} from '../types';

export const loadSearchDataRequested = () => ({
  type: SEARCH_DATA_LOAD_REQUESTED,
});

export const loadSearchDataResolved = ({ entries }) => ({
  type: SEARCH_DATA_LOAD_RESOLVED,
  payload: { entries },
});

export const loadSearchDataRejected = ({ error }) => ({
  type: SEARCH_DATA_LOAD_REJECTED,
  payload: { error },
});

export const loadSearchData = ({ port = 6174, baseURL }) => async (
  dispatch,
  getState,
) => {
  if (process.env.BUILD_TARGET === 'client') {
    const { users } = getState();
    const { currentUser } = users || {};
    const { customerID } = currentUser || {};

    if (currentUser) {
      return new Promise((resolve, reject) => {
        const worker = new PreloadSearchData();

        worker.onmessage = ({ data }) => {
          if (data.type === 'PRELOAD_SEARCH_DATA_RESOLVED') {
            if (data.success) {
              const records = data.value?.SearchEngine;

              idbPutSearchEngineRecords(records);
              dispatch(loadSearchDataResolved({ entries: records }));

              return resolve(data.value);
            }

            dispatch(loadSearchDataRejected({ error: data.error }));
            return reject(data.error);
          }
        };

        dispatch(loadSearchDataRequested());
        worker.postMessage({
          type: 'PRELOAD_SEARCH_DATA',
          port,
          baseURL,
          customerID,
          location: JSON.stringify({ ...window.location }),
        });
      });
    }
  }
};

// ======= Actions

export const SEARCH_DATA_ACTIONS = { loadSearchData };
