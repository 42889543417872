import PropTypes from 'prop-types';
import { publish } from 'pubsub-js';
import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import CancelIcon from '../../../assets/img/CancelIcon';
import { PUBSUB_TOPICS, useSubscriber, useUUID } from '../../hooks';
import { USERS_ACTIONS } from '../../store/actions/reducer-actions/users';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Button from './Button';
import Confirm from './Confirm';

const DisableUserDialog = ({ clientID, userID, users, disableUser }) => {
  const uuid = useUUID();

  const clientUsers = (users && users[clientID]) || { records: [] };
  const user = clientUsers.records.find(({ kIDUser }) => kIDUser === userID);

  useSubscriber(
    PUBSUB_TOPICS.DISABLE_USER_DIALOG_ACCEPTED,
    (_, { uuid: otherUUID }) => {
      if (otherUUID === uuid) {
        disableUser({
          userID,
          customerID: clientID,
        });
      }
    },
    [userID, uuid],
  );

  const onDialogConfirm = useCallback(() => {
    publish(PUBSUB_TOPICS.DISABLE_USER_DIALOG_ACCEPTED, { uuid });
  }, [uuid]);

  if (!user) {
    return null;
  }

  const { sUserFirstName: firstName, sUserLastName: lastName } = user;

  const confirmDisable = Confirm({
    title: `Désactiver l'utilisateur «\xa0${[firstName, lastName]
      .filter(n => n)
      .join(' ')}\xa0»`,

    message: 'La désactivation est définitive.',

    rejectButtonLabel: 'Annuler',

    acceptButtonLabel: (
      <>
        <CancelIcon />
        <span>Désactiver</span>
      </>
    ),
    acceptButtonProps: {
      hoverColor: Colors.pureWhite,
      hoverBackground: Colors.red,
    },

    onConfirm: onDialogConfirm,
  });

  return (
    <>
      <Button
        color={Colors.pureWhite}
        background={Colors.red}
        hoverBackground={Colors.darkRed}
        onClick={confirmDisable}
      >
        <span>Désactiver l&apos;utilisateur</span>
      </Button>
    </>
  );
};

DisableUserDialog.propTypes = {
  clientID: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  users: PropTypes.objectOf(PropTypes.any).isRequired,

  disableUser: PropTypes.func.isRequired,
};

const ConnectedDisableUserDialog = connect(state => ({ ...state.users }), {
  ...USERS_ACTIONS,
})(withRouter(DisableUserDialog));

export default ConnectedDisableUserDialog;
