import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';

import ArrowLeftIcon from '../../../assets/img/ArrowLeftIcon';
import { usePageTitle } from '../../hooks';
import { ORDER_ACTIONS } from '../../store/actions/reducer-actions/order';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import {
  OrderDetailsPanel,
  OrderDetailsPanelTrack,
  OrderListButton,
  OrderListElement,
  OrdersListGrid,
  OrdersListWapper,
} from '../elements/AccountLists';
import BlockPlaceholder from '../elements/BlockPlaceholder';
import Button from '../elements/Button';
import OrderDetails from '../elements/OrderDetails';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const PlaceholderData = Array(12)
  .fill()
  .map((_, index) => index);

const OrdersList = ({ status, orders, getOrders, match }) => {
  usePageTitle('Mes commandes');

  const history = useHistory();

  const { params } = match;
  const { orderID: selected } = params;

  useEffect(() => {
    getOrders();
  }, []);

  const toggleSelection = code => {
    if (selected === code) {
      history.push(Paths.OrdersList({ orderID: '' }));
    } else {
      history.push(Paths.OrdersList({ orderID: code }));
    }
  };

  const renderList = () => {
    if (status === Status.LOADING) {
      return PlaceholderData.map(key => (
        <BlockPlaceholder key={key} height={55} />
      ));
    }

    const sortedOrders = [...Object.values(orders)].sort((left, right) => {
      if (left.date.isBefore(right.date)) return 1;
      if (left.date.isAfter(right.date)) return -1;

      return `${right?.code}`.localeCompare(`${left?.code}`);
    });

    const getColorForOrderStatus = orderStatus =>
      ({
        Livré: Colors.lightBlue,
        Terminé: Colors.lightGreen,
        'Facturé partiellement': Colors.lightYellow,
      }[orderStatus]);

    return sortedOrders.map(({ code, formattedDate, orderStatus }) => (
      <OrderListElement
        key={code}
        selected={selected === code}
        hasSelection={selected}
        className="order-block"
      >
        <OrderListButton
          onClick={() => toggleSelection(code)}
          selected={selected === code}
          hasSelection={selected}
        >
          <header>
            <h3>Dossier n°{code}</h3>
            <h4>{formattedDate}</h4>

            {orderStatus && (
              <div
                className="pill"
                style={{ backgroundColor: getColorForOrderStatus(orderStatus) }}
              >
                {orderStatus}
              </div>
            )}
          </header>
        </OrderListButton>
      </OrderListElement>
    ));
  };

  return (
    <OrdersListWapper>
      <div className="title-row">
        <PageTitle>Mes commandes</PageTitle>

        <Button
          fromComponent={Link}
          to={Paths.PendingOrdersList}
          background={Colors.pureWhite}
          style={{ padding: '10px 16px', fontSize: '14px' }}
        >
          <span>Intentions de commande en attente de validation</span>

          <ArrowLeftIcon
            direction="right"
            style={{
              marginTop: '1px',
              marginLeft: '6px',
              marginRight: '0px',
            }}
          />
        </Button>
      </div>

      <OrdersListGrid>{renderList()}</OrdersListGrid>
      <OrderDetailsPanelTrack>
        <OrderDetailsPanel selected={selected}>
          <div className="contents">
            {selected && <OrderDetails code={selected} />}
          </div>
        </OrderDetailsPanel>
      </OrderDetailsPanelTrack>
    </OrdersListWapper>
  );
};

OrdersList.propTypes = {
  status: PropTypes.oneOf(Object.values(Status)).isRequired,
  orders: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  getOrders: PropTypes.func.isRequired,

  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

OrdersList.defaultProps = {
  orders: [],
};

export const ConnectedOrdersList = connect(state => ({ ...state.order }), {
  ...ORDER_ACTIONS,
})(withRouter(OrdersList));

export default {
  path: Paths.OrdersList(),
  name: 'Mes commandes',
  component: ConnectedOrdersList,
};
