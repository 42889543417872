import styled, { css } from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';

export const OrdersListWapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column: 1 / span 12;
  gap: 0 ${Sizing.gutterWidth};
  margin-bottom: ${Sizing.gutterWidth};

  @media all and (max-width: ${Sizing.mobileWidth}) {
    gap: 0 16px;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column: 1 / span 12;
  }
`;

export const OrdersListGrid = styled.div`
  display: grid;
  grid-column: 1 / span 4;
  grid-template-columns: 1fr;
  gap: 20px 0;

  height: -webkit-fill-available;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: intrinsic;
  height: max-content;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
    gap: 8px 0;
  }

  &:hover {
    .order-block {
      opacity: 1;
    }
  }
`;

export const OrderListElement = styled.div`
  position: relative;
  display: block;
  padding: 0;

  .delete-button {
    z-index: 20;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &:hover,
  &:focus {
    .delete-button {
      transform: scale(1);
      opacity: 1;

      pointer-events: initial;
    }
  }

  ${props =>
    props.selected &&
    css`
      position: sticky;
      top: ${Sizing.gutterWidth};
      bottom: ${Sizing.gutterWidth};

      z-index: 10;

      .delete-button {
        transform: scale(1);
        opacity: 1;

        pointer-events: initial;
      }
    `}
`;

export const OrderListButton = styled.a`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  position: relative;
  display: block;
  padding: 0;
  width: 100%;

  background: white;
  color: ${Colors.black};
  border: none;
  outline: none;

  opacity: 1;

  transition-property: opacity, color, background-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  cursor: pointer;
  z-index: 1;

  &:hover,
  &:focus {
    color: ${Colors.black};
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left;

    background-color: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    transition-property: width, border-radius;
    transition-duration: 0.3s;
    transition-delay: 0.4s;
    transition-timing-function: ease-out;

    pointer-events: none;
    z-index: -1;
  }

  ${props =>
    props.hasSelection &&
    !props.selected &&
    css`
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }
    `}

  ${props =>
    props.selected &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.primary};
      opacity: 1 !important;

      &:hover,
      &:focus {
        color: ${Colors.white};
      }

      &::before {
        width: calc(100% + 65px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        transition-delay: 0s;
        transition-timing-function: ease-in;
      }
    `}

  header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px;

    font-size: 14px;

    h3,
    h4 {
      margin: 0;
      color: inherit;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
    }

    h4 {
      font-size: 12px;
      font-weight: normal;

      opacity: 0.8;
    }

    .pill {
      position: absolute;
      top: 50%;
      right: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      transform: translateY(-50%);

      color: ${Colors.black};
      background-color: ${Colors.white};
      border-radius: 20px;

      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;

      pointer-events: none;
    }
  }
`;

export const OrderDetailsPanelTrack = styled.div`
  grid-column-end: span 8;
  position: relative;
  display: block;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);

    pointer-events: none;
    z-index: 90;
  }
`;

export const OrderDetailsPanel = styled.div`
  position: sticky;
  top: ${Sizing.gutterWidth};
  display: block;
  width: 100%;
  height: calc(100vh - ${Sizing.gutterWidth} * 2);

  @media all and (max-width: ${Sizing.mobileWidth}) {
    height: 100%;

    pointer-events: none;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    transform-origin: left;
    transform: scaleX(0);

    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;

    pointer-events: none;
    z-index: -1;
  }

  .contents {
    display: block;
    height: 100%;

    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;

    pointer-events: none;
  }

  ${props =>
    props.selected &&
    css`
      &::before {
        transform: scaleX(1);

        transition-property: transform;
        transition-duration: 0.4s;
        transition-delay: 0.3s;
        transition-timing-function: ease-in;
      }

      .contents {
        opacity: 1;
        pointer-events: initial;

        transition-delay: 0.8s;
      }
    `}
`;
