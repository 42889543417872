/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,

    global.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(applyMiddleware(thunk), global.__REDUX_DEVTOOLS_EXTENSION__())
      : applyMiddleware(thunk),
  );

  if (module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      const nextRootReducer = require('./reducers/rootReducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
