import 'antd/dist/antd.css';
import './app.css';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import Confirm from '../components/elements/Confirm';
import NotificationArea from '../components/elements/NotificationArea';
import { withGlobalLayout } from '../components/layouts/GlobalLayout';
import { getRoutes } from '../components/routes';
import StaticPage from '../components/routes/StaticPage';
import ErrorBoundary from '../components/utils/ErrorBoundary';
import { PUBSUB_TOPICS, useSubscriber } from '../hooks';
import {
  FAMILIES_ACTIONS,
  LOCATION_ACTIONS,
  PRODUCT_ACTIONS,
} from '../store/actions';
import { SEARCH_DATA_ACTIONS } from '../store/actions/reducer-actions/search-data';
import { USERS_ACTIONS } from '../store/actions/reducer-actions/users';
import { CORS_PROXY } from '../store/models/europrisme';
import { Colors } from '../styles/vars';
import { API_BASE_URL } from '../utils/api-base';
import { getEnv } from '../utils/global';
import { connect } from '../utils/redux';

const GlobalStyle = memo(createGlobalStyle`
  *::selection {
    background-color: ${Colors.primary} !important;
    color: ${Colors.white} !important;
  }

  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: ${hexToRgba(Colors.primary, 0.2)};
  }
`);

const PreprodMessage = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;

  font-size: 12px;
  text-transform: uppercase;
  color: ${Colors.darkYellow};
  background-color: ${Colors.lightYellow};

  transition: opacity 0.4s;

  cursor: default;
  z-index: 9999999;

  &:hover {
    opacity: 0.25;
  }

  span {
    display: inline-block;

    &.main {
      grid-column: 2 / span 1;
    }

    &.version {
      grid-column: 3 / span 1;
      justify-self: flex-end;

      text-transform: none;

      code {
        font-family: 'Courier New', Courier, monospace;
        font-size: 10px;
      }
    }
  }
`;

const App = ({
  currentUser,
  loginUser,
  loadSearchData,
  fetchFamiliesByCustomer,
  fetchProductsByCustomer,
  getLocations,
  staticPages,
}) => {
  useEffect(() => {
    document.body.classList.add('react-app--loaded');
    loginUser(null, null, true);
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const baseApiUrl = API_BASE_URL();
      const baseUrl =
        getEnv('vitaeUseProxy', 'VITAE_USE_PROXY') === '1'
          ? `${CORS_PROXY()}/${baseApiUrl}`
          : baseApiUrl;

      loadSearchData({
        port: getEnv('corsProxyPort', 'RAZZLE_CORS_PORT') || 6174,
        baseURL: baseUrl,
      });

      fetchFamiliesByCustomer({
        port: getEnv('corsProxyPort', 'RAZZLE_CORS_PORT') || 6174,
        baseURL: baseUrl,
      });

      fetchProductsByCustomer({
        port: getEnv('corsProxyPort', 'RAZZLE_CORS_PORT') || 6174,
        baseURL: baseUrl,
      });

      getLocations();
    }
  }, [currentUser?.id]);

  const shouldShowPreprodMessage =
    getEnv('vitaeEnv', 'VITAE_ENV') === 'preprod';

  useSubscriber(PUBSUB_TOPICS.SHOW_GENERIC_CONFIRM, (_, confirmOptions) => {
    const genericConfirm = Confirm({ ...confirmOptions });

    genericConfirm();
  });

  return (
    <>
      <GlobalStyle />
      <NotificationArea />

      {shouldShowPreprodMessage && (
        <PreprodMessage>
          <span className="main">Site en pré-production</span>
          <span className="version">
            Version <code>{compileTime.env.vitaeVersionNumber}</code>
          </span>
        </PreprodMessage>
      )}

      <ErrorBoundary>
        <Switch>
          {staticPages.map(page => {
            const { attributes, body } = page;
            const { path } = attributes;

            return (
              <Route
                key={path}
                path={`/${path}`}
                exact
                render={withGlobalLayout(() => (
                  <StaticPage attributes={attributes} body={body} />
                ))}
              />
            );
          })}

          {getRoutes(currentUser).map(({ path, exact, component }) => {
            return (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={withGlobalLayout(component)}
              />
            );
          })}
        </Switch>
      </ErrorBoundary>
    </>
  );
};

App.propTypes = {
  currentUser: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    v => v === null,
  ]),

  loginUser: PropTypes.func.isRequired,
  loadSearchData: PropTypes.func.isRequired,
  fetchFamiliesByCustomer: PropTypes.func.isRequired,
  fetchProductsByCustomer: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,

  staticPages: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }).isRequired,
      body: PropTypes.string,
    }),
  ),
};

App.defaultProps = {
  currentUser: null,
  staticPages: [],
};

export default connect(state => ({ ...state.users }), {
  ...USERS_ACTIONS,
  ...SEARCH_DATA_ACTIONS,
  ...FAMILIES_ACTIONS,
  ...PRODUCT_ACTIONS,
  ...LOCATION_ACTIONS,
})(App);
