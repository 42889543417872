export const Sizing = {
  wrapperWidth: '1440px',
  gutterWidth: '40px',
  headerHeight: '80px',
  mobileHeaderHeight: '60px',
  mobileWidth: '900px',

  numeric: {
    wrapperWidth: 1440,
    gutterWidth: 40,
    headerHeight: 80,
  },

  borderRadius: {
    buttons: '0px',
  },
};

export const Colors = {
  primary: '#153F8E',
  accent: '#C8D400',
  black: '#1B1B1B',
  darkGrey: '#454545',
  lightGrey: '#7A7A7A',
  lighterGrey: '#D1D1D1',
  white: '#E9EDF1',
  pureWhite: '#FFFFFF',

  red: '#D21414',
  lightRed: '#F29797',
  darkRed: '#7A1631',

  green: '#36a348',
  lightGreen: '#E9FABB',
  dimmedLightGreen: '#cee396',
  darkGreen: '#35620E',

  lightYellow: '#FFE19B',
  darkYellow: '#7A3200',

  blue: '#48BED9',
  lightBlue: '#BAE6F1',
};
