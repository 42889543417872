import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { usePageTitle } from '../../../hooks';
import {
  AISLES_ACTIONS,
  FAMILIES_ACTIONS,
  PRODUCT_ACTIONS,
} from '../../../store/actions';
import { Sizing } from '../../../styles/vars';
import renderFields, { RESOURCE_FIELDS } from '../../../utils/admin-resources';
import { capitalize } from '../../../utils/misc';
import { connect } from '../../../utils/redux';

const SINGULAR_MAP = {
  aisles: 'aisle',
  families: 'family',
  products: 'product',
};

const ID_PARAM_MAP = {
  aisles: 'sRayonCode',
  families: 'sFamilleCode',
  products: 'sArticleCode',
};

const ResourceFieldsContainer = styled.div`
  .row {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: ${Sizing.gutterWidth};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }

  .resource-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    grid-column: 1 / span 5;
  }

  .resource-title--secondary {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  .resource-price {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    grid-column: 6 / span 2;
    gap: 0;

    .resource-price--value,
    .resource-price--currency {
      font-size: 30px;
      line-height: 36px;
      font-weight: bold;
    }

    .resource-price--ht {
      margin-left: 10px;
      font-size: 8px;
      line-height: 9px;
    }
  }

  .resource-info {
    grid-column: 1 / span 5;
  }

  .resource-stock {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-column: 6 / span 2;
  }

  .resource-parents {
    .resource-parents--aisle {
      grid-column: 1 / span 3;
    }

    .resource-parents--family {
      grid-column: 4 / span 3;
    }
  }

  .resource-upload--image {
    grid-column: 1 / span 3;
  }

  .resource-upload--subtitle {
    font-size: 10px;
    line-height: 14px;
    font-weight: 300;
  }

  .resource-uploader {
    margin-top: 15px;
  }

  .resource-stock {
    display: flex;
    justify-content: flex-end;
  }
`;

const SingleResource = ({ type, id, state, ...actions }) => {
  const fetch = actions[`fetchSingle${capitalize(SINGULAR_MAP[type])}`];

  const resourceState = state[type] || {};
  const resources = resourceState[type] || {};
  const resource = resources[id] || {
    sLibelle: capitalize(SINGULAR_MAP[type]),
  };

  usePageTitle(resource.sLibelle);

  useEffect(() => {
    fetch({ [ID_PARAM_MAP[type]]: id });
  }, [type, id]);

  const fields = RESOURCE_FIELDS[type];

  if (!resource || !fields) return null;

  return (
    <ResourceFieldsContainer>
      {renderFields(resource, fields)}
    </ResourceFieldsContainer>
  );
};

SingleResource.propTypes = {
  type: PropTypes.oneOf(['aisles', 'families', 'products']).isRequired,
  id: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const ConnectedSingleResource = connect(state => ({ state }), {
  ...AISLES_ACTIONS,
  ...FAMILIES_ACTIONS,
  ...PRODUCT_ACTIONS,
})(SingleResource);

export default ConnectedSingleResource;
