import PropTypes from 'prop-types';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { usePageTitle } from '../../hooks';
import { FAMILIES_ACTIONS, PRODUCT_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { connect } from '../../utils/redux';
import ShopLayout from '../layouts/ShopLayout';
import Paths from './paths';

const Shop = ({
  usersState,
  familiesState,
  productsState,
  fetchFamilies,
  fetchDiscountProducts,
  searchProducts,
  clearProducts,

  location,
}) => {
  usePageTitle('Boutique');

  const { currentUser } = usersState;
  const customerCode = (currentUser || { customerID: '' }).customerID;

  const history = useHistory();
  const search = parse(location.search.replace(/^\?/, '')).q;
  const isLiveProductsList = !!search;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const perPage = 15;

  const products = isLiveProductsList
    ? Object.values(productsState.products || {}).filter(
        product => product.status !== Status.ERROR,
      )
    : Object.values(productsState.discounts || {});

  const pagedProducts = isLiveProductsList
    ? products.slice(0, perPage * currentPage)
    : products;

  const pagedSortedProducts = filters.sSortWay
    ? pagedProducts.sort((a, b) =>
        filters.sSortWay === 'ASC'
          ? a.sLibelle.localeCompare(b.sLibelle)
          : b.sLibelle.localeCompare(a.sLibelle),
      )
    : pagedProducts;

  const totalPages = isLiveProductsList
    ? Math.ceil(products.length / perPage)
    : 1;

  const handleFilter = ({ sSortField, sSortWay }) => {
    setFilters({ sSortField, sSortWay });
    setCurrentPage(1);
    clearProducts();
  };

  const handleSearch = value => {
    history.push(`${Paths.Shop()}/?q=${value || ''}`);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    if (search) {
      searchProducts({
        sSearch: search,
        iPageSize: `${perPage}`,
        iPageNum: currentPage,
        ...filters,

        clear: currentPage === 1,
      });
    } else {
      fetchDiscountProducts({ sCustomerCode: customerCode });
    }
  }, [customerCode, currentPage, search, filters]);

  useEffect(() => {
    if (Object.entries(familiesState.families).length === 0) fetchFamilies();
  }, []);

  return (
    <ShopLayout
      products={pagedSortedProducts}
      productsState={productsState}
      totalPages={totalPages}
      totalProducts={products?.length}
      perPage={perPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      search={search}
      handleSearch={handleSearch}
      handleFilter={handleFilter}
      isStatic={!isLiveProductsList}
    />
  );
};

Shop.propTypes = {
  usersState: PropTypes.objectOf(PropTypes.any).isRequired,
  familiesState: PropTypes.objectOf(PropTypes.any).isRequired,
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,

  fetchFamilies: PropTypes.func.isRequired,
  fetchDiscountProducts: PropTypes.func.isRequired,
  searchProducts: PropTypes.func.isRequired,
  clearProducts: PropTypes.func.isRequired,

  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const ConnectedShop = connect(
  state => ({
    usersState: state.users,
    familiesState: state.families,
    productsState: state.products,
  }),
  { ...FAMILIES_ACTIONS, ...PRODUCT_ACTIONS },
)(withRouter(Shop));

export default {
  path: Paths.Shop(),
  exact: true,
  name: 'Boutique',
  component: ConnectedShop,
  getDataFromState: () => ({
    name: 'Boutique',
    path: Paths.Shop(),
  }),
};
