import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import { CART_ACTIONS } from '../../store/actions';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Confirm from './Confirm';

const ConfirmEmptyCart = ({ clearCart }) => {
  const [open, setOpen] = useState(false);

  useSubscriber(PUBSUB_TOPICS.NOTIFY_CART_NOT_EMPTY, () => {
    setOpen(true);
  });

  const openPopup = Confirm({
    title: 'Reprise du panier',
    message: 'Vous avez des articles dans votre panier, voulez-vous le vider ?',
    onConfirm: clearCart,

    rejectButtonLabel: 'Annuler',
    rejectButtonProps: {
      color: Colors.black,
      background: Colors.pureWhite,
      hoverColor: Colors.pureWhite,
      hoverBackground: Colors.accent,
    },

    acceptButtonLabel: 'Vider le panier',
    acceptButtonProps: {
      color: Colors.pureWhite,
      background: Colors.primary,
      hoverColor: Colors.pureWhite,
      hoverBackground: Colors.accent,
    },
  });

  useEffect(() => {
    if (open) {
      openPopup();
    }
  }, [open]);

  return <></>;
};

ConfirmEmptyCart.propTypes = {
  clearCart: PropTypes.func.isRequired,
};

const ConnectedConfirmEmptyCart = connect(state => ({ ...state.cart }), {
  ...CART_ACTIONS,
})(ConfirmEmptyCart);

export default ConnectedConfirmEmptyCart;
