import PropTypes from 'prop-types';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import UpArrowIcon from '../../../../../assets/img/UpArrowIcon';
import { usePageTitle } from '../../../../hooks';
import { CLIENT_ACTIONS } from '../../../../store/actions/reducer-actions/clients';
import { Status } from '../../../../store/models/europrisme';
import { storageGet, storagePut } from '../../../../store/models/localstorage';
import { Colors, Sizing } from '../../../../styles/vars';
import { connect } from '../../../../utils/redux';
import Button from '../../../elements/Button';
import SearchInput from '../../../elements/SearchInput';
import Select from '../../../elements/Select';
import Paths from '../../../routes/paths';

const ResourceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  form {
    margin-bottom: ${Sizing.gutterWidth};
  }
`;

const ResourceListRoot = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  align-items: stretch;
  width: 100%;
  margin-top: 0;
  padding: 0;
  list-style: none;
`;

const CreateInternalContainer = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1 / span 3;
  margin-top: 20px;

  .buttons {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: center;

    > * {
      white-space: nowrap;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

const CreateInternalSeparator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    width: 40%;
    height: 1px;

    background-color: ${Colors.white};
    opacity: 0.25;

    pointer-events: none;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const ResourceListItem = styled.li`
  grid-column: 1 / span 3;
`;

const SingleResourceLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 15px;
  padding-left: 30px;

  text-decoration: none;
  color: ${Colors.white};
  outline: none;
  background-color: ${Colors.white};

  overflow: hidden;

  .image-wrapper {
    display: block;
    position: relative;
    width: 100%;

    background-color: ${Colors.lightGrey};

    &::before {
      content: '';
      display: block;
      position: relative;
      padding-bottom: 75%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .details {
    color: ${Colors.black};

    h2 {
      position: relative;
      display: block;
      margin: 0;

      font-size: 18px;
      line-height: 24px;
      font-weight: 450;

      &.missing {
        opacity: 0.5;
      }
    }

    .content {
      margin-top: 10px;

      font-size: 14px;
      color: ${Colors.lightGrey};

      .description {
        margin-top: 10px;

        p {
          margin: 0;
        }
      }
    }
  }

  .arrow {
    display: flex;
    flex-direction: row;
    align-items: center;

    transition: transform 0.2s ease;

    span {
      color: ${Colors.darkGrey};
      font-size: 14px;
    }
  }

  &:hover {
    .arrow {
      transform: translateX(5px);
    }
  }
`;

const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${Sizing.gutterWidth};
`;

const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  grid-column: 1 / span 3;

  opacity: 0.5;
`;

const ClientsIndex = ({
  clientsState,
  usersState,

  search20Clients,
  location,
}) => {
  const { clients, status } = clientsState;
  const { currentUser } = usersState;

  usePageTitle('Utilisateurs');

  const history = useHistory();
  const search = parse(location.search.replace(/^\?/, '')).q || '';

  const [query, setQuery] = useState(search);
  const [filters, setFilters] = useState({});
  const resources = clients || [];

  useEffect(() => {
    const previousSearch = storageGet('vitae/last-client-search');

    if (previousSearch) {
      history.push(`/admin/users/?q=${previousSearch}`);
      setQuery(previousSearch);
    }
  }, []);

  useEffect(() => {
    // Current user is not allowed to view the global list
    if (currentUser && !currentUser.isInternal) {
      history.push(
        Paths.Admin({ resource: 'users', id: currentUser.customerID }),
      );
    }
  }, [currentUser]);

  const handleSearch = value => {
    storagePut('vitae/last-client-search', value);
    history.push(`/admin/users/?q=${value}`);
    setQuery(value);
  };

  const handleFiltersChange = ({
    label,
    'data-key': sSortField,
    'data-order': sSortWay,
  }) => {
    const nextFilter = { label, sSortField, sSortWay };

    setFilters(nextFilter);
  };

  const fetchResources = (options = {}) => {
    if (search20Clients) {
      search20Clients({
        sClientCode: '',
        sSearch: query,

        ...filters,
        ...options,
      });
    }
  };

  useEffect(() => {
    fetchResources();
  }, [query, filters]);

  const isLoading = status === Status.LOADING;
  const options = [
    {
      value: 'default',
      label: 'défaut',
    },
    {
      value: 'asc',
      label: 'ordre alphabétique croissant',
      'data-key': 'Libelle',
      'data-order': 'ASC',
    },
    {
      value: 'desc',
      label: 'ordre alphabétique décroissant',
      'data-key': 'Libelle',
      'data-order': 'DESC',
    },
  ];

  const renderList = () => {
    if (isLoading) {
      return (
        <MessageRow>
          <span>Chargement...</span>
        </MessageRow>
      );
    }

    if (!resources || !resources.length) {
      return (
        <MessageRow>
          <span>
            {search
              ? 'Aucun résultat à afficher'
              : 'Aucune recherche à afficher'}
          </span>
        </MessageRow>
      );
    }

    return resources.map(
      ({
        sCode,
        sLibelleTitre,
        sNom,
        sPrenom,
        sCodeEtablissement,
        sSIRET,
        sCommentaire,
      }) => {
        const libelle = (sLibelleTitre || '').trim();
        const firstname = (sPrenom || '').trim();
        const lastname = (sNom || '').trim();
        const fullname = [libelle, firstname, lastname].join(' ');
        const hasName = firstname || lastname;

        const hasContent = sCodeEtablissement || sSIRET || sCommentaire;

        return (
          <ResourceListItem key={sCode}>
            <SingleResourceLink
              to={Paths.Admin({ resource: 'users', id: sCode })}
            >
              <div className="details">
                <h2 className={hasName ? '' : 'missing'}>
                  {hasName ? fullname : 'Nom manquant'}
                </h2>

                {hasContent && (
                  <div className="content">
                    {sCodeEtablissement && (
                      <div className="code">
                        Code établissement&nbsp;:&nbsp;
                        {sCodeEtablissement}
                      </div>
                    )}

                    {sSIRET && (
                      <div className="siret">SIRET&nbsp;:&nbsp;{sSIRET}</div>
                    )}

                    <div className="description">
                      {(sCommentaire || '').split('<br/>').map((line, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={`description-line--${i}`}>{line}</p>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="arrow">
                <span>Gestion des utilisateurs</span>
                <UpArrowIcon angle={90} includeCircle={false} />
              </div>
            </SingleResourceLink>
          </ResourceListItem>
        );
      },
    );
  };

  const renderContent = () => {
    if (currentUser.isInternal) {
      return (
        <>
          <CreateInternalContainer>
            <div className="buttons">
              <Button
                fromComponent={Link}
                to={Paths.Admin({
                  resource: 'users',
                  id: 'internal',
                })}
              >
                Administrer les utilisateurs internes
              </Button>

              <Button
                fromComponent={Link}
                to={Paths.Admin({
                  resource: 'users',
                  id: 'internal',
                  userID: 'create',
                })}
              >
                Créer un utilisateur interne
              </Button>
            </div>
            <CreateInternalSeparator>OU</CreateInternalSeparator>
          </CreateInternalContainer>
          {renderList()}
        </>
      );
    }

    return renderList();
  };

  return (
    <ResourceListWrapper>
      <FiltersRow>
        <SearchInput
          initialValue={query}
          onSearch={handleSearch}
          placeholder="Rechercher un client"
          style={{
            margin: 0,
            flexShrink: 1,
          }}
        />

        <Select
          options={options}
          defaultValue={options[0]}
          onChange={handleFiltersChange}
        >
          Résultats triés par
        </Select>
      </FiltersRow>
      <ResourceListRoot>{renderContent()}</ResourceListRoot>
    </ResourceListWrapper>
  );
};

ClientsIndex.propTypes = {
  clientsState: PropTypes.objectOf(PropTypes.any).isRequired,
  usersState: PropTypes.objectOf(PropTypes.any).isRequired,

  location: PropTypes.objectOf(PropTypes.any).isRequired,

  search20Clients: PropTypes.func.isRequired,
};

ClientsIndex.defaultProps = {};

export const ConnectedClientsIndex = connect(
  state => ({ clientsState: state.clients, usersState: state.users }),
  { ...CLIENT_ACTIONS },
)(withRouter(ClientsIndex));

export default ConnectedClientsIndex;
