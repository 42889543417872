import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import MagnifyIcon from '../../../assets/img/MagnifyIcon';
import { idbQuerySearchEngine } from '../../store/models/idb';
import { Colors } from '../../styles/vars';
import Paths from '../routes/paths';
import Button from './Button';
import Input from './Input';

const SIContainer = styled.form`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;

  width: 460px;

  z-index: 100;

  &:not(:focus-within) .search-input--suggestions {
    padding: 0 !important;
    padding-top: 20px !important;

    opacity: 0 !important;
    box-shadow: 0 0 0 transparent !important;
  }
`;

const SISubmit = styled(Button)`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  aspect-ratio: 1;
  min-width: unset;
  padding: 0;

  background-color: ${Colors.darkGrey};
  border: none;
  border-radius: 2px;
  outline: none;

  cursor: pointer;
  z-index: 20;

  .loader {
    --rotation: 0deg;

    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);

    border-radius: 6px;
    opacity: ${props => (props.loading ? 1 : 0)};

    transition-property: opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;

    overflow: hidden;
    pointer-events: none;
    z-index: -1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      transform: translate3d(-50%, -50%, 0);

      background: conic-gradient(
        ${Colors.primary} calc(0turn + var(--rotation)),
        ${Colors.primary} calc(0.25turn + var(--rotation)),
        ${Colors.accent} calc(0.25turn + var(--rotation)),
        ${Colors.accent} calc(0.5turn + var(--rotation)),
        ${Colors.blue} calc(0.5turn + var(--rotation)),
        ${Colors.blue} calc(1turn + var(--rotation))
      );

      animation-name: AnimationSearchInputSpinner;
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes AnimationSearchInputSpinner {
        from {
          transform: translate3d(-50%, -50%, 0) rotate(0turn);
        }

        to {
          transform: translate3d(-50%, -50%, 0) rotate(1turn);
        }
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: inherit;
    border-radius: inherit;

    pointer-events: none;
    z-index: -1;
  }

  svg {
    margin-right: 0;
  }
`;

const SISuggestions = styled.ul`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 20px;

  list-style: none;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  opacity: 0;
  box-shadow: 0 0 0 transparent;
  transition: padding 0.4s ease-in-out, opacity 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;

  ${props =>
    props.hasResults &&
    css`
      padding: 6px 0;
      padding-top: 26px;

      opacity: 1;
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    `}

  pointer-events: none;
  overflow: hidden;
  z-index: 10;
`;

const SISuggestion = styled.li`
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 6px 30px;

    background-color: transparent;
    transition: background-color 0.4s ease-in-out;

    color: currentColor;
    text-decoration: none;

    pointer-events: initial;

    .codes {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-left: 16px;

      span.code {
        display: inline-block;

        font-size: 12px;
        opacity: 0.5;

        &.article {
          margin-bottom: 2px;
          font-size: 10px;
        }
      }
    }

    &:hover {
      background-color: ${Colors.white};
    }
  }

  ${props =>
    props.selected &&
    css`
      a {
        background-color: ${Colors.white};
      }
    `}
`;

const SearchInput = ({
  initialValue,
  onChange,
  onSearch,
  style,
  placeholder,
  suggest,
}) => {
  const history = useHistory();

  const [value, setValue] = useState(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [suggestions, setSuggestions] = useState([]);
  const querySuggestions = useRef();

  useEffect(() => {
    if (suggest && !querySuggestions.current) {
      querySuggestions.current = debounce(async v => {
        const results = await idbQuerySearchEngine(v);

        setLoading(false);
        setSuggestions(results.slice(0, 10));
      }, 200);
    }
  }, []);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = newValue => {
    setValue(newValue);
    setSelectedIndex(-1);

    if (suggest) {
      if (newValue.length > 0 && querySuggestions.current) {
        setLoading(true);
        querySuggestions.current(newValue);
      } else if (suggestions.length > 0) {
        setSuggestions([]);
      }
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault();
        return setSelectedIndex(Math.max(selectedIndex - 1, 0));
      }
      case 'ArrowDown': {
        event.preventDefault();
        return setSelectedIndex(
          Math.min(selectedIndex + 1, suggestions.length - 1),
        );
      }
      case 'Enter': {
        if (selectedIndex >= 0 && selectedIndex <= suggestions.length - 1) {
          event.preventDefault();

          return history.push(
            Paths.SingleProduct({
              productID: suggestions[selectedIndex]?.sG5ArticleCode,
            }),
          );
        }

        break;
      }
      default:
        break;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (
      document.activeElement &&
      typeof document.activeElement.blur === 'function'
    ) {
      document.activeElement.blur();
    }

    if (onSearch) {
      onSearch(value);
    }
  };

  return (
    <SIContainer style={style} onSubmit={handleSubmit}>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ zIndex: 20 }}
      />

      <SISubmit type="submit" loading={isLoading ? 1 : 0}>
        <span className="loader" />
        <MagnifyIcon />
      </SISubmit>

      <SISuggestions
        className="search-input--suggestions"
        hasResults={suggestions.length > 0}
      >
        {suggestions.map(
          (
            { sG5ArticleLibelle, sG5ArticleCode, sG5ArticleManufCode },
            index,
          ) => (
            <SISuggestion
              key={sG5ArticleCode}
              selected={index === selectedIndex}
            >
              <Link to={Paths.SingleProduct({ productID: sG5ArticleCode })}>
                <span className="name">{sG5ArticleLibelle}</span>
                <div className="codes">
                  <span className="code article">{sG5ArticleCode}</span>
                  <span className="code manufacturer">
                    {sG5ArticleManufCode}
                  </span>
                </div>
              </Link>
            </SISuggestion>
          ),
        )}
      </SISuggestions>
    </SIContainer>
  );
};

SearchInput.propTypes = {
  initialValue: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  suggest: PropTypes.bool,
};

SearchInput.defaultProps = {
  initialValue: '',
  style: {},
  onChange: null,
  onSearch: null,
  placeholder: 'Recherche',
  suggest: false,
};

export default SearchInput;
