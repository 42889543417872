import { Colors } from '../../../styles/vars';

export const selectTheme = theme => ({
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: Colors.primary,
    primary75: 'rgba(232, 94, 133, 0.4)',
    primary50: 'rgba(232, 94, 133, 0.2)',
    primary25: 'rgba(232, 94, 133, 0.1)',
  },
});

export const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: state.isFocused ? 950 : 'unset',
  }),

  control: provided => ({
    ...provided,
    background: Colors.primary,
    border: 'none',
    boxShadow: 'none',
  }),

  placeholder: provided => ({ ...provided, color: Colors.white }),
  singleValue: provided => ({ ...provided, color: Colors.pureWhite }),

  indicatorSeparator: () => ({ display: 'none' }),

  dropdownIndicator: provided => ({ ...provided, color: Colors.white }),

  menuList: provided => ({
    ...provided,
    padding: 0,
    background: Colors.primary,
  }),

  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? Colors.white : Colors.black,
  }),
};

export const civilityOptions = [
  { value: '1', label: 'Monsieur' },
  { value: '2', label: 'Madame' },
  { value: '3', label: 'Mademoiselle' },
  { value: '4', label: 'Maître' },
  { value: '5', label: 'Docteur' },
];

export const roleOptions = currentUser => {
  if (currentUser?.canUseDASRI) {
    return [
      { value: '0', label: 'Utilisateur' },
      { value: '4', label: 'Utilisateur avec accès DASRI' },
      { value: '2', label: 'Gestionnaire' },
      { value: '6', label: 'Gestionnaire avec accès DASRI' },
      { value: '1', label: 'Administrateur' },
      { value: '5', label: 'Administrateur avec accès DASRI' },
    ];
  }

  return [
    { value: '0', label: 'Utilisateur' },
    { value: '2', label: 'Gestionnaire' },
    { value: '1', label: 'Administrateur' },
  ];
};
