import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';
import Chevron from './Chevron';
import Details from './Details';

const DropdownWrapper = styled.div`
  position: relative;

  color: ${props => (props.theme === 'dark' ? Colors.white : Colors.black)};

  z-index: 100;
`;

const DropdownScrim = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;

  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;

  pointer-events: ${props => (props.isOpen ? 'initial' : 'none')};
  z-index: -1;
`;

const DropdownTitle = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  display: flex;
  align-items: center;
  padding: 8px;

  font-size: inherit;
  background: none;
  border: none;
  outline: none;
  color: currentColor;

  cursor: pointer;
  z-index: 100;

  span,
  i {
    color: currentColor;
  }

  i {
    margin-left: 8px;
  }
`;

const DropdownContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  top: 100%;
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%);

  color: ${Colors.black};
  background-color: ${Colors.pureWhite};
  border-radius: 6px;
  white-space: nowrap;

  /* Details */
  > section {
    /* Details content wrapper */
    > div {
      border-radius: 6px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.035),
        0px 8px 16px rgba(0, 0, 0, 0.07);

      /* Details content */
      > div {
        padding: 8px 0;

        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
        border-radius: 6px;
      }
    }
  }
`;

const Dropdown = ({ title, theme, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownWrapper isOpen={isOpen} theme={theme}>
      <DropdownScrim isOpen={isOpen} onClick={() => setIsOpen(false)} />

      <DropdownTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {title && <span>{title}</span>}
        <Chevron direction={isOpen ? 'up' : 'down'} />
      </DropdownTitle>

      <DropdownContentWrapper>
        <Details open={isOpen} onContentClick={() => setIsOpen(false)}>
          {children}
        </Details>
      </DropdownContentWrapper>
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'regular']),
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  title: null,
  theme: 'regular',
};

export default Dropdown;
