import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

import { Colors } from '../../styles/vars';
import CustomScrollbar from './CustomScrollbar';

const SelectLabelContainer = styled.label`
  position: relative;
  white-space: nowrap;

  ${props =>
    props.expand &&
    css`
      width: 100%;
    `}

  span.select__label-container--children {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-right: 1ch;
  }

  > *:last-child {
    color: ${Colors.black};

    [class$='-menu'] {
      right: 0;
      width: unset;
      min-width: 100%;
    }
  }
`;

const SelectMenuListContainer = styled.div``;
const SelectMenuListInner = styled.div``;
const SelectMenuListFloating = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;
`;

const SelectMenuListResizer = ({ setWidth, children }) => {
  const innerRef = useRef();

  useEffect(() => {
    if (innerRef?.current) {
      const { width } = innerRef.current.getBoundingClientRect();

      setWidth(width);
    }
  }, [children]);

  return (
    <SelectMenuListContainer>
      <SelectMenuListInner>{children}</SelectMenuListInner>
      <SelectMenuListFloating ref={innerRef}>{children}</SelectMenuListFloating>
    </SelectMenuListContainer>
  );
};

SelectMenuListResizer.propTypes = {
  setWidth: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export const SelectMenuList = ({ children }) => {
  const [width, setWidth] = useState(0);

  return (
    <CustomScrollbar width={width}>
      <SelectMenuListResizer setWidth={setWidth}>
        {children}
      </SelectMenuListResizer>
    </CustomScrollbar>
  );
};

SelectMenuList.propTypes = {
  children: PropTypes.node.isRequired,
};

const Select = ({
  options,
  defaultValue,
  placeholder,
  isSearchable,
  noOptionsMessage,
  onChange,
  children,
  expand,
  theme: parentTheme,
  styles,
  components,

  ...rest
}) => {
  const customTheme = theme => {
    const baseTheme = {
      ...theme,
      colors: {
        ...theme.colors,
        primary: Colors.primary,
        primary75: hexToRgba(Colors.primary, 0.4),
        primary50: hexToRgba(Colors.primary, 0.2),
        primary25: hexToRgba(Colors.primary, 0.1),
      },
    };

    return { ...baseTheme, ...parentTheme(baseTheme) };
  };

  return (
    <SelectLabelContainer expand={expand}>
      {children && (
        <span className="select__label-container--children">{children}</span>
      )}

      <ReactSelect
        components={{ ...components, MenuList: SelectMenuList }}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        styles={styles}
        theme={customTheme}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </SelectLabelContainer>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,

  defaultValue: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  ]),
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  expand: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onChange: PropTypes.func,

  children: PropTypes.node,
  theme: PropTypes.func,
  styles: PropTypes.shape({
    clearIndicator: PropTypes.func,
    container: PropTypes.func,
    control: PropTypes.func,
    dropdownIndicator: PropTypes.func,
    group: PropTypes.func,
    groupHeading: PropTypes.func,
    indicatorsContainer: PropTypes.func,
    indicatorSeparator: PropTypes.func,
    input: PropTypes.func,
    loadingIndicator: PropTypes.func,
    loadingMessage: PropTypes.func,
    menu: PropTypes.func,
    menuList: PropTypes.func,
    menuPortal: PropTypes.func,
    multiValue: PropTypes.func,
    multiValueLabel: PropTypes.func,
    multiValueRemove: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    option: PropTypes.func,
    placeholder: PropTypes.func,
    singleValue: PropTypes.func,
    valueContainer: PropTypes.func,
  }),

  components: PropTypes.shape({
    ClearIndicator: PropTypes.elementType,
    Control: PropTypes.elementType,
    DropdownIndicator: PropTypes.elementType,
    DownChevron: PropTypes.elementType,
    CrossIcon: PropTypes.elementType,
    Group: PropTypes.elementType,
    GroupHeading: PropTypes.elementType,
    IndicatorsContainer: PropTypes.elementType,
    IndicatorSeparator: PropTypes.elementType,
    Input: PropTypes.elementType,
    LoadingIndicator: PropTypes.elementType,
    Menu: PropTypes.elementType,
    MenuList: PropTypes.elementType,
    MenuPortal: PropTypes.elementType,
    LoadingMessage: PropTypes.elementType,
    NoOptionsMessage: PropTypes.elementType,
    MultiValue: PropTypes.elementType,
    MultiValueContainer: PropTypes.elementType,
    MultiValueLabel: PropTypes.elementType,
    MultiValueRemove: PropTypes.elementType,
    Option: PropTypes.elementType,
    Placeholder: PropTypes.elementType,
    SelectContainer: PropTypes.elementType,
    SingleValue: PropTypes.elementType,
    ValueContainer: PropTypes.elementType,
  }),
};

Select.defaultProps = {
  defaultValue: null,
  placeholder: null,
  onChange: null,
  noOptionsMessage: () => 'Aucune option',
  isSearchable: false,

  children: null,
  expand: false,
  theme: () => ({}),
  styles: {},
  components: {},
};

export default Select;
