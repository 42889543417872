import PropTypes from 'prop-types';
import React from 'react';

const getRotation = direction =>
  ({ left: 0, right: 180, up: 90, down: -90 }[direction]);

const ArrowLeftIcon = ({ size, direction, style }) => {
  const angle = getRotation(direction);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-arrow-left"
      style={{ ...style, transform: `rotate(${angle}deg)` }}
    >
      <line x1="19" y1="12" x2="5" y2="12" />
      <polyline points="12 19 5 12 12 5" />
    </svg>
  );
};

ArrowLeftIcon.propTypes = {
  size: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  style: PropTypes.objectOf(PropTypes.any),
};

ArrowLeftIcon.defaultProps = {
  size: 12,
  direction: 'left',
  style: {},
};

export default ArrowLeftIcon;
