import {
  NotificationType,
  sendNotification,
} from '../../../components/elements/NotificationArea';
import { createEndpoint, Type } from '../../models/europrisme';
import { Keys, storageGet, storagePut } from '../../models/localstorage';
import { createAdaptorUser } from '../../reducers/users';
import {
  CREATE_USER_REJECTED,
  CREATE_USER_REQUESTED,
  CREATE_USER_RESOLVED,
  DISABLE_USER_REJECTED,
  DISABLE_USER_REQUESTED,
  DISABLE_USER_RESOLVED,
  GET_SINGLE_USER_REJECTED,
  GET_SINGLE_USER_REQUESTED,
  GET_SINGLE_USER_RESOLVED,
  GET_USERS_REJECTED,
  GET_USERS_REQUESTED,
  GET_USERS_RESOLVED,
  LOGIN_USER_REJECTED,
  LOGIN_USER_REQUESTED,
  LOGIN_USER_RESOLVED,
  LOGOUT_USER,
  REQUEST_PASSWORD_REJECTED,
  REQUEST_PASSWORD_REQUESTED,
  REQUEST_PASSWORD_RESOLVED,
  RESET_PASSWORD_REJECTED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_RESOLVED,
  UPDATE_USER_REJECTED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_RESOLVED,
} from '../types';

// ===== Login/Logout user

export const loginUserRequested = (email, password) => ({
  type: LOGIN_USER_REQUESTED,
  payload: { email, password },
});

export const loginUserResolved = ({ isStorage, xmlst }) => {
  const { Message, Success, Records } = xmlst;
  const user = Records ? Records.Item : null;

  const isSuccess = Success.toLowerCase() === 'true';

  if (!isStorage && isSuccess) {
    sendNotification(
      `Connecté avec succès. Bienvenue ${user.sUserFirstName} ${user.sUserLastName}.`,
      NotificationType.SUCCESS,
    );
  }

  return {
    type: LOGIN_USER_RESOLVED,
    payload: { message: Message, success: isSuccess, user },
  };
};

export const loginUserRejected = error => {
  if (error.originalData) {
    sendNotification(error.originalData.xmlst.Message, NotificationType.ERROR);
  } else {
    sendNotification('Une erreur est survenue...', NotificationType.ERROR);
  }

  return {
    type: LOGIN_USER_REJECTED,
    payload: { error },
  };
};

export const logoutUser = (message = 'Vous êtes maintenant déconnecté.') => {
  storagePut(Keys.CURRENT_USER, null);
  sendNotification(message, NotificationType.SUCCESS);

  return { type: LOGOUT_USER };
};

export const getUserConnection = createEndpoint(
  Type.GET,
  'getConnexion',
  {
    sUserIdentifier: '',
    sUserPassword: '',
  },
  {
    request: loginUserRequested,
    resolve: loginUserResolved,
    reject: loginUserRejected,
  },
  ({ xmlst }) => xmlst.Success && xmlst.Success.toLowerCase() === 'true',
);

export const loginUser = (
  email,
  password,
  onlyRestore = false,
) => async dispatch => {
  try {
    const loggedUser = storageGet(Keys.CURRENT_USER, null);

    if (loggedUser) {
      const { initialData, expires } = loggedUser;

      if (expires && Date.now() > expires) {
        return dispatch(
          logoutUser('Session expirée. Veuillez vous connecter à nouveau.'),
        );
      }

      return dispatch(
        loginUserResolved({
          isStorage: true,
          xmlst: {
            Message: 'Restored from storage',
            Success: 'TRUE',
            Records: {
              Item: initialData,
            },
          },
        }),
      );
    }
  } catch (error) {
    console.warn(`Error while restoring current user: ${error}`);
  }

  if (!onlyRestore) {
    dispatch(
      getUserConnection({ sUserIdentifier: email, sUserPassword: password }),
    );
  }
};

// ===== Password recovery

export const requestPasswordResetRequested = fullParams => ({
  type: REQUEST_PASSWORD_REQUESTED,
  params: fullParams,
});

export const requestPasswordResetRejected = error => ({
  type: REQUEST_PASSWORD_REJECTED,
  payload: { error },
});

export const requestPasswordResetResolved = data => ({
  type: REQUEST_PASSWORD_RESOLVED,
  payload: data,
});

export const requestPasswordReset = createEndpoint(
  Type.GET,
  'sendPassword',
  { sUserEmail: '', sSubject: '', iMode: 2 },
  {
    request: requestPasswordResetRequested,
    resolve: requestPasswordResetResolved,
    reject: requestPasswordResetRejected,
  },
  data =>
    data && data.xmlst && data.xmlst.Success && data.xmlst.Success === 'TRUE',
);

export const resetPasswordRequested = fullParams => ({
  type: RESET_PASSWORD_REQUESTED,
  payload: fullParams,
});

export const resetPasswordRejected = error => ({
  type: RESET_PASSWORD_REJECTED,
  payload: { error },
});

export const resetPasswordResolved = data => ({
  type: RESET_PASSWORD_RESOLVED,
  payload: data,
});

export const resetPassword = createEndpoint(
  Type.GET,
  'resetPassword',
  {
    sUserIdentifier: '',
    sEMailLogKey: '',
    kIDEMailLog: '',
    sUserPassword: '',
  },
  {
    request: resetPasswordRequested,
    resolve: resetPasswordResolved,
    reject: resetPasswordRejected,
  },
  data =>
    data && data.xmlst && data.xmlst.Success && data.xmlst.Success === 'TRUE',
);

// ===== Fetch users

export const fetchUsersRequested = payload => ({
  type: GET_USERS_REQUESTED,
  payload,
});

export const fetchUsersRejected = (error, fullParams) => ({
  type: GET_USERS_REJECTED,
  payload: { error, fullParams },
});

export const fetchUsersResolved = (data, fullParams) => ({
  type: GET_USERS_RESOLVED,
  payload: { data, fullParams },
});

export const fetchUsers = createEndpoint(
  Type.GET,
  'getUsersByCustomer',
  {
    sCustomerCode: 0,
  },
  {
    request: fetchUsersRequested,
    resolve: fetchUsersResolved,
    reject: fetchUsersRejected,
  },
);

// ===== Fetch single user

export const fetchSingleUserRequested = payload => ({
  type: GET_SINGLE_USER_REQUESTED,
  payload,
});

export const fetchSingleUserRejected = (error, fullParams) => ({
  type: GET_SINGLE_USER_REJECTED,
  payload: { error, fullParams },
});

export const fetchSingleUserResolved = (data, fullParams) => ({
  type: GET_SINGLE_USER_RESOLVED,
  payload: { data, fullParams },
});

export const fetchSingleUser = createEndpoint(
  Type.GET,
  'getUser',
  {
    sCustomerCode: 0,
    kIDUser: 0,
  },
  {
    request: fetchSingleUserRequested,
    resolve: fetchSingleUserResolved,
    reject: fetchSingleUserRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success, Records } = xmlst;

      return Success === 'TRUE' && Records;
    } catch (error) {
      return false;
    }
  },
);

// ===== Create user

export const createUserRequested = payload => ({
  type: CREATE_USER_REQUESTED,
  payload,
});

export const createUserRejected = (error, fullParams) => ({
  type: CREATE_USER_REJECTED,
  payload: { error, fullParams },
});

export const createUserResolved = (data, fullParams) => ({
  type: CREATE_USER_RESOLVED,
  payload: { data, fullParams },
});

export const createUser = createEndpoint(
  Type.POST,
  'addUser',
  {
    sUserIdentifier: '',
    sUserPassword: '',
    sCustomerCode: '',
    sUserFirstName: '',
    sUserLastName: '',
    kIDCivility: 0,
    kIDRole: 0,
    iIDSignature: 0,
  },
  {
    request: createUserRequested,
    resolve: createUserResolved,
    reject: createUserRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

// ===== Update user

export const updateUserRequested = payload => ({
  type: UPDATE_USER_REQUESTED,
  payload,
});

export const updateUserRejected = (error, fullParams) => ({
  type: UPDATE_USER_REJECTED,
  payload: { error, fullParams },
});

export const updateUserResolved = (data, fullParams) => ({
  type: UPDATE_USER_RESOLVED,
  payload: { data, fullParams },
});

export const updateUser = createEndpoint(
  Type.POST,
  'updateUser',
  {
    kIDUser: '',
    sUserIdentifier: '',
    sCustomerCode: '',
    sUserFirstName: '',
    sUserLastName: '',
    kIDCivility: 0,
    kIDRole: 0,
    sUserPassword: '',
    iIDSignature: 0,
  },
  {
    request: updateUserRequested,
    resolve: updateUserResolved,
    reject: updateUserRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

// ===== Disable user

export const disableUserRequested = payload => ({
  type: DISABLE_USER_REQUESTED,
  payload,
});

export const disableUserRejected = (error, fullParams) => ({
  type: DISABLE_USER_REJECTED,
  payload: { error, fullParams },
});

export const disableUserResolved = (data, fullParams) => ({
  type: DISABLE_USER_RESOLVED,
  payload: { data, fullParams },
});

export const disableUserEndpoint = createEndpoint(
  Type.POST,
  'disableUser',
  {
    kIDUser: '',
    sUserIdentifier: '',
    sCustomerCode: '',
    iIDSignature: 0,
  },
  {
    request: disableUserRequested,
    resolve: disableUserResolved,
    reject: disableUserRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const disableUser = ({ userID, customerID }) => async (
  dispatch,
  getState,
) => {
  const { users: usersState } = getState();
  const { [customerID]: customerUsers } = usersState?.users;
  const targetUser = (customerUsers?.records || []).find(
    ({ kIDUser }) => kIDUser === userID,
  );

  if (targetUser) {
    const user = createAdaptorUser(targetUser);

    return dispatch(
      disableUserEndpoint({
        kIDUser: userID,
        sUserIdentifier: user.email,
        sCustomerCode: user.customerID,
      }),
    );
  }
};

// ===== ACTIONS

export const USERS_ACTIONS = {
  loginUser,
  logoutUser,
  requestPasswordReset,
  resetPassword,
  fetchUsers,
  fetchSingleUser,
  createUser,
  updateUser,
  disableUser,
};
