import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BaseSVG = styled.svg`
  path {
    transition-property: fill;
    transition-duration: 0.25s;
  }
`;

const getColor = ({ color, opacity = 1, defaultColor }) => {
  if (color) {
    return { fill: color, style: { opacity } };
  }

  return { fill: defaultColor };
};

const Logo = ({ height, color }) => (
  <BaseSVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 426 146"
    height={height}
    fill="none"
  >
    <path
      d="M15.07 88.74c-.02-.56-.04-1.12-.04-1.69 0-24.07 19.51-43.58 43.58-43.58 24.07 0 43.58 19.51 43.58 43.58 0 .57-.02 1.13-.04 1.69h15.03c.01-.56.04-1.12.04-1.69 0-32.37-26.24-58.61-58.61-58.61C26.24 28.44 0 54.68 0 87.05c0 .57.03 1.13.04 1.69h15.03Z"
      {...getColor({ color, opacity: 0.35, defaultColor: '#C8D400' })}
    />

    <path
      d="M58.61 145.65c31.69-.04 57.62-25.24 58.57-56.91h-15.03c-.92 24.04-21.15 42.79-45.19 41.88-22.76-.87-41.01-19.12-41.88-41.88H.05c.95 31.67 26.87 56.87 58.56 56.91Z"
      {...getColor({ color, opacity: 0.7, defaultColor: '#48BED9' })}
    />

    <path
      d="M58.61 43.47V28.44C26.26 28.48.04 54.69 0 87.04c0 .57.03 1.13.04 1.69h15.03c-.02-.56-.04-1.12-.04-1.69.03-24.06 19.52-43.55 43.58-43.57"
      {...getColor({ color, opacity: 1, defaultColor: '#153F8E' })}
    />

    <path
      d="M136.08 18.36H102.7v15.02h18.35v18.36h15.03V18.36Z"
      {...getColor({ color, opacity: 0.35, defaultColor: '#C8D400' })}
    />

    <path
      d="M121.04 0v18.36h15.05v15.03h18.35V18.36h-18.36V0h-15.04Z"
      {...getColor({ color, opacity: 0.7, defaultColor: '#48BED9' })}
    />

    <path
      d="M136.09 18.36h-15.05v15.03h15.05V18.36Zm31.39 91.35-9.47-23.22-1.98 23.71h-10.96l4.02-43.33h10.71l11.21 27.36 11.27-27.36h10.71l4.02 43.33h-10.96l-1.98-23.71-9.48 23.22h-7.11Zm84.95-8.54h5.76c8.17 0 12.82-5.45 12.82-12.63 0-7.18-4.64-12.63-12.82-12.63h-5.76v25.26Zm-10.96 9.04V66.87h18.76c11.97-.11 21.76 9.5 21.87 21.47.11 11.97-9.5 21.76-21.47 21.87h-19.16Zm88.73-44.02c5.9-.06 11.6 2.1 15.98 6.07L340.85 80a15.139 15.139 0 0 0-10.65-4.21c-7.04 0-12.76 5.7-12.77 12.74 0 7.04 5.7 12.76 12.74 12.76h.02c3.96.05 7.79-1.47 10.65-4.21l5.33 7.74a23.451 23.451 0 0 1-15.98 6.07c-14.68 0-23.9-10.28-23.9-22.35 0-12.07 9.22-22.35 23.9-22.35m-30.51.68h-10.96v43.34h10.96V66.87Zm63.65 26.87h10.52l-5.14-15.23-5.39 15.23h.01Zm-16.53 16.47 16.78-43.34h10.22l17.03 43.34h-11.52l-2.97-7.68h-15.35l-2.85 7.68H346.8Zm48.54-43.34v43.34h30.46v-9.04h-19.5v-34.3h-10.96ZM229.83 84.51h-25.5v8.54h25.5v-8.54Zm4.34 16.66h-29.84v9.04h29.84v-9.04Zm0-34.3h-29.84v9.04h29.84v-9.04Z"
      {...getColor({ color, opacity: 1, defaultColor: '#153F8E' })}
    />
  </BaseSVG>
);

Logo.propTypes = {
  height: PropTypes.number,
  color: PropTypes.string,
};

Logo.defaultProps = {
  height: 50,
  color: null,
};

export default Logo;
