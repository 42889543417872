import PropTypes from 'prop-types';
import React from 'react';

import { useWindowSize } from '../../hooks';

const FadedImage = ({ src, x, y, width, height, id }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 900;

  return (
    <svg
      viewBox="0 0 100 100"
      className="faded-image background"
      preserveAspectRatio={isMobile ? 'xMidYMid slice' : ''}
    >
      <defs>
        <filter id={`fadeFilter__${id}`}>
          <feColorMatrix type="saturate" values="1" />
        </filter>

        <radialGradient id={`fadeRadialGradient__${id}`}>
          <stop offset="0%" stopColor="transparent" />
          <stop offset="100%" stopColor="black" />
        </radialGradient>

        <linearGradient id={`fadeLinearGradientRight__${id}`}>
          <stop offset="50%" stopColor="transparent" />
          <stop offset="100%" stopColor="black" />
        </linearGradient>

        <linearGradient id={`fadeLinearGradientLeft__${id}`}>
          <stop offset="0%" stopColor="black" />
          <stop offset="50%" stopColor="transparent" />
        </linearGradient>

        <mask id={`fadeMask__${id}`} maskContentUnits="objectBoundingBox">
          <rect width="1" height="1" fill="white" />
          <rect
            x="0.501"
            width="0.5"
            height="1"
            fill={`url(#fadeLinearGradientRight__${id})`}
          />
          <rect
            x="-0.01"
            width="0.5"
            height="1"
            fill={`url(#fadeLinearGradientLeft__${id})`}
          />
          <rect
            x="-0.125"
            y="-0.125"
            width="1.25"
            height="1.25"
            fill={`url(#fadeRadialGradient__${id})`}
          />
        </mask>
      </defs>

      <image
        xlinkHref={src}
        x={x}
        y={y}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid slice"
        mask={`url(#fadeMask__${id})`}
        filter={`url(#fadeFilter__${id})`}
      />
    </svg>
  );
};

FadedImage.propTypes = {
  src: PropTypes.string,

  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  id: PropTypes.string.isRequired,
};

FadedImage.defaultProps = {
  src: '/public/img/hero_header.png',

  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

export default FadedImage;
