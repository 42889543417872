import { createEndpoint, Type } from '../../models/europrisme';
import {
  GET_IMAGE_REJECTED,
  GET_IMAGE_REQUESTED,
  GET_IMAGE_RESOLVED,
  POST_IMAGE_REJECTED,
  POST_IMAGE_REQUESTED,
  POST_IMAGE_RESOLVED,
} from '../types';

export const fetchImageRequested = params => ({
  type: GET_IMAGE_REQUESTED,
  payload: { params },
});

export const fetchImageRejected = (error, params) => ({
  type: GET_IMAGE_REJECTED,
  payload: { error, params },
});

export const fetchImageResolved = (data, params) => ({
  type: GET_IMAGE_RESOLVED,
  payload: { data, params },
});

export const uploadImageRequested = () => ({ type: POST_IMAGE_REQUESTED });

export const uploadImageRejected = error => ({
  type: POST_IMAGE_REJECTED,
  payload: { error },
});

export const uploadImageResolved = () => ({ type: POST_IMAGE_RESOLVED });

export const fetchImage = createEndpoint(
  Type.GET,
  'getResourceB64',
  {
    sResourceCategory: 'IMG',
    sParentEntityType: '',
    sParentEntityValue: '',
    kIDUser: 1,
  },
  {
    request: fetchImageRequested,
    resolve: fetchImageResolved,
    reject: fetchImageRejected,
  },
);

export const uploadImage = createEndpoint(
  Type.POST,
  'insertResourceB64',
  {
    sResourceCategory: 'IMG',
    sResourceTypeExtension: 'PNG',
    sParentEntityType: '',
    sParentEntityValue: '',
    sB64Resource: '',
    kIDUser: 1,
  },
  {
    request: uploadImageRequested,
    resolve: uploadImageResolved,
    reject: uploadImageRejected,
  },
);

export const IMAGES_ACTIONS = { fetchImage, uploadImage };
