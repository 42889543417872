import { isArray } from 'lodash';
import { publish } from 'pubsub-js';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import { PUBSUB_TOPICS } from '../../hooks';
import DateFormat, { Formats } from '../../utils/date';
import {
  GET_ORDERS_REJECTED,
  GET_ORDERS_REQUESTED,
  GET_ORDERS_RESOLVED,
  GET_SINGLE_ORDER_REJECTED,
  GET_SINGLE_ORDER_REQUESTED,
  GET_SINGLE_ORDER_RESOLVED,
  ORDER_SEND_CART_REJECTED,
  ORDER_SEND_CART_REQUESTED,
  ORDER_SEND_CART_RESOLVED,
  ORDER_SET_COMMENT,
} from '../actions/types';
import { Status } from '../models/europrisme';

const initialState = {
  status: Status.IDLE,
  message: null,
  comment: '',
  orderInfo: {},
  invalidOrderInfo: {},
  orders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SEND_CART_REQUESTED: {
      return {
        ...state,
        status: Status.LOADING,
      };
    }
    case ORDER_SEND_CART_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.ERROR,
        message,
      };
    }
    case ORDER_SEND_CART_RESOLVED: {
      const { data, params } = action.payload;
      const { __options } = params;

      if (__options.isInvalid) {
        return {
          ...state,
          status: Status.IDLE,
          invalidOrderInfo: data,
        };
      }

      publish(PUBSUB_TOPICS.SEND_ORDER_SUCCESS);

      return {
        ...state,
        status: Status.IDLE,
        orderInfo: data,
      };
    }
    case ORDER_SET_COMMENT: {
      const { comment } = action.payload;
      const cleanComment = (comment || '').replace(/<br\s*\/?>/gi, '\n');

      return { ...state, comment: cleanComment };
    }
    case GET_ORDERS_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }
    case GET_ORDERS_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.ERROR,
        message,
      };
    }
    case GET_ORDERS_RESOLVED: {
      const { data } = action.payload;
      const { recordOrders } = data;

      let ordersList = [];
      if (recordOrders) {
        if (isArray(recordOrders)) {
          ordersList = recordOrders;
        } else {
          ordersList = [recordOrders];
        }
      }

      const orders = ordersList.reduce(
        (acc, { sOrderCode, sOrderDate, sOrderComment, sOrderStatus }) => {
          const date = DateFormat.parse(sOrderDate, Formats.ORDERS_API);
          const formattedDate = DateFormat.format(date, Formats.DAY_MONTH_YEAR);

          acc[sOrderCode] = {
            code: sOrderCode,
            date,
            formattedDate,
            comment: sOrderComment,
            orderStatus: sOrderStatus,
            status: Status.IDLE,
          };

          return acc;
        },
        {},
      );

      return {
        ...state,
        status: Status.IDLE,
        orders,
      };
    }
    case GET_SINGLE_ORDER_REQUESTED: {
      const { params } = action.payload;
      const { sOrderCode } = params;

      const previousOrderData = state.orders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.LOADING,
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          [sOrderCode]: order,
        },
      };
    }
    case GET_SINGLE_ORDER_REJECTED: {
      const { error, params } = action.payload;
      const { sOrderCode } = params;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      const previousOrderData = state.orders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.ERROR,
        message,
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          [sOrderCode]: order,
        },
      };
    }
    case GET_SINGLE_ORDER_RESOLVED: {
      const { data, params } = action.payload;
      const { sOrderCode } = params;
      const { recordOrders: records } = data;

      const previousOrderData = state.orders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.IDLE,
        records,
      };

      return {
        ...state,
        orders: {
          ...state.orders,
          [sOrderCode]: order,
        },
      };
    }
    default:
      return state;
  }
};
