import {
  GET_AISLES_REJECTED,
  GET_AISLES_REQUESTED,
  GET_AISLES_RESOLVED,
  GET_SINGLE_AISLE_REJECTED,
  GET_SINGLE_AISLE_REQUESTED,
  GET_SINGLE_AISLE_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  aisles: {},
  meta: {},
  error: null,
  status: Status.IDLE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AISLES_REQUESTED:
    case GET_SINGLE_AISLE_REQUESTED: {
      return { ...state, status: Status.LOADING, error: null };
    }
    case GET_AISLES_RESOLVED:
    case GET_SINGLE_AISLE_RESOLVED: {
      const { data } = action.payload;
      const { recordRayons, ...meta } = data;

      const newAisles = {
        ...state.aisles,
        ...recordRayons.reduce((acc, aisle) => {
          const { sCode } = aisle;

          acc[sCode] = aisle;

          return acc;
        }, {}),
      };

      return {
        ...state,
        status: Status.IDLE,
        meta,
        aisles: newAisles,
        error: null,
      };
    }
    case GET_AISLES_REJECTED:
    case GET_SINGLE_AISLE_REJECTED: {
      const { error } = action.payload;

      return { ...state, status: Status.ERROR, error };
    }
    default:
      return state;
  }
};
