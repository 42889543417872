import PropTypes from 'prop-types';
import React from 'react';

import { Colors } from '../../common/styles/vars';

const PDFIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8.467 8.467"
    >
      <g>
        <path
          fill={color}
          d="M1.323.53v7.407h5.82V2.381L5.293.53h-3.97zm3.704.264L6.88 2.646H5.027V.794z"
        />
        <g fill="#fff" aria-label="PDF">
          <path d="M2.802 3.894q.3 0 .435.17.139.166.139.46 0 .177-.056.33-.057.152-.189.245-.13.093-.355.093h-.14v.717h-.327V3.894zm-.017.35h-.15v.598h.109q.138 0 .219-.07.082-.074.082-.235 0-.293-.26-.293z" />
          <path d="M4.952 4.882q0 .51-.223.77-.223.257-.622.257H3.67V3.894h.485q.242 0 .42.113.18.113.277.333.1.22.1.542zm-.34.011q0-.333-.113-.491-.112-.158-.327-.158h-.175v1.312h.14q.475 0 .475-.663z" />
          <path d="M5.595 5.909h-.322V3.894h.885v.35h-.563v.52h.524v.35h-.524z" />
        </g>
      </g>
    </svg>
  );
};

PDFIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

PDFIcon.defaultProps = {
  size: 15,
  color: Colors.primary,
};

export default PDFIcon;
