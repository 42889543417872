import PropTypes from 'prop-types';
import React from 'react';

const InfoIcon = ({ size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="16" x2="12" y2="12" />
      <line x1="12" y1="8" x2="12.01" y2="8" />
    </svg>
  );
};

InfoIcon.propTypes = {
  size: PropTypes.number,
};

InfoIcon.defaultProps = {
  size: 12,
};

export default InfoIcon;
