import PreloadFamiliesByCustomer from '../../../../workers/preload-families-by-customer.worker';
import { createEndpoint, Type } from '../../models/europrisme';
import {
  idbGetAllFamiliesByCustomer,
  idbGetFamiliesByAisle,
  idbGetSingleFamily,
  idbPutFamiliesByCustomerRecords,
} from '../../models/idb';
import {
  GET_FAMILIES_REJECTED,
  GET_FAMILIES_REQUESTED,
  GET_FAMILIES_RESOLVED,
  GET_SINGLE_FAMILY_REJECTED,
  GET_SINGLE_FAMILY_REQUESTED,
  GET_SINGLE_FAMILY_RESOLVED,
} from '../types';

export const fetchFamiliesRequested = () => ({ type: GET_FAMILIES_REQUESTED });

export const fetchFamiliesRejected = error => ({
  type: GET_FAMILIES_REJECTED,
  payload: { error },
});

export const fetchFamiliesResolved = data => ({
  type: GET_FAMILIES_RESOLVED,
  payload: { data },
});

export const fetchFamiliesEndpoint = createEndpoint(
  Type.GET,
  'getFamilles',
  {},
  {
    request: fetchFamiliesRequested,
    resolve: fetchFamiliesResolved,
    reject: fetchFamiliesRejected,
  },
);

export const fetchFamilies = (...args) => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users || {};

  if (currentUser) {
    const records = await idbGetAllFamiliesByCustomer();

    console.debug('📤 idbGetAllFamiliesByCustomer');

    if (records) {
      return dispatch(fetchFamiliesResolved({ recordFamilles: records }));
    }
  }

  return dispatch(fetchFamiliesEndpoint(...args));
};

export const fetchFamiliesByCustomer = ({ port = 6174, baseURL }) => async (
  dispatch,
  getState,
) => {
  // getFamillesByCustomer
  if (process.env.BUILD_TARGET === 'client') {
    const { users } = getState();
    const { currentUser } = users || {};
    const { customerID } = currentUser || {};

    if (currentUser) {
      const idbFamilies = await idbGetAllFamiliesByCustomer();

      console.debug('📤 idbGetAllFamiliesByCustomer');

      if (idbFamilies) {
        dispatch(fetchFamiliesResolved({ recordFamilles: idbFamilies }));
      }

      return new Promise((resolve, reject) => {
        const worker = new PreloadFamiliesByCustomer();

        worker.onmessage = ({ data }) => {
          if (data.type === 'PRELOAD_FAMILIES_BY_CUSTOMER_RESOLVED') {
            if (data.success) {
              const records = data.value;

              idbPutFamiliesByCustomerRecords(records?.recordFamilles);
              dispatch(fetchFamiliesResolved(records));

              return resolve(records);
            }

            dispatch(fetchFamiliesRejected(data.error));
            return reject(data.error);
          }
        };

        dispatch(fetchFamiliesRequested());
        worker.postMessage({
          type: 'PRELOAD_FAMILIES_BY_CUSTOMER',
          port,
          baseURL,
          customerID,
          location: JSON.stringify({ ...window.location }),
        });
      });
    }
  }
};

export const fetchFamiliesPerAisleEndpoint = createEndpoint(
  Type.GET,
  'getFamillesByRayon',
  { sRayon: 0 },
  {
    request: fetchFamiliesRequested,
    resolve: fetchFamiliesResolved,
    reject: fetchFamiliesRejected,
  },
);

export const fetchFamiliesPerAisle = ({ sRayon }) => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users || {};

  if (currentUser) {
    const records = await idbGetFamiliesByAisle(sRayon);

    console.debug('📤 idbGetFamiliesByAisle');

    if (records) {
      return dispatch(fetchFamiliesResolved({ recordFamilles: records }));
    }
  }

  return dispatch(fetchFamiliesPerAisleEndpoint({ sRayon }));
};

export const fetchSingleFamilyRequested = () => ({
  type: GET_SINGLE_FAMILY_REQUESTED,
});

export const fetchSingleFamilyRejected = error => ({
  type: GET_SINGLE_FAMILY_REJECTED,
  payload: { error },
});

export const fetchSingleFamilyResolved = data => ({
  type: GET_SINGLE_FAMILY_RESOLVED,
  payload: { data },
});

export const fetchSingleFamilyEndpoint = createEndpoint(
  Type.GET,
  'getFamille',
  { sFamilleCode: '' },
  {
    request: fetchSingleFamilyRequested,
    resolve: fetchSingleFamilyResolved,
    reject: fetchSingleFamilyRejected,
  },
);

export const fetchSingleFamily = args => async dispatch => {
  const idbFamily = idbGetSingleFamily(args?.sFamilleCode);

  if (idbFamily) {
    await dispatch(fetchSingleFamilyResolved({ recordFamilles: [idbFamily] }));
  } else {
    await dispatch(fetchSingleFamilyEndpoint(args));
  }
};

export const FAMILIES_ACTIONS = {
  fetchFamilies,
  fetchFamiliesByCustomer,
  fetchFamiliesPerAisle,
  fetchSingleFamily,
};
