import { isArray } from 'lodash';

import {
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED,
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED,
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED,
  GET_LOCATIONS_REJECTED,
  GET_LOCATIONS_REQUESTED,
  GET_LOCATIONS_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  elementsAtLocation: { ...defaultState, locations: {} },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }

    case GET_LOCATIONS_RESOLVED: {
      const { data } = action.payload;

      return { ...state, status: Status.IDLE, tree: [data?.xmlst?.Account] };
    }

    case GET_LOCATIONS_REJECTED: {
      return { ...state, status: Status.ERROR };
    }

    case GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED: {
      return {
        ...state,
        elementsAtLocation: {
          ...state.elementsAtLocation,
          status: Status.LOADING,
        },
      };
    }

    case GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED: {
      const { data, params } = action.payload;
      const { kIDLayoutType, kIDLayout } = params;
      const elementKey = [kIDLayoutType, kIDLayout].join('-');

      const { xmlst } = data;
      const { LayoutContent } = xmlst;

      const parsedLayoutContent = (() => {
        if (!LayoutContent) {
          return [];
        }

        if (isArray(LayoutContent?.Item)) {
          return [...LayoutContent?.Item];
        }

        return [LayoutContent?.Item];
      })();

      const newLocations = {
        ...state.elementsAtLocation.locations,
        [elementKey]: parsedLayoutContent,
      };

      return {
        ...state,
        elementsAtLocation: {
          ...state.elementsAtLocation,
          status: Status.IDLE,
          locations: newLocations,
        },
      };
    }

    case GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED: {
      return {
        ...state,
        elementsAtLocation: {
          ...state.elementsAtLocation,
          status: Status.ERROR,
        },
      };
    }

    default:
      return state;
  }
};
