import throttle from 'lodash/throttle';
import { subscribe, unsubscribe } from 'pubsub-js';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import appConfig from '../utils/app-config';
import global from '../utils/global';
import { generateUUID } from '../utils/misc';

export const usePageTitle = title => {
  const originTitle = useRef(global.document?.title);

  useEffect(() => {
    const fullTitle = [title, appConfig.get('app.name')]
      .filter(part => part)
      .join(' \u2014 ');

    if (global.document) {
      global.document.title = fullTitle;
    }

    return () => {
      const { current } = originTitle;

      if (global.document) {
        global.document.title = current;
      }
    };
  }, [title]);
};

export const useResources = type => useSelector(state => state[type]);

export const useSingleResource = (type, subtree, id) => {
  const resources = useResources(type);
  const subtreeObj = resources[subtree] || {};
  const resource = subtreeObj[id];

  return { resource, status: resources.status, error: resources.error };
};

export const useUUID = () => {
  const state = useState(generateUUID());

  return state[0];
};

export const PUBSUB_TOPICS = {
  ADMIN_RESOURCE_SAVE_REQUESTED: 'ADMIN_RESOURCE_SAVE_REQUESTED',
  ADMIN_RESOURCE_SAVE_RESOLVED: 'ADMIN_RESOURCE_SAVE_RESOLVED',
  NOTIFICATION_PUSHED: 'NOTIFICATION_PUSHED',
  CREATE_USER_PAGE_REQUESTED: 'CREATE_USER_PAGE_REQUESTED',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  CONTACT_MESSAGE_SENT_SUCCESS: 'CONTACT_MESSAGE_SENT_SUCCESS',
  CONTACT_MESSAGE_SENT_ERROR: 'CONTACT_MESSAGE_SENT_ERROR',
  SEND_ORDER_SUCCESS: 'SEND_ORDER_SUCCESS',
  SEND_PREORDER_SUCCESS: 'SEND_PREORDER_SUCCESS',
  IMPORT_VIRTUAL_CART_RESOLVED: 'IMPORT_VIRTUAL_CART_RESOLVED',
  NOTIFY_CART_NOT_EMPTY: 'NOTIFY_CART_NOT_EMPTY',
  PENDING_ORDER_CANCELLED: 'PENDING_ORDER_CANCELLED',
  SHOW_GENERIC_CONFIRM: 'SHOW_GENERIC_CONFIRM',
  DISABLE_USER_DIALOG_ACCEPTED: 'DISABLE_USER_DIALOG_ACCEPTED',
  DISABLE_USER_RESOLVED: 'DISABLE_USER_RESOLVED',
};

export const useSubscriber = (topic, subscriberCallback, deps = []) => {
  useEffect(() => {
    const token = subscribe(topic, subscriberCallback);

    return () => unsubscribe(token);
  }, deps);
};

export const useTimeout = (callback, timeout = 0) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    if (timeout) {
      const id = setTimeout(tick, timeout);

      return () => clearTimeout(id);
    }
  }, [timeout]);
};

export const useResizeObserver = (elementRef, resizeCallback) => {
  const savedObserver = useRef();
  const savedCallback = useRef();

  useEffect(() => {
    if (elementRef?.current && savedCallback?.current) {
      savedObserver.current = new ResizeObserver(savedCallback.current);
      savedObserver.current.observe(elementRef.current);
    }

    return () => {
      if (elementRef?.current && savedObserver?.current) {
        savedObserver.current.unobserve(elementRef.current);
      }
    };
  }, [elementRef, savedCallback.current]);

  useEffect(() => {
    savedCallback.current = resizeCallback;
  }, [resizeCallback]);
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useScroll = callback => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = throttle(callback, 1000 / 60);
  }, [callback]);

  useEffect(() => {
    window.addEventListener('scroll', savedCallback.current);

    return () => window.removeEventListener('scroll', savedCallback);
  }, [callback]);
};

export const useForceUpdate = () => useState()[1];
