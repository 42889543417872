import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  AISLES_ACTIONS,
  FAMILIES_ACTIONS,
  PRODUCT_ACTIONS,
} from '../../store/actions';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';

const ResourceLink = styled(Link)`
  color: ${Colors.white};
  text-decoration: none;
`;

const ResourceField = ({
  type,
  code,
  field,
  state,
  fetchSingleAisle,
  fetchSingleFamily,
  fetchSingleProduct,
}) => {
  const resourceState = state[type] || {};
  const resources = resourceState[type] || {};
  const resource = resources[code];

  const fetch = {
    aisles: fetchSingleAisle,
    families: fetchSingleFamily,
    products: fetchSingleProduct,
  }[type];

  const fetchParam = {
    aisles: 'sRayonCode',
    families: 'sFamilleCode',
    products: 'sArticleCode',
  }[type];

  useEffect(() => {
    if (!resource) {
      fetch({ [fetchParam]: code });
    }
  }, [type, code]);

  if (resource) {
    return (
      <ResourceLink
        className="resource-field"
        data-type={type}
        data-code={code}
        data-field={field}
        to={Paths.Admin({ resource: type, id: code })}
      >
        {resource[field]}
      </ResourceLink>
    );
  }

  return (
    <div
      className="resource-field resource-field--missing"
      data-type={type}
      data-code={code}
      data-field={field}
    >
      None
    </div>
  );
};

ResourceField.propTypes = {
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,

  state: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleAisle: PropTypes.func.isRequired,
  fetchSingleFamily: PropTypes.func.isRequired,
  fetchSingleProduct: PropTypes.func.isRequired,
};

const ConnectedResourceField = connect(state => ({ state }), {
  ...AISLES_ACTIONS,
  ...FAMILIES_ACTIONS,
  ...PRODUCT_ACTIONS,
})(ResourceField);

export default ConnectedResourceField;
