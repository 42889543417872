import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import UpArrowIcon from '../../../../../assets/img/UpArrowIcon';
import { PUBSUB_TOPICS, usePageTitle, useSubscriber } from '../../../../hooks';
import { USERS_ACTIONS } from '../../../../store/actions/reducer-actions/users';
import { Status } from '../../../../store/models/europrisme';
import { Colors, Sizing } from '../../../../styles/vars';
import appConfig from '../../../../utils/app-config';
import { connect } from '../../../../utils/redux';
import Button from '../../../elements/Button';
import DisableUserDialog from '../../../elements/DisableUserDialog';
import Paths from '../../../routes/paths';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';

const ResourceListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  form {
    margin-bottom: ${Sizing.gutterWidth};
  }
`;

const ResourceListRoot = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  align-items: stretch;
  width: 100%;
  margin-top: 0;
  padding: 0;
  list-style: none;
`;

const ResourceListItem = styled.li``;

const SingleResourceLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 15px;
  padding-left: 30px;

  text-decoration: none;
  color: ${Colors.white};
  outline: none;
  background-color: ${Colors.white};

  overflow: hidden;

  .image-wrapper {
    display: block;
    position: relative;
    width: 100%;

    background-color: ${Colors.lightGrey};

    &::before {
      content: '';
      display: block;
      position: relative;
      padding-bottom: 75%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  span {
    position: relative;
    display: block;
    color: ${Colors.black};

    &.missing {
      opacity: 0.5;
    }
  }

  .arrow {
    display: flex;
    flex-direction: row;
    align-items: center;

    transition: transform 0.2s ease;

    span {
      color: ${Colors.darkGrey};
      font-size: 14px;
    }
  }

  &:hover {
    .arrow {
      transform: translateX(5px);
    }
  }
`;

const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${props => props.align || 'center'};
  width: 100%;
  margin-bottom: ${Sizing.gutterWidth};

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h2 {
      margin: 0;
      margin-bottom: 10px;
      font-weight: normal;

      &.missing {
        opacity: 0.5;
      }
    }

    h3 {
      margin-top: 0;
      font-weight: normal;
    }
  }

  .content {
    .code,
    .siret {
      font-size: 16px;
    }

    .description {
      margin-top: 15px;
      font-size: 12px;

      p {
        margin: 0;
        line-height: 1.5;
      }
    }
  }
`;

const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  grid-column: 1 / span 3;

  opacity: 0.5;
`;

const getClientPageTitle = ({ client, isInternal }) => {
  if (client) {
    return `Utilisateurs du client "${[client.sNom, client.sPrenom].join(
      ' ',
    )}"`;
  }

  if (isInternal) {
    return 'Utilisateurs internes';
  }

  return 'Utilisateurs';
};

const ClientUsers = ({
  isInternal,
  clientsState,
  usersState,
  fetchUsers,
  match,
}) => {
  const history = useHistory();

  const { id: clientID, userID } = match.params;
  const { currentUser } = usersState;

  const [client] = clientsState.clients.filter(c => `${c.sCode}` === clientID);

  usePageTitle(getClientPageTitle({ client, isInternal }));

  useEffect(() => {
    if (
      currentUser &&
      !currentUser.isInternal &&
      clientID !== currentUser.customerID
    ) {
      history.push(
        Paths.Admin({ resource: 'users', id: currentUser.customerID }),
      );
    }
  }, [currentUser]);

  useEffect(() => {
    fetchUsers({
      sCustomerCode: clientID === 'internal' ? '' : clientID,
    });
  }, [clientID, userID]);

  const localState = (usersState.users || {})[clientID] || {};
  const isLoading = localState.status === Status.LOADING;
  const resources = localState.records;

  useSubscriber(PUBSUB_TOPICS.CREATE_USER_PAGE_REQUESTED, () => {
    history.push(
      Paths.Admin({ resource: 'users', id: clientID, userID: 'create' }),
    );
  });

  const renderList = () => {
    if (isLoading) {
      return (
        <MessageRow>
          <span>Chargement...</span>
        </MessageRow>
      );
    }

    if (!resources || !resources.length) {
      return (
        <MessageRow>
          <span>Aucun résultat à afficher</span>
        </MessageRow>
      );
    }

    return resources.map(({ kIDUser, sUserFirstName, sUserLastName }) => (
      <ResourceListItem
        key={`user--${kIDUser}__${sUserFirstName}-${sUserLastName}`}
      >
        <SingleResourceLink
          to={Paths.Admin({
            resource: 'users',
            id: clientID,
            userID: kIDUser,
          })}
        >
          <span className={sUserFirstName || sUserLastName ? '' : 'missing'}>
            {sUserFirstName || sUserLastName
              ? [sUserFirstName, sUserLastName].join(' ')
              : 'Nom manquant'}
          </span>
          <div className="arrow">
            <span>Modifier</span>
            <UpArrowIcon angle={90} includeCircle={false} />
          </div>
        </SingleResourceLink>
      </ResourceListItem>
    ));
  };

  const renderPreviousPageLink = () => {
    const hasPreviousPage = userID || (isInternal && clientID);

    if (!hasPreviousPage) return null;

    const previousPage = userID
      ? Paths.Admin({ resource: 'users', id: clientID })
      : Paths.Admin({ resource: 'users' });

    return (
      <FiltersRow align="flex-start">
        <Button fromComponent={Link} to={previousPage}>
          Retour
        </Button>
      </FiltersRow>
    );
  };

  const renderPageTitle = () => {
    let clientName = <h2 className="missing">Nom du client manquant</h2>;

    if (clientID === 'internal') {
      clientName = (
        <h2>{appConfig.get('settings.internalOrganizationName')}</h2>
      );
    } else if (client) {
      const clientFullname = [client.sLibelleTitre, client.sNom, client.sPrenom]
        .join(' ')
        .trim();

      clientName = (
        <h2 className={clientFullname ? '' : 'missing'}>
          {clientFullname || 'Nom du client manquant'}
        </h2>
      );
    }

    let userName = null;

    if (userID) {
      if (userID === 'create') {
        userName = <h3>Ajouter un utilisateur</h3>;
      } else {
        const [user] = (
          usersState.users[clientID] || { records: [] }
        ).records.filter(u => u.kIDUser === userID);

        if (user) {
          const userFullname = [
            user.sUserFirstName,
            user.sUserLastName,
            /* Please format this code properly, Prettier */
          ].join(' ');

          userName = <h3>Utilisateur {userFullname}</h3>;
        } else {
          userName = <h3>Chargement...</h3>;
        }
      }
    }

    return (
      <div className="titles">
        {clientName}
        {userName}
      </div>
    );
  };

  const renderContent = () => {
    if (userID) {
      if (userID === 'create') {
        return <CreateUser for={clientID} />;
      }

      return <UpdateUser for={clientID} userID={userID} />;
    }

    return <ResourceListRoot>{renderList()}</ResourceListRoot>;
  };

  const renderDisableUserButton = () => {
    if (clientID && userID && userID !== 'create') {
      return <DisableUserDialog clientID={clientID} userID={userID} />;
    }
  };

  return (
    <ResourceListWrapper>
      {renderPreviousPageLink()}
      <FiltersRow align="flex-start">
        {renderPageTitle()}
        {renderDisableUserButton()}

        {client && !userID && (
          <>
            <div className="content">
              {client.sCodeEtablissement && (
                <div className="code">
                  Code établissement&nbsp;:&nbsp;{client.sCodeEtablissement}
                </div>
              )}

              {client.sSIRET && (
                <div className="siret">SIRET&nbsp;:&nbsp;{client.sSIRET}</div>
              )}

              <div className="description">
                {(client.sCommentaire || '').split('<br/>').map((line, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={`description-line--${i}`}>{line}</p>
                ))}
              </div>
            </div>
          </>
        )}
      </FiltersRow>

      {renderContent()}
    </ResourceListWrapper>
  );
};

ClientUsers.propTypes = {
  isInternal: PropTypes.bool.isRequired,

  clientsState: PropTypes.objectOf(PropTypes.any).isRequired,
  usersState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchUsers: PropTypes.func.isRequired,

  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const ConnectedClientUsers = connect(
  state => ({ clientsState: state.clients, usersState: state.users }),
  { ...USERS_ACTIONS },
)(withRouter(ClientUsers));

export default ConnectedClientUsers;
