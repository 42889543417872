import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import CloseIcon from '../../../assets/img/CloseIcon';
import { Colors } from '../../styles/vars';
import Button from './Button';
import Flex from './containers/Flex';

const Scrim = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 999999;

  pointer-events: ${props => (props.opened ? 'initial' : 'none')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;

    pointer-events: none;
    z-index: -1;

    ${props =>
      props.opened &&
      css`
        opacity: 1;
        pointer-events: initial;
      `}
  }
`;

const PopupWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: calc(100vh - 40px);
  max-width: calc(100vw - 40px);
  gap: 20px;

  ${props =>
    props.fullscreen
      ? css`
          width: calc(100vw - 40px);
          max-width: unset;
          height: calc(100vh - 40px);
          max-height: unset;
          padding: 38px;
        `
      : css`
          max-width: ${props.maxWidth}px;
        `}

  color: ${Colors.white};
  background-color: ${props => props.$backgroundColor || Colors.lightGrey};
  border-radius: 20px;
  opacity: ${props => (props.opened ? 1 : 0)};
  transform: translateX(${props => (props.opened ? 0 : -20)}px);

  transition-property: opacity, transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;

  overflow: hidden;
  pointer-events: ${props => (props.opened ? 'initial' : 'none')};

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    min-width: unset;
    padding: 6px;

    z-index: 100;

    svg {
      margin-right: 0;
    }
  }

  .contents-wrapper {
    position: relative;
    display: block;
    width: 100%;

    overflow-y: auto;
  }

  .contents {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => (props.fullscreen ? '0' : '30px 16px 16px 16px')};

    ${props =>
      props.fullscreen &&
      css`
        width: 100%;
        height: 100%;

        border-radius: 5px;
        overflow: hidden;
      `}
  }
`;

const Popup = ({
  open,
  onClose,
  maxWidth,
  backgroundColor,
  closeButtonBackgroundColor,
  fullscreen,
  dismissable,
  confirmButton,
  cancelButton,
  style,
  children,
}) => {
  const hasActions = confirmButton || cancelButton;
  const ConfirmButton = confirmButton;
  const CancelButton = cancelButton;

  const onActionClick = useMemo(
    () => success => event => {
      if (!event.detail || typeof event.detail !== 'object') {
        event.detail = {};
      }

      event.detail.popupSuccess = success;

      onClose(event);
    },
    [onClose],
  );

  useEffect(() => {
    if (fullscreen && open) {
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [fullscreen, open]);

  if (typeof window !== 'undefined') {
    return createPortal(
      <Scrim opened={open}>
        <PopupWrapper
          maxWidth={maxWidth}
          opened={open}
          fullscreen={fullscreen}
          style={style}
          $backgroundColor={backgroundColor}
        >
          {dismissable && (
            <Button
              background={closeButtonBackgroundColor}
              className="close"
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          )}

          <div className="contents-wrapper">
            <div className="contents">{children}</div>
          </div>

          {hasActions && (
            <Flex justify="flex-end" gap="20px">
              {CancelButton && <CancelButton onClick={onActionClick(false)} />}
              {ConfirmButton && <ConfirmButton onClick={onActionClick(true)} />}
            </Flex>
          )}
        </PopupWrapper>
      </Scrim>,
      document.body,
    );
  }

  return null;
};

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
  closeButtonBackgroundColor: PropTypes.string,
  fullscreen: PropTypes.bool,
  dismissable: PropTypes.bool,
  confirmButton: PropTypes.elementType,
  cancelButton: PropTypes.elementType,
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

Popup.defaultProps = {
  maxWidth: 800,
  fullscreen: false,
  dismissable: true,
  confirmButton: null,
  cancelButton: null,
  closeButtonBackgroundColor: null,
  style: {},
};

export default Popup;
