import {
  GET_IMAGE_REJECTED,
  GET_IMAGE_REQUESTED,
  GET_IMAGE_RESOLVED,
  POST_IMAGE_REJECTED,
  POST_IMAGE_REQUESTED,
  POST_IMAGE_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = { ...defaultState, images: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_REQUESTED: {
      const { params } = action.payload;
      const { sParentEntityValue } = params;

      return {
        ...state,
        images: {
          ...state.images,
          [sParentEntityValue]: {
            ...defaultState,
            status: Status.LOADING,
            B64: null,
          },
        },
        status: Status.IDLE,
        error: null,
      };
    }
    case GET_IMAGE_RESOLVED: {
      const { data, params } = action.payload;
      const { sParentEntityValue } = params;

      return {
        ...state,
        images: {
          ...state.images,
          [sParentEntityValue]: {
            ...state.images[sParentEntityValue],
            ...data,
            status: Status.IDLE,
          },
        },
        status: Status.IDLE,
        error: null,
      };
    }
    case GET_IMAGE_REJECTED: {
      const { error, params } = action.payload;
      const { sParentEntityValue } = params;

      return {
        ...state,
        images: {
          ...state.images,
          [sParentEntityValue]: {
            ...state.images[sParentEntityValue],
            status: Status.ERROR,
            error,
          },
        },
        status: Status.ERROR,
        error,
      };
    }
    case POST_IMAGE_REQUESTED: {
      return { ...state, uploadStatus: Status.LOADING };
    }
    case POST_IMAGE_RESOLVED: {
      return { ...state, uploadStatus: Status.IDLE };
    }
    case POST_IMAGE_REJECTED: {
      const { error } = action.payload;

      return { ...state, uploadStatus: Status.ERROR, error };
    }
    default:
      return state;
  }
};
