import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';

const Placeholder = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: ${props => props.height};

  background-color: white;

  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;

    opacity: 0.75;
    background: linear-gradient(
      to right,
      ${Colors.white} 0%,
      white 25%,
      ${Colors.white} 50%,
      white 75%,
      ${Colors.white} 100%
    );

    animation-name: BlockPlaceholderAnimation;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    z-index: 10;
    pointer-events: none;
  }

  @keyframes BlockPlaceholderAnimation {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(50%);
    }
  }
`;

const BlockPlaceholder = ({ height, style, children }) => {
  if (children) {
    return children;
  }

  const heightProp = typeof height === 'number' ? `${height}px` : height;

  return <Placeholder height={heightProp} style={style} />;
};

BlockPlaceholder.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

BlockPlaceholder.defaultProps = {
  height: 100,
  style: {},
  children: null,
};

export default BlockPlaceholder;
