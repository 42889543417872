import { isArray, isObject } from 'lodash';

import { createEndpoint, Type } from '../../models/europrisme';
import {
  GET_ORDERS_REJECTED,
  GET_ORDERS_REQUESTED,
  GET_ORDERS_RESOLVED,
  GET_SINGLE_ORDER_REJECTED,
  GET_SINGLE_ORDER_REQUESTED,
  GET_SINGLE_ORDER_RESOLVED,
  ORDER_SEND_CART_REJECTED,
  ORDER_SEND_CART_REQUESTED,
  ORDER_SEND_CART_RESOLVED,
  ORDER_SET_COMMENT,
} from '../types';

export const sendCartAsOrderRequested = fullParams => ({
  type: ORDER_SEND_CART_REQUESTED,
  params: fullParams,
});

export const sendCartAsOrderResolved = (data, fullParams) => ({
  type: ORDER_SEND_CART_RESOLVED,
  payload: { data, params: fullParams },
});

export const sendCartAsOrderRejected = (error, fullParams) => ({
  type: ORDER_SEND_CART_REJECTED,
  payload: { error, params: fullParams },
});

export const sendCartAsOrderEndpoint = createEndpoint(
  Type.POST,
  'doOrder',
  {
    sOrderJson: JSON.stringify({}),
  },
  {
    request: sendCartAsOrderRequested,
    resolve: sendCartAsOrderResolved,
    reject: sendCartAsOrderRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const sendProductsAsOrder = ({
  dispatch,

  products,
  customerID,
  comment,

  isInvalid = false,
}) => {
  const payload = {
    order: {
      sContext: 'HX',
      sCustomerCode: customerID,
      sOrderComment: comment || '',
      items: {
        item: Object.values(products).map(
          ({
            sCode,
            id,
            count,
            sFamille,
            sBelowComment,
            iIsLoc,
            kIDEntity,
            iEntityType,
          }) => ({
            sArticleCode: sCode || id,
            sFamille: sFamille || '-1',
            sQuantite: count,
            sBelowComment: sBelowComment || '',
            iIsLoc: iIsLoc || 0,
            kIDEntity: kIDEntity || undefined,
            iEntityType: iEntityType || undefined,
          }),
        ),
      },
    },
  };

  return dispatch(
    sendCartAsOrderEndpoint({ sOrderJson: JSON.stringify(payload), isInvalid }),
  );
};

export const sendCartAsOrder = ({ comment }) => async (dispatch, getState) => {
  const { cart, users } = getState();

  const { currentUser } = users;
  const { customerID } = currentUser;

  const { present } = cart;
  const { products, invalidProducts } = present;

  // Handle case were invalidProducts is an object of shape {[id]: {...product}, ...}
  const invalidProductsData = isObject(invalidProducts)
    ? Object.values(invalidProducts)
    : invalidProducts;

  if (
    invalidProductsData &&
    isArray(invalidProductsData) &&
    invalidProductsData.length > 0
  ) {
    await sendProductsAsOrder({
      dispatch,
      products: invalidProductsData,
      customerID,
      comment: [
        'Reprise de commande : Produits invalides ou inconnus.',
        `"${comment || ''}"`,
      ].join('\n\n'),

      isInvalid: true,
    });
  }

  if (!products || Object.keys(products).length === 0) {
    return dispatch(sendCartAsOrderResolved({}, { comment }));
  }

  return sendProductsAsOrder({ dispatch, products, customerID, comment });
};

export const setOrderComment = comment => ({
  type: ORDER_SET_COMMENT,
  payload: { comment },
});

export const getOrdersRequested = () => ({
  type: GET_ORDERS_REQUESTED,
});

export const getOrdersResolved = data => ({
  type: GET_ORDERS_RESOLVED,
  payload: { data },
});

export const getOrdersRejected = error => ({
  type: GET_ORDERS_REJECTED,
  payload: { error },
});

export const getOrdersEndpoint = createEndpoint(
  Type.GET,
  'getOrders',
  {
    sCustomerCode: '',
  },
  {
    request: getOrdersRequested,
    resolve: getOrdersResolved,
    reject: getOrdersRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getOrders = () => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(getOrdersEndpoint({ sCustomerCode: customerID }));
};

export const getSingleOrderRequested = params => ({
  type: GET_SINGLE_ORDER_REQUESTED,
  payload: { params },
});

export const getSingleOrderResolved = (data, params) => ({
  type: GET_SINGLE_ORDER_RESOLVED,
  payload: { data, params },
});

export const getSingleOrderRejected = (error, params) => ({
  type: GET_SINGLE_ORDER_REJECTED,
  payload: { error, params },
});

export const getSingleOrderEndpoint = createEndpoint(
  Type.GET,
  'getOrder',
  {
    sCustomerCode: '',
    sOrderCode: '',
  },
  {
    request: getSingleOrderRequested,
    resolve: getSingleOrderResolved,
    reject: getSingleOrderRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getSingleOrder = orderID => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(
    getSingleOrderEndpoint({ sCustomerCode: customerID, sOrderCode: orderID }),
  );
};

export const ORDER_ACTIONS = {
  sendCartAsOrder,
  setOrderComment,
  getOrders,
  getSingleOrder,
};
