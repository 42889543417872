import '@brainhubeu/react-carousel/lib/style.css';

import Carousel from '@brainhubeu/react-carousel';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';
import Paths from '../routes/paths';
import Button from './Button';
import ProductPrice, { PriceMode } from './price/ProductPrice';
import ResourceImage from './ResourceImage';

const ProductsGrid = styled.div`
  display: block;
  width: 100%;
  grid-column: 1 / span 12;
  margin: 0;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    width: calc(100% + 40px);
    margin: unset;
    margin-left: 0px;
  }

  .BrainhubCarousel__dots {
    margin-top: 20px;

    .BrainhubCarousel__dot {
      background: none;

      &::before {
        transition: transform 0.2s ease-in-out;
      }

      &.BrainhubCarousel__dot--selected {
        &::before {
          transform: scale(1.5);
        }
      }
    }
  }
`;

const ProductBlock = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  min-height: 280px;
  width: 100%;
  padding: 24px 55px;
  margin: 0 20px;

  background-color: white;
  text-decoration: none;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    flex-direction: column;
    width: calc(100vw - 40px) !important;
    padding: 20px;
  }

  img {
    max-width: 160px;
    height: auto;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      max-width: unset;
      width: 100%;
      max-height: 180px !important;
      margin-bottom: 20px;

      object-fit: contain;
      object-position: center;
    }
  }
`;

const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 1;
  margin-left: 30px;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    align-items: flex-start;
    margin-left: 0;

    .generic-price {
      width: 100%;
      margin-top: 12px !important;
      margin-bottom: 12px !important;

      > div {
        flex-direction: column;

        > * {
          &:not(:last-child) {
            margin-right: unset !important;
            margin-bottom: 8px;
          }
        }
      }
    }

    button {
      width: 100%;
      max-width: 220px;
      margin-top: auto !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ProductTitle = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.black};
  font-weight: 500;
`;

const ProductCarousel = ({ products }) => {
  return (
    <ProductsGrid>
      <Carousel
        autoPlay={4_000}
        slidesPerPage={2}
        infinite
        stopAutoPlayOnHover
        dots
        breakpoints={{
          [Sizing.numeric.wrapperWidth]: {
            slidesPerPage: 1,
          },
        }}
      >
        {products.map(product => {
          const { sCode, sLibelle } = product;

          return (
            <ProductBlock
              key={sCode}
              to={Paths.SingleProduct({ productID: sCode })}
            >
              <ResourceImage
                type="Article"
                code={sCode}
                style={{ maxHeight: '300px' }}
              />
              <ProductDescription>
                <ProductTitle>{sLibelle}</ProductTitle>

                <ProductPrice
                  product={product}
                  mode={PriceMode.HOMEPAGE_FEATURED}
                  style={{ marginTop: 'auto' }}
                />

                <Button
                  fromComponent="button"
                  style={{
                    marginTop: '20px',
                    minWidth: '160px',
                    fontSize: '15px',
                    fontWeight: 500,
                  }}
                >
                  Voir
                </Button>
              </ProductDescription>
            </ProductBlock>
          );
        })}
      </Carousel>
    </ProductsGrid>
  );
};

ProductCarousel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default ProductCarousel;
