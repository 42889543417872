import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import TrashIcon from '../../../assets/img/TrashIcon';
import { usePageTitle } from '../../hooks';
import { PREORDER_ACTIONS } from '../../store/actions/reducer-actions/preorder';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import {
  OrderDetailsPanel,
  OrderDetailsPanelTrack,
  OrderListButton,
  OrderListElement,
  OrdersListGrid,
  OrdersListWapper,
} from '../elements/AccountLists';
import BlockPlaceholder from '../elements/BlockPlaceholder';
import Button from '../elements/Button';
import Confirm from '../elements/Confirm';
import OrderDetails from '../elements/OrderDetails';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const DeleteButton = styled(Button)`
  position: relative;
  bottom: 0;
  right: 0;
  min-width: unset !important;
  padding: 3px 6px !important;
  margin-top: 6px;

  font-size: 12px;
  transform: scale(0.8);
  opacity: 0;

  transition-property: transform, opacity, background-color, color !important;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  cursor: pointer;
  pointer-events: none;
`;

const PlaceholderData = Array(12)
  .fill()
  .map((_, index) => index);

const PreordersList = ({
  status,
  preorders,
  getPreorders,
  getSinglePreorder,
  deleteSinglePreorder,
  match,
}) => {
  usePageTitle('Mes commandes');

  const history = useHistory();

  const { params } = match;
  const { preorderID: selected } = params;

  useEffect(() => {
    getPreorders();
  }, []);

  useEffect(() => {
    if (selected) {
      getSinglePreorder(selected);
    }
  }, [selected]);

  const toggleSelection = code => {
    if (selected === code) {
      history.push(Paths.PreordersList({ preorderID: '' }));
    } else {
      history.push(Paths.PreordersList({ preorderID: code }));
    }
  };

  const requestDeletePreorder = (event, code) => {
    event.preventDefault();
    event.stopPropagation();

    const title = preorders[code]?.title;

    Confirm({
      title: 'Supprimer la précommande',
      message: [
        'Êtes-vous sûr(e) de vouloir supprimer cette précommande ?',
        `- Précommande #${code} "${title}"`,
        'Les données ne pourront pas être récupérées.',
      ],
      onConfirm: () => {
        deleteSinglePreorder(code);
        history.push(Paths.PreordersList({ preorderID: '' }));
      },
    })();
  };

  const renderList = () => {
    if (status === Status.LOADING) {
      return PlaceholderData.map(key => (
        <BlockPlaceholder key={key} height={55} />
      ));
    }

    if (Object.entries(preorders).length === 0) {
      return <div className="empty">Aucune précommande trouvée.</div>;
    }

    const sortedPreorders = [...Object.values(preorders)]
      .filter(v => v)
      .sort((left, right) => {
        if (left.date?.isBefore(right.date)) return 1;
        if (left.date?.isAfter(right.date)) return -1;

        return 0;
      });

    return sortedPreorders.map(({ title, code, formattedDate }) => (
      <OrderListElement
        key={code}
        selected={selected === code}
        hasSelection={selected}
        className="order-block"
      >
        <OrderListButton
          onClick={() => toggleSelection(code)}
          selected={selected === code}
          hasSelection={selected}
        >
          <header>
            <h3
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>{title}</span>
              <span style={{ opacity: 0.6 }}>#{code}</span>
            </h3>

            <div className="row">
              <h4>{formattedDate}</h4>

              <DeleteButton
                className="delete-button"
                background="white"
                color={Colors.red}
                hoverBackground={Colors.red}
                hoverColor={Colors.white}
                onClick={e => requestDeletePreorder(e, code)}
              >
                <TrashIcon size={14} />
                <span>Supprimer</span>
              </DeleteButton>
            </div>
          </header>
        </OrderListButton>
      </OrderListElement>
    ));
  };

  return (
    <OrdersListWapper>
      <PageTitle>Mes précommandes</PageTitle>

      <OrdersListGrid>{renderList()}</OrdersListGrid>
      <OrderDetailsPanelTrack>
        <OrderDetailsPanel selected={selected}>
          <div className="contents">
            {selected && <OrderDetails code={selected} type="preorder" />}
          </div>
        </OrderDetailsPanel>
      </OrderDetailsPanelTrack>
    </OrdersListWapper>
  );
};

PreordersList.propTypes = {
  status: PropTypes.oneOf(Object.values(Status)).isRequired,
  preorders: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  getPreorders: PropTypes.func.isRequired,
  getSinglePreorder: PropTypes.func.isRequired,
  deleteSinglePreorder: PropTypes.func.isRequired,

  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

PreordersList.defaultProps = {
  preorders: {},
};

export const ConnectedPreordersList = connect(
  state => ({ ...state.preorders }),
  {
    ...PREORDER_ACTIONS,
  },
)(withRouter(PreordersList));

export default {
  path: Paths.PreordersList(),
  name: 'Mes commandes',
  component: ConnectedPreordersList,
};
