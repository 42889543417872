import 'formdata-polyfill';

import { API_BASE_URL } from '../../../utils/api-base';
import global, { getEnv } from '../../../utils/global';
import { generateUUID } from '../../../utils/misc';

const defaultHostname = global.location
  ? global.location.hostname
  : 'localhost';

const defaultProtocol = global.location ? global.location.protocol : 'https';

const HOST = getEnv('corsProxyHost', 'RAZZLE_CORS_HOST') || defaultHostname;
const PORT = getEnv('corsProxyPort', 'RAZZLE_CORS_PORT') || 6174;

export const CORS_PROXY = () => `${defaultProtocol}//${HOST}:${PORT}`.trim();

export const BASE_URL = `${API_BASE_URL()}/webservice.asmx`;

export const Type = {
  GET: 'GET',
  POST: 'POST',
  PLAIN_TEXT: 'PLAIN_TEXT',
  XML: 'XML',
};

export const Status = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  REFRESHING: 'REFRESHING',
};

export const defaultState = { status: Status.IDLE };

export const parseResponse = (xmlResponse, plain = false) => {
  try {
    const doc = new DOMParser().parseFromString(xmlResponse, 'text/xml');
    const node = doc.children[0];

    return plain
      ? node.textContent
      : JSON.parse(node.textContent.replace(/\\r?\\n/g, '<br/>'));
  } catch (error) {
    return {
      success: false,
      Success: 'FALSE',
      cause: 'Parse error',
      error,
      xmlResponse,
    };
  }
};

export const defaultEndpointActions = {
  request: () => ({ type: 'NOOP' }),
  resolve: () => ({ type: 'NOOP' }),
  reject: () => ({ type: 'NOOP' }),
};

export const defaultSuccessCheck = () => true;

export const createBareEndpoint = (
  url,
  defaultParams,
  actions = {},
) => handler => (params = {}) => async dispatch => {
  const { request, resolve, reject } = {
    ...defaultEndpointActions,
    ...actions,
  };

  const whitelistedParams = Object.keys(params)
    .filter(key => Object.keys(defaultParams).includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  const blacklistedParams = Object.keys(params)
    .filter(key => !Object.keys(defaultParams).includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  const requestParams = { ...defaultParams, ...whitelistedParams };
  const fullParams = {
    ...requestParams,
    __options: blacklistedParams,
    __request: {
      uuid: generateUUID(),
      pending: true,
      cancelled: false,
    },
  };

  dispatch(request(fullParams));

  const formData = new FormData();

  Object.entries(requestParams).forEach(([key, value]) =>
    formData.set(key, value),
  );

  const body = new URLSearchParams(formData);

  try {
    const endpointURL =
      getEnv('vitaeUseProxy', 'VITAE_USE_PROXY') === '1'
        ? `${CORS_PROXY()}/${BASE_URL}/${url}`
        : `${BASE_URL}/${url}`;

    const response = await fetch(endpointURL, {
      method: 'POST',
      node: 'no-cors',
      body,
    });

    await handler(response, { dispatch, resolve, reject, fullParams });
  } catch (error) {
    dispatch(reject(error, fullParams, dispatch));
  }
};

export const createGetEndpoint = (
  url,
  defaultParams,
  actions = {},
  successCheck = defaultSuccessCheck,
) => {
  return createBareEndpoint(
    url,
    defaultParams,
    actions,
  )(async (response, { dispatch, resolve, reject, fullParams }) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const text = await response.text();
    const json = parseResponse(text);

    if (successCheck(json)) {
      dispatch(resolve(json, fullParams, dispatch));
    } else {
      dispatch(
        reject(
          {
            success: false,
            message: 'Automatic rejection caused by failed successCheck',
            originalData: json,
          },
          fullParams,
          dispatch,
        ),
      );
    }
  });
};

export const defaultTextParser = text =>
  Number.parseInt(text.trim().split('|')[0], 0) || 0;

export const defaultTextSuccessCheck = text => {
  try {
    const json = JSON.parse(text);

    return json.Success !== 'FALSE';
  } catch (err) {
    return true;
  }
};

export const createPlainTextEndpoint = (
  url,
  defaultParams,
  actions = {},
  successCheck = defaultTextSuccessCheck,
  textParser = defaultTextParser,
) => {
  return createBareEndpoint(
    url,
    defaultParams,
    actions,
  )(async (response, { dispatch, resolve, reject, fullParams }) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const text = await response.text();
    const textContent = parseResponse(text, true);

    if (successCheck(textContent)) {
      const data = textParser(textContent);

      dispatch(resolve(data, fullParams, dispatch));
    } else {
      dispatch(
        reject(
          {
            success: false,
            message: 'Automatic rejection caused by failed successCheck',
            originalData: textContent,
          },
          fullParams,
          dispatch,
        ),
      );
    }
  });
};

export const defaultXMLSuccessCheck = doc => {
  try {
    const xml = doc.getElementsByTagName('xml')[0];
    const success = xml.getElementsByTagName('Success')[0];

    return success.textContent === 'TRUE';
  } catch (err) {
    return false;
  }
};

export const createXMLEndpoint = (
  url,
  defaultParams,
  actions = {},
  successCheck = defaultXMLSuccessCheck,
) => {
  return createBareEndpoint(
    url,
    defaultParams,
    actions,
  )(async (response, { dispatch, resolve, reject, fullParams }) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const text = await response.text();
    const doc = new DOMParser().parseFromString(text, 'text/xml');

    if (successCheck(doc)) {
      dispatch(
        resolve(
          {
            success: true,
            message: 'Message envoyé avec succès',
            doc,
          },
          fullParams,
          dispatch,
        ),
      );
    } else {
      dispatch(
        reject(
          {
            success: false,
            message: "Erreur lors de l'envoi du message",
            doc,
          },
          fullParams,
          dispatch,
        ),
      );
    }
  });
};

export const createEndpoint = (
  type,
  url,
  defaultParams,
  actions = {},
  successCheck = defaultSuccessCheck,
  textParser = defaultTextParser,
) => {
  switch (type) {
    case Type.GET:
      return createGetEndpoint(url, defaultParams, actions, successCheck);
    case Type.POST:
      return createGetEndpoint(url, defaultParams, actions, successCheck);
    case Type.PLAIN_TEXT:
      return createPlainTextEndpoint(
        url,
        defaultParams,
        actions,
        successCheck,
        textParser,
      );
    case Type.XML:
      return createXMLEndpoint(url, defaultParams, actions, successCheck);
    default:
      throw new Error('Unknown endpoint type');
  }
};
