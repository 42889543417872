/* eslint-disable no-bitwise */

import sanitize from 'sanitize-html';

import { API_BASE_URL } from './api-base';
import appConfig from './app-config';
import global from './global';

export const noop = () => undefined;

export const isString = v => v && typeof v === typeof '';

export const capitalize = str =>
  str && `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const generateUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });

export const generateVirtualCartID = () => {
  const now = Date.now();
  let entropy;

  if (typeof window !== 'undefined' && window.crypto) {
    const arr = new Uint32Array(1);

    window.crypto.getRandomValues(arr);
    [entropy] = arr;
  } else {
    entropy = Math.round(Math.random() * (2 << 24));
  }

  return Math.abs(entropy | now);
};

export const sanitizeHTML = html => ({ __html: sanitize(html) });

export const parsePriceString = (number, defaultValue = 0) => {
  if (!number) {
    return defaultValue;
  }

  if (typeof number === 'number') {
    return number;
  }

  if (typeof number === 'string') {
    if (number.match(/,\d{2}$/)) {
      return Number.parseFloat(number.replace(',', '.').replace(' ', ''));
    }

    return Number.parseFloat(number.replace(/[,\s]/g, ''));
  }

  return defaultValue;
};

export const Configurators = {
  PRESSURE_ULCER_PREVENTION: `${API_BASE_URL()}/#config`,
};

export const shouldShowConfigurator = (name, currentUser) => {
  if (!currentUser) {
    return false;
  }

  // XXX: Respect the .use setting in hx.config.json
  // XXX: Do not show configurator if use is explicitly set to false
  if (appConfig.get(`settings.connections.${name}.use`) === false) {
    return false;
  }

  return currentUser.canUse(name);
};

export const isHttpUrl = str => {
  try {
    const url = new URL(str);

    return ['http:', 'https:'].includes(url.protocol);
  } catch (_) {
    return false;
  }
};

export const isUrlExternal = href => {
  if (!global.location) {
    return true;
  }

  const host = global.location.hostname;

  const linkHost = (url => {
    if (/^https?:\/\//.test(url)) {
      const parser = document.createElement('a');

      parser.href = url;

      return parser.hostname;
    }

    return global.location.hostname;
  })(href);

  return host !== linkHost;
};

export const isTruthy = value => !!value;

export const isFalsy = value => !isTruthy(value);

/**
 * Inserts an element between each items of an array
 *
 * @param {any[]} array Items to join
 * @param {Function | any} elementOrCallback Element to interleave or callback to use as element
 * @returns the array with interleaved elements
 */
export const interleave = (array, elementOrCallback) =>
  array
    .flatMap((element, index) => [
      element,
      typeof elementOrCallback === 'function'
        ? elementOrCallback(element, index)
        : elementOrCallback,
    ])
    .slice(0, -1);
