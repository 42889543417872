/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '../common/containers/App';
import configureStore from '../common/store/index';

const { __PRELOADED_STATE__, hxStaticPages } = window;
const store = configureStore(__PRELOADED_STATE__);

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App staticPages={hxStaticPages} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
