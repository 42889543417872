import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FlexElement = styled.div`
  display: ${props => (props.$inline ? 'inline-flex' : 'flex')};
  flex-direction: ${props => props.$direction};
  justify-content: ${props => props.$justify};
  align-items: ${props => props.$align};
  gap: ${props => props.$gap};
`;

const Flex = ({
  direction,
  justify,
  align,
  gap,
  inline,
  style,
  as,
  children,
  ...rest
}) => {
  return (
    <FlexElement
      $direction={direction}
      $justify={justify}
      $align={align}
      $gap={gap}
      $inline={inline}
      style={style}
      as={as}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </FlexElement>
  );
};

Flex.propTypes = {
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),

  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
  ]),

  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'baseline',
  ]),

  gap: PropTypes.string,

  inline: PropTypes.bool,

  style: PropTypes.objectOf(PropTypes.any),
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Flex.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
  gap: '0',
  inline: false,
  as: 'div',
  style: {},
};

export default Flex;
