import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Chevron from './Chevron';

const DetailsWrapper = styled.section`
  position: relative;
  display: block;
  width: 100%;
`;

const DetailsSummary = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

const DetailsContentWrapper = styled.div`
  position: relative;
  display: block;
  height: ${props => (props.open ? `${props.maxHeight}px` : 0)};

  transition: height 0.4s ease;
  will-change: height;

  overflow: hidden;
`;

const DetailsContent = styled.div`
  position: relative;
  display: block;

  opacity: ${props => (props.open ? 1 : 0)};
  transition: opacity 0.4s ease;

  overflow: hidden;
`;

const Details = ({ summary, open: defaultOpen, children, onContentClick }) => {
  const content = useRef();

  const [open, setOpen] = useState(defaultOpen);
  const [maxHeight, setMaxheight] = useState(0);

  useEffect(() => {
    if (defaultOpen !== open) {
      setOpen(defaultOpen);
    }
  }, [defaultOpen]);

  useEffect(() => {
    const { current } = content;

    if (current) {
      const { height } = current.getBoundingClientRect();

      setMaxheight(height + 1);
    }
  }, [content, children]);

  return (
    <DetailsWrapper>
      {summary && (
        <DetailsSummary onClick={() => setOpen(!open)}>
          <span>{summary}</span>
          <Chevron direction={open ? 'up' : 'down'} size="8px" />
        </DetailsSummary>
      )}

      <DetailsContentWrapper open={open} maxHeight={maxHeight}>
        <DetailsContent ref={content} open={open} onClick={onContentClick}>
          {children}
        </DetailsContent>
      </DetailsContentWrapper>
    </DetailsWrapper>
  );
};

Details.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,

  children: PropTypes.node.isRequired,
  onContentClick: PropTypes.func,
};

Details.defaultProps = {
  summary: null,
  open: false,
  onContentClick: null,
};

export default Details;
