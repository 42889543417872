import PropTypes from 'prop-types';
import React from 'react';

const LogoutIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-power"
  >
    <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
    <line x1="12" y1="2" x2="12" y2="12" />
  </svg>
);

LogoutIcon.propTypes = {
  size: PropTypes.number,
};

LogoutIcon.defaultProps = {
  size: 12,
};

export default LogoutIcon;
