import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { usePageTitle } from '../../hooks';
import Paths from './paths';

const AdminIndex = () => {
  usePageTitle('Administration');

  const history = useHistory();

  useEffect(() => {
    history.replace(Paths.Admin({ resource: 'aisles' }));
  }, []);

  return null;
};

AdminIndex.propTypes = {};

export default {
  path: Paths.AdminIndex(),
  exact: true,
  name: 'Back Office',
  component: AdminIndex,
};
