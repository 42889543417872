const FIELD_TYPES = {
  Row: 'Row',
  Col: 'Col',
  Raw: 'Raw',
  Resource: 'Resource',
  Stock: 'Stock',
  ImageUpload: 'Image-Upload',
};

export default FIELD_TYPES;
