import PropTypes from 'prop-types';
import { publish } from 'pubsub-js';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  PUBSUB_TOPICS,
  usePageTitle,
  useSubscriber,
  useTimeout,
} from '../../hooks';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import AdminSidebar from '../elements/AdminSidebar';
import Button from '../elements/Button';
import AdminLayout from '../layouts/AdminLayout';
import Paths from './paths';

const AdminContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 4 / span 9;
  padding-bottom: 120px;
  padding-top: 70px;

  color: ${Colors.white};
  background-color: ${Colors.lightGrey};
`;

const Controls = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: ${Colors.darkGrey};
  z-index: 900;

  .inner {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: ${Sizing.gutterWidth};
    width: 100%;
    max-width: ${Sizing.wrapperWidth};
    margin: 0 auto;
    padding: 0 ${Sizing.gutterWidth};

    .left,
    .right {
      position: relative;
      display: flex;
      align-items: center;
      height: 80px;

      color: ${Colors.white};
    }

    .left {
      grid-column: 1 / span 3;

      background-color: ${Colors.black};

      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;

        background-color: inherit;
        z-index: -1;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%;

        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: ${Colors.white};
        text-decoration: none;
      }
    }

    .right {
      grid-column: 4 / span 9;
      justify-content: flex-end;
    }
  }
`;

const Admin = ({ match, currentUser }) => {
  usePageTitle('Administration');

  const { params } = match;
  const { resource, id, userID } = params;

  const [saved, setSaved] = useState(false);

  const publishSaveRequest = () => {
    publish(PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_REQUESTED);
  };

  const publishCreateUserRequest = () => {
    publish(PUBSUB_TOPICS.CREATE_USER_PAGE_REQUESTED);
  };

  useSubscriber(PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_RESOLVED, () =>
    setSaved(true),
  );

  useTimeout(() => setSaved(false), 2000, [saved]);

  const shouldShowPublishButton = resource === 'users' ? id && userID : id;
  const shouldShowCreateUserButton = resource === 'users' && id && !userID;

  return (
    <>
      <AdminSidebar
        isInternal={currentUser.isInternal}
        customerID={currentUser.customerID}
      />

      <AdminContent>
        <AdminLayout
          isInternal={currentUser.isInternal}
          currentUser={currentUser}
          resource={resource || 'aisles'}
          id={id}
        />
      </AdminContent>

      <Controls>
        <div className="inner">
          <div className="left">
            <Link to={Paths.Shop()}>Retour vers la boutique</Link>
          </div>
          <div className="right">
            {shouldShowPublishButton && (
              <Button
                color={saved ? Colors.darkGreen : Colors.white}
                background={saved ? Colors.lightGreen : Colors.primary}
                onClick={publishSaveRequest}
                style={{ fontSize: '15px', fontWeight: 'bold' }}
                disabled={saved}
                ignoreDisabledStyle
              >
                {saved ? 'Publié' : 'Publier'}
              </Button>
            )}

            {shouldShowCreateUserButton && (
              <Button
                color={Colors.white}
                background={Colors.primary}
                onClick={publishCreateUserRequest}
                style={{ fontSize: '15px', fontWeight: 'bold' }}
                ignoreDisabledStyle
              >
                Ajouter un utilisateur
              </Button>
            )}
          </div>
        </div>
      </Controls>
    </>
  );
};

Admin.propTypes = {
  currentUser: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

Admin.defaultProps = {
  currentUser: {},
};

export const ConnectedAdmin = connect(state => ({ ...state.users }), {})(Admin);

export default {
  path: Paths.Admin(),
  name: 'Back Office',
  component: ConnectedAdmin,
};
