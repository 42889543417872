import PropTypes from 'prop-types';
import { publish } from 'pubsub-js';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import { IMAGES_ACTIONS } from '../../store/actions';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import ImageDropzone from './ImageDropzone';

const ResourceUploaderContainer = styled.div`
  position: relative;
  display: block;
  grid-column: 1 / span 3;
  padding: 10px;

  background-color: ${Colors.white};

  &::before {
    content: '';
    display: block;
    position: relative;
    padding-bottom: 75%;
  }
`;

const ResourceImageUpload = ({
  category,
  type,
  code,
  images,
  fetchImage,
  uploadImage,
}) => {
  const [base64, setBase64] = useState(null);
  const [nextData, setNextData] = useState(null);

  useEffect(() => {
    fetchImage({
      sResourceCategory: category,
      sParentEntityType: type,
      sParentEntityValue: code,
    });
  }, []);

  useEffect(() => {
    const image = images[code] || {};

    if (image.B64) {
      setBase64(image.B64);
    }
  }, [images]);

  const onImageDrop = data => {
    if (base64 === data) return;

    setNextData(data);
  };

  useSubscriber(
    PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_REQUESTED,
    () => {
      uploadImage({
        sParentEntityType: type,
        sParentEntityValue: code,
        sB64Resource: nextData,
      });

      publish(PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_RESOLVED);
    },
    [nextData],
  );

  return (
    <ResourceUploaderContainer className="resource-uploader">
      {base64 ? (
        <ImageDropzone defaultImageData={base64} onImageDrop={onImageDrop} />
      ) : (
        <ImageDropzone onImageDrop={onImageDrop} />
      )}
    </ResourceUploaderContainer>
  );
};

ResourceImageUpload.propTypes = {
  category: PropTypes.oneOf(['IMG', 'DOC']),
  type: PropTypes.oneOf(['Rayon', 'Famille', 'Article']),
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  images: PropTypes.objectOf(PropTypes.any).isRequired,

  fetchImage: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
};

ResourceImageUpload.defaultProps = {
  category: 'IMG',
  type: 'Article',
};

export default connect(state => ({ ...state.images }), { ...IMAGES_ACTIONS })(
  ResourceImageUpload,
);
