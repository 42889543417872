import 'simplebar/dist/simplebar.min.css';

import PropTypes from 'prop-types';
import React from 'react';
import SimpleBar from 'simplebar-react';

const CustomScrollbar = ({ children, width, maxHeight }) => (
  <SimpleBar
    style={{
      width: `${width}px`,
      minWidth: '100%',
      maxWidth: '600px',
      maxHeight,
    }}
  >
    {children}
  </SimpleBar>
);

CustomScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
};

CustomScrollbar.defaultProps = { width: 300, maxHeight: 300 };

export default CustomScrollbar;
