import { createEndpoint, Type } from '../../models/europrisme';
import {
  GET_PREORDERS_REJECTED,
  GET_PREORDERS_REQUESTED,
  GET_PREORDERS_RESOLVED,
  GET_SINGLE_PREORDER_REJECTED,
  GET_SINGLE_PREORDER_REQUESTED,
  GET_SINGLE_PREORDER_RESOLVED,
  PREORDER_DELETE_REJECTED,
  PREORDER_DELETE_REQUESTED,
  PREORDER_DELETE_RESOLVED,
  PREORDER_SEND_CART_REJECTED,
  PREORDER_SEND_CART_REQUESTED,
  PREORDER_SEND_CART_RESOLVED,
} from '../types';

export const getPreordersRequested = () => ({
  type: GET_PREORDERS_REQUESTED,
});

export const getPreordersResolved = data => ({
  type: GET_PREORDERS_RESOLVED,
  payload: { data },
});

export const getPreordersRejected = error => ({
  type: GET_PREORDERS_REJECTED,
  payload: { error },
});

export const getPreordersEndpoint = createEndpoint(
  Type.GET,
  'getPreOrders',
  { sG5CustomerCode: '' },
  {
    request: getPreordersRequested,
    resolve: getPreordersResolved,
    reject: getPreordersRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getPreorders = () => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(getPreordersEndpoint({ sG5CustomerCode: customerID }));
};

export const getSinglePreorderRequested = params => ({
  type: GET_SINGLE_PREORDER_REQUESTED,
  payload: { params },
});

export const getSinglePreorderResolved = (data, params) => ({
  type: GET_SINGLE_PREORDER_RESOLVED,
  payload: { data, params },
});

export const getSinglePreorderRejected = (error, params) => ({
  type: GET_SINGLE_PREORDER_REJECTED,
  payload: { error, params },
});

export const getSinglePreorderEndpoint = createEndpoint(
  Type.GET,
  'getPreOrder',
  {
    sG5CustomerCode: '',
    sIDPreOrder: '',
  },
  {
    request: getSinglePreorderRequested,
    resolve: getSinglePreorderResolved,
    reject: getSinglePreorderRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getSinglePreorder = preorderID => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(
    getSinglePreorderEndpoint({
      sG5CustomerCode: customerID,
      sIDPreOrder: preorderID,
    }),
  );
};

export const sendCartAsPreorderRequested = fullParams => ({
  type: PREORDER_SEND_CART_REQUESTED,
  params: fullParams,
});

export const sendCartAsPreorderResolved = (data, fullParams, dispatch) => {
  const { __options } = fullParams;
  const { __kIDPreOrder, __hardRefresh } = __options;

  if (__kIDPreOrder && __hardRefresh) {
    dispatch(getSinglePreorder(__kIDPreOrder));
  }

  return {
    type: PREORDER_SEND_CART_RESOLVED,
    payload: { data, params: fullParams },
  };
};

export const sendCartAsPreorderRejected = (error, fullParams) => ({
  type: PREORDER_SEND_CART_REJECTED,
  payload: { error, params: fullParams },
});

export const sendCartAsPreorderEndpoint = createEndpoint(
  Type.POST,
  'processPreOrder',
  {
    kIDUser: '',
    sPreOrderJson: JSON.stringify({}),
  },
  {
    request: sendCartAsPreorderRequested,
    resolve: sendCartAsPreorderResolved,
    reject: sendCartAsPreorderRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const sendProductsAsPreorder = ({
  products,
  customerID,
  kIDUser,
  title,
  comment,
  preorderID = null,

  __hardRefresh = false,
}) => async dispatch => {
  const payload = {
    preorder: {
      kIDPreOrder: preorderID ?? -1,
      sContext: 'HX',
      sCustomerCode: customerID,
      sPreOrderTitle: title || '',
      sPreOrderComment: comment || '',
      items: {
        item: Object.values(products).map(
          ({ sCode, id, count, sFamille, sBelowComment }) => ({
            sArticleCode: sCode || id,
            sFamille: sFamille || '-1',
            sQuantite: count,
            sBelowComment: sBelowComment || '',
          }),
        ),
      },
    },
  };

  return dispatch(
    sendCartAsPreorderEndpoint({
      kIDUser,
      sPreOrderJson: JSON.stringify(payload),

      __kIDPreOrder: preorderID ?? -1,
      __hardRefresh,
    }),
  );
};

export const sendPreorderLinesAsPreorder = ({
  products,
  title,
  comment,
  preorderID,

  __hardRefresh = false,
}) => async (dispatch, getState) => {
  const { users, products: productState } = getState();
  const { products: allProducts } = productState;
  const { currentUser } = users;
  const { customerID, id } = currentUser;

  const mappedProducts = products
    .map(({ sG5ArticleCode, iQuantity }) => {
      const product = allProducts[sG5ArticleCode];

      if (!product) return null;

      const { sCode, id: productID, sFamille } = product;

      return { sCode, id: productID, count: iQuantity, sFamille };
    })
    .filter(p => p);

  dispatch(
    sendProductsAsPreorder({
      products: mappedProducts,
      customerID,
      kIDUser: id,
      preorderID,
      title,
      comment,

      __hardRefresh,
    }),
  );
};

export const sendCartAsPreorder = ({
  title,
  comment,
  preorderID = null,
}) => async (dispatch, getState) => {
  const { cart, users } = getState();

  const { currentUser } = users;
  const { customerID, id } = currentUser;

  const { present } = cart;
  const { products } = present;

  return dispatch(
    sendProductsAsPreorder({
      products,
      customerID,
      kIDUser: id,
      preorderID,
      title,
      comment,
    }),
  );
};

export const deleteSinglePreorderRequested = params => ({
  type: PREORDER_DELETE_REQUESTED,
  payload: { params },
});

export const deleteSinglePreorderResolved = (data, params) => ({
  type: PREORDER_DELETE_RESOLVED,
  payload: { data, params },
});

export const deleteSinglePreorderRejected = (error, params) => ({
  type: PREORDER_DELETE_REJECTED,
  payload: { error, params },
});

export const deleteSinglePreorderEndpoint = createEndpoint(
  Type.GET,
  'removePreOrder',
  { sG5CustomerCode: '', sIDPreOrder: -1 },
  {
    request: deleteSinglePreorderRequested,
    resolve: deleteSinglePreorderResolved,
    reject: deleteSinglePreorderRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const deleteSinglePreorder = preorderID => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users || {};
  const { customerID } = currentUser || {};

  return dispatch(
    deleteSinglePreorderEndpoint({
      sG5CustomerCode: customerID,
      sIDPreOrder: preorderID,
    }),
  );
};

export const PREORDER_ACTIONS = {
  sendCartAsPreorder,
  getPreorders,
  getSinglePreorder,
  deleteSinglePreorder,
  sendPreorderLinesAsPreorder,
};
