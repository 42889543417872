import { createEndpoint, Type } from '../../models/europrisme';
import {
  GET_AISLES_REJECTED,
  GET_AISLES_REQUESTED,
  GET_AISLES_RESOLVED,
  GET_SINGLE_AISLE_REQUESTED,
  GET_SINGLE_AISLE_RESOLVED,
} from '../types';

export const fetchAislesRequested = () => ({ type: GET_AISLES_REQUESTED });

export const fetchAislesRejected = error => ({
  type: GET_AISLES_REJECTED,
  payload: { error },
});

export const fetchAislesResolved = data => ({
  type: GET_AISLES_RESOLVED,
  payload: { data },
});

export const fetchAisles = createEndpoint(
  Type.GET,
  'getRayons',
  {},
  {
    request: fetchAislesRequested,
    resolve: fetchAislesResolved,
    reject: fetchAislesRejected,
  },
);

export const fetchSingleAisleRequested = () => ({
  type: GET_SINGLE_AISLE_REQUESTED,
});

export const fetchSingleAisleRejected = error => ({
  type: GET_SINGLE_AISLE_REQUESTED,
  payload: { error },
});

export const fetchSingleAisleResolved = data => ({
  type: GET_SINGLE_AISLE_RESOLVED,
  payload: { data },
});

export const fetchSingleAisle = createEndpoint(
  Type.GET,
  'getRayon',
  {
    sRayonCode: '',
  },
  {
    request: fetchSingleAisleRequested,
    resolve: fetchSingleAisleResolved,
    rejected: fetchSingleAisleRejected,
  },
);

export const AISLES_ACTIONS = { fetchAisles, fetchSingleAisle };
