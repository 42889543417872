import PropTypes from 'prop-types';
import React from 'react';
import styled, { css as styledCSS } from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';

const ButtonComponent = styled.button`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: ${props =>
    props.$square || props.$borderless ? 'unset' : '140px'};

  padding: ${props => {
    if (props.$borderless) {
      return 0;
    }

    if (props.$square) {
      return '8px';
    }

    return '10px 30px';
  }};

  text-decoration: none;
  color: ${props => props.$color};
  background-color: ${props => props.$background};
  border: none;
  border-radius: ${props =>
    props.$borderless ? 0 : Sizing.borderRadius.buttons};
  outline: none;

  cursor: pointer;

  transition-property: color, background-color, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    box-shadow: 0 0 0 0 ${props => props.$background};
    border-radius: 2px;
    opacity: 0;

    transition-property: box-shadow, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    pointer-events: none;
    isolation: isolate;
  }

  &:hover,
  &:focus-visible {
    color: ${props => props.$hoverColor};
    background-color: ${props => props.$hoverBackground};
  }

  &:hover,
  &:focus-visible {
    &::before {
      box-shadow: 0 0 0 4px ${props => props.$hoverBackground};
      opacity: 0.4;
    }
  }

  ${props =>
    props.disabled &&
    styledCSS`
      pointer-events: none;
      opacity: ${props.$ignoreDisabledStyle ? 1 : 0.5};
    `}

  ${props =>
    props.$shade &&
    styledCSS`
        box-shadow: inset 0 0 0 3px ${props.$shade};
    `}

  svg {
    margin-right: ${props => (props.$iconOnly ? 0 : '5px')};
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: stretch;
  width: 100%;

  > * {
    white-space: nowrap;

    &:not(:first-child):not(:last-child) {
      border-radius: 0 !important;
    }

    &:first-child {
      ${props =>
        props.vertical
          ? styledCSS`
            border-radius: 20px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `
          : styledCSS`
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            `}

      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    &:last-child {
      ${props =>
        props.vertical
          ? styledCSS`
            border-radius: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          `
          : styledCSS`
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            `}
    }
  }
`;

export const ButtonGroup = ({ vertical, style, children }) => (
  <Group vertical={vertical} style={style}>
    {children}
  </Group>
);

ButtonGroup.propTypes = {
  vertical: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node.isRequired,
};

ButtonGroup.defaultProps = {
  vertical: false,
  style: {},
};

const Button = ({
  fromComponent,
  color,
  background,
  hoverColor,
  hoverBackground,
  shade,
  type,
  style,
  iconOnly,
  ignoreDisabledStyle,
  square,
  borderless,
  children,
  ...rest
}) => {
  return (
    <ButtonComponent
      as={fromComponent}
      type={type}
      style={style}
      $color={color}
      $background={background}
      $hoverColor={hoverColor || color}
      $hoverBackground={hoverBackground || background}
      $shade={shade}
      $iconOnly={iconOnly}
      $ignoreDisabledStyle={ignoreDisabledStyle}
      $square={square}
      $borderless={borderless}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

Button.propTypes = {
  fromComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),

  type: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBackground: PropTypes.string,
  shade: PropTypes.string,
  iconOnly: PropTypes.bool,
  ignoreDisabledStyle: PropTypes.bool,
  square: PropTypes.bool,
  borderless: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),

  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  fromComponent: 'button',

  type: 'button',
  color: Colors.black,
  background: Colors.white,
  hoverColor: Colors.white,
  hoverBackground: Colors.primary,
  shade: 'transparent',
  iconOnly: false,
  ignoreDisabledStyle: false,
  square: false,
  borderless: false,
  style: {},
};

Button.Group = ButtonGroup;

export default Button;
