import PropTypes from 'prop-types';
import React from 'react';

const CloseIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
};

CloseIcon.propTypes = {
  size: PropTypes.number,
};

CloseIcon.defaultProps = {
  size: 15,
};

export default CloseIcon;
