import { isArray } from 'lodash';

export const localizationLevels = [
  { plural: 'Sites', singular: 'Site' },
  { plural: 'Buildings', singular: 'Building' },
  { plural: 'Services', singular: 'Service' },
  { plural: 'Bedrooms', singular: 'Bedroom' },
  { plural: 'Positions', singular: 'Position' },
];

export const parseLocationTree = (
  locationData,
  { depth = 0, parentKeys = [] } = {},
) => {
  if (!isArray(locationData) || locationData.length < 1) {
    return null;
  }

  const children = locationData
    .map(node => {
      if (!node) {
        return null;
      }

      const { text, iEntityType, kIDEntity } = node;
      const { plural, singular } = localizationLevels[depth] || {};

      const key = `${depth}__${iEntityType}--${kIDEntity}`;

      if (text === 'Vide') {
        return null;
      }

      const grandChildren = (() => {
        if (node && plural && plural in node) {
          const subNode = node[plural];

          if (subNode && singular && singular in subNode) {
            const grandChildNode = subNode[singular];

            if (!isArray(grandChildNode)) {
              return [grandChildNode];
            }

            return grandChildNode;
          }
        }

        return null;
      })();

      const parsedGrandChildren = parseLocationTree(grandChildren, {
        depth: depth + 1,
        parentKeys: [...parentKeys, key],
      })?.children;

      return {
        key,
        value: key,
        label: text,
        depth: depth + 1,
        children: parsedGrandChildren,
        parentKeys,
        userData: { iEntityType, kIDEntity },
      };
    })
    .filter(node => node);

  return {
    key: '__ROOT__',
    value: '__ROOT__',
    label: 'ROOT',
    depth,
    parentKeys,
    children,
  };
};

export const findLocationTreeNodePath = (treeNode, searchItem) => {
  if (!treeNode || !searchItem) {
    return [];
  }

  if (treeNode.key === searchItem) {
    return [searchItem];
  }

  if (!treeNode.children || treeNode.children.length === 0) {
    return [];
  }

  if (
    treeNode.children &&
    treeNode.children.find(child => child.key === searchItem.key)
  ) {
    return [treeNode, searchItem];
  }

  const search = treeNode.children
    .map(child => findLocationTreeNodePath(child, searchItem))
    .find(path => path && path.length > 0);

  if (search) {
    return [treeNode, ...search];
  }

  return [];
};
