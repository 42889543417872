import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const HR = styled.hr`
  position: relative;
  width: 100%;

  color: ${props => props.color || 'white'};
  border: none;
  border-top: 1px solid currentColor;

  overflow: visible;

  ${props =>
    props.text &&
    css`
  &::after {
    content: '${props.text}';
    position: absolute;
    display: inline-block;
    padding: 0 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: currentColor;
    background-color: ${props.background || 'transparent'};
  }
  `}
`;

const HorizontalRule = ({ text, color, background, style }) => {
  return <HR text={text} color={color} background={background} style={style} />;
};

HorizontalRule.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

HorizontalRule.defaultProps = {
  text: null,
  color: null,
  background: null,
  style: {},
};

export default HorizontalRule;
