import PropTypes from 'prop-types';
import React from 'react';

const AssignIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 6.35 6.35"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".529"
    >
      <path d="M.794 3.174h3.175M2.381 1.587 3.97 3.174 2.381 4.762" />
      <path d="M1.043 1.302A2.646 2.646 0 0 1 3.926.73a2.646 2.646 0 0 1 1.633 2.445 2.646 2.646 0 0 1-1.633 2.444 2.646 2.646 0 0 1-2.883-.574" />
    </svg>
  );
};

AssignIcon.propTypes = {
  size: PropTypes.number,
};

AssignIcon.defaultProps = {
  size: 15,
};

export default AssignIcon;
