import PropTypes from 'prop-types';
import React from 'react';

const ShoppingCartIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-shopping-cart"
    >
      <circle cx="9" cy="21" r="1" />
      <circle cx="20" cy="21" r="1" />
      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
    </svg>
  );
};

ShoppingCartIcon.propTypes = {
  size: PropTypes.number,
};

ShoppingCartIcon.defaultProps = {
  size: 15,
};

export default ShoppingCartIcon;
