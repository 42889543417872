import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CheckIcon from '../../../assets/img/CheckIcon';
import ShoppingCartIcon from '../../../assets/img/ShoppingCartIcon';
import { CART_ACTIONS } from '../../store/actions';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import BlockPlaceholder from './BlockPlaceholder';
import Button from './Button';
import NumberInput from './NumberInput';
import ProductPrice, { PriceMode } from './price/ProductPrice';
import ResourceImage from './ResourceImage';

const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px 15px 20px;

  text-decoration: none;
  color: ${Colors.black};
  background-color: #ffffff;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    padding: 0 12px 8px 12px;
  }

  a {
    width: 100%;

    text-decoration: none;
    color: ${Colors.black};

    h4 {
      margin: 0;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      color: #1b1b1b;
      font-weight: 400;

      &.missing {
        opacity: 0.5;
      }
    }

    > .price {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 15px;

      p {
        margin: 0;

        font-size: 12px;
        line-height: 14px;
        opacity: 0.75;
      }
    }
  }

  a.see-product {
    flex-grow: 1;
    margin-right: 20px;
  }
`;

const ProductCardImageWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  margin: 15px 0;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    height: 120px;
  }
`;

const CardAddToCart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    display: grid;
    grid-template-columns: auto auto;
    gap: 6px 0;

    .see-product {
      grid-column: 1 / span 2;
      grid-row: 2;
      margin-right: 0 !important;
    }
  }

  .add-to-cart--container {
    position: relative;

    button.add-to-cart {
      min-width: unset;
      padding: 7.5px;
      margin-left: 5px;

      svg {
        margin-right: 0;

        &.feather-shopping-cart {
          transform: translateX(-1px);
        }
      }
    }

    .added-to-cart-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: calc(100% - 9px);
      left: calc(100% - 9px);
      padding: 3px;

      color: ${Colors.darkGreen};
      background-color: ${Colors.lightGreen};
      border-radius: 50%;

      pointer-events: none;
    }
  }
`;

const RentalLabel = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 7px;

  color: ${Colors.white};
  background-color: ${Colors.primary};
  border-radius: 20px;
  font-size: 12px;
  line-height: 1;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);

  pointer-events: none;
  z-index: 0;
`;

const ProductCard = ({ product, cartState, setCartItemCount }) => {
  const { sLibelle, sCode, iIsLoc } = product;
  const isRental = Number.parseInt(iIsLoc, 10) === 1;

  const [count, setCount] = useState(1);
  const [addToCartClicked, setAddToCartClicked] = useState(false);

  const isInCart = Object.keys(cartState?.present?.products).includes(
    `${sCode}`,
  );

  const quickAddToCart = event => {
    event.preventDefault();

    setCartItemCount({ product, count, add: true });
    setAddToCartClicked(true);
  };

  // // For some reason now, don't revert to the regular mode
  useEffect(() => {
    if (addToCartClicked) {
      const id = setTimeout(() => {
        setAddToCartClicked(false);
      }, 1000);

      return () => clearTimeout(id);
    }
  }, [addToCartClicked]);

  return (
    <ProductCardContainer>
      <Link to={Paths.SingleProduct({ productID: sCode })}>
        <ProductCardImageWrapper>
          {sCode && (
            <ResourceImage
              code={sCode}
              loader={<BlockPlaceholder height={200} />}
            />
          )}
        </ProductCardImageWrapper>

        <h4 className={sLibelle ? '' : 'missing'}>
          {sLibelle || 'Libellé manquant'}
        </h4>

        <div className="price">
          {isRental && <RentalLabel className="rental">LOC</RentalLabel>}

          <ProductPrice
            product={product}
            mode={PriceMode.PRODUCT_ARCHIVE}
            style={{ marginTop: 'auto' }}
          />
        </div>
      </Link>

      <CardAddToCart>
        <Button
          fromComponent={Link}
          to={Paths.SingleProduct({ productID: sCode })}
          className="see-product"
          hoverBackground={Colors.primary}
          hoverColor={Colors.white}
        >
          Détails
        </Button>
        <NumberInput value={1} min={1} onChange={setCount} small />

        <div className="add-to-cart--container">
          <Button
            type="button"
            className="add-to-cart"
            color={addToCartClicked ? Colors.darkGreen : Colors.white}
            background={addToCartClicked ? Colors.lightGreen : Colors.primary}
            onClick={quickAddToCart}
            disabled={addToCartClicked}
          >
            {addToCartClicked ? <CheckIcon /> : <ShoppingCartIcon />}
          </Button>

          {isInCart && !addToCartClicked && (
            <div className="added-to-cart-icon">
              <CheckIcon size={10} />
            </div>
          )}
        </div>
      </CardAddToCart>
    </ProductCardContainer>
  );
};

ProductCard.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,

  cartState: PropTypes.shape({
    present: PropTypes.shape({
      products: PropTypes.objectOf(PropTypes.any),
    }),
  }).isRequired,

  setCartItemCount: PropTypes.func.isRequired,
};

export const ConnectedProductCard = connect(
  state => ({ cartState: state.cart }),
  {
    ...CART_ACTIONS,
  },
)(ProductCard);

export default ConnectedProductCard;
