import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IMAGES_ACTIONS } from '../../store/actions';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import PageTitle from './PageTitle';
import Select from './Select';

const ShopLayoutHeaderContainer = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 240px;
  padding-top: 50px;
  padding-bottom: 35px;

  background-color: ${Colors.white};
  color: ${Colors.black};
  border-bottom: 1px solid currentColor;

  z-index: 200;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    min-height: unset;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;

    animation-name: Animation-Shop-Layout-Header-Image;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: ease;

    mix-blend-mode: lighten;

    z-index: -1;

    @keyframes Animation-Shop-Layout-Header-Image {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
`;

const ShopLayoutHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: center;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    justify-content: space-between !important;

    &:last-child {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  h2 {
    margin: 0;
    max-width: 75%;
    height: unset;
    align-items: flex-start;

    &.desktop-only {
      @media all and (max-width: ${Sizing.mobileWidth}) {
        display: none;
      }
    }

    &.mobile-only {
      @media all and (min-width: ${Sizing.mobileWidth}) {
        display: none;
      }
    }

    svg {
      width: 30px;
      height: 55px;
    }

    span {
      display: flex;
      align-items: center;
      height: 55px;

      font-size: 50px;
      font-weight: bold;
    }
  }

  em {
    margin-left: 30px;
    margin-bottom: 16px;
    align-self: flex-end;

    font-size: 8px;
    line-height: 9px;
    color: ${Colors.black};
    font-weight: 500;

    opacity: 0.75;
  }

  h3 {
    margin: 30px 0 0 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;

    @media all and (max-width: ${Sizing.mobileWidth}) {
      margin-top: 0;

      font-size: 14px;
      line-height: 20px;

      &.desktop-only {
        display: none;
      }
    }
  }

  label {
    position: relative;
    align-self: flex-end;

    span {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-right: 1ch;
    }

    @media all and (max-width: ${Sizing.mobileWidth}) {
      font-size: 14px !important;

      span {
        font-size: inherit !important;
        line-height: 20px;
      }
    }
  }
`;

const ShopLayoutHeader = ({
  aisle,
  family,
  products,
  images,
  fetchImage,
  handleFilter,
  isStatic,
}) => {
  const currentResource = family || aisle;
  const currentResourceType = family ? 'Famille' : 'Rayon';

  const image = images[(currentResource || {}).sCode];

  useEffect(() => {
    if (!image && currentResource) {
      fetchImage({
        sParentEntityType: currentResourceType,
        sParentEntityValue: currentResource.sCode,
      });
    }
  }, [currentResource]);

  const handleFilterChange = ({
    label,
    'data-key': sSortField,
    'data-order': sSortWay,
  }) => {
    const nextFilter = { label, sSortField, sSortWay };

    if (handleFilter) {
      handleFilter(nextFilter);
    }
  };

  const options = [
    { value: 'default', label: 'défaut' },
    {
      value: 'asc',
      label: 'ordre alphabétique croissant',
      'data-key': 'Libelle',
      'data-order': 'ASC',
    },
    {
      value: 'desc',
      label: 'ordre alphabétique décroissant',
      'data-key': 'Libelle',
      'data-order': 'DESC',
    },
  ];

  const getUpperTitle = ({ mobile = false } = {}) => {
    if (mobile) {
      return aisle?.sLibelle ? aisle.sLibelle : 'Tous les rayons';
    }

    if (isStatic) {
      return 'À la une';
    }

    return family?.sLibelle ? family.sLibelle : 'Toutes les familles';
  };

  const getLowerTitle = () => {
    if (isStatic) {
      return null;
    }

    return aisle ? aisle.sLibelle : 'Tous les rayons';
  };

  return (
    <ShopLayoutHeaderContainer>
      <ShopLayoutHeaderRow>
        <PageTitle fromComponent="h2" className="desktop-only">
          {getUpperTitle({ mobile: false })}
        </PageTitle>

        <PageTitle fromComponent="h2" className="mobile-only">
          {getUpperTitle({ mobile: true })}
        </PageTitle>

        <em>{products.length} Produits</em>
      </ShopLayoutHeaderRow>

      <ShopLayoutHeaderRow justifyContent="space-between">
        {!isStatic && (
          <>
            <h3 className="desktop-only">{getLowerTitle()}</h3>
            <Select
              options={options}
              placeholder="défaut"
              defaultValue={{ value: 'default', label: 'défaut' }}
              onChange={handleFilterChange}
              styles={{
                container: provided => ({
                  ...provided,
                  display: 'inline-block',
                }),

                control: provided => ({
                  ...provided,
                  background: 'none',
                  border: 'none',
                  boxShadow: 'none',
                  minHeight: 'unset',
                }),

                valueContainer: provided => ({
                  ...provided,
                  padding: '4px',
                }),

                singleValue: provided => ({
                  ...provided,
                  position: 'relative',
                  maxWidth: 'unset',
                  top: 'unset',
                  margin: '0',
                  transform: 'unset',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: Colors.lightGrey,
                }),

                input: provided => ({ ...provided, position: 'absolute' }),

                indicatorsContainer: () => ({ display: 'none' }),

                menu: provided => ({
                  ...provided,
                  width: 'unset',
                  minWidth: '100%',
                  left: 'unset',
                  right: 0,
                }),

                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? Colors.pureWhite : Colors.lightGrey,
                }),
              }}
            >
              Résultats triés par
            </Select>
          </>
        )}
      </ShopLayoutHeaderRow>
    </ShopLayoutHeaderContainer>
  );
};

ShopLayoutHeader.propTypes = {
  aisle: PropTypes.objectOf(PropTypes.any),
  family: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),

  images: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchImage: PropTypes.func.isRequired,
  handleFilter: PropTypes.func,
  isStatic: PropTypes.bool,
};

ShopLayoutHeader.defaultProps = {
  aisle: null,
  family: null,
  products: [],

  handleFilter: null,
  isStatic: false,
};

const ConnectedShopLayoutHeader = connect(state => ({ ...state.images }), {
  ...IMAGES_ACTIONS,
})(ShopLayoutHeader);

export default ConnectedShopLayoutHeader;
