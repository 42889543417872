import PropTypes from 'prop-types';
import React from 'react';

import Paths from '../routes/paths';
import Sidebar from './Sidebar';
import SidebarEntry from './SidebarEntry';

const AdminSidebar = ({ isInternal, customerID }) => {
  return (
    <Sidebar isAdmin>
      <h3>Back Office</h3>

      {isInternal && (
        <>
          <SidebarEntry
            label="Rayons"
            to={Paths.Admin({ resource: 'aisles' })}
            isAdmin
          />

          <SidebarEntry
            label="Familles"
            to={Paths.Admin({ resource: 'families' })}
            isAdmin
          />

          <SidebarEntry
            label="Articles"
            to={Paths.Admin({ resource: 'products' })}
            isAdmin
          />
        </>
      )}

      <SidebarEntry
        label="Utilisateurs"
        to={Paths.Admin({
          resource: 'users',
          id: isInternal ? null : customerID,
        })}
        isAdmin
      />
    </Sidebar>
  );
};

AdminSidebar.propTypes = {
  isInternal: PropTypes.bool,
  customerID: PropTypes.string.isRequired,
};

AdminSidebar.defaultProps = {
  isInternal: false,
};

export default AdminSidebar;
