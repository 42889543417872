import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { usePageTitle } from '../../hooks';
import { USERS_ACTIONS } from '../../store/actions/reducer-actions/users';
import { ResetPasswordStatus } from '../../store/reducers/users';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import FadedImage from '../elements/FadedImage';
import HeroHeader from '../elements/HeroHeader';
import Input from '../elements/Input';
import {
  NotificationType,
  sendNotification,
} from '../elements/NotificationArea';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  width: 100%;
  max-width: ${Sizing.wrapperWidth};

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    gap: 10px;
  }
`;

const LoginForm = styled.form`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  grid-column: 1 / span 6;
  padding: 30px 0;

  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
  }
`;

const LoginFormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 2 / span 4;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 6;
    padding: 0 16px;

    h1,
    h1 span.children {
      font-size: 26px;
      line-height: 28px;
    }
  }

  label {
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};

  > :first-child {
    grid-column: 1 / span 2;
  }

  > :last-child {
    grid-column: 3 / span 2;
  }

  ${props =>
    props.flex &&
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 40px;

      > * {
        width: 100%;
      }

      label {
        margin-bottom: 0;
      }

      .error-notification {
        position: absolute;
        top: calc(100% + 3px);
        left: 0;

        color: ${Colors.darkRed};
        font-size: 12px;
        font-style: italic;
      }
    `}
`;

const ResetPassword = ({ status, data, idEmailLog, email, resetPassword }) => {
  usePageTitle('Changer mon mot de passe');

  const history = useHistory();

  const [key, setKey] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const isResetPending = status === ResetPasswordStatus.RESET_PENDING;
  const isPasswordMatch = password === passwordConfirm;
  const isFormValid =
    isPasswordMatch && key.length && password.length && passwordConfirm.length;

  useEffect(() => {
    if (status === ResetPasswordStatus.RESET_RESOLVED) {
      const { Message } = data.xmlst;

      sendNotification(Message, NotificationType.SUCCESS);
      history.push(Paths.Login());
    } else if (status === ResetPasswordStatus.ERROR) {
      sendNotification(
        'Une erreur est survenue lors de la réinitialisation du mot de passe',
        NotificationType.ERROR,
      );
    }
  }, [status]);

  const handleSubmit = event => {
    event.preventDefault();

    if (isFormValid) {
      resetPassword({
        sUserIdentifier: email,
        kIDEMailLog: idEmailLog,
        sEMailLogKey: key,
        sUserPassword: password,
      });
    }

    return false;
  };

  return (
    <>
      <HeroHeader showSearch={false} fullHeight>
        <FadedImage
          src="/public/img/hero_header.png"
          x="25%"
          id="reset-password"
        />

        <Contents>
          <LoginForm onSubmit={handleSubmit}>
            <LoginFormInner>
              <PageTitle
                margin="0 0 24px 0"
                style={{ gridColumn: '1 / span 4', color: Colors.white }}
              >
                Modification du mot de passe
              </PageTitle>

              <Input
                type="text"
                label="Saisir la clé reçue par mail"
                color={Colors.white}
                background={Colors.primary}
                onChange={setKey}
                disabled={isResetPending}
                required
                square
              />

              <Input
                type="password"
                label="Nouveau mot de passe"
                color={Colors.white}
                background={Colors.primary}
                onChange={setPassword}
                disabled={isResetPending}
                required
                square
              />

              <Row flex>
                <Input
                  type="password"
                  label="Confirmer votre nouveau mot de passe"
                  color={Colors.white}
                  background={Colors.primary}
                  onChange={setPasswordConfirm}
                  disabled={isResetPending}
                  required
                  square
                />

                {!isPasswordMatch && (
                  <span className="error-notification">
                    Le mot de passe ne correspond pas
                  </span>
                )}
              </Row>

              <Row>
                <Button
                  type="button"
                  fromComponent={Link}
                  to={Paths.Login()}
                  color={Colors.black}
                  background={Colors.white}
                  hoverBackground={Colors.lightGrey}
                  disabled={isResetPending}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                  }}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  color={Colors.white}
                  background={Colors.primary}
                  disabled={!isFormValid || isResetPending}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                  }}
                >
                  Valider
                </Button>
              </Row>
            </LoginFormInner>
          </LoginForm>
        </Contents>
      </HeroHeader>
    </>
  );
};

ResetPassword.propTypes = {
  status: PropTypes.oneOf(Object.values(ResetPasswordStatus)).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  idEmailLog: PropTypes.string,
  email: PropTypes.string,

  resetPassword: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
  idEmailLog: '',
  email: '',
};

export const ConnectedResetPassword = connect(
  state => ({ ...state.users.passwordReset }),
  { ...USERS_ACTIONS },
)(ResetPassword);

export default {
  path: Paths.ResetPassword(),
  exact: true,
  name: 'ResetPassword',
  component: ConnectedResetPassword,
};
