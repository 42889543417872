import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { PUBSUB_TOPICS, useSubscriber } from '../../../../hooks';
import { USERS_ACTIONS } from '../../../../store/actions/reducer-actions/users';
import { Colors } from '../../../../styles/vars';
import appConfig from '../../../../utils/app-config';
import { connect } from '../../../../utils/redux';
import Input from '../../../elements/Input';
import {
  NotificationType,
  sendNotification,
} from '../../../elements/NotificationArea';
import Select from '../../../elements/Select';
import Paths from '../../../routes/paths';
import {
  civilityOptions,
  roleOptions,
  selectStyles,
  selectTheme,
} from '../admin-utils';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: flex-start;

  input[type='submit'] {
    display: none;
    visibility: hidden;
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 360px;
  margin-bottom: 20px;

  span {
    margin-bottom: 8px;

    font-size: 14px;
    line-height: 21px;
    color: ${Colors.white};
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    display: block;
    width: 100%;
    height: 40px;
    padding: 0 15px;

    background-color: ${Colors.primary};
    color: ${Colors.white};
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }

  &.select {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 18px;
      right: 15px;
      width: 8px;
      height: 8px;

      border-bottom: 1px solid white;
      border-right: 1px solid white;

      transform-origin: center;
      transform: rotate(45deg);

      pointer-events: none;
    }
  }
`;

const UpdateUser = ({
  for: clientID,
  userID,
  usersState,
  fetchSingleUser,
  updateUser,
}) => {
  const history = useHistory();

  const submit = useRef();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [civility, setCivility] = useState(1);
  const [role, setRole] = useState(0);

  const { users, currentUser } = usersState;

  const usersForClient = users[clientID] || {};
  const [user] = (usersForClient.records || []).filter(
    u => u.kIDUser === userID,
  );

  useEffect(() => {
    if (!user) {
      fetchSingleUser({
        sCustomerCode: clientID,
        kIDUser: userID,
      });
    }
  }, [clientID, userID]);

  useEffect(() => {
    if (user) {
      setFirstname(user.sUserFirstName);
      setLastname(user.sUserLastName);
      setEmail(user.sUserIdentifier);
      setCivility(user.kIDCivility);
      setRole(user.isAdmin);
    }
  }, [user]);

  useSubscriber(PUBSUB_TOPICS.ADMIN_RESOURCE_SAVE_REQUESTED, () => {
    const { current } = submit;

    if (current) {
      current.click();
    }
  });

  useSubscriber(PUBSUB_TOPICS.UPDATE_USER_SUCCESS, () => {
    history.push(
      Paths.Admin({
        resource: 'users',
        id: clientID,
      }),
    );
  });

  const handleSubmit = event => {
    event.preventDefault();

    const internalDomains = appConfig.get('settings.internalDomains');

    const reg = new RegExp(`@(${internalDomains.join('|')})$`);
    const matches = email.match(reg);

    if (clientID !== 'internal' || (clientID === 'internal' && matches)) {
      updateUser({
        kIDUser: userID,
        sUserIdentifier: email,
        sCustomerCode: clientID === 'internal' ? '' : clientID,
        sUserFirstName: firstname,
        sUserLastName: lastname,
        kIDCivility: civility,
        kIDRole: role,
      });
    } else {
      const domainList = internalDomains.join(', ');

      const domainLabel =
        internalDomains.length > 1
          ? "L'adresse e-mail doit être dans l'un des domaines :"
          : "L'adresse e-mail doit être dans le domaine :";

      sendNotification(
        [domainLabel, domainList].join(' '),
        NotificationType.WARNING,
      );
    }

    return false;
  };

  if (!user || !currentUser) {
    return <span>Chargement...</span>;
  }

  const roleOptionsForCurrentUser = roleOptions(currentUser);

  return (
    <Form onSubmit={handleSubmit}>
      <Label>
        <span>Prénom</span>
        <Input
          type="text"
          name="firstname"
          background={Colors.primary}
          color={Colors.white}
          value={user.sUserFirstName}
          onChange={setFirstname}
          square
          required
        />
      </Label>

      <Label>
        <span>Nom de famille</span>
        <Input
          type="text"
          name="lastname"
          background={Colors.primary}
          color={Colors.white}
          value={user.sUserLastName}
          onChange={setLastname}
          square
          required
        />
      </Label>

      <Label>
        <span>E-mail</span>
        <Input
          type="email"
          name="email"
          background={Colors.primary}
          color={Colors.white}
          value={user.sUserIdentifier}
          onChange={setEmail}
          square
          required
        />
      </Label>

      <Label className="select">
        <span>Civilité</span>

        <Select
          placeholder="Choisir une civilité"
          options={civilityOptions}
          defaultValue={civilityOptions.find(
            ({ value }) => value === user.kIDCivility,
          )}
          onChange={({ value }) => setCivility(value)}
          expand
          required
          theme={selectTheme}
          styles={selectStyles}
        />
      </Label>

      <Label className="select">
        <span>Rôle</span>

        <Select
          placeholder="Choisir un rôle"
          options={roleOptionsForCurrentUser}
          defaultValue={roleOptionsForCurrentUser.find(
            ({ value }) => value === user.isAdmin,
          )}
          onChange={({ value }) => setRole(value)}
          expand
          required
          theme={selectTheme}
          styles={selectStyles}
        />
      </Label>

      <input type="submit" value="Hidden submit" ref={submit} />
    </Form>
  );
};

UpdateUser.propTypes = {
  for: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,

  usersState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const ConnectedUpdateUser = connect(
  state => ({ usersState: state.users /* Format */ }),
  { ...USERS_ACTIONS },
)(withRouter(UpdateUser));

export default ConnectedUpdateUser;
