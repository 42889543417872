import {
  CANCEL_GET_PRODUCT_DELAY,
  GET_PRODUCT_DELAY_REJECTED,
  GET_PRODUCT_DELAY_REQUESTED,
  GET_PRODUCT_DELAY_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  delays: {},
  error: null,
  status: Status.IDLE,

  requests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_DELAY_REQUESTED: {
      const { sArticleCode, iQuantity, __request } = action.params;
      const { delays } = state;

      const delaysForProduct = delays[sArticleCode] || {};

      return {
        ...state,
        status: Status.LOADING,
        delays: {
          ...delays,
          [sArticleCode]: {
            ...delaysForProduct,
            status: Status.LOADING,
            [iQuantity]: null,
          },
        },
        error: null,
        requests: [...state.requests, __request],
      };
    }

    case GET_PRODUCT_DELAY_RESOLVED: {
      const { data, params } = action.payload;
      const { iQuantity, sArticleCode, __request } = params;
      const { delays } = state;
      const { uuid } = __request;

      if (state.requests.some(req => req.uuid === uuid && req.cancelled)) {
        return { ...state };
      }

      const delaysForProduct = delays[sArticleCode] || {};

      return {
        ...state,
        status: Status.IDLE,
        delays: {
          ...delays,
          [sArticleCode]: {
            ...delaysForProduct,
            status: Status.IDLE,
            [iQuantity]: data,
          },
        },
        error: null,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }

    case GET_PRODUCT_DELAY_REJECTED: {
      const { error, params } = action.payload;
      const { __request, sArticleCode } = params;
      const { uuid } = __request;

      const delaysForProduct = state.delays[sArticleCode] || {};

      return {
        ...state,
        status: Status.ERROR,
        error,
        delays: {
          ...state.delays,
          [sArticleCode]: {
            ...delaysForProduct,
            status: Status.IDLE,
          },
        },
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }

    case CANCEL_GET_PRODUCT_DELAY: {
      return {
        ...state,
        requests: state.requests.map(req => ({ ...req, cancelled: true })),
      };
    }

    default:
      return state;
  }
};
