import PropTypes from 'prop-types';
import React from 'react';

import { Colors } from '../../common/styles/vars';

const PlusIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 24"
    width={size * (50 / 24)}
    height={size}
    fill={color}
  >
    <path d="M24.9979 24C38.5256 23.9831 49.5945 13.3558 50 0H43.5841C43.1913 10.1381 34.5556 18.0453 24.2935 17.6616C14.5778 17.2947 6.78733 9.59831 6.41595 0H0C0.405532 13.3558 11.4702 23.9831 24.9979 24Z" />
  </svg>
);

PlusIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

PlusIcon.defaultProps = {
  size: 24,
  color: Colors.blue,
};

export default PlusIcon;
