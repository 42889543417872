import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import Button from '../elements/Button';
import Spinner from '../elements/Spinner';

const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const SeeMoreContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  min-height: 24px;

  text-align: center;
  font-size: 10px;
  line-height: 12px;

  strong {
    margin: 0 0.75ch;
  }

  .loading {
    font-weight: bold;
  }
`;

const ShopLayoutPagination = ({
  productsState,
  products,
  totalPages,
  totalProducts,
  perPage,
  currentPage,
  setCurrentPage,
}) => {
  let content = null;

  if (productsState.status === Status.LOADING) {
    content = <Spinner style={{ marginBottom: '40px' }}>Chargement...</Spinner>;
  } else if (totalProducts > 0) {
    content = (
      <>
        <div className="line">Vous avez vu</div>
        <div className="line">
          <strong>{Math.min(currentPage * perPage, products.length)}</strong>
          <span>sur</span>
          <strong>{totalProducts}</strong>
          <span>articles</span>
        </div>
      </>
    );
  }

  return (
    <SeeMoreContainer>
      <SeeMoreContent>{content}</SeeMoreContent>
      {currentPage < totalPages && (
        <Button
          color={Colors.white}
          background={Colors.darkGrey}
          style={{ minWidth: '160px', height: '40px' }}
          disabled={productsState?.status === Status.LOADING}
          onClick={() => setCurrentPage && setCurrentPage(currentPage + 1)}
        >
          <span>Voir plus</span>
        </Button>
      )}
    </SeeMoreContainer>
  );
};

ShopLayoutPagination.propTypes = {
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  totalProducts: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func,
};

ShopLayoutPagination.defaultProps = {
  setCurrentPage: null,
};

export default ShopLayoutPagination;
