import { isArray } from 'lodash';

import {
  CANCEL_SEARCH_20_CLIENTS,
  CLEAR_CLIENTS,
  SEARCH_20_CLIENTS_REJECTED,
  SEARCH_20_CLIENTS_REQUESTED,
  SEARCH_20_CLIENTS_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  clients: [],
  error: null,
  status: Status.IDLE,

  requests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_20_CLIENTS_REQUESTED: {
      const { __request } = action.params;

      return {
        ...state,
        status: Status.LOADING,
        clients: [],
        error: null,
        requests: [...state.requests, __request],
      };
    }
    case SEARCH_20_CLIENTS_RESOLVED: {
      const { data, params } = action.payload;
      const { __request } = params;
      const { uuid } = __request;

      const { recordClients } = data;

      let recordsList = [];
      if (recordClients) {
        if (isArray(recordClients)) {
          recordsList = recordClients;
        } else {
          recordsList = [recordClients];
        }
      }

      if (state.requests.some(req => req.uuid === uuid && req.cancelled)) {
        return { ...state };
      }

      return {
        ...state,
        status: Status.IDLE,
        clients: recordsList,
        error: null,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }
    case SEARCH_20_CLIENTS_REJECTED: {
      const { error, params } = action.payload;
      const { __request } = params;
      const { uuid } = __request;

      return {
        ...state,
        status: Status.ERROR,
        error,
        requests: state.requests.filter(req => req.uuid !== uuid),
      };
    }
    case CANCEL_SEARCH_20_CLIENTS: {
      const newState = {
        ...state,
        requests: state.requests.map(req => ({ ...req, cancelled: true })),
      };

      return newState;
    }
    case CLEAR_CLIENTS: {
      return { ...state, clients: [], status: Status.IDLE };
    }
    default:
      return state;
  }
};
