import React from 'react';
import styled from 'styled-components';

import UpArrowIcon from '../../../assets/img/UpArrowIcon';
import { Colors, Sizing } from '../../styles/vars';

const BTTWrapper = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  margin-left: 30px;
  padding: 0;

  background-color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;

  cursor: pointer;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    margin-left: 0;
    background-color: ${Colors.pureWhite};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const BackToTop = () => {
  const callback = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BTTWrapper onClick={callback}>
      <UpArrowIcon color={Colors.primary} />
    </BTTWrapper>
  );
};

BackToTop.propTypes = {};

export default BackToTop;
