import Dexie from 'dexie';
import levenshtein from 'js-levenshtein';
import { escapeRegExp } from 'lodash';

import { isTruthy } from '../../../utils/misc';

export const db = new Dexie('osmosis_data');

/* ========== Definitions ========== */
// Delete old database (bad versioning scheme)
Dexie.delete('OsmosisDB');

db.version(4).stores({
  searchEngine:
    '&sG5ArticleCode, sG5ArticleManufCode, sG5ArticleLibelle, sCodeLPP, sCodeCIP, sCodeEAN',

  familiesByCustomer: '&sCode, sRayon',

  productsByCustomer:
    '&sCode, sLibelle, sFamille, sCodeTypeArticle, sManufacturerRef, sCodeFournisseur, sCodeCIP, sCodeEAN, sCodeGTIN, sCodeLPP',
});

db.version(3).stores({
  searchEngine:
    '&sG5ArticleCode, sG5ArticleManufCode, sG5ArticleLibelle, sCodeLPP, sCodeCIP, sCodeEAN',

  familiesByCustomer: '&sCode, sRayon',

  productsByCustomer: '&sCode, sFamille',
});

db.version(2).stores({
  searchEngine:
    '&sG5ArticleCode, sG5ArticleManufCode, sG5ArticleLibelle, sCodeLPP, sCodeCIP, sCodeEAN',

  familiesByCustomer: '&sCode, sRayon',
});

db.version(1).stores({
  searchEngine: '&sG5ArticleCode',
});

/* ========== Functions ========== */
// → Search engine
export const idbPutSearchEngineRecords = async records => {
  await db.searchEngine.clear();
  await db.searchEngine.bulkPut(records);
};

export const idbQueryToFuzzyRegex = query => {
  const search = escapeRegExp(query || '')
    .trim()
    .replace(/\s+/g, '.+');

  return new RegExp(`.*${search}.*`, 'i');
};

export const idbQuerySearchEngine = async search => {
  const reg = idbQueryToFuzzyRegex(search);

  const records = await db.searchEngine
    .filter(
      ({
        sG5ArticleCode,
        sG5ArticleManufCode,
        sG5ArticleLibelle,
        sCodeLPP,
        sCodeCIP,
        sCodeEAN,
      }) =>
        reg.test(sG5ArticleCode || '') ||
        reg.test(sG5ArticleManufCode || '') ||
        reg.test(sG5ArticleLibelle || '') ||
        reg.test(sCodeLPP || '') ||
        reg.test(sCodeCIP || '') ||
        reg.test(sCodeEAN || ''),
    )
    .toArray();

  return records.sort(
    (
      { sG5ArticleLibelle: a1, sG5ArticleCode: a2 },
      { sG5ArticleLibelle: b1, sG5ArticleCode: b2 },
    ) =>
      levenshtein(a1, search) - levenshtein(b1, search) ||
      levenshtein(a2, search) - levenshtein(b2, search),
  );
};

// → FamiliesByCustomer
export const idbPutFamiliesByCustomerRecords = async records => {
  console.debug('📥 putFamiliesByCustomerRecords');

  await db.familiesByCustomer.clear();
  await db.familiesByCustomer.bulkPut(records);
};

export const idbGetAllFamiliesByCustomer = async () =>
  db.familiesByCustomer.toArray();

export const idbGetFamiliesByAisle = async aisleCode =>
  db.familiesByCustomer.where({ sRayon: aisleCode }).toArray();

export const idbGetSingleFamily = async familyCode =>
  db.familiesByCustomer.get(familyCode);

export const idbClearAllFamilies = async () => db.familiesByCustomer.clear();

// → ProductsByCustomer
export const idbPutProductsByCustomerRecords = async records => {
  console.debug('📥 putProductsByCustomerRecords');

  await db.productsByCustomer.clear();
  await db.productsByCustomer.bulkPut(records);
};

/**
 * @warn Potentially very slow and memory heavy operation
 */
export const idbGetAllProductsByCustomer = async () =>
  db.productsByCustomer.toArray();

export const idbGetProductsByFamily = async familyCode =>
  db.productsByCustomer.where({ sFamille: familyCode }).toArray();

export const idbClearAllProducts = async () => db.productsByCustomer.clear();

export const idbGetSingleProduct = async code =>
  db.productsByCustomer.get(code);

export const idbSearchProducts = async args => {
  const { sSearch } = args;

  const search = `${sSearch}`.toLocaleLowerCase();
  const re = new RegExp(`.*${search}.*`);

  const allMatchigProducts = await db.productsByCustomer
    .filter(product =>
      [
        'sLibelle',
        'sCodeTypeArticle',
        'sManufacturerRef',
        'sCodeFournisseur',
        'sCodeCIP',
        'sCodeEAN',
        'sCodeGTIN',
        'sCodeLPP',
      ]
        .map(prop => re.test(product[prop]?.toString()?.toLocaleLowerCase()))
        .some(test => isTruthy(test)),
    )
    .toArray();

  return allMatchigProducts;
};
