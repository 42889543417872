import { combineReducers } from 'redux';

import { LOGOUT_USER } from '../actions/types';
import { idbClearAllFamilies } from '../models/idb';
import aisles from './aisles';
import cart from './cart';
import clients from './clients';
import delay from './delay';
import email from './email';
import families from './families';
import images from './images';
import locations from './locations';
import order from './order';
import pendingOrders from './pending-orders';
import preorders from './preorders';
import products from './products';
import users from './users';
import virtualCart from './virtual-cart';

export const appReducer = combineReducers({
  aisles,
  cart,
  clients,
  delay,
  email,
  families,
  images,
  locations,
  order,
  pendingOrders,
  preorders,
  products,
  users,
  virtualCart,
});

export default (state, action) => {
  if (action.type === 'NOOP') {
    // eslint-disable-next-line no-console
    console.warn('Unimplemented no-op action', action);

    return state;
  }

  if (action.type === LOGOUT_USER) {
    idbClearAllFamilies();
    state = undefined;
  }

  return appReducer(state, action);
};
