import PropTypes from 'prop-types';
import React from 'react';

const ShoppingCartAddIcon = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={size}
    height={size}
  >
    <circle cx="9" cy="21" r="1" />
    <circle cx="20" cy="21" r="1" />
    <path d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6m0 0h-4.25m-8.5 0H6" />
    <g strokeWidth="2.898">
      <path strokeWidth="1.9997069400000003" d="M14.507 3.688v9.66" />
      <path
        strokeWidth="1.9997069400000003"
        d="M19.337 8.518l-4.83 4.83-4.83-4.83"
      />
    </g>
  </svg>
);

ShoppingCartAddIcon.propTypes = {
  size: PropTypes.number,
};

ShoppingCartAddIcon.defaultProps = {
  size: 15,
};

export default ShoppingCartAddIcon;
