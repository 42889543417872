import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../styles/vars';
import { parsePriceString } from '../../utils/misc';

const Price = styled.div`
  display: inline-flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  justify-content: flex-end;
  align-items: ${props => (props.vertical ? 'flex-end' : 'center')};
  width: 100%;

  .number,
  .currency {
    display: block;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.75;
  }

  .prices {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: nowrap;

    &.align-left {
      justify-content: flex-start;
    }

    .content {
      margin-left: 3px;
    }
  }

  .price-unit {
    position: relative;
    display: flex;
    flex-direction: row;

    &[data-has-quantity='true'] {
      margin-bottom: 20px;
    }

    @media print {
      font-weight: bold;
    }

    .quantity {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;

      font-size: 9px;
      line-height: normal;
      white-space: nowrap;

      opacity: 0.75;
    }
  }

  .old-price {
    margin-right: 10px;
    opacity: 0.5;

    text-decoration: line-through;
  }

  .new-price {
    font-weight: bold;
    color: ${Colors.primary};
  }

  ${props =>
    props.size &&
    css`
      .number,
      .currency {
        font-size: ${props.size}px;
        line-height: ${props.size + 2}px;
        opacity: ${props.size >= 16 ? 1 : 0.75};
      }

      .old-price {
        .number,
        .currency {
          font-size: ${props.size * 0.75}px;
        }
      }
    `}
`;

const PromoTagWrapper = styled.div`
  margin-right: ${props => (props.vertical ? 0 : '20px')};

  ${props =>
    props.inProductCard &&
    css`
      display: flex;
      justify-content: center;
      flex-grow: 1;
    `}
`;

const PromoTag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: ${props => (props.vertical ? 'flex-end' : 'center')};
  margin-right: ${props =>
    props.vertical || props.inProductCard ? 0 : '15px'};
  margin-bottom: ${props => (props.vertical ? '10px' : 0)};
  min-width: 100px;
  padding: 8px 15px;

  color: ${Colors.white};
  background-color: ${Colors.primary};
  border-radius: 20px;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);

  text-transform: uppercase;
  font-size: 12px;
  line-height: normal;

  ${props =>
    props.compact &&
    css`
      padding: 5px 10px;
      font-size: 10px;
    `}
`;

const ProductPrice = ({
  value,
  quantity,
  promoValue,
  promoQuantity,
  currency,
  size,
  vertical,
  compact,
  inProductCard,
  align,
  style,
  children,
}) => {
  const formatValue = number =>
    (parsePriceString(number) || 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return (
    <Price
      className="product-price"
      size={size}
      style={style}
      vertical={vertical}
      compact={compact}
    >
      {promoValue ? (
        <>
          <PromoTagWrapper vertical={vertical} inProductCard={inProductCard}>
            <PromoTag
              vertical={vertical}
              inProductCard={inProductCard}
              compact={compact}
            >
              Promotion
            </PromoTag>
          </PromoTagWrapper>

          <div className={`prices align-${align}`}>
            <div
              className="price-unit old-price"
              data-has-quantity={quantity > 1}
            >
              <span className="number">{formatValue(value)}</span>
              <span className="currency">{currency}</span>
              {quantity > 1 && (
                <div className="quantity">(dès {quantity} unités)</div>
              )}
            </div>

            <div
              className="price-unit new-price"
              data-has-quantity={promoQuantity > 1}
            >
              <span className="number">{formatValue(promoValue)}</span>
              <span className="currency">{currency}</span>
              {promoQuantity > 1 && (
                <div className="quantity">(dès {promoQuantity} unités)</div>
              )}
            </div>

            {children && <div className="content">{children}</div>}
          </div>
        </>
      ) : (
        <div className={`prices align-${align}`}>
          <div className="price-unit" data-has-quantity={quantity > 1}>
            <span className="number">{formatValue(value)}</span>
            <span className="currency">{currency}</span>

            {quantity > 1 && (
              <div className="quantity">(dès {quantity} unités)</div>
            )}
          </div>

          {children && <div className="content">{children}</div>}
        </div>
      )}
    </Price>
  );
};

ProductPrice.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  quantity: PropTypes.number,
  promoValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  promoQuantity: PropTypes.number,
  currency: PropTypes.string,
  size: PropTypes.number,
  vertical: PropTypes.bool,
  compact: PropTypes.bool,
  inProductCard: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

ProductPrice.defaultProps = {
  quantity: 0,
  promoValue: null,
  promoQuantity: 0,
  currency: '€',
  size: 14,
  vertical: false,
  compact: false,
  inProductCard: false,
  align: 'right',
  style: {},
  children: null,
};

export default ProductPrice;
