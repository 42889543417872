import { PropTypes } from 'prop-types';
import React, { memo, useEffect } from 'react';
import { NavLink, Route, useRouteMatch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import { BreadcrumbRoutes } from '../routes';
import Paths from '../routes/paths';
import ErrorBoundary from '../utils/ErrorBoundary';

const BreadcrumbLink = styled(NavLink)`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;

  font-size: 7px;
  line-height: 21px;
  font-weight: 300;
  color: ${Colors.black};

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 7px);
    left: 0;
    width: 100%;
    height: 1px;

    background-color: currentColor;
    opacity: 0.5;
  }

  &:last-of-type + span {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }

  &[aria-current='page'] {
    &::after {
      content: unset;
      display: none;
    }

    pointer-events: none;
  }
`;

const BreadcrumbSeparator = styled.span`
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-left: 3px;
  margin-right: 5px;

  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;

  transform-origin: center;
  transform: rotate(45deg);

  opacity: 0.75;
`;

const createBreadcrumb = ({
  basePath,
  defaultName,
  mapStateToProps,
  mapDispatchToProps,
  getDataFromState,
  getFetchParams,
}) => {
  const Breadcrumb = props => {
    const { fetch, params } = props;

    const defaultData = () => ({ name: defaultName, path: basePath });

    const { name, path } = (getDataFromState || defaultData)({
      ...props,
      basePath,
    });

    useEffect(() => {
      if (name === defaultName && fetch) {
        if (getFetchParams) {
          const additionalParams = getFetchParams({ ...props, basePath });

          fetch({ ...props, basePath, ...additionalParams });
        } else {
          fetch(params);
        }
      }
    }, []);

    return (
      <>
        <BreadcrumbLink to={path || Paths.Home()} exact>
          {name}
        </BreadcrumbLink>
        <BreadcrumbSeparator />
      </>
    );
  };

  Breadcrumb.propTypes = {
    fetch: PropTypes.func,
    params: PropTypes.objectOf(PropTypes.any),
  };

  Breadcrumb.defaultProps = {
    fetch: null,
    params: {},
  };

  const BreadcrumWithRouter = withRouter(Breadcrumb);

  if (mapStateToProps && mapDispatchToProps && getDataFromState) {
    return connect(mapStateToProps, mapDispatchToProps)(BreadcrumWithRouter);
  }

  return BreadcrumWithRouter;
};

const Container = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  padding-right: 30px;
`;

const Breadcrumbs = () => {
  const isOnSingleProduct = useRouteMatch(Paths.SingleProduct());

  return (
    <ErrorBoundary>
      <Container>
        {BreadcrumbRoutes.filter(({ name }) => {
          if (isOnSingleProduct) {
            return !['Rayon', 'Famille'].includes(name);
          }

          return true;
        }).map(
          ({
            path,
            name,
            exactBreadcrumb,
            mapStateToProps,
            mapDispatchToProps,
            getDataFromState,
            getFetchParams,
          }) => {
            return (
              <Route
                key={path}
                path={path}
                exact={(isOnSingleProduct && exactBreadcrumb) || false}
                component={memo(
                  createBreadcrumb({
                    basePath: path,
                    defaultName: name,
                    mapStateToProps,
                    mapDispatchToProps,
                    getDataFromState,
                    getFetchParams,
                  }),
                )}
              />
            );
          },
        )}
      </Container>
    </ErrorBoundary>
  );
};

export default withRouter(memo(Breadcrumbs));
