import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import ProductCard from '../elements/ProductCard';
import SearchInput from '../elements/SearchInput';
import ShopLayoutHeader from '../elements/ShopLayoutHeader';
import ShopSidebar from '../elements/ShopSidebar';
import ErrorBoundary from '../utils/ErrorBoundary';
import ScrollToTop from '../utils/ScrollToTop';
import ShopLayoutPagination from './ShopLayoutPagination';

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProductsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 40px;

  grid-column-start: 4;
  grid-column-end: span 9;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
  }
`;

const ProductSectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  align-items: stretch;
  margin-top: 2rem;

  @media all and (max-width: ${Sizing.mobileWidth}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
  }
`;

const NoProductsMessage = styled.div`
  grid-column: 1 / span 3;
  text-align: center;
  font-size: 14px;
  color: ${Colors.lightGrey};
`;

const ShopLayout = ({
  aisle,
  family,
  products,
  productsState,

  totalPages,
  totalProducts,
  perPage,
  currentPage,
  setCurrentPage,

  search,
  handleSearch,
  handleFilter,

  isStatic,
  children,
}) => {
  const isRootShop = !aisle && !family;

  const renderProductsGrid = () => {
    if (
      (!products || products.length === 0) &&
      productsState.status !== Status.LOADING
    ) {
      return <NoProductsMessage>Aucun produit à afficher</NoProductsMessage>;
    }

    return products.map((product, index) => (
      <ProductCard
        // eslint-disable-next-line react/no-array-index-key
        key={`product-${index}--${product.sCode}`}
        product={product}
      />
    ));
  };

  return (
    <>
      <ScrollToTop />

      <ErrorBoundary>
        <ShopSidebar />
      </ErrorBoundary>

      <ProductsSection>
        <ShopLayoutHeader
          aisle={aisle}
          family={family}
          products={products}
          handleFilter={handleFilter}
          isStatic={isStatic}
        />

        {isRootShop && (
          <ErrorBoundary>
            <SearchRow>
              <SearchInput
                initialValue={search}
                style={{ marginTop: '30px' }}
                onSearch={handleSearch}
                suggest
              />
            </SearchRow>
          </ErrorBoundary>
        )}

        <ProductSectionGrid>
          {children || renderProductsGrid()}
        </ProductSectionGrid>

        <ShopLayoutPagination
          products={products}
          productsState={productsState}
          totalPages={totalPages}
          totalProducts={totalProducts}
          perPage={perPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </ProductsSection>
    </>
  );
};

ShopLayout.propTypes = {
  aisle: PropTypes.objectOf(PropTypes.any),
  family: PropTypes.objectOf(PropTypes.any),
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  productsState: PropTypes.objectOf(PropTypes.any).isRequired,

  totalProducts: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func,

  search: PropTypes.string,
  handleSearch: PropTypes.func,
  handleFilter: PropTypes.func,

  isStatic: PropTypes.bool,
  children: PropTypes.node,
};

ShopLayout.defaultProps = {
  aisle: null,
  family: null,
  setCurrentPage: null,

  search: '',
  handleSearch: null,
  handleFilter: null,

  isStatic: false,
  children: null,
};

export default ShopLayout;
