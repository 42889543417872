import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { FAMILIES_ACTIONS } from '../../store/actions';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import SidebarEntry, { SidebarPanel } from './SidebarEntry';

const ShopSidebarEntry = ({ aisle, families, fetchFamiliesPerAisle }) => {
  const { sCode, sLibelle } = aisle;

  useEffect(() => {
    if (!Object.values(families || {}).some(fam => fam.sRayon === sCode))
      fetchFamiliesPerAisle({ sRayon: sCode });
  }, []);

  const currentFamilies = (
    Object.values(families || {}).filter(fam => fam.sRayon === sCode) || []
  ).sort((a, b) => a.sLibelle.localeCompare(b.sLibelle));

  return (
    <SidebarEntry to={Paths.SingleAisle({ aisleID: sCode })} label={sLibelle}>
      {currentFamilies.length > 0 && (
        <SidebarPanel
          header={sLibelle}
          aisle={aisle}
          entries={currentFamilies.map(family => ({
            key: family.sCode,
            label: family.sLibelle,
            to: Paths.SingleFamily({ aisleID: sCode, familyID: family.sCode }),
          }))}
        />
      )}
    </SidebarEntry>
  );
};

ShopSidebarEntry.propTypes = {
  aisle: PropTypes.objectOf(PropTypes.any).isRequired,
  families: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchFamiliesPerAisle: PropTypes.func.isRequired,
};

export default connect(state => ({ ...state.families }), {
  ...FAMILIES_ACTIONS,
})(ShopSidebarEntry);
