import { createEndpoint, Type } from '../../models/europrisme';
import {
  CANCEL_PENDING_ORDER_REJECTED,
  CANCEL_PENDING_ORDER_REQUESTED,
  CANCEL_PENDING_ORDER_RESOLVED,
  GET_PENDING_ORDERS_REJECTED,
  GET_PENDING_ORDERS_REQUESTED,
  GET_PENDING_ORDERS_RESOLVED,
  GET_SINGLE_PENDING_ORDER_REJECTED,
  GET_SINGLE_PENDING_ORDER_REQUESTED,
  GET_SINGLE_PENDING_ORDER_RESOLVED,
} from '../types';

export const getPendingOrdersRequested = () => ({
  type: GET_PENDING_ORDERS_REQUESTED,
});

export const getPendingOrdersResolved = data => ({
  type: GET_PENDING_ORDERS_RESOLVED,
  payload: { data },
});

export const getPendingOrdersRejected = error => ({
  type: GET_PENDING_ORDERS_REJECTED,
  payload: { error },
});

export const getPendingOrdersEndpoint = createEndpoint(
  Type.GET,
  'getPendingOrders',
  {
    sCustomerCode: '',
  },
  {
    request: getPendingOrdersRequested,
    resolve: getPendingOrdersResolved,
    reject: getPendingOrdersRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getPendingOrders = () => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(getPendingOrdersEndpoint({ sCustomerCode: customerID }));
};

// ============================================================================

export const getSinglePendingOrderRequested = params => ({
  type: GET_SINGLE_PENDING_ORDER_REQUESTED,
  payload: { params },
});

export const getSinglePendingOrderResolved = (data, params) => ({
  type: GET_SINGLE_PENDING_ORDER_RESOLVED,
  payload: { data, params },
});

export const getSinglePendingOrderRejected = (error, params) => ({
  type: GET_SINGLE_PENDING_ORDER_REJECTED,
  payload: { error, params },
});

export const getSinglePendingOrderEndpoint = createEndpoint(
  Type.GET,
  'getPendingOrder',
  {
    sCustomerCode: '',
    sOrderCode: '',
  },
  {
    request: getSinglePendingOrderRequested,
    resolve: getSinglePendingOrderResolved,
    reject: getSinglePendingOrderRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getSinglePendingOrder = sOrderCode => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users;
  const { customerID } = currentUser;

  return dispatch(
    getSinglePendingOrderEndpoint({ sOrderCode, sCustomerCode: customerID }),
  );
};

// ============================================================================

export const cancelPendingOrderRequested = () => ({
  type: CANCEL_PENDING_ORDER_REQUESTED,
});

export const cancelPendingOrderResolved = (data, fullParams) => ({
  type: CANCEL_PENDING_ORDER_RESOLVED,
  payload: { data, params: fullParams },
});

export const cancelPendingOrderRejected = error => ({
  type: CANCEL_PENDING_ORDER_REJECTED,
  payload: { error },
});

export const cancelPendingOrderEndpoint = createEndpoint(
  Type.GET,
  'cancelOrder',
  {
    sOrderCode: '',
  },
  {
    request: cancelPendingOrderRequested,
    resolve: cancelPendingOrderResolved,
    reject: cancelPendingOrderRejected,
  },
  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const cancelPendingOrder = code => async dispatch => {
  return dispatch(cancelPendingOrderEndpoint({ sOrderCode: code }));
};

export const PENDING_ORDER_ACTIONS = {
  getPendingOrders,
  getSinglePendingOrder,
  cancelPendingOrder,
};
