import {
  createEndpoint,
  defaultSuccessCheck,
  Type,
} from '../../models/europrisme';
import {
  CANCEL_GET_PRODUCT_DELAY,
  GET_PRODUCT_DELAY_REJECTED,
  GET_PRODUCT_DELAY_REQUESTED,
  GET_PRODUCT_DELAY_RESOLVED,
} from '../types';

export const getProductDelayRequested = fullParams => ({
  type: GET_PRODUCT_DELAY_REQUESTED,
  params: fullParams,
});

export const getProductDelayRejected = (error, fullParams) => ({
  type: GET_PRODUCT_DELAY_REJECTED,
  payload: { error, params: fullParams },
});

export const getProductDelayResolved = (data, fullParams) => ({
  type: GET_PRODUCT_DELAY_RESOLVED,
  payload: { data, params: fullParams },
});

export const cancelGetProductDelays = () => ({
  type: CANCEL_GET_PRODUCT_DELAY,
});

export const getProductDelayEndpoint = createEndpoint(
  Type.PLAIN_TEXT,
  'getDelay',
  {
    sCustomerCode: '',
    sArticleCode: '',
    iQuantity: 1,
  },
  {
    request: getProductDelayRequested,
    resolve: getProductDelayResolved,
    reject: getProductDelayRejected,
  },
  defaultSuccessCheck,
  text => {
    const [numeric, label, details] = text
      .trim()
      .split('|')
      .map(part => part.trim());

    return {
      numeric: Number.parseInt(numeric, 10) || 1,
      label,
      details,
    };
  },
);

export const getProductDelay = (...args) => async dispatch => {
  // Cancels delays when mass-adding products to cart (order repeat)
  // await dispatch(cancelGetProductDelays());

  return dispatch(getProductDelayEndpoint(...args));
};

export const DELAY_ACTIONS = {
  getProductDelay,
};
