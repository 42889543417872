/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';

const getColor = props => {
  if (props.isDragAccept) {
    return Colors.green;
  }

  if (props.isDragReject) {
    return Colors.red;
  }

  if (props.isDragActive) {
    return Colors.primary;
  }

  return 'transparent';
};

const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Dropzone = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  outline: none;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
  }

  &::before {
    background-color: ${Colors.lightGrey};
    opacity: 0.1;
  }

  &::after {
    outline: 2px dashed ${props => getColor(props)};
  }

  p {
    margin-bottom: 20px;

    color: ${Colors.black};
    opacity: 0.5;
  }
`;

const Thumb = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  pointer-events: none;
  z-index: 200;
`;

const DropzoneButton = styled.button`
  appearance: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;

  transform: translate(-50%, -50%);

  border: none;
  outline: none;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);

  cursor: pointer;
  z-index: 350;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 2px;

    transform-origin: center;
    transform: translate(-50%, -50%);

    background-color: ${Colors.white};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const AddButton = styled(DropzoneButton)`
  background-color: ${Colors.darkGrey};
  pointer-events: none;

  &::before {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const RemoveButton = styled(DropzoneButton)`
  background-color: ${Colors.red};
`;

const ImageDropzone = ({ onImageDrop, defaultImageData }) => {
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);

  useEffect(() => {
    if (!base64 && defaultImageData) {
      setBase64(`data:image/png;base64,${defaultImageData}`);
    }
  }, [defaultImageData]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    onDrop: acceptedFiles => {
      setFile(
        acceptedFiles.map(f =>
          Object.assign(f, {
            preview: URL.createObjectURL(f),
          }),
        )[0],
      );
    },
  });

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        setBase64(reader.result);
      });

      reader.readAsDataURL(file);
    }

    return () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  useEffect(() => {
    if (onImageDrop && base64) {
      const base64Data = base64.replace(/^data:image\/.+;base64,/, '');

      onImageDrop(base64Data);
    }
  }, [base64]);

  const clearImage = () => {
    setFile(null);
    setBase64(null);
  };

  return (
    <Wrapper>
      <Dropzone {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {!base64 && <p>Glissez et déposez une image ici</p>}
      </Dropzone>

      {base64 && <Thumb style={{ backgroundImage: `url(${base64})` }} />}

      {base64 ? <RemoveButton onClick={clearImage} /> : <AddButton />}
    </Wrapper>
  );
};

ImageDropzone.propTypes = {
  onImageDrop: PropTypes.func,
  defaultImageData: PropTypes.string,
};

ImageDropzone.defaultProps = {
  onImageDrop: null,
  defaultImageData: '',
};

export default ImageDropzone;
