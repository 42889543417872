import PropTypes from 'prop-types';
import React from 'react';

const CancelIcon = ({ size }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="15" y1="9" x2="9" y2="15" />
      <line x1="9" y1="9" x2="15" y2="15" />
    </svg>
  );
};

CancelIcon.propTypes = {
  size: PropTypes.number,
};

CancelIcon.defaultProps = {
  size: 15,
};

export default CancelIcon;
