import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Logo from '../../../assets/img/Logo';
import PlusIcon from '../../../assets/img/PlusIcon';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import BackToTop from '../elements/BackToTop';

const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 50px;
  padding-top: 55px;
  padding-bottom: 70px;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    flex-direction: column;
    padding: 40px 16px;
  }

  @media print {
    display: none;
  }
`;

const FooterSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  flex-basis: 0;
  flex-grow: 1;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    &:first-child {
      margin-bottom: 40px;
    }

    ${props =>
      props.isFloating &&
      css`
        position: absolute;
        top: 20px;
        right: 20px;

        z-index: 600;
      `}
  }
`;

const FooterLinkList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const FooterLinkItem = styled.li`
  color: ${Colors.black};
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  span,
  a {
    margin-left: 12px;

    text-decoration: none;
    color: currentColor;
    font-size: 14px;
    font-weight: 500;
  }
`;

const FooterLink = styled.a``;

const FooterAdditionalLinks = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -17.5px;
    left: 0;
    width: 100%;
    height: 1px;

    background-color: ${Colors.black};
    opacity: 0.2;
  }

  a {
    color: currentColor;
    text-decoration: none;
    font-weight: normal;
  }
`;

const FooterAdditionalLink = styled.span`
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const FooterRightLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterSection alignItems="flex-start">
        <Logo height={35} />
      </FooterSection>

      <FooterSection>
        <FooterLinkList>
          {appConfig.mapArray(
            'content.footer.menu',
            ({ text, url, target }, id) => (
              // eslint-disable-next-line react/no-array-index-key
              <FooterLinkItem key={`footer-menu-link-${id}`}>
                <PlusIcon size={12} />

                {url.startsWith('/') ? (
                  <NavLink to={url}>{text}</NavLink>
                ) : (
                  <FooterLink href={url} target={target}>
                    {text}
                  </FooterLink>
                )}
              </FooterLinkItem>
            ),
          )}
        </FooterLinkList>

        <FooterAdditionalLinks>
          {appConfig.mapArray(
            'content.footer.links',
            ({ text, url, target }, id) => {
              if (!url) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterAdditionalLink key={`footer-additional-link-${id}`}>
                    {text}
                  </FooterAdditionalLink>
                );
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <FooterAdditionalLink key={`footer-additional-link-${id}`}>
                  {url.startsWith('/') ? (
                    <NavLink to={url}>{text}</NavLink>
                  ) : (
                    <FooterLink href={url} target={target}>
                      {text}
                    </FooterLink>
                  )}
                </FooterAdditionalLink>
              );
            },
          )}
        </FooterAdditionalLinks>
      </FooterSection>

      <FooterSection flexDirection="row" justifyContent="flex-end" isFloating>
        <FooterRightLinks>
          <BackToTop />
        </FooterRightLinks>
      </FooterSection>
    </FooterWrapper>
  );
};

export default Footer;
