import TYPES from './types';

export default [
  {
    type: TYPES.Row,
    children: [{ key: 'sLibelle', className: 'resource-title' }],
  },
  {
    type: TYPES.Row,
    children: [
      {
        type: TYPES.Col,
        className: 'resource-upload--image',
        children: [
          {
            type: TYPES.Raw,
            className: 'resource-title--secondary',
            children: ['Photo mise en avant'],
          },
          {
            type: TYPES.Raw,
            className: 'resource-upload--subtitle',
            children: ['Privilégiez une image sur fond blanc ou transparent.'],
          },
          { type: TYPES.ImageUpload, resourceType: 'Rayon' },
        ],
      },
    ],
  },
];
