import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useTimeout } from '../../hooks';

const SpinnerElement = styled.div`
  position: relative;
  display: block;

  &::before {
    content: '';
    display: block;
    position: relative;
    width: 40px;
    height: 40px;

    border-style: solid;
    border-width: 3px;
    border-color: currentColor transparent transparent transparent;

    animation-name: SpinnerAnimation;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: both;

    border-radius: 40px;
  }

  @keyframes SpinnerAnimation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerMessage = styled.span`
  position: absolute;
  display: block;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);

  white-space: nowrap;
  text-transform: uppercase;
  font-size: 10px;

  opacity: ${props => (props.visible ? 1 : 0)};

  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
`;

const Spinner = ({ messageDelay, children, style }) => {
  const [visible, setVisible] = useState(messageDelay === 0);

  useTimeout(() => {
    setVisible(true);
  }, messageDelay * 1000);

  return (
    <SpinnerElement style={style}>
      {children && (
        <SpinnerMessage visible={visible}>{children}</SpinnerMessage>
      )}
    </SpinnerElement>
  );
};

Spinner.propTypes = {
  messageDelay: PropTypes.number,
  children: PropTypes.node,
  style: PropTypes.objectOf(PropTypes.any),
};

Spinner.defaultProps = {
  messageDelay: 0,
  children: null,
  style: {},
};

export default Spinner;
