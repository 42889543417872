import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { usePageTitle } from '../../hooks';
import {
  AISLES_ACTIONS,
  FAMILIES_ACTIONS,
  PRODUCT_ACTIONS,
} from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { connect } from '../../utils/redux';
import ShopLayout from '../layouts/ShopLayout';
import Paths from './paths';

const SingleFamily = ({
  match,

  aislesState,
  fetchSingleAisle,

  familiesState,
  fetchSingleFamily,

  productsState,
  fetchProducts,
  clearProducts,
}) => {
  const { aisleID, familyID } = match.params;
  const familyIDsArray =
    familyID.indexOf(',') !== -1 ? familyID.split(',') : [familyID];

  const aisle = (aislesState.aisles || {})[aisleID] || {};
  const family = (familiesState.families || {})[familyID];

  const familyProducts = Object.values(productsState.products || {}).filter(
    product =>
      product.status !== Status.ERROR &&
      familyIDsArray.includes(product.sFamille),
  );

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;

  useEffect(() => {
    setCurrentPage(1);
  }, [aisleID, familyID]);

  usePageTitle((family || {}).sLibelle);

  const [filters, setFilters] = useState({});

  const handleFilter = ({ sSortField, sSortWay }) => {
    setFilters({ sSortField, sSortWay });
    setCurrentPage(1);

    clearProducts();
  };

  useEffect(() => {
    if (!family) fetchSingleFamily(familyID);
    if (!aisle) fetchSingleAisle(aisleID);
  }, [familyID]);

  useEffect(() => {
    fetchProducts({
      sFamille: familyID,
      iPageSize: perPage,
      iPageNum: currentPage,
      ...filters,
    });
  }, [aisleID, familyID, currentPage, perPage, filters]);

  const totalPages = Math.ceil(productsState.meta.iTotalPage);
  const pagedProducts = familyProducts.slice(
    0,
    (currentPage - 1) * perPage + perPage,
  );

  const sortedPagedProducts = filters.sSortField
    ? pagedProducts.sort((a, b) =>
        filters.sSortWay === 'ASC'
          ? a.sLibelle.localeCompare(b.sLibelle)
          : b.sLibelle.localeCompare(a.sLibelle),
      )
    : pagedProducts;

  return (
    <ShopLayout
      aisle={aisle}
      family={family}
      products={sortedPagedProducts}
      totalProducts={productsState.meta.iTotalCount || 0}
      productsState={productsState}
      totalPages={totalPages}
      perPage={perPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      handleFilter={handleFilter}
    />
  );
};

SingleFamily.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,

  aislesState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleAisle: PropTypes.func.isRequired,

  familiesState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchSingleFamily: PropTypes.func.isRequired,

  productsState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchProducts: PropTypes.func.isRequired,
  clearProducts: PropTypes.func.isRequired,
};

export const ConnectedSingleFamily = connect(
  state => ({
    aislesState: state.aisles,
    familiesState: state.families,
    productsState: state.products,
  }),
  { ...AISLES_ACTIONS, ...FAMILIES_ACTIONS, ...PRODUCT_ACTIONS },
)(SingleFamily);

export default {
  path: Paths.SingleFamily(),
  name: 'Famille',
  component: ConnectedSingleFamily,
  exactBreadcrumb: true,
  mapStateToProps: state => ({
    ...state.families,
  }),
  geFetchParams: ({ match }) => ({ sFamilleCode: match.params.familyID }),
  mapDispatchToProps: {
    fetch: FAMILIES_ACTIONS.fetchSingleFamily,
  },
  getDataFromState: ({ families, path, match }) => {
    const { params, url } = match;
    const { familyID } = params;

    const family = (families || {})[familyID];

    if (family) return { name: family.sLibelle, path: url };

    return { name: 'Famille', path };
  },
};
