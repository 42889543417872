import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import PDFIcon from '../../../assets/img/PDFIcon';
import { Colors } from '../../styles/vars';
import Button from './Button';
import Popup from './Popup';

const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${props =>
    props.expand &&
    css`
      flex-grow: 1;
    `}

  .date {
    color: ${Colors.lightGrey};
    font-size: 10px;
  }
`;

const DocumentViewer = ({ base64, className, expand, children }) => {
  const [open, setOpen] = useState(false);

  const handleOnOpen = () => setOpen(true);
  const handleOnClose = () => setOpen(false);

  if (!base64) {
    return null;
  }

  return (
    <>
      <Button
        className={className}
        hoverColor={Colors.black}
        hoverBackground={Colors.pureWhite}
        onClick={handleOnOpen}
        style={{
          minWidth: 'unset',
          width: expand ? '100%' : 'auto',
          padding: '8px 12px 8px 8px',
          borderRadius: '4px',
          textAlign: 'left',
        }}
      >
        <PDFIcon size={50} />
        {children && (
          <ButtonTextContainer expand>{children}</ButtonTextContainer>
        )}
      </Button>

      <Popup open={open} onClose={handleOnClose} fullscreen>
        <iframe
          title="Document PDF"
          src={`data:application/pdf;base64,${base64}`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </Popup>
    </>
  );
};

DocumentViewer.propTypes = {
  base64: PropTypes.string,
  className: PropTypes.string,
  expand: PropTypes.bool,
  children: PropTypes.node,
};

DocumentViewer.defaultProps = {
  base64: null,
  className: '',
  expand: false,
  children: null,
};

export default DocumentViewer;
