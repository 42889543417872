import PropTypes from 'prop-types';
import React from 'react';

const UpArrowIcon = ({ size, color, angle, includeCircle, title }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    {title && <title>{title}</title>}
    <g
      transform="translate(1 1)"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <g
        stroke={color || '#E85E85'}
        strokeLinecap="round"
        style={{
          transformOrigin: 'center',
          transform: `rotate(${angle}deg)`,
        }}
      >
        <path d="M30.5 41.5v-21M30.5 20.5l10.512 10.512M30.5 20.5L19.988 31.012" />
      </g>
      {includeCircle && (
        <circle stroke="#1B1B1B" opacity=".125" cx="30" cy="30" r="30" />
      )}
    </g>
  </svg>
);

UpArrowIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  angle: PropTypes.number,
  includeCircle: PropTypes.bool,
  title: PropTypes.string,
};

UpArrowIcon.defaultProps = {
  size: 62,
  color: null,
  angle: 0,
  includeCircle: true,
  title: null,
};

export default UpArrowIcon;
