import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { usePageTitle, useWindowSize } from '../../hooks';
import { AISLES_ACTIONS, PRODUCT_ACTIONS } from '../../store/actions';
import { Colors, Sizing } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import HeroHeader from '../elements/HeroHeader';
import PageTitle from '../elements/PageTitle';
import ProductCarousel from '../elements/ProductCarousel';
import ResourceImage from '../elements/ResourceImage';
import Paths from './paths';

const CenterGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 12;
`;

const AislesGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1 / span 12;
  gap: ${Sizing.gutterWidth};

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;

const ContactGrid = styled(AislesGrid)`
  margin: 100px 0;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    margin: 40px 0;
  }
`;

const AisleBlock = styled.div`
  position: relative;
  background-color: ${Colors.lightGrey};

  &::before {
    content: '';
    display: block;
    position: relative;
    padding-bottom: 111%;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      padding-bottom: 80%;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: 10;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.2;

    pointer-events: none;
    z-index: 20;

    @media screen and (max-width: ${Sizing.mobileWidth}) {
      opacity: 0.4;
    }
  }
`;

const AisleContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;

  z-index: 50;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    padding: 12px;
  }
`;

const AisleName = styled.span`
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  color: ${Colors.white};
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;

    text-align: center;

    & + a[type='button'] {
      width: 100% !important;
      min-width: unset !important;
      margin-top: auto !important;
      padding: 6px 12px !important;
    }
  }
`;

const ContactBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  grid-column: 1 / span 2;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    grid-column: 1 / span 3;
    flex-direction: column-reverse;
  }
`;

const ContactDescription = styled.div`
  max-width: 260px;

  font-size: 14px;
  line-height: 21px;
  color: ${Colors.lightGrey};

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    max-width: unset;
    margin-top: 30px;
  }
`;

const ContactButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    .button {
      align-self: flex-end;
    }
  }
`;

const Home = ({
  aislesState,
  fetchAisles,
  productsState,
  usersState,
  fetchDiscountProducts,
}) => {
  const { width: innerWidth } = useWindowSize();
  const [aisleLimit, setAisleLimit] = useState(3);

  usePageTitle();

  const { currentUser } = usersState;
  const customerCode = (currentUser || { customerID: '' }).customerID;
  const hasUser = !!currentUser;

  const aisles = Object.values(aislesState.aisles || {});
  const products = Object.values(productsState.discounts || {});

  useEffect(() => {
    if (!aisles.length) {
      fetchAisles();
    }
  }, []);

  useEffect(() => {
    fetchDiscountProducts({ sCustomerCode: customerCode });
  }, [customerCode]);

  const pagedAisles = aisles.slice(
    0,
    aisleLimit === 3 && innerWidth <= 900 ? 4 : aisleLimit,
  );

  return (
    <>
      <HeroHeader showSearch={hasUser} />

      {hasUser && (
        <>
          <PageTitle fromComponent="h2" margin="70px 0 40px 0">
            À la une
          </PageTitle>

          <ProductCarousel products={products} />

          <CenterGrid>
            <Button
              fromComponent={Link}
              to={Paths.Shop()}
              color={Colors.black}
              background="white"
              style={{
                margin: '0 auto',
                marginTop: '40px',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Tous les produits
            </Button>
          </CenterGrid>

          <PageTitle fromComponent="h2">Nos rayons</PageTitle>
          <AislesGrid>
            {pagedAisles.map(({ sCode, sLibelle }) => (
              <AisleBlock key={sCode}>
                <ResourceImage type="Rayon" code={sCode} />
                <AisleContent>
                  <AisleName>{sLibelle}</AisleName>
                  <Button
                    fromComponent={Link}
                    to={Paths.SingleAisle({ aisleID: sCode })}
                    color={Colors.white}
                    background={Colors.accent}
                    style={{
                      minWidth: '160px',
                      marginTop: '40px',
                      fontSize: '15px',
                      fontWeight: 600,
                    }}
                  >
                    Voir
                  </Button>
                </AisleContent>
              </AisleBlock>
            ))}
          </AislesGrid>

          {aisleLimit > 0 && (
            <CenterGrid>
              <Button
                fromComponent="a"
                to="#"
                color={Colors.black}
                background="white"
                onClick={() => setAisleLimit(-1)}
                style={{
                  margin: '0 auto',
                  marginTop: '40px',
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                Tous les rayons
              </Button>
            </CenterGrid>
          )}
        </>
      )}

      <ContactGrid>
        <ContactBlock>
          <ContactDescription>
            {appConfig.mapSegments('content.pages.home.textContent')}
          </ContactDescription>

          <ContactButtonWrapper>
            <PageTitle fromComponent="h2" margin="0">
              Envoyez nous un message
            </PageTitle>
            <Button
              fromComponent={Link}
              to={Paths.Contact()}
              color={Colors.black}
              background={Colors.pureWhite}
              style={{
                minWidth: '160px',
                marginTop: '30px',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Contact
            </Button>
          </ContactButtonWrapper>
        </ContactBlock>
      </ContactGrid>
    </>
  );
};

Home.propTypes = {
  aislesState: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAisles: PropTypes.func.isRequired,

  productsState: PropTypes.objectOf(PropTypes.any).isRequired,
  usersState: PropTypes.objectOf(PropTypes.any).isRequired,

  fetchDiscountProducts: PropTypes.func.isRequired,
};

export const ConnectedHome = connect(
  state => ({
    aislesState: state.aisles,
    productsState: state.products,
    usersState: state.users,
  }),
  { ...AISLES_ACTIONS, ...PRODUCT_ACTIONS },
)(Home);

export default {
  path: Paths.Home(),
  exact: true,
  name: 'Accueil',
  component: ConnectedHome,
};
