import { Checkbox as AntCheckbox } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';

const CheckboxElement = styled(AntCheckbox)`
  --antd-wave-shadow-color: ${Colors.primary};

  cursor: pointer;

  .ant-checkbox-inner {
    border-radius: 0;
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${Colors.accent};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Colors.primary};
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  input {
    margin-top: 5px;
  }

  span.label {
    font-size: 14px;
    line-height: 21px;
    color: ${Colors.lightGrey};
  }
`;

const Checkbox = ({
  label,
  name,
  type = 'checkbox',
  required,
  checked: initialChecked,
  onChange,
  style,
}) => {
  const [checked, setChecked] = useState(initialChecked);

  // React to external state changes
  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const handleChange = event => {
    setChecked(!checked);

    if (onChange) {
      event.detail = { checked: !checked };
      onChange(event);
    }
  };

  const element = (
    <CheckboxElement
      name={name}
      id={name}
      checked={type === 'checkbox' ? checked : null}
      onChange={handleChange}
      required={required}
      style={style}
    />
  );

  if (label) {
    return (
      <Label>
        {element}
        <span className="label">{label}</span>
      </Label>
    );
  }

  return element;
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  checked: PropTypes.bool,
  required: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

Checkbox.defaultProps = {
  label: null,
  name: null,
  type: 'checkbox',
  checked: false,
  required: false,
  style: null,
};

export default Checkbox;
