import { isEmpty } from 'lodash';
import { publish } from 'pubsub-js';

import { PUBSUB_TOPICS } from '../../../hooks';
import { createEndpoint, Status, Type } from '../../models/europrisme';
import { Keys, storageGet } from '../../models/localstorage';
import {
  CART_ADD_INVALID_ITEM,
  CART_ADD_ITEM,
  CART_ASSIGN_ITEM_TO_LOCATION,
  CART_CLEAR_ALL_ITEMS,
  CART_CLEAR_HISTORY,
  CART_CREATE_VIRTUAL_REJECTED,
  CART_CREATE_VIRTUAL_REQUESTED,
  CART_CREATE_VIRTUAL_RESOLVED,
  CART_GET_VIRTUAL_REJECTED,
  CART_GET_VIRTUAL_REQUESTED,
  CART_GET_VIRTUAL_RESOLVED,
  CART_REMOVE_ITEM,
  CART_RESTORE_REJECTED,
  CART_RESTORE_REQUESTED,
  CART_RESTORE_RESOLVED,
  CART_SAVE_CURRENT_TO_STORAGE,
  CART_SET_ITEM_COMMENT,
  CART_SET_ITEM_COUNT,
  CART_UNDO_ACTION,
  CART_UPDATE_ITEM_DATA,
  HARTMANN_AUTH_REJECTED,
  HARTMANN_AUTH_REQUESTED,
  HARTMANN_AUTH_RESOLVED,
  ONTEX_AUTH_REJECTED,
  ONTEX_AUTH_REQUESTED,
  ONTEX_AUTH_RESOLVED,
} from '../types';
import { setOrderComment } from './order';
import { fetchSingleProduct } from './products';

export const restoreCartRequested = () => ({ type: CART_RESTORE_REQUESTED });

export const restoreCartResolved = ({
  products,
  invalidProducts,
  initialOrderComment,
}) => ({
  type: CART_RESTORE_RESOLVED,
  payload: { products, invalidProducts, initialOrderComment },
});

export const restoreCartRejected = error => ({
  type: CART_RESTORE_REJECTED,
  payload: { error },
});

export const restoreCartFromStorage = () => async dispatch => {
  dispatch(restoreCartRequested());

  try {
    const { products, invalidProducts, initialOrderComment } = storageGet(
      Keys.CART,
    );

    if (
      (products && !isEmpty(products)) ||
      (invalidProducts && !isEmpty(invalidProducts))
    ) {
      publish(PUBSUB_TOPICS.NOTIFY_CART_NOT_EMPTY);
    }

    return dispatch(
      restoreCartResolved({ products, invalidProducts, initialOrderComment }),
    );
  } catch (error) {
    return dispatch(restoreCartRejected(error));
  }
};

export const addToCart = (product, count = 1) => ({
  type: CART_ADD_ITEM,
  payload: { product, count },
});

export const addInvalidToCart = (product, count = 1) => ({
  type: CART_ADD_INVALID_ITEM,
  payload: { product, count },
});

export const removeFromCart = (product, options = {}) => ({
  type: CART_REMOVE_ITEM,
  payload: { product, options },
});

export const clearCart = () => async dispatch => {
  dispatch({ type: CART_CLEAR_ALL_ITEMS });
  dispatch(setOrderComment(''));
};

export const setCartItemCount = ({
  product,
  count,
  add = false,
  skipSort = false,
}) => ({
  type: CART_SET_ITEM_COUNT,
  payload: { product, count, add, skipSort },
});

export const cartUpdateItemData = ({ product, skipSort = false }) => ({
  type: CART_UPDATE_ITEM_DATA,
  payload: { product, skipSort },
});

export const undoCartAction = () => ({ type: CART_UNDO_ACTION });

export const clearCartHistory = () => ({ type: CART_CLEAR_HISTORY });

export const saveCurrentCartToStorage = () => ({
  type: CART_SAVE_CURRENT_TO_STORAGE,
});

export const setCartItemComment = ({ itemID, comment }) => ({
  type: CART_SET_ITEM_COMMENT,
  payload: { itemID, comment },
});

export const assignCartItemToLocation = ({
  itemID,
  kIDEntity,
  iEntityType,
}) => ({
  type: CART_ASSIGN_ITEM_TO_LOCATION,
  payload: { itemID, kIDEntity, iEntityType },
});

// ======= VIRTUAL CART

export const createVirtualCartRequested = params => ({
  type: CART_CREATE_VIRTUAL_REQUESTED,
  payload: { params },
});

export const createVirtualCartResolved = (response, params) => ({
  type: CART_CREATE_VIRTUAL_RESOLVED,
  payload: { response, params },
});

export const createVirtualCartRejected = (error, params) => ({
  type: CART_CREATE_VIRTUAL_REJECTED,
  payload: { error, params },
});

export const createVirtualCartEndpoint = createEndpoint(
  Type.POST,
  'virtualCartRequest',
  {
    sRequestOrigin: 'HX',
    sCustomerCode: '',
    kIDUser: '',
    sUID: '',
  },
  {
    request: createVirtualCartRequested,
    resolve: createVirtualCartResolved,
    reject: createVirtualCartRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const createVirtualCart = ({ origin, uuid, customerID }) => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users;

  if (!currentUser) {
    return null;
  }

  const { id, customerID: currentCustomerID } = currentUser;

  return dispatch(
    createVirtualCartEndpoint({
      sRequestOrigin: origin || 'HX',
      sCustomerCode: customerID || currentCustomerID,
      kIDUser: id,
      sUID: uuid,
    }),
  );
};

export const getVirtualCartRequested = params => ({
  type: CART_GET_VIRTUAL_REQUESTED,
  payload: { params },
});

export const getVirtualCartResolved = (response, params) => ({
  type: CART_GET_VIRTUAL_RESOLVED,
  payload: { response, params },
});

export const getVirtualCartRejected = (error, params) => ({
  type: CART_GET_VIRTUAL_REJECTED,
  payload: { error, params },
});

export const getVirtualCart = createEndpoint(
  Type.GET,
  'getVirtualCart',
  {
    sRequestOrigin: 'HX',
    sCustomerCode: '',
    kIDUser: '',
    sUID: '',
  },
  {
    request: getVirtualCartRequested,
    resolve: getVirtualCartResolved,
    reject: getVirtualCartRejected,
  },

  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getAndMergeVirtualCart = ({ uuid, origin }) => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users;

  if (!currentUser) {
    return null;
  }

  const { customerID, id } = currentUser;

  await dispatch(
    getVirtualCart({
      sRequestOrigin: origin || 'HX',
      sCustomerCode: customerID,
      kIDUser: id,
      sUID: uuid,
    }),
  );

  const { virtualCart } = getState();
  const currentCart = virtualCart.carts[uuid];

  const { products: cartProducts } = currentCart;

  await Promise.all(
    cartProducts.map(({ sArticleCode }) =>
      dispatch(fetchSingleProduct({ sArticleCode })),
    ),
  );

  const { products: productsState } = getState();
  const { products } = productsState;

  const getPrimaryKey = ({ sArticleCode, sBelowComment }) => {
    if (sBelowComment) {
      return `__INVALID/${sBelowComment}`;
    }

    if (['ONTX'].includes(origin)) {
      return `${origin}/${sArticleCode}`;
    }

    return sArticleCode;
  };

  await Promise.all(
    cartProducts.map(
      ({
        sArticleCode,
        sQuantite,
        sQuantityInCartons,
        sQuantityInPieces,
        sBelowComment,
      }) => {
        const { status } = products[sArticleCode];

        if (status === Status.ERROR) {
          return dispatch(
            addInvalidToCart(
              products[sArticleCode],
              Number.parseInt(sQuantite, 10) || 1,
            ),
          );
        }

        return dispatch(
          addToCart(
            {
              ...products[sArticleCode],

              primaryKey: getPrimaryKey({ sArticleCode, sBelowComment }),
              sCode: sArticleCode,
              hasSpecificQuantities: !!(
                sQuantityInPieces || sQuantityInCartons
              ),
              quantities: {
                units: Number.parseInt(sQuantityInPieces, 10) || 0,
                boxes: Number.parseInt(sQuantite, 10) || 1,
                cartons: Number.parseInt(sQuantityInCartons, 10) || 0,
              },
              sBelowComment,
              isDummy: !!sBelowComment,
            },

            Number.parseInt(sQuantite, 10) || 1,
          ),
        );
      },
    ),
  );

  publish(PUBSUB_TOPICS.IMPORT_VIRTUAL_CART_RESOLVED, { uuid });
};

// ======= Ontex

export const ontexAuthRequested = params => ({
  type: ONTEX_AUTH_REQUESTED,
  payload: { params: { ...params } },
});

export const ontexAuthResolved = (response, params) => ({
  type: ONTEX_AUTH_RESOLVED,
  payload: { response, params: { ...params } },
});

export const ontexAuthRejected = (error, params) => ({
  type: ONTEX_AUTH_REJECTED,
  payload: { error, params: { ...params } },
});

export const ontexAuthEndpoint = createEndpoint(
  Type.GET,
  'OntxAuth',
  {
    sIDUser: '',
    sCustomerCode: '',
    sVirtualCartID: '',
    sOrigin: 'ONTX',
  },
  {
    request: ontexAuthRequested,
    resolve: ontexAuthResolved,
    reject: ontexAuthRejected,
  },

  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const ontexAuthenticate = ({ uuid, customerID }) => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users;

  const { id, customerID: currentCustomerID } = currentUser;

  if (!uuid) {
    return console.warn('Missing virtual cart uuid:', uuid);
  }

  dispatch(
    ontexAuthEndpoint({
      sIDUser: id,
      sCustomerCode: customerID || currentCustomerID,
      sVirtualCartID: uuid,
    }),
  );
};

// ======= Hartmann

export const hartmannAuthRequested = params => ({
  type: HARTMANN_AUTH_REQUESTED,
  payload: { params: { ...params } },
});

export const hartmannAuthResolved = (response, params) => ({
  type: HARTMANN_AUTH_RESOLVED,
  payload: { response, params: { ...params } },
});

export const hartmannAuthRejected = (error, params) => ({
  type: HARTMANN_AUTH_REJECTED,
  payload: { error, params: { ...params } },
});

export const hartmannAuthEndpoint = createEndpoint(
  Type.GET,
  'HartAuth',
  {
    sIDUser: '',
    sCustomerCode: '',
    sVirtualCartID: '',
    sOrigin: 'HRTM',
  },
  {
    request: hartmannAuthRequested,
    resolve: hartmannAuthResolved,
    reject: hartmannAuthRejected,
  },

  data => {
    try {
      const { Success } = data;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const hartmannAuthenticate = ({ uuid, customerID }) => async (
  dispatch,
  getState,
) => {
  const { users } = getState();
  const { currentUser } = users;

  const { id, customerID: currentCustomerID } = currentUser;

  if (!uuid) {
    return console.warn('Missing virtual cart uuid:', uuid);
  }

  dispatch(
    hartmannAuthEndpoint({
      sIDUser: id,
      sCustomerCode: customerID || currentCustomerID,
      sVirtualCartID: uuid,
    }),
  );
};

// ======= Actions

export const CART_ACTIONS = {
  restoreCartFromStorage,
  addToCart,
  addInvalidToCart,
  removeFromCart,
  clearCart,
  setCartItemCount,
  undoCartAction,
  clearCartHistory,
  saveCurrentCartToStorage,
  setCartItemComment,
  assignCartItemToLocation,

  createVirtualCart,
  ontexAuthenticate,
  hartmannAuthenticate,
  getAndMergeVirtualCart,
};
