import PropTypes from 'prop-types';
import React from 'react';

const MagnifyIcon = ({ size, color, title }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <g
      transform="translate(1.547 1.634)"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <circle cx="4.683" cy="4.683" r="4.683" />
      <path strokeLinecap="round" d="M8.195 8.195l2.76 2.76" />
    </g>
  </svg>
);

MagnifyIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
};

MagnifyIcon.defaultProps = {
  size: 16,
  color: '#FFF',
  title: 'Rechercher',
};

export default MagnifyIcon;
