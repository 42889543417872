import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { DELAY_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import appConfig from '../../utils/app-config';
import { connect } from '../../utils/redux';

const ProductDelayInfo = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: 140px;
  padding: 7px 20px;

  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  white-space: nowrap;

  color: ${Colors.lightGrey};
  background-color: white;
  border-radius: 0;

  ${props =>
    props.leftAlign &&
    css`
      justify-content: flex-start;
      padding: 7px 0;

      div.loading {
        left: 0;
        transform: translate(0, -50%);
      }
    `}
`;

const ProductDelayMessage = styled.div`
  > span:not(:last-of-type)::after {
    content: '\\a0';
  }
`;

export const getMessage = delay => {
  if (!appConfig.get('settings.useProductDelay')) {
    return null;
  }

  if (appConfig.get('settings.useLabelForDelay')) {
    return (
      <ProductDelayMessage>
        <span>{delay?.label || 'Chargement...'}</span>
      </ProductDelayMessage>
    );
  }

  const delayDuration = typeof delay === 'number' ? delay : delay?.numeric;

  return (
    <ProductDelayMessage>
      <span>Disponible sous</span>
      <span>{delayDuration}</span>
      <span>{delayDuration === 1 ? 'jour' : 'jours'}</span>
    </ProductDelayMessage>
  );
};

const ProductDelay = ({
  product,
  quantity,
  className,
  leftAlign,
  headless,

  delayState,
  propagateState,
  getProductDelay,
}) => {
  const currentUser = useSelector(state => state.users.currentUser);
  const { customerID } = currentUser || {};

  const productCode = product.sCode;

  useEffect(() => {
    if (currentUser && productCode) {
      getProductDelay({
        sCustomerCode: customerID,
        sArticleCode: productCode,
        iQuantity: quantity,
      });
    }
  }, [productCode, quantity]);

  const delays = delayState.delays || {};
  const delayForProduct = delays[product.sCode] || {};

  const { status } = delayForProduct;
  const delayForQuantity = delayForProduct[quantity] || 0;

  useEffect(() => {
    if (propagateState) {
      propagateState({
        product,
        delay: delayForQuantity,
        status,
      });
    }
  }, [product, delayForQuantity, status]);

  if (headless) {
    return null;
  }

  return (
    <ProductDelayInfo
      data-stock={0}
      className={className}
      data-state={status}
      leftAlign={leftAlign ? 1 : 0}
    >
      {status === Status.LOADING ? (
        <>
          <ProductDelayMessage>
            <span className="loading">Chargement...</span>
          </ProductDelayMessage>
        </>
      ) : (
        getMessage(delayForQuantity)
      )}
    </ProductDelayInfo>
  );
};

ProductDelay.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  quantity: PropTypes.number,
  className: PropTypes.string,
  leftAlign: PropTypes.bool,
  propagateState: PropTypes.func,
  headless: PropTypes.bool,

  delayState: PropTypes.objectOf(PropTypes.any).isRequired,
  getProductDelay: PropTypes.func.isRequired,
};

ProductDelay.defaultProps = {
  quantity: 1,
  className: '',
  leftAlign: false,
  headless: false,

  propagateState: null,
};

export default connect(state => ({ delayState: state.delay }), {
  ...DELAY_ACTIONS,
})(memo(ProductDelay));
