import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { AISLES_ACTIONS, FAMILIES_ACTIONS } from '../../store/actions';
import { connect } from '../../utils/redux';
import Details from './Details';
import ShopSidebarEntry from './ShopSidebarEntry';
import Sidebar from './Sidebar';

const ShopSidebar = ({ aisles, fetchAisles, fetchFamilies }) => {
  useEffect(() => {
    fetchAisles();
    fetchFamilies();
  }, []);

  const sortedAisles = Object.values(aisles || {}).sort((a, b) =>
    a.sLibelle.toLowerCase().localeCompare(b.sLibelle.toLowerCase()),
  );

  return (
    <>
      <Sidebar>
        <h3>Nos rayons</h3>

        {sortedAisles.map(aisle => (
          <ShopSidebarEntry key={aisle.sCode} aisle={aisle} />
        ))}
      </Sidebar>

      <Sidebar isMobile>
        <Details summary={<h3>Nos rayons</h3>}>
          {sortedAisles.map(aisle => (
            <ShopSidebarEntry key={aisle.sCode} aisle={aisle} />
          ))}
        </Details>
      </Sidebar>
    </>
  );
};

ShopSidebar.propTypes = {
  aisles: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAisles: PropTypes.func.isRequired,
  fetchFamilies: PropTypes.func.isRequired,
};

export default connect(state => ({ ...state.aisles, ...state.families }), {
  ...AISLES_ACTIONS,
  ...FAMILIES_ACTIONS,
})(ShopSidebar);
