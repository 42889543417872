import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CART_ACTIONS } from '../../store/actions/reducer-actions/cart';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import { HeaderButtonLink } from '../sections/Header';
import HeaderCircle from './HeaderCircle';

const CartIndicator = ({ theme, shade, present, restoreCartFromStorage }) => {
  const { products } = present;

  const count = Object.values(products).reduce(
    (acc, prod) => acc + prod.count,
    0,
  );

  const boundCount = count > 999 ? '999+' : count;

  useEffect(() => {
    restoreCartFromStorage();
  }, []);

  return (
    <>
      <HeaderButtonLink
        className="with-header-circle cart"
        to={Paths.Cart()}
        theme={theme}
      >
        <span>Mon panier</span>
        <HeaderCircle shade={shade} floating>
          {boundCount}
        </HeaderCircle>
      </HeaderButtonLink>
    </>
  );
};

CartIndicator.propTypes = {
  theme: PropTypes.oneOf(['regular', 'dark']).isRequired,
  shade: PropTypes.string,
  present: PropTypes.objectOf(PropTypes.any).isRequired,
  restoreCartFromStorage: PropTypes.func.isRequired,
};

CartIndicator.defaultProps = {
  shade: null,
};

export default connect(state => ({ ...state.cart }), { ...CART_ACTIONS })(
  CartIndicator,
);
