import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import InfoIcon from '../../../assets/img/InfoIcon';
import { Colors } from '../../styles/vars';

const ErrorBoundaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 16px;
  padding-bottom: 16px;

  background-color: ${Colors.lightRed};
  color: ${Colors.darkRed};

  .sadface {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 40px;
    font-weight: 300;

    pointer-events: none;
    user-select: none;
  }

  .message {
    margin-top: 30px;
    font-size: 14px;
  }

  details {
    width: 100%;

    font-size: 14px;

    summary {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 1em;

      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;

      cursor: pointer;

      span {
        display: inline-block;
        margin-left: 8px;

        opacity: 0;
        transform: translateX(-8px);

        transition-property: opacity, transform;
        transition-duration: 0.3s;
        transition-delay: 0.5s;
        transition-timing-function: ease-in-out;
      }

      &:first-of-type {
        list-style-type: none;
      }

      &::marker,
      &::-webkit-details-marker {
        display: none;
      }

      &:hover {
        opacity: 1;

        span {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .details {
      margin-top: 8px;
      padding: 12px 8px;
      background-color: rgba(255, 255, 255, 0.2);

      font-family: 'Courier New', Courier, monospace;

      p {
        margin: 0;
      }

      ul {
        margin: 0;
        margin-top: 12px;
        padding-left: 1.5em;
      }
    }

    &[open] {
      summary {
        opacity: 1;

        span {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { resetOnChange: previous } = prevProps;
    const { resetOnChange: current } = this.props;

    if (current !== previous) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        error: null,
        errorInfo: null,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (error && errorInfo) {
      return (
        <ErrorBoundaryContainer>
          <div className="sadface">:(</div>
          <div className="message">Une erreur est survenue</div>

          <details>
            <summary>
              <InfoIcon size={14} />
              <span>Informations développeurs</span>
            </summary>

            <div className="details">
              {error && (
                <p>
                  {error.name}: {error.message}
                </p>
              )}

              <ul className="stack">
                {errorInfo.componentStack &&
                  errorInfo.componentStack
                    .trim()
                    .split('\n')
                    // eslint-disable-next-line react/no-array-index-key
                    .map((line, index) => <li key={index}>{line.trim()}</li>)}
              </ul>
            </div>
          </details>
        </ErrorBoundaryContainer>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  resetOnChange: PropTypes.any,
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultProps = {
  resetOnChange: null,
};

export default ErrorBoundary;
