import { AnimatePresence, motion } from 'framer-motion';
import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import CheckIcon from '../../../../assets/img/CheckIcon';
import { Colors } from '../../../styles/vars';
import Button from '../Button';
import Chevron from '../Chevron';
import { treeItemShape } from './types';

const TreeWrapper = styled.div`
  position: relative;

  .breadcrumbs {
    position: sticky;
    top: 0;
    padding-top: 8px;
    padding-bottom: 16px;

    background-color: ${Colors.white};
    font-size: 12px;
    line-height: 1;

    user-select: none;
    z-index: 100;

    .separator {
      color: ${Colors.lighterGrey};
    }

    span {
      display: inline-block;
    }
  }
`;

const TreePage = styled(motion.ul)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin: 0;
  padding: 0;
  padding-left: ${props => (props.$subChild ? '35px' : 0)};

  list-style: none;

  ${props =>
    props.$subChild &&
    css`
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        left: 17px;

        background-color: ${Colors.lighterGrey};

        pointer-events: none;
      }
    `};

  .item-selector__sub-children {
    display: flex;
    flex-direction: column;
    padding-left: 1.5em;

    transition: margin 0.3s ease-in-out;
    overflow: hidden;

    &.is-selected {
      margin-top: 4px;
    }
  }
`;

const TreeItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  border-radius: 40px;

  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &:hover,
  &:has(:focus-visible) {
    background: ${Colors.pureWhite};
  }

  ${props =>
    props.$isSelected &&
    css`
      background: ${Colors.pureWhite};
    `}

  .item-selector {
    justify-content: flex-start;
    text-align: left;
    padding: 8px 20px;

    .item-selector__check-wrapper {
      display: flex;
    }
  }

  .next-page {
    position: relative;
    justify-content: center;
    align-items: center;
    min-width: 35px;
    padding: 0;
    text-align: center;
  }
`;

const RecursiveTree = ({
  item,
  selectedItemKey,
  expandedItems,
  level,
  handleOnChange,
  expandItem,
  collapseItem,
}) => {
  if (!item) return null;

  const { key, label, children } = item;
  const hasChildren = children && children.length > 0;
  const isExpanded =
    item?.key === '__ROOT__' || expandedItems.includes(item?.key);
  const shouldShowChildren = isExpanded && hasChildren;

  const isSelected = useMemo(() => key === selectedItemKey, [
    key,
    selectedItemKey,
  ]);

  const toggleItem = () => {
    if (isExpanded) {
      collapseItem(item);
    } else {
      expandItem(item);
    }
  };

  useEffect(() => {
    if (isSelected) {
      expandItem(item);
    }
  }, [isSelected]);

  if (key === '__ROOT__' && hasChildren) {
    return (
      <>
        <TreePage>
          {children.map(
            child =>
              child.children &&
              child.children.map(grandChild => (
                <RecursiveTree
                  key={`${child.key}--${grandChild.key}__subtree`}
                  item={grandChild}
                  selectedItemKey={selectedItemKey}
                  expandedItems={expandedItems}
                  level={level + 1}
                  handleOnChange={handleOnChange}
                  expandItem={expandItem}
                  collapseItem={collapseItem}
                />
              )),
          )}
        </TreePage>
      </>
    );
  }

  return (
    <>
      <TreeItem key={key} $level={level} $isSelected={isSelected}>
        {hasChildren && (
          <Button
            type="button"
            className="next-page"
            background={Colors.pureWhite}
            onClick={toggleItem}
            iconOnly
          >
            <Chevron direction={isExpanded ? 'down' : 'right'} />
          </Button>
        )}

        <Button
          type="button"
          className="item-selector"
          color={isSelected ? Colors.darkGreen : Colors.black}
          background={isSelected ? Colors.lightGreen : 'transparent'}
          hoverColor={Colors.black}
          hoverBackground={isSelected ? Colors.dimmedLightGreen : 'transparent'}
          style={{ flexGrow: 1 }}
          onClick={handleOnChange(item)}
        >
          <AnimatePresence>
            {isSelected && (
              <motion.div
                initial={{ width: 0, opacity: 0 }}
                animate={{ width: 'auto', opacity: 1 }}
                exit={{ width: 0, opacity: 0 }}
                className="item-selector__check-wrapper"
              >
                <CheckIcon />
              </motion.div>
            )}
          </AnimatePresence>

          <span>{label}</span>
        </Button>
      </TreeItem>

      <AnimatePresence>
        {shouldShowChildren && (
          <TreePage
            key={`${key}__page`}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: 'spring', duration: 0.4 }}
            $subChild
          >
            {children.map(child => (
              <RecursiveTree
                key={`${child.key}__subtree`}
                item={child}
                selectedItemKey={selectedItemKey}
                expandedItems={expandedItems}
                level={level + 1}
                handleOnChange={handleOnChange}
                expandItem={expandItem}
                collapseItem={collapseItem}
              />
            ))}
          </TreePage>
        )}
      </AnimatePresence>
    </>
  );
};

RecursiveTree.propTypes = {
  item: PropTypes.shape(treeItemShape),
  selectedItemKey: PropTypes.string,
  expandedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  level: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
  expandItem: PropTypes.func.isRequired,
  collapseItem: PropTypes.func.isRequired,
};

RecursiveTree.defaultProps = {
  item: null,
  selectedItemKey: null,
  level: -1,
};

const Tree = ({
  items,
  selectedItem: selectedItemKey,
  onChange: parentOnChange,
}) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const handleOnChange = useCallback(
    item => event => {
      if (parentOnChange) {
        if (item?.key === selectedItemKey) {
          parentOnChange(null, event);
        } else {
          parentOnChange(item, event);
        }
      }
    },
    [parentOnChange, selectedItemKey],
  );

  const expandItem = useCallback(item => {
    const { key } = item;

    setExpandedItems([...new Set([...expandedItems, key])]);
  });

  const filterChildrenFromExpandedItems = (itemsList, item) => {
    const { key, children } = item;
    const filtered = itemsList.filter(k => k !== key);

    if (children) {
      const filteredChildren = children.map(child =>
        filterChildrenFromExpandedItems(filtered, child),
      );

      return intersection(filtered, ...filteredChildren);
    }

    return filtered;
  };

  const collapseItem = useCallback(item => {
    setExpandedItems(filterChildrenFromExpandedItems(expandedItems, item));
  });

  return (
    <TreeWrapper>
      <RecursiveTree
        item={items}
        expandedItems={expandedItems}
        selectedItemKey={selectedItemKey}
        handleOnChange={handleOnChange}
        expandItem={expandItem}
        collapseItem={collapseItem}
      />
    </TreeWrapper>
  );
};

Tree.propTypes = {
  items: PropTypes.shape(treeItemShape),
  selectedItem: PropTypes.string,
  onChange: PropTypes.func,
};

Tree.defaultProps = {
  items: null,
  selectedItem: null,
  onChange: null,
};

export default Tree;
