// ==============================================
// Users
// ==============================================

export const LOGIN_USER_REQUESTED = 'LOGIN_USER_REQUESTED';
export const LOGIN_USER_RESOLVED = 'LOGIN_USER_RESOLVED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const REQUEST_PASSWORD_REQUESTED = 'REQUEST_PASSWORD_REQUESTED';
export const REQUEST_PASSWORD_RESOLVED = 'REQUEST_PASSWORD_RESOLVED';
export const REQUEST_PASSWORD_REJECTED = 'REQUEST_PASSWORD_REJECTED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_RESOLVED = 'RESET_PASSWORD_RESOLVED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_RESOLVED = 'GET_USERS_RESOLVED';
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED';

export const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED';
export const CREATE_USER_RESOLVED = 'CREATE_USER_RESOLVED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const GET_SINGLE_USER_REQUESTED = 'GET_SINGLE_USER_REQUESTED';
export const GET_SINGLE_USER_RESOLVED = 'GET_SINGLE_USER_RESOLVED';
export const GET_SINGLE_USER_REJECTED = 'GET_SINGLE_USER_REJECTED';

export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_RESOLVED = 'UPDATE_USER_RESOLVED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const DISABLE_USER_REQUESTED = 'DISABLE_USER_REQUESTED';
export const DISABLE_USER_RESOLVED = 'DISABLE_USER_RESOLVED';
export const DISABLE_USER_REJECTED = 'DISABLE_USER_REJECTED';

// ==============================================
// Clients
// ==============================================

export const SEARCH_20_CLIENTS_REQUESTED = 'SEARCH_20_CLIENTS_REQUESTED';
export const SEARCH_20_CLIENTS_RESOLVED = 'SEARCH_20_CLIENTS_RESOLVED';
export const SEARCH_20_CLIENTS_REJECTED = 'SEARCH_20_CLIENTS_REJECTED';
export const CANCEL_SEARCH_20_CLIENTS = 'CANCEL_SEARCH_20_CLIENTS';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';

// ==============================================
// Aisles
// ==============================================

export const GET_AISLES_REQUESTED = 'GET_AISLES_REQUESTED';
export const GET_AISLES_RESOLVED = 'GET_AISLES_RESOLVED';
export const GET_AISLES_REJECTED = 'GET_AISLES_REJECTED';

export const GET_SINGLE_AISLE_REQUESTED = 'GET_SINGLE_AISLE_REQUESTED';
export const GET_SINGLE_AISLE_RESOLVED = 'GET_SINGLE_AISLE_RESOLVED';
export const GET_SINGLE_AISLE_REJECTED = 'GET_SINGLE_AISLE_REJECTED';

// ==============================================
// Families
// ==============================================

export const GET_FAMILIES_REQUESTED = 'GET_FAMILIES_REQUESTED';
export const GET_FAMILIES_RESOLVED = 'GET_FAMILIES_RESOLVED';
export const GET_FAMILIES_REJECTED = 'GET_FAMILIES_REJECTED';

export const GET_SINGLE_FAMILY_REQUESTED = 'GET_SINGLE_FAMILY_REQUESTED';
export const GET_SINGLE_FAMILY_RESOLVED = 'GET_SINGLE_FAMILY_RESOLVED';
export const GET_SINGLE_FAMILY_REJECTED = 'GET_SINGLE_FAMILY_REJECTED';

// ==============================================
// Products
// ==============================================

export const GET_PRODUCTS_REQUESTED = 'GET_PRODUCTS_REQUESTED';
export const GET_PRODUCTS_RESOLVED = 'GET_PRODUCTS_RESOLVED';
export const GET_PRODUCTS_REJECTED = 'GET_PRODUCTS_REJECTED';

export const GET_DISCOUNT_PRODUCTS_REQUESTED =
  'GET_DISCOUNT_PRODUCTS_REQUESTED';
export const GET_DISCOUNT_PRODUCTS_RESOLVED = 'GET_DISCOUNT_PRODUCTS_RESOLVED';
export const GET_DISCOUNT_PRODUCTS_REJECTED = 'GET_DISCOUNT_PRODUCTS_REJECTED';
export const CANCEL_GET_DISCOUNT_PRODUCTS = 'CANCEL_GET_DISCOUNT_PRODUCTS';

export const SEARCH_PRODUCTS_REQUESTED = 'SEARCH_PRODUCTS_REQUESTED';
export const SEARCH_PRODUCTS_RESOLVED = 'SEARCH_PRODUCTS_RESOLVED';
export const SEARCH_PRODUCTS_REJECTED = 'SEARCH_PRODUCTS_REJECTED';

export const GET_SINGLE_PRODUCT_REQUESTED = 'GET_SINGLE_PRODUCT_REQUESTED';
export const GET_SINGLE_PRODUCT_RESOLVED = 'GET_SINGLE_PRODUCT_RESOLVED';
export const GET_SINGLE_PRODUCT_REJECTED = 'GET_SINGLE_PRODUCT_REJECTED';

export const GET_SINGLE_PRODUCT_PDF_REQUESTED =
  'GET_SINGLE_PRODUCT_PDF_REQUESTED';
export const GET_SINGLE_PRODUCT_PDF_RESOLVED =
  'GET_SINGLE_PRODUCT_PDF_RESOLVED';
export const GET_SINGLE_PRODUCT_PDF_REJECTED =
  'GET_SINGLE_PRODUCT_PDF_REJECTED';

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

// ==============================================
// Search data
// ==============================================

export const SEARCH_DATA_RESTORE_REQUESTED = 'SEARCH_DATA_RESTORE_REQUESTED';
export const SEARCH_DATA_RESTORE_RESOLVED = 'SEARCH_DATA_RESTORE_RESOLVED';
export const SEARCH_DATA_RESTORE_REJECTED = 'SEARCH_DATA_RESTORE_REJECTED';

export const SEARCH_DATA_LOAD_REQUESTED = 'SEARCH_DATA_LOAD_REQUESTED';
export const SEARCH_DATA_LOAD_RESOLVED = 'SEARCH_DATA_LOAD_RESOLVED';
export const SEARCH_DATA_LOAD_REJECTED = 'SEARCH_DATA_LOAD_REJECTED';

// ==============================================
// Cart
// ==============================================

export const CART_RESTORE_REQUESTED = 'CART_RESTORE_REQUESTED';
export const CART_RESTORE_RESOLVED = 'CART_RESTORE_RESOLVED';
export const CART_RESTORE_REJECTED = 'CART_RESTORE_REJECTED';
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_ADD_INVALID_ITEM = 'CART_ADD_INVALID_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_CLEAR_ALL_ITEMS = 'CART_CLEAR_ALL_ITEMS';
export const CART_SET_ITEM_COUNT = 'CART_SET_ITEM_COUNT';
export const CART_UPDATE_ITEM_DATA = 'CART_UPDATE_ITEM_DATA';
export const CART_HISTORY_UPDATE = 'CART_HISTORY_UPDATE';
export const CART_UNDO_ACTION = 'CART_UNDO_ACTION';
export const CART_CLEAR_HISTORY = 'CART_CLEAR_HISTORY';
export const CART_SAVE_CURRENT_TO_STORAGE = 'CART_SAVE_CURRENT_TO_STORAGE';
export const CART_SET_ITEM_COMMENT = 'CART_SET_ITEM_COMMENT';
export const CART_ASSIGN_ITEM_TO_LOCATION = 'CART_ASSIGN_ITEM_TO_LOCATION';

export const CART_CREATE_VIRTUAL_REQUESTED = 'CART_CREATE_VIRTUAL_REQUESTED';
export const CART_CREATE_VIRTUAL_RESOLVED = 'CART_CREATE_VIRTUAL_RESOLVED';
export const CART_CREATE_VIRTUAL_REJECTED = 'CART_CREATE_VIRTUAL_REJECTED';

export const CART_GET_VIRTUAL_REQUESTED = 'CART_GET_VIRTUAL_REQUESTED';
export const CART_GET_VIRTUAL_RESOLVED = 'CART_GET_VIRTUAL_RESOLVED';
export const CART_GET_VIRTUAL_REJECTED = 'CART_GET_VIRTUAL_REJECTED';

export const ONTEX_AUTH_REQUESTED = 'ONTEX_AUTH_REQUESTED';
export const ONTEX_AUTH_RESOLVED = 'ONTEX_AUTH_RESOLVED';
export const ONTEX_AUTH_REJECTED = 'ONTEX_AUTH_REJECTED';

export const HARTMANN_AUTH_REQUESTED = 'HARTMANN_AUTH_REQUESTED';
export const HARTMANN_AUTH_RESOLVED = 'HARTMANN_AUTH_RESOLVED';
export const HARTMANN_AUTH_REJECTED = 'HARTMANN_AUTH_REJECTED';

// ==============================================
// Order
// ==============================================

export const ORDER_SEND_CART_REQUESTED = 'ORDER_SEND_CART_REQUESTED';
export const ORDER_SEND_CART_RESOLVED = 'ORDER_SEND_CART_RESOLVED';
export const ORDER_SEND_CART_REJECTED = 'ORDER_SEND_CART_REJECTED';
export const ORDER_SET_COMMENT = 'ORDER_SET_COMMENT';

export const GET_ORDERS_REQUESTED = 'GET_ORDERS_REQUESTED';
export const GET_ORDERS_RESOLVED = 'GET_ORDERS_RESOLVED';
export const GET_ORDERS_REJECTED = 'GET_ORDERS_REJECTED';

export const GET_SINGLE_ORDER_REQUESTED = 'GET_SINGLE_ORDER_REQUESTED';
export const GET_SINGLE_ORDER_RESOLVED = 'GET_SINGLE_ORDER_RESOLVED';
export const GET_SINGLE_ORDER_REJECTED = 'GET_SINGLE_ORDER_REJECTED';

// ==============================================
// Pending Order
// ==============================================

export const GET_PENDING_ORDERS_REQUESTED = 'GET_PENDING_ORDERS_REQUESTED';
export const GET_PENDING_ORDERS_RESOLVED = 'GET_PENDING_ORDERS_RESOLVED';
export const GET_PENDING_ORDERS_REJECTED = 'GET_PENDING_ORDERS_REJECTED';

export const GET_SINGLE_PENDING_ORDER_REQUESTED =
  'GET_SINGLE_PENDING_ORDER_REQUESTED';
export const GET_SINGLE_PENDING_ORDER_RESOLVED =
  'GET_SINGLE_PENDING_ORDER_RESOLVED';
export const GET_SINGLE_PENDING_ORDER_REJECTED =
  'GET_SINGLE_PENDING_ORDER_REJECTED';

export const CANCEL_PENDING_ORDER_REQUESTED = 'CANCEL_PENDING_ORDER_REQUESTED';
export const CANCEL_PENDING_ORDER_RESOLVED = 'CANCEL_PENDING_ORDER_RESOLVED';
export const CANCEL_PENDING_ORDER_REJECTED = 'CANCEL_PENDING_ORDER_REJECTED';

// ==============================================
// Preorder
// ==============================================

export const GET_PREORDERS_REQUESTED = 'GET_PREORDERS_REQUESTED';
export const GET_PREORDERS_RESOLVED = 'GET_PREORDERS_RESOLVED';
export const GET_PREORDERS_REJECTED = 'GET_PREORDERS_REJECTED';

export const GET_SINGLE_PREORDER_REQUESTED = 'GET_SINGLE_PREORDER_REQUESTED';
export const GET_SINGLE_PREORDER_RESOLVED = 'GET_SINGLE_PREORDER_RESOLVED';
export const GET_SINGLE_PREORDER_REJECTED = 'GET_SINGLE_PREORDER_REJECTED';

export const PREORDER_SEND_CART_REQUESTED = 'PREORDER_SEND_CART_REQUESTED';
export const PREORDER_SEND_CART_RESOLVED = 'PREORDER_SEND_CART_RESOLVED';
export const PREORDER_SEND_CART_REJECTED = 'PREORDER_SEND_CART_REJECTED';

export const PREORDER_DELETE_REQUESTED = 'PREORDER_DELETE_REQUESTED';
export const PREORDER_DELETE_RESOLVED = 'PREORDER_DELETE_RESOLVED';
export const PREORDER_DELETE_REJECTED = 'PREORDER_DELETE_REJECTED';

// ==============================================
// Images
// ==============================================

export const GET_IMAGE_REQUESTED = 'GET_IMAGE_REQUESTED';
export const GET_IMAGE_RESOLVED = 'GET_IMAGE_RESOLVED';
export const GET_IMAGE_REJECTED = 'GET_IMAGE_REJECTED';

export const POST_IMAGE_REQUESTED = 'POST_IMAGE_REQUESTED';
export const POST_IMAGE_RESOLVED = 'POST_IMAGE_RESOLVED';
export const POST_IMAGE_REJECTED = 'POST_IMAGE_REJECTED';

// ==============================================
// Delay
// ==============================================

export const GET_PRODUCT_DELAY_REQUESTED = 'GET_PRODUCT_DELAY_REQUESTED';
export const GET_PRODUCT_DELAY_RESOLVED = 'GET_PRODUCT_DELAY_RESOLVED';
export const GET_PRODUCT_DELAY_REJECTED = 'GET_PRODUCT_DELAY_REJECTED';
export const CANCEL_GET_PRODUCT_DELAY = 'CANCEL_GET_PRODUCT_DELAY';

// ==============================================
// Email
// ==============================================

export const SEND_EMAIL_REQUESTED = 'SEND_EMAIL_REQUESTED';
export const SEND_EMAIL_RESOLVED = 'SEND_EMAIL_RESOLVED';
export const SEND_EMAIL_REJECTED = 'SEND_EMAIL_REJECTED';

// ==============================================
// Locations
// ==============================================

export const GET_LOCATIONS_REQUESTED = 'GET_LOCATIONS_REQUESTED';
export const GET_LOCATIONS_RESOLVED = 'GET_LOCATIONS_RESOLVED';
export const GET_LOCATIONS_REJECTED = 'GET_LOCATIONS_REJECTED';

export const GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED =
  'GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED';
export const GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED =
  'GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED';
export const GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED =
  'GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED';
