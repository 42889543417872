/* eslint-disable import/prefer-default-export */

import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const connect = (mapStateToProps, mapDispatchToProps = {}) =>
  reduxConnect(mapStateToProps, dispatch =>
    bindActionCreators(mapDispatchToProps, dispatch),
  );

export const initialStateToUndoable = present => ({
  past: null,
  present,
  future: null,
});

export const makeHistoryState = (state, action) => ({
  state: { ...state },
  payload: action.payload,
});

export const undoable = (
  reducer,
  initialState,
  {
    triggerOnActions = [],
    undoAction,
    onUndoAction,
    redoAction,
    onRedoAction,
    clearAction,
    onClearAction,
  },
) => (state = initialStateToUndoable(initialState), action) => {
  if (triggerOnActions.includes(action.type)) {
    const past = { state: { ...state.present }, payload: action.payload };
    const present = reducer(state.present, action);

    return { ...state, past, present };
  }

  if (action.type === undoAction) {
    const newState = {
      ...state,
      past: null,
      present: state.past.state,
      future: state.present,
    };

    if (onUndoAction) onUndoAction(newState.present);

    return newState;
  }

  if (action.type === redoAction) {
    const newState = {
      ...state,
      past: state.present,
      present: state.future.state,
      future: null,
    };

    if (onRedoAction) onRedoAction(newState.present);

    return newState;
  }

  if (action.type === clearAction) {
    const newState = {
      ...state,
      past: null,
      future: null,
    };

    if (onClearAction) onClearAction(newState.present);

    return newState;
  }

  return { ...state, present: reducer(state.present, action) };
};
