import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.locale(fr);

export const Formats = {
  ORDERS_API: 'YYYY-MM-DDTh:m:s',
  PREORDERS_API: 'YYYY-MM-DDTh:m:s',
  ORDER_HUMAN_READABLE: 'dddd Do MMMM YYYY',
  DATETIME_HUMAN_READABLE: 'dddd Do MMMM YYYY à H[h]m',

  DAY_MONTH_YEAR: 'DD/MM/YYYY',
};

const DateFormat = {
  parse(...args) {
    return dayjs(...args);
  },

  format(inputOrDate, format) {
    if (typeof inputOrDate === 'string') {
      return this.parse(inputOrDate).format(format);
    }

    if (
      inputOrDate &&
      'format' in inputOrDate &&
      typeof inputOrDate.format === 'function'
    ) {
      return inputOrDate.format(format);
    }

    return inputOrDate;
  },
};

export default DateFormat;
