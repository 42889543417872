import { createEndpoint, Type } from '../../models/europrisme';
import {
  SEND_EMAIL_REJECTED,
  SEND_EMAIL_REQUESTED,
  SEND_EMAIL_RESOLVED,
} from '../types';

export const sendEmailRequested = () => ({
  type: SEND_EMAIL_REQUESTED,
});

export const sendEmailRejected = error => ({
  type: SEND_EMAIL_REJECTED,
  payload: { error },
});

export const sendEmailResolved = data => ({
  type: SEND_EMAIL_RESOLVED,
  payload: { data },
});

export const sendEmailEndpoint = createEndpoint(
  Type.XML,
  'sendEmailMessageClient',
  {
    sFirstName: '',
    sLastName: '',
    sRequirerEmail: '',
    sPhoneNumber: '',
    sEmailBodyText: '',
    sClientCode: '',
    sClientName: '',
  },
  {
    request: sendEmailRequested,
    resolve: sendEmailResolved,
    reject: sendEmailRejected,
  },
);

export const sendEmail = args => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users || {};

  return dispatch(
    sendEmailEndpoint({
      ...args,
      sClientCode: currentUser?.customerID,
      sClientName: currentUser?.customerName,
    }),
  );
};

export const EMAIL_ACTIONS = { sendEmail };
