import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import CloseIcon from '../../../assets/img/CloseIcon';
import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import { CART_ACTIONS } from '../../store/actions/reducer-actions/cart';
import { Status } from '../../store/reducers/cart';
import { Colors } from '../../styles/vars';
import { API_BASE_URL } from '../../utils/api-base';
import { generateVirtualCartID, isHttpUrl, noop } from '../../utils/misc';
import { connect } from '../../utils/redux';
import Paths from '../routes/paths';
import Button from './Button';
import { NotificationType, sendNotification } from './NotificationArea';
import Spinner from './Spinner';

// ===== IFramePortal

const Scrim = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 999999;

  pointer-events: ${props => (props.opened ? 'initial' : 'none')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;

    pointer-events: none;
    z-index: -1;

    ${props =>
      props.opened &&
      css`
        opacity: 1;
        pointer-events: initial;
      `}
  }
`;

const IFrameWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.fullscreen ? '100%' : `${props.width + 80}px`)};
  height: ${props => (props.fullscreen ? '100%' : `${props.height + 80}px`)};

  color: ${Colors.white};
  background-color: ${Colors.lightGrey};
  border-radius: ${props => (props.fullscreen ? 0 : '20px')};
  opacity: ${props => (props.opened ? 1 : 0)};
  transform: translateX(${props => (props.opened ? 0 : -20)}px);

  transition-property: opacity, transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;

  pointer-events: ${props => (props.opened ? 'initial' : 'none')};

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    min-width: unset;
    padding: 6px;

    svg {
      margin-right: 0;
    }
  }

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: white;
    border: none;
    opacity: ${props => (props.loaded ? 1 : 0)};

    transition-property: opacity;
    transition-duration: 0.4s;
    transition-delay: 2s;
    transition-timing-function: ease-in-out;

    z-index: 100;
    overflow: hidden;

    ${props =>
      props.fullscreen &&
      css`
        width: calc(100% - 80px);
        height: calc(100% - 80px);
      `}

    ${props =>
      props.$aspectRatio &&
      css`
        width: unset;
        min-width: min(1000px, calc(100% - 80px));
        max-width: calc(100% - 80px);
        aspect-ratio: ${props.$aspectRatio};
      `}
  }
`;

const IFramePortal = ({
  src,
  title,
  width,
  height,
  opened,
  finished,
  forwardOnClick,
  fullscreen,
  aspectRatio,
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleOnClose = event => {
    forwardOnClick(event);
    setLoaded(false);
  };

  if (typeof window !== 'undefined') {
    return createPortal(
      <Scrim opened={opened}>
        <IFrameWrapper
          width={width}
          height={height}
          opened={opened}
          loaded={loaded && !finished}
          fullscreen={fullscreen}
          $aspectRatio={aspectRatio}
        >
          <Button className="close" onClick={handleOnClose}>
            <CloseIcon />
          </Button>

          {opened && (
            <>
              <Spinner messageDelay={3}>Encore un instant...</Spinner>

              <iframe
                src={src}
                title={title}
                width={width}
                height={height}
                sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms allow-downloads allow-modals allow-popups"
                onLoad={() => setLoaded(true)}
              />
            </>
          )}
        </IFrameWrapper>
      </Scrim>,
      document.body,
    );
  }

  return null;
};

IFramePortal.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  opened: PropTypes.bool,
  finished: PropTypes.bool,
  fullscreen: PropTypes.bool,
  aspectRatio: PropTypes.string,

  forwardOnClick: PropTypes.func.isRequired,
};

IFramePortal.defaultProps = {
  width: 800,
  height: 600,
  opened: false,
  finished: false,
  fullscreen: false,
  aspectRatio: null,
};

// ===== ExternalConfigurator

const ClickArea = styled.div``;

const getPlatformAutologinURL = raw => {
  if (isHttpUrl(raw)) {
    return raw;
  }

  try {
    const json = JSON.parse(raw);

    if (json?.SuccessStatus && json.SuccessStatus === 'true') {
      return json.SelfAuthenticatedURL;
    }
  } catch (_) {
    return null;
  }

  return null;
};

const ExternalConfigurator = ({
  src,
  title,
  width,
  height,
  isOntex,
  isHartmann,
  isDASRI,
  fullscreen,
  origin,

  content,
  children,

  usersState,
  virtualCartState,
  skipVirtualCart,

  createVirtualCart,
  getAndMergeVirtualCart,
  ontexAuthenticate,
  hartmannAuthenticate,
}) => {
  const history = useHistory();

  const [opened, setOpened] = useState(false);
  const [finished, setFinished] = useState(false);
  const [uuid, setUUID] = useState(null);
  const [source, setSource] = useState(src);

  const { currentUser } = usersState;
  const { isTTC } = currentUser || { isTTC: false };

  const virtualCart = virtualCartState.carts[uuid];

  const isExternal = isOntex || isHartmann;
  const externalAuthFunction = (() => {
    if (isOntex) return ontexAuthenticate;
    if (isHartmann) return hartmannAuthenticate;
    if (isDASRI) return noop;
  })();

  useSubscriber(
    PUBSUB_TOPICS.IMPORT_VIRTUAL_CART_RESOLVED,
    (_, { uuid: targetUUID }) => {
      if (targetUUID === uuid) {
        setFinished(true);
        setOpened(false);

        history.push(Paths.Cart());
      }
    },
    [uuid],
  );

  const validateMessageOrigin = messageOrigin => {
    switch (origin) {
      case 'ONTX':
        return [
          'https://ontexref.exedio.com',
          'https://www.id-direct.com',
        ].includes(messageOrigin);

      case 'HRTM':
        return [
          'https://websphere.them-is.fr',
          'https://his.hartmann.fr',
        ].includes(messageOrigin);

      /**
       * Ignore messages from DASRI since no communication with
       * the external platform is needed
       */
      case 'DASRI':
        return false;

      case 'HX':
        return messageOrigin === API_BASE_URL();

      default:
        return false;
    }
  };

  const handleMessage = event => {
    const { data, origin: eventOrigin } = event;

    if (validateMessageOrigin(eventOrigin)) {
      if (typeof data === 'string') {
        const reg = new RegExp(`(0|1)\\|${uuid}`);
        const match = data.match(reg);

        if (match) {
          const success = match[1] === '1';

          if (success) {
            getAndMergeVirtualCart({ uuid, origin });
          }

          setFinished(true);
        }
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && opened) {
      document.documentElement.style.overflow = 'hidden';
      window.addEventListener('message', handleMessage, false);
    }

    return () => {
      if (typeof window !== 'undefined') {
        document.documentElement.style.overflow = '';
        window.removeEventListener('message', handleMessage);
      }
    };
  }, [opened]);

  useEffect(() => {
    if (isExternal && externalAuthFunction && virtualCart && uuid) {
      const { status, response } = virtualCart;

      if (status === Status.LOADED) {
        externalAuthFunction({ uuid });
      }

      if (status === Status.AUTHENTICATED && response?.sAutoURL) {
        const autologinURL = getPlatformAutologinURL(response.sAutoURL);

        if (isHttpUrl(autologinURL)) {
          setSource(autologinURL);
          setOpened(true);
        } else {
          sendNotification(
            'Erreur de chargement de la plateforme externe.',
            NotificationType.WARNING,
          );
        }
      }
    }
  }, [isExternal, virtualCart, uuid]);

  const handleOpenStatic = () => {
    if (!isExternal && src) {
      const { id, customerID, token } = currentUser;

      setSource(
        `${src}?kIDUser=${id}&sCustomerCode=${customerID}&sUserToken=${token}`,
      );

      setOpened(true);
    }
  };

  const handleOnRequestOpen = () => {
    if (skipVirtualCart) {
      return handleOpenStatic();
    }

    const { id, customerID } = currentUser;
    const token = generateVirtualCartID();

    createVirtualCart({ uuid: token, origin });
    setUUID(token);

    if (!isExternal && src) {
      setSource(
        `${src}?kIDUser=${id}&sUID=${token}&sParam=${customerID}&isTTC=${isTTC}`,
      );

      setOpened(true);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  return (
    <>
      {children ? (
        <ClickArea
          className="external-configurator with-children"
          onClick={handleOnRequestOpen}
        >
          {children}
        </ClickArea>
      ) : (
        <Button
          background="white"
          onClick={handleOnRequestOpen}
          style={{ whiteSpace: 'nowrap' }}
          className="external-configurator"
        >
          {content}
        </Button>
      )}

      {source && (
        <IFramePortal
          src={source}
          title={title}
          width={width}
          height={height}
          opened={opened}
          finished={finished}
          forwardOnClick={handleOnClose}
          fullscreen={fullscreen}
          aspectRatio={isDASRI ? '7/18' : null}
        />
      )}
    </>
  );
};

ExternalConfigurator.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isOntex: PropTypes.bool,
  isHartmann: PropTypes.bool,
  isDASRI: PropTypes.bool,
  fullscreen: PropTypes.bool,
  origin: PropTypes.string,

  children: PropTypes.node,
  content: PropTypes.string,

  usersState: PropTypes.objectOf(PropTypes.any).isRequired,
  virtualCartState: PropTypes.shape({
    carts: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  skipVirtualCart: PropTypes.bool,

  createVirtualCart: PropTypes.func.isRequired,
  getAndMergeVirtualCart: PropTypes.func.isRequired,
  ontexAuthenticate: PropTypes.func.isRequired,
  hartmannAuthenticate: PropTypes.func.isRequired,
};

ExternalConfigurator.defaultProps = {
  src: null,
  title: 'Configurateur externe',
  width: 700,
  height: 500,
  isOntex: false,
  isHartmann: false,
  isDASRI: false,
  fullscreen: false,
  origin: 'HX',
  skipVirtualCart: false,

  children: null,
  content: 'Configurateur externe',
};

export default connect(
  state => ({
    usersState: state.users,
    ...state.cart,
    virtualCartState: state.virtualCart,
  }),
  {
    ...CART_ACTIONS,
  },
)(ExternalConfigurator);
