import PropTypes from 'prop-types';
import React, { Children, cloneElement, useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/vars';
import Button from './Button';

const SwitcherPaneWrapper = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SwitcherPane = ({ isActive, children }) => {
  return (
    <SwitcherPaneWrapper isActive={isActive}>{children}</SwitcherPaneWrapper>
  );
};

SwitcherPane.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SwitcherPane.defaultProps = {
  isActive: false,
};

const SwitcherWrapper = styled.div`
  width: 100%;
`;

const SwitcherTab = styled(Button)`
  flex-grow: 1;
`;

const SwitcherPanes = styled.div`
  padding: 20px 12px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Switcher = ({ tabs, children }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const childrenArray = Children.toArray(children);
  const mappedChildren = childrenArray.map((child, i) =>
    cloneElement(child, { isActive: i === currentTab }),
  );

  const tabCount = (tabs || []).length;
  const childCount = childrenArray.length;

  if (tabCount !== childCount) {
    throw new Error(
      `Switcher must have as many tabs as there are children. (${tabCount} tabs, but ${childCount} children)`,
    );
  }

  return (
    <SwitcherWrapper>
      <Button.Group>
        {tabs.map((tab, i) => {
          const current = i === currentTab;

          return (
            <SwitcherTab
              // eslint-disable-next-line react/no-array-index-key
              key={`${i}--${tab}`}
              color={current ? Colors.white : Colors.black}
              background={current ? Colors.primary : Colors.white}
              hoverColor={current ? Colors.white : Colors.black}
              hoverBackground={current ? Colors.primary : 'white'}
              onClick={() => setCurrentTab(i)}
            >
              {tab}
            </SwitcherTab>
          );
        })}
      </Button.Group>

      <SwitcherPanes>{mappedChildren}</SwitcherPanes>
    </SwitcherWrapper>
  );
};

Switcher.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ).isRequired,
  children: PropTypes.node.isRequired,
};

Switcher.Pane = SwitcherPane;

export default Switcher;
