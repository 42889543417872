import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PUBSUB_TOPICS, useSubscriber } from '../../hooks';
import { storagePut } from '../../store/models/localstorage';
import Paths from '../routes/paths';
import ErrorBoundary from '../utils/ErrorBoundary';
import ResourceList from './admin/ResourceList';
import SingleResource from './admin/SingleResource';
import ClientsIndex from './admin/users/ClientsIndex';
import ClientUsers from './admin/users/ClientUsers';

const AdminLayout = ({ isInternal, currentUser, resource, id }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isInternal && resource !== 'users') {
      history.replace(
        Paths.Admin({ resource: 'users', id: currentUser.customerID }),
      );
    }
  }, [isInternal, resource]);

  useEffect(() => {
    if (resource !== 'users') {
      storagePut('vitae/last-client-search', null);
    }
  }, [resource]);

  useSubscriber(PUBSUB_TOPICS.DISABLE_USER_RESOLVED, () => {
    history.replace(
      Paths.Admin({ resource: 'users', id: currentUser.customerID }),
    );
  });

  if (id) {
    if (resource === 'users') {
      return (
        <ErrorBoundary>
          <ClientUsers isInternal={isInternal} />
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <SingleResource type={resource} id={id} />
      </ErrorBoundary>
    );
  }

  if (resource === 'users') {
    return (
      <ErrorBoundary>
        <ClientsIndex />
      </ErrorBoundary>
    );
  }

  if (isInternal) {
    return (
      <ErrorBoundary resetOnChange={resource}>
        <ResourceList type={resource} />
      </ErrorBoundary>
    );
  }

  return null;
};

AdminLayout.propTypes = {
  isInternal: PropTypes.bool,
  currentUser: PropTypes.objectOf(PropTypes.any),
  resource: PropTypes.oneOf(['aisles', 'families', 'products', 'users'])
    .isRequired,
  id: PropTypes.string,
};

AdminLayout.defaultProps = {
  isInternal: false,
  currentUser: {},
  id: null,
};

export default AdminLayout;
