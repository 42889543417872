import { isArray } from 'lodash';
import { publish } from 'pubsub-js';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import { PUBSUB_TOPICS } from '../../hooks';
import DateFormat, { Formats } from '../../utils/date';
import {
  CANCEL_PENDING_ORDER_REJECTED,
  CANCEL_PENDING_ORDER_REQUESTED,
  CANCEL_PENDING_ORDER_RESOLVED,
  GET_PENDING_ORDERS_REJECTED,
  GET_PENDING_ORDERS_REQUESTED,
  GET_PENDING_ORDERS_RESOLVED,
  GET_SINGLE_PENDING_ORDER_REJECTED,
  GET_SINGLE_PENDING_ORDER_REQUESTED,
  GET_SINGLE_PENDING_ORDER_RESOLVED,
} from '../actions/types';
import { Status } from '../models/europrisme';

const initialState = {
  status: Status.IDLE,
  message: null,
  pendingOrders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_ORDERS_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }

    case GET_PENDING_ORDERS_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return {
        ...state,
        status: Status.ERROR,
        message,
      };
    }

    case GET_PENDING_ORDERS_RESOLVED: {
      const { data } = action.payload;
      const { recordOrders } = data;

      let ordersList = [];
      if (recordOrders) {
        if (isArray(recordOrders)) {
          ordersList = recordOrders;
        } else {
          ordersList = [recordOrders];
        }
      }

      const pendingOrders = ordersList.reduce(
        (
          acc,
          {
            sOrderCode,
            sOrderDate,
            sOrderComment,
            sOrderStatus,
            sUnderCancellation,
          },
        ) => {
          const date = DateFormat.parse(sOrderDate, Formats.ORDERS_API);
          const formattedDate = DateFormat.format(date, Formats.DAY_MONTH_YEAR);

          acc[sOrderCode] = {
            code: sOrderCode,
            date,
            formattedDate,
            comment: sOrderComment,
            orderStatus: sOrderStatus,
            cancelled: sUnderCancellation === '1',
            status: Status.IDLE,
          };

          return acc;
        },
        {},
      );

      return {
        ...state,
        status: Status.IDLE,
        pendingOrders,
      };
    }

    case CANCEL_PENDING_ORDER_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }

    case CANCEL_PENDING_ORDER_REJECTED: {
      const { error } = action.payload;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      return { ...state, status: Status.ERROR, message };
    }

    case CANCEL_PENDING_ORDER_RESOLVED: {
      const { params } = action.payload;
      const { sOrderCode } = params;

      if (sOrderCode in state.pendingOrders) {
        publish(PUBSUB_TOPICS.PENDING_ORDER_CANCELLED);
      }

      return { ...state, status: Status.IDLE };
    }

    case GET_SINGLE_PENDING_ORDER_REQUESTED: {
      const { params } = action.payload;
      const { sOrderCode } = params;

      const previousOrderData = state.pendingOrders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.LOADING,
      };

      return {
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          [sOrderCode]: order,
        },
      };
    }

    case GET_SINGLE_PENDING_ORDER_REJECTED: {
      const { error, params } = action.payload;
      const { sOrderCode } = params;

      const message = error.originalData
        ? error.originalData.Message
        : error.message;

      sendNotification(message, NotificationType.ERROR);

      const previousOrderData = state.pendingOrders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.ERROR,
        message,
      };

      return {
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          [sOrderCode]: order,
        },
      };
    }

    case GET_SINGLE_PENDING_ORDER_RESOLVED: {
      const { data, params } = action.payload;
      const { sOrderCode } = params;
      const { recordOrders: records } = data;

      const previousOrderData = state.pendingOrders[sOrderCode];

      if (!previousOrderData) return state;

      const order = {
        ...previousOrderData,
        status: Status.IDLE,
        records,
      };

      return {
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          [sOrderCode]: order,
        },
      };
    }

    default:
      return state;
  }
};
