import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { usePageTitle } from '../../hooks';
import { USERS_ACTIONS } from '../../store/actions/reducer-actions/users';
import { ResetPasswordStatus } from '../../store/reducers/users';
import { Colors, Sizing } from '../../styles/vars';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import FadedImage from '../elements/FadedImage';
import HeroHeader from '../elements/HeroHeader';
import Input from '../elements/Input';
import {
  NotificationType,
  sendNotification,
} from '../elements/NotificationArea';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  width: 100%;
  max-width: ${Sizing.wrapperWidth};

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    gap: 10px;
  }
`;

const LoginForm = styled.form`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};
  grid-column: 1 / span 6;
  padding: 30px 0;

  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 12;
  }
`;

const LoginFormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-column: 2 / span 4;

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    grid-column: 1 / span 6;
    padding: 0 16px;

    h1,
    h1 span.children {
      font-size: 26px;
      line-height: 28px;
    }
  }

  label:first-of-type {
    margin-bottom: 20px;
  }

  label:last-of-type {
    margin-bottom: 40px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: ${Sizing.gutterWidth};

  > :first-child {
    grid-column: 1 / span 2;
  }

  > :last-child {
    grid-column: 3 / span 2;
  }
`;

const RequestPassword = ({ status, data, requestPasswordReset }) => {
  usePageTitle('Demande de mot de passe');

  const history = useHistory();
  const [email, setEmail] = useState('');

  const isLoading = status === ResetPasswordStatus.REQUEST_PENDING;

  useEffect(() => {
    if (status === ResetPasswordStatus.REQUEST_RESOLVED) {
      const { Message } = data;

      sendNotification(Message, NotificationType.SUCCESS);
      history.push(Paths.ResetPassword());
    } else if (status === ResetPasswordStatus.ERROR) {
      sendNotification(
        "Une erreur est survenue lors de l'envoi de l'email",
        NotificationType.ERROR,
      );
    }
  }, [status]);

  const handleSubmit = event => {
    event.preventDefault();

    requestPasswordReset({
      sUserEmail: email,
      sSubject: 'Demande de réinitialisation de mot de passe - Vitae Boutique',
    });

    return false;
  };

  return (
    <>
      <HeroHeader showSearch={false} fullHeight>
        <FadedImage
          src="/public/img/hero_header.png"
          x="25%"
          id="request-password"
        />

        <Contents>
          <LoginForm onSubmit={handleSubmit}>
            <LoginFormInner>
              <PageTitle
                margin="0 0 24px 0"
                style={{ gridColumn: '1 / span 4', color: Colors.white }}
              >
                Demande de mot de passe
              </PageTitle>

              <Input
                type="email"
                label="Adresse mail"
                color={Colors.white}
                background={Colors.primary}
                onChange={setEmail}
                required
                square
                disabled={isLoading}
              />

              <Row>
                <Button
                  type="button"
                  fromComponent={Link}
                  to={Paths.Login()}
                  color={Colors.black}
                  background={Colors.white}
                  hoverBackground={Colors.lightGrey}
                  disabled={isLoading}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                  }}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  color={Colors.white}
                  background={Colors.primary}
                  disabled={isLoading}
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    height: '45px',
                  }}
                >
                  Valider
                </Button>
              </Row>
            </LoginFormInner>
          </LoginForm>
        </Contents>
      </HeroHeader>
    </>
  );
};

RequestPassword.propTypes = {
  status: PropTypes.oneOf(Object.values(ResetPasswordStatus)).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,

  requestPasswordReset: PropTypes.func.isRequired,
};

export const ConnectedRequestPassword = connect(
  state => ({ ...state.users.passwordReset }),
  { ...USERS_ACTIONS },
)(RequestPassword);

export default {
  path: Paths.RequestPassword(),
  exact: true,
  name: 'RequestPassword',
  component: ConnectedRequestPassword,
};
