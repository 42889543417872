import { Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import AlertOctagonIcon from '../../../assets/img/AlertOctagonIcon';
import AssignIcon from '../../../assets/img/AssignIcon';
import CheckIcon from '../../../assets/img/CheckIcon';
import TrashIcon from '../../../assets/img/TrashIcon';
import { CART_ACTIONS, LOCATION_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import {
  findLocationTreeNodePath,
  parseLocationTree,
} from '../../utils/location';
import { connect } from '../../utils/redux';
import Button from './Button';
import Flex from './containers/Flex';
import PageTitle from './PageTitle';
import Popup from './Popup';
import Tree from './tree/Tree';

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: baseline;
  margin-bottom: 17px;

  font-size: 12px;
  font-weight: normal;

  .column {
    line-height: 22px;
  }

  span {
    &.label {
      flex-shrink: 0;
    }

    &.value {
      &.empty {
        color: ${Colors.lightGrey};
      }

      .segment {
        white-space: nowrap;

        .separator {
          display: inline-block;
          padding: 0 0.25ch;

          opacity: 0.5;
        }
      }
    }
  }

  button {
    position: absolute;
    left: calc(100% + 12px);
    top: 0;
    display: inline-flex;
    min-width: unset;
  }
`;

const PopupPanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  flex-grow: 1;
  gap: 40px;

  overflow: hidden;
`;

const PopupScroller = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;

  overflow-x: hidden;
  overflow-y: auto;
`;

const PopupPanel = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .ant-spin-nested-loading {
    display: flex;
    flex-grow: 1;

    .ant-spin-container {
      display: flex;
      width: 100%;
      min-height: 100%;
    }
  }
`;

const ElementsAtLocationWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 20px 0;

  .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    margin: 0;
  }
`;

const ElementsAtLocationTable = styled.table`
  width: 100%;

  background-color: ${Colors.pureWhite};
  border: none;
  border-collapse: collapse;
  border-radius: 5px;

  overflow: hidden;

  tr {
    th {
      background-color: ${Colors.lightBlue};
    }

    th,
    td {
      padding: 8px 16px;
      border-bottom: 1px solid ${Colors.lightBlue};

      text-align: left;
      text-align: start;

      &[disabled] {
        opacity: 0.5;
      }
    }

    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }
`;

const CartListItemLocationSelector = ({
  itemID,
  tree,
  elementsAtLocation,
  style,
  assignCartItemToLocation,
  getElementsAssignedAtLocation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const parsedTree = useMemo(() => parseLocationTree(tree), [tree]);

  const elementsAtActiveItemLocation = useMemo(() => {
    if (!activeItem || !elementsAtLocation?.locations) {
      return null;
    }

    const { iEntityType, kIDEntity } = activeItem.userData;
    const key = [iEntityType, kIDEntity].join('-');

    return elementsAtLocation.locations[key];
  }, [activeItem, elementsAtLocation]);

  const handlePopupClosed = event => {
    if (typeof event.detail !== 'object' || !('popupSuccess' in event.detail)) {
      setIsOpen(false);
      return false;
    }

    const { popupSuccess } = event.detail;

    if (!popupSuccess) {
      setActiveItem(null);
      setSelectedItem(null);

      if (itemID) {
        assignCartItemToLocation({
          itemID,
          kIDEntity: null,
          iEntityType: null,
        });
      }
    } else {
      const { userData } = activeItem;

      if (itemID && userData) {
        const { kIDEntity, iEntityType } = userData;

        assignCartItemToLocation({
          itemID,
          kIDEntity,
          iEntityType,
        });

        setSelectedItem(activeItem);
      }
    }

    setIsOpen(false);
  };

  const onSelectionChange = useCallback(newActiveItem => {
    setActiveItem(newActiveItem);
  }, []);

  useEffect(() => {
    if (activeItem) {
      const { kIDEntity, iEntityType } = activeItem.userData;

      getElementsAssignedAtLocation({ kIDEntity, iEntityType });
    }
  }, [activeItem]);

  const selectedItemPath = useMemo(() => {
    if (!parsedTree || !selectedItem) {
      return null;
    }

    return findLocationTreeNodePath(parsedTree, selectedItem);
  }, [parsedTree, selectedItem]);

  const selectedItemLabel = useMemo(() => {
    if (!parsedTree || !selectedItemPath || selectedItemPath.length === 0) {
      return null;
    }

    return selectedItemPath
      .filter(node => node.key !== '__ROOT__' && node.depth > 1)
      .map((node, index) => (
        <span key={`label__${node.key}`} className="segment">
          {index > 0 && <span className="separator">&gt;</span>}
          <span className="segment-label">{node.label}</span>
        </span>
      ));
  }, [selectedItemPath]);

  if (!parsedTree) {
    return null;
  }

  const renderElementsAtLocation = () => {
    switch (elementsAtLocation?.status) {
      case Status.IDLE:
      case Status.LOADING: {
        if (
          !elementsAtActiveItemLocation ||
          elementsAtActiveItemLocation.length === 0
        ) {
          return (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Aucun élément"
            />
          );
        }

        return (
          <ElementsAtLocationTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Libellé</th>
                <th>Code parc</th>
                <th>Numéro de série</th>
                <th>Mode G5</th>
              </tr>
            </thead>

            <tbody>
              {elementsAtActiveItemLocation.map(item => (
                <tr key={item.kIDProductItem}>
                  {[
                    'kIDProductItem',
                    'sProductTypeTitle',
                    'sLibelle',
                    'sParcCode',
                    'sSerialNumber',
                    'sG5Mode',
                  ].map(prop => (
                    <td key={prop} disabled={!item[prop]}>
                      {item[prop] || '-'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </ElementsAtLocationTable>
        );
      }

      case Status.ERROR: {
        return (
          <Empty
            image={
              <AlertOctagonIcon size={100} style={{ color: Colors.lightRed }} />
            }
            description="Erreur : impossible de charger les éléments"
            style={{ color: Colors.darkRed }}
          />
        );
      }

      default: {
        return (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Aucun élément"
          />
        );
      }
    }
  };

  return (
    <>
      <ButtonContainer style={style}>
        <span className="label">Pré-affecté à : </span>

        <div className="column">
          {selectedItem ? (
            <span className="value">{selectedItemLabel}</span>
          ) : (
            <span className="value empty">Aucun emplacement</span>
          )}
        </div>

        <Button
          type="button"
          data-tip="Pré-affecter un emplacement"
          onClick={() => setIsOpen(true)}
          background={selectedItem ? Colors.lightGreen : Colors.white}
          color={selectedItem ? Colors.darkGreen : Colors.black}
          hoverBackground={Colors.primary}
          iconOnly
          square
        >
          {selectedItem ? <CheckIcon /> : <AssignIcon />}
        </Button>

        <ReactTooltip place="top" type="dark" effect="solid" />
      </ButtonContainer>

      <Popup
        backgroundColor={Colors.white}
        closeButtonBackgroundColor={Colors.pureWhite}
        onClose={handlePopupClosed}
        open={isOpen}
        fullscreen
        cancelButton={({ onClick }) => (
          <Button
            hoverBackground={Colors.red}
            disabled={!activeItem && !selectedItem}
            onClick={onClick}
          >
            <TrashIcon />
            <span>Annuler</span>
          </Button>
        )}
        confirmButton={({ onClick }) => (
          <Button
            color={Colors.white}
            background={Colors.accent}
            disabled={!activeItem && !selectedItem}
            onClick={onClick}
          >
            <AssignIcon />
            <span>Pré-affecter</span>
          </Button>
        )}
      >
        <Flex direction="column" style={{ width: '100%', height: '100%' }}>
          <PageTitle
            fromComponent="h2"
            margin="0 0 1em 0"
            style={{ flexShrink: 0 }}
          >
            Pré-affecter le dispositif à un emplacement
          </PageTitle>

          <PopupPanelsWrapper>
            <PopupScroller>
              <PopupPanel>
                <Tree
                  items={parsedTree}
                  selectedItem={activeItem?.key || selectedItem?.key}
                  onChange={onSelectionChange}
                />
              </PopupPanel>
            </PopupScroller>

            <PopupScroller>
              <PopupPanel style={{ color: 'black' }}>
                <h3>
                  {activeItem
                    ? `Dispositifs déjà affectés à l'emplacement "${activeItem.label}"`
                    : 'Aucun emplacement sélectionné'}
                </h3>

                <Spin spinning={elementsAtLocation?.status === Status.LOADING}>
                  <ElementsAtLocationWrapper>
                    {renderElementsAtLocation()}
                  </ElementsAtLocationWrapper>
                </Spin>
              </PopupPanel>
            </PopupScroller>
          </PopupPanelsWrapper>
        </Flex>
      </Popup>
    </>
  );
};

CartListItemLocationSelector.propTypes = {
  itemID: PropTypes.string.isRequired,
  tree: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  elementsAtLocation: PropTypes.shape({
    status: PropTypes.string.isRequired,
    locations: PropTypes.objectOf(PropTypes.any),
  }),

  style: PropTypes.objectOf(PropTypes.any),

  assignCartItemToLocation: PropTypes.func.isRequired,
  getElementsAssignedAtLocation: PropTypes.func.isRequired,
};

CartListItemLocationSelector.defaultProps = {
  tree: [],
  elementsAtLocation: {},
  style: {},
};

export default connect(state => ({ ...state.locations }), {
  assignCartItemToLocation: CART_ACTIONS.assignCartItemToLocation,
  getElementsAssignedAtLocation: LOCATION_ACTIONS.getElementsAssignedAtLocation,
})(CartListItemLocationSelector);
