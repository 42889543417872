import {
  GET_FAMILIES_REJECTED,
  GET_FAMILIES_REQUESTED,
  GET_FAMILIES_RESOLVED,
  GET_SINGLE_FAMILY_REJECTED,
  GET_SINGLE_FAMILY_REQUESTED,
  GET_SINGLE_FAMILY_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = {
  ...defaultState,
  families: {},
  error: null,
  status: Status.IDLE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FAMILIES_REQUESTED:
    case GET_SINGLE_FAMILY_REQUESTED:
      return { ...state, status: Status.LOADING, error: null };

    case GET_FAMILIES_RESOLVED:
    case GET_SINGLE_FAMILY_RESOLVED: {
      const { data } = action.payload;
      const { recordFamilles } = data;

      const newFamilies = {
        ...state.families,
        ...recordFamilles.reduce(
          (acc, family) => {
            const { sCode } = family;

            family.isLocalizable =
              typeof family.isLocalizable === 'boolean'
                ? family.isLocalizable
                : Number.parseInt(family?.isLocalizable, 10) === 1;

            acc[sCode] = family;

            return acc;
          },
          { ...state.families },
        ),
      };

      return {
        ...state,
        status: Status.IDLE,
        families: newFamilies,
        error: null,
      };
    }

    case GET_FAMILIES_REJECTED:
    case GET_SINGLE_FAMILY_REJECTED: {
      const { error } = action.payload;

      return { ...state, status: Status.ERROR, error };
    }

    default:
      return state;
  }
};
