import { createEndpoint, Type } from '../../models/europrisme';
import {
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED,
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED,
  GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED,
  GET_LOCATIONS_REJECTED,
  GET_LOCATIONS_REQUESTED,
  GET_LOCATIONS_RESOLVED,
} from '../types';

// ========== GET LOCATIONS ==========

export const getLocationsRequested = () => ({
  type: GET_LOCATIONS_REQUESTED,
});

export const getLocationsRejected = error => ({
  type: GET_LOCATIONS_REJECTED,
  payload: { error },
});

export const getLocationsResolved = data => ({
  type: GET_LOCATIONS_RESOLVED,
  payload: { data },
});

export const getLocationsEndpoint = createEndpoint(
  Type.GET,
  'getMapTreeList',
  { sAccount: '' },
  {
    request: getLocationsRequested,
    resolve: getLocationsResolved,
    reject: getLocationsRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getLocations = args => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users || {};

  return dispatch(
    getLocationsEndpoint({
      ...args,
      sAccount: currentUser?.customerID,
    }),
  );
};

// ========== GET ELEMENTS ASSIGNED AT LOCATION ==========

export const getElementsAssignedAtLocationRequested = () => ({
  type: GET_ELEMENTS_ASSIGNED_AT_LOCATION_REQUESTED,
});

export const getElementsAssignedAtLocationRejected = error => ({
  type: GET_ELEMENTS_ASSIGNED_AT_LOCATION_REJECTED,
  payload: { error },
});

export const getElementsAssignedAtLocationResolved = (data, fullParams) => ({
  type: GET_ELEMENTS_ASSIGNED_AT_LOCATION_RESOLVED,
  payload: { data, params: fullParams },
});

export const getElementsAssignedAtLocationEndpoint = createEndpoint(
  Type.GET,
  'getLayoutContent',
  { sAccount: '', kIDLayout: '', kIDLayoutType: '' },
  {
    request: getElementsAssignedAtLocationRequested,
    resolve: getElementsAssignedAtLocationResolved,
    reject: getElementsAssignedAtLocationRejected,
  },
  data => {
    try {
      const { xmlst } = data;
      const { Success } = xmlst;

      return Success === 'TRUE';
    } catch (error) {
      return false;
    }
  },
);

export const getElementsAssignedAtLocation = ({
  kIDEntity,
  iEntityType,
}) => async (dispatch, getState) => {
  const { users } = getState();
  const { currentUser } = users || {};

  return dispatch(
    getElementsAssignedAtLocationEndpoint({
      sAccount: currentUser?.customerID,
      kIDLayout: kIDEntity,
      kIDLayoutType: iEntityType,
    }),
  );
};

export const LOCATION_ACTIONS = { getLocations, getElementsAssignedAtLocation };
