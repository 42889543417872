import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { usePageTitle } from '../../hooks';
import { Colors } from '../../styles/vars';
import { isUrlExternal } from '../../utils/misc';
import PageTitle from '../elements/PageTitle';
import ErrorBoundary from '../utils/ErrorBoundary';
import ScrollToTop from '../utils/ScrollToTop';

const StaticPageWrapper = styled.div`
  grid-column: 2 / span 10;
  padding: 80px 0;

  .main-content {
    > *:first-child {
      margin-top: 0 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: flex;
    }

    h1,
    h2,
    h3 {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    h4,
    h5,
    h6 {
      margin-bottom: 6px;

      & + p {
        margin-top: 0;
      }
    }

    a {
      color: ${Colors.accent};
    }

    pre,
    blockquote,
    table {
      margin: 25px 0;
    }

    code {
      position: relative;
      display: inline-block;
      margin: 0 0.5ch;

      font-size: 14px;
      font-family: 'Courier New', Courier, monospace;

      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);

        background-color: ${Colors.pureWhite};
        border-radius: 5px;

        z-index: -1;
      }
    }

    pre {
      code {
        width: 100%;
        padding: 8px 12px;
        margin: 0;

        &::before {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    table {
      width: 100%;

      background-color: ${Colors.pureWhite};
      border: none;
      border-collapse: collapse;
      border-radius: 5px;

      overflow: hidden;

      tr {
        th {
          background-color: ${Colors.lightBlue};
        }

        th,
        td {
          padding: 8px 16px;
          border-bottom: 1px solid ${Colors.lightBlue};
        }

        &:last-child {
          th,
          td {
            border-bottom: none;
          }
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 40px auto;

      border-radius: 3px;
    }
  }
`;

const MainContent = styled.div``;

const StaticPage = ({ attributes, body }) => {
  const { title } = attributes;

  usePageTitle(title);

  const getTitleFontSize = name =>
    ({ h1: '30px', h2: '24px', h3: '20px' }[name]);

  const transformNode = (node, index) => {
    const { type, name, attribs, children } = node;

    if (type === 'tag') {
      const textContent = children.map(({ data }) => data).join(' ');

      switch (name) {
        case 'h1':
        case 'h2':
        case 'h3': {
          return (
            <PageTitle
              key={index}
              fromComponent={name}
              fontSize={getTitleFontSize(name)}
            >
              {textContent}
            </PageTitle>
          );
        }

        case 'a': {
          const { class: className, href } = attribs;

          if (className === 'anchor') {
            return null;
          }

          if (isUrlExternal(attribs.href)) {
            return (
              <a
                className="external"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                {...attribs}
              >
                {textContent}
              </a>
            );
          }

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Link key={index} to={{ pathname: href }} {...attribs}>
              {textContent}
            </Link>
          );
        }

        default:
          return undefined;
      }
    }
  };

  return (
    <>
      <ScrollToTop />
      <StaticPageWrapper>
        <MainContent className="main-content">
          <ErrorBoundary>
            {ReactHtmlParser(body, { transform: transformNode })}
          </ErrorBoundary>
        </MainContent>
      </StaticPageWrapper>
    </>
  );
};

StaticPage.propTypes = {
  attributes: PropTypes.shape({ title: PropTypes.string.isRequired })
    .isRequired,

  body: PropTypes.string.isRequired,
};

export default StaticPage;
