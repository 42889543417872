import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const getAngle = direction => {
  switch (direction) {
    case 'up':
      return -135;
    case 'down':
      return 45;
    case 'right':
      return -45;
    case 'left':
      return 135;
    default:
      return 0;
  }
};

const ChevronElement = styled.i`
  display: block;
  position: relative;
  width: ${props => props.size};
  height: ${props => props.size};
  margin-right: calc(${props => props.size} / 4);

  transform-origin: center;
  transform: ${props => `rotate(${getAngle(props.direction)}deg)`};

  transition: transform 0.4s ease;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15%;
    left: -15%;
    width: 100%;
    height: 100%;

    border-bottom: ${props => props.lineWidth}px solid currentColor;
    border-right: ${props => props.lineWidth}px solid currentColor;
  }
`;

const Chevron = ({ direction = 'down', lineWidth, size, style }) => (
  <ChevronElement
    direction={direction}
    lineWidth={lineWidth}
    size={size}
    style={style}
  />
);

Chevron.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']).isRequired,
  lineWidth: PropTypes.number,
  size: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

Chevron.defaultProps = {
  lineWidth: 1,
  size: '0.5em',
  style: null,
};

export default Chevron;
