import { publish } from 'pubsub-js';

import {
  NotificationType,
  sendNotification,
} from '../../components/elements/NotificationArea';
import { PUBSUB_TOPICS } from '../../hooks';
import {
  SEND_EMAIL_REJECTED,
  SEND_EMAIL_REQUESTED,
  SEND_EMAIL_RESOLVED,
} from '../actions/types';
import { defaultState, Status } from '../models/europrisme';

const initialState = { ...defaultState };

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_REQUESTED: {
      return { ...state, status: Status.LOADING };
    }
    case SEND_EMAIL_RESOLVED: {
      sendNotification('Message envoyé avec succès', NotificationType.SUCCESS);

      publish(PUBSUB_TOPICS.CONTACT_MESSAGE_SENT_SUCCESS);

      return { ...state, status: Status.IDLE };
    }
    case SEND_EMAIL_REJECTED: {
      sendNotification(
        "Erreur lors de l'envoi du message, veuillez essayer à nouveau",
        NotificationType.ERROR,
      );

      return { ...state, status: Status.ERROR };
    }
    default:
      return state;
  }
};
