import PropTypes from 'prop-types';
import React from 'react';

const MattressIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M251.155 123.462c.003-.012-.001-.025.002-.038.026-.155.032-.309.02-.462-.002-.037-.003-.073-.009-.11a1.868 1.868 0 00-.12-.471c-.008-.02-.02-.04-.031-.062a1.906 1.906 0 00-.223-.373c-.022-.029-.042-.058-.065-.084a1.864 1.864 0 00-.37-.335c-.008-.005-.011-.011-.018-.015-.018-.011-.037-.015-.055-.026-.073-.045-.146-.093-.226-.129L88.592 49.8a1.907 1.907 0 00-1.367-.066L6.118 76.525a1.908 1.908 0 00-.426.21c-.014.008-.029.01-.041.02-.02.012-.03.03-.049.044a1.828 1.828 0 00-.328.3c-.014.017-.03.03-.043.046-.101.127-.181.266-.247.414-.016.035-.028.071-.041.107-.05.134-.087.272-.106.415-.003.023-.01.044-.012.067-.017.161-.007.325.02.49.002.017-.001.034.002.051L8.56 98.06c.094.486.373.915.778 1.2l152.586 106.743c.259.187.569.312.907.349h.004c.046.006.091.008.137.01h.005l.063.001c.417 0 .807-.136 1.122-.368l81.93-59.45c.388-.282.656-.7.748-1.17l4.306-21.87.001-.007zm-89.802 77.497L12.153 96.584 9.42 82.341l153.161 98.861zM11.087 78.889l76.635-25.314 157.652 69.866-80.848 54.49zm232.166 65.015l-78.066 56.648 1.2-19.29 80.147-54.017z"
    />
    <path
      fill={color}
      d="M63.135 65.994a10.392 10.392 0 00-1.502-.09c-.47.004-.996.057-1.54.136a2.637 2.637 0 00-.587-.208c-1.05-.227-2.017-.173-3.051.157a6.41 6.41 0 00-.77-.097 6.758 6.758 0 00-1.169.029c-.296.033-.481.082-.481.082s.064.181.204.443c.128.244.327.558.577.89a2.121 2.121 0 00-.18.649c-.36.217-.7.437-.994.66-.483.369-.87.719-1.132.99-.265.266-.403.451-.403.451s.223.061.595.11c.374.053.894.092 1.501.087a11.952 11.952 0 001.681-.156c.178.084.365.154.57.198.436.095.852.14 1.27.14.574 0 1.144-.102 1.734-.285.312.063.618.112.904.128.463.025.872.006 1.17-.032.295-.033.48-.083.48-.083s-.063-.182-.204-.444a6.749 6.749 0 00-.638-.98c0-.003-.003-.004-.005-.006a2.14 2.14 0 00.158-.62 10.4 10.4 0 002.003-1.583c.264-.268.403-.454.403-.454s-.222-.063-.594-.112z"
    />
    <path
      fill={color}
      d="M230.86 125.698a16.944 16.944 0 00-1.458-1.306 18.89 18.89 0 00-1.32-.955 2.13 2.13 0 00.03-.735c-.064-.476-.282-.868-.564-1.201.074-.084.16-.17.229-.252.389-.473.69-.905.886-1.229.198-.321.293-.535.293-.535s-.233-.007-.61.032c-.377.036-.896.119-1.49.266-.413.103-.862.256-1.32.431a6.008 6.008 0 00-.298-.099c-.615-.19-1.338-.395-2.116-.395a4.25 4.25 0 00-1.903.45 20.786 20.786 0 00-2.125-.555 17.205 17.205 0 00-1.937-.272c-.48-.039-.77-.03-.77-.03s.18.227.52.57c.337.344.837.803 1.458 1.303.338.27.72.55 1.126.828a2.16 2.16 0 00-.04.778c.06.454.26.832.524 1.157-.112.124-.236.248-.336.37-.39.472-.69.905-.886 1.23-.197.323-.292.536-.292.536s.234.008.61-.03c.378-.036.898-.119 1.493-.264.464-.115.972-.291 1.49-.498.099.036.195.069.274.094.617.189 1.34.39 2.112.39.655 0 1.273-.144 1.87-.431.837.27 1.644.487 2.358.625.783.152 1.455.235 1.938.27.48.038.77.028.77.028s-.177-.23-.517-.571z"
    />
    <path
      fill={color}
      d="M202.865 143.876c-.337-.371-.834-.866-1.458-1.41-.4-.351-.873-.716-1.37-1.08.135-.4.144-.761.114-1.016a2.538 2.538 0 00-.502-1.214c.184-.184.374-.37.533-.547.463-.52.824-.99 1.063-1.344.24-.349.36-.578.36-.578s-.259.008-.678.073a13.11 13.11 0 00-1.671.377c-.504.145-1.059.356-1.626.594-.077-.03-.149-.062-.229-.091-.644-.223-1.417-.45-2.259-.45-.701 0-1.369.16-2.027.481a22.045 22.045 0 00-2.169-.698 18.495 18.495 0 00-1.988-.402c-.494-.07-.792-.08-.792-.08s.178.24.515.606c.337.37.836.864 1.46 1.408.345.302.74.614 1.159.928-.154.419-.164.8-.132 1.067.048.412.214.804.47 1.165-.241.234-.473.468-.676.693-.463.518-.824.989-1.064 1.34-.241.35-.36.578-.36.578s.258-.009.677-.075c.42-.062 1.001-.185 1.67-.378.562-.163 1.184-.4 1.823-.677.07.027.135.058.208.083.645.223 1.418.453 2.264.453.692 0 1.349-.155 1.997-.466.858.33 1.686.603 2.423.79.804.205 1.493.334 1.99.401.494.07.792.079.792.079s-.179-.243-.517-.61z"
    />
    <path
      fill={color}
      d="M190.118 124.838c.102.038.204.078.303.108.544.17 1.269.366 2.043.366.658 0 1.282-.14 1.895-.417a18.7 18.7 0 002.185.611 15.66 15.66 0 001.844.273c.459.04.736.03.736.03s-.161-.226-.471-.566a15.66 15.66 0 00-2.482-2.189c.09-.336.087-.628.065-.809a2.342 2.342 0 00-.388-1.02c.134-.132.278-.265.397-.396.435-.474.774-.909.999-1.237.226-.323.337-.539.337-.539s-.242-.01-.636.026c-.395.032-.941.111-1.568.253-.457.103-.96.263-1.475.448a4.694 4.694 0 00-.334-.12c-.54-.169-1.26-.363-2.033-.363a4.54 4.54 0 00-1.925.431 18.615 18.615 0 00-1.964-.54 15.924 15.924 0 00-1.844-.276c-.458-.04-.735-.031-.735-.031s.163.224.473.564c.309.342.768.797 1.341 1.294.29.249.61.505.955.762a2.245 2.245 0 00-.083.854c.041.36.17.683.352.974-.18.174-.364.348-.52.517-.434.476-.773.911-.997 1.24-.226.323-.337.54-.337.54s.243.01.637-.025c.396-.03.942-.109 1.57-.25.513-.114 1.08-.296 1.66-.513z"
    />
    <path
      fill={color}
      d="M186.989 108.907c.132.05.263.093.392.128.493.138 1.15.296 1.854.296.628 0 1.222-.127 1.803-.374.7.207 1.376.373 1.98.468.693.11 1.291.159 1.721.168.428.015.688-.01.688-.01s-.15-.214-.436-.532a13.427 13.427 0 00-1.24-1.204 14.482 14.482 0 00-.949-.742c.05-.26.047-.488.03-.635a2.195 2.195 0 00-.296-.862c.093-.09.199-.184.283-.274.41-.44.73-.846.94-1.153.214-.304.318-.509.318-.509s-.228-.024-.6-.014c-.372.007-.888.05-1.48.151-.416.072-.873.192-1.34.334a4.82 4.82 0 00-.421-.138c-.492-.139-1.146-.297-1.853-.297-.641 0-1.247.133-1.836.39a15.97 15.97 0 00-1.782-.413c-.693-.11-1.29-.16-1.72-.17a6.286 6.286 0 00-.687.008s.15.213.437.53a13.609 13.609 0 002.027 1.823 2.13 2.13 0 00-.04.688c.033.298.134.565.27.812-.136.13-.279.259-.397.386a10 10 0 00-.939 1.154c-.213.305-.317.51-.317.51s.229.026.6.015c.374-.006.89-.048 1.48-.15.468-.078.982-.216 1.51-.384z"
    />
    <path
      fill={color}
      d="M85.98 66.18c-.44.005-.93.057-1.436.131a2.668 2.668 0 00-.603-.21c-1.082-.234-2.043-.178-3.052.155a7.075 7.075 0 00-.883-.111 7.227 7.227 0 00-1.217.034 3.853 3.853 0 00-.497.086s.076.182.235.447c.158.268.4.617.715.986.006.007.015.015.02.022-.066.194-.101.399-.106.61a9.805 9.805 0 00-1.887 1.577c-.248.268-.377.454-.377.454s.218.06.578.11c.362.052.866.09 1.452.085a10.86 10.86 0 001.595-.155c.175.079.356.148.554.19.45.099.874.147 1.297.147a5.59 5.59 0 001.71-.289c.355.07.702.125 1.024.141.484.024.91.003 1.218-.036.308-.035.499-.087.499-.087s-.075-.184-.235-.45a7.184 7.184 0 00-.713-.987c-.03-.034-.068-.069-.1-.102.06-.194.09-.397.091-.608a9.85 9.85 0 001.77-1.498c.248-.266.378-.45.378-.45s-.218-.06-.578-.11a10.002 10.002 0 00-1.452-.082z"
    />
    <path
      fill={color}
      d="M90.231 56.721a8.915 8.915 0 00-1.373-.13 9.78 9.78 0 00-1.325.078 2.611 2.611 0 00-.635-.22c-1.065-.205-1.996-.144-2.938.172a6.388 6.388 0 00-1.937.029c-.294.048-.476.106-.476.106s.074.177.226.432c.144.247.368.566.65.9a2.03 2.03 0 00-.068.446A8.89 8.89 0 0080.638 60a4.399 4.399 0 00-.355.436s.206.068.547.128c.343.064.82.12 1.374.134.444.01.94-.028 1.452-.095.189.089.388.162.605.205.414.08.802.12 1.189.12a5.53 5.53 0 001.693-.277c.318.049.63.082.918.084a6.65 6.65 0 001.165-.09c.294-.048.476-.108.476-.108s-.073-.178-.225-.434a6.754 6.754 0 00-.683-.947l-.043-.044c.036-.142.053-.291.058-.443.216-.146.425-.295.61-.445.43-.35.773-.685 1.005-.946.233-.255.355-.435.355-.435s-.207-.064-.548-.123z"
    />
    <path
      fill={color}
      d="M58.66 76.578c-.396-.039-.95-.058-1.595-.03-.511.021-1.086.097-1.679.2a2.759 2.759 0 00-.55-.202c-1.09-.26-2.092-.209-3.189.14a7.207 7.207 0 00-.865-.157 7.229 7.229 0 00-1.222-.035c-.309.017-.503.057-.503.057s.066.186.212.46c.141.271.36.625.648 1.003-.138.264-.22.549-.245.845-.41.243-.796.491-1.127.741-.515.39-.93.758-1.21 1.041-.283.28-.432.473-.432.473s.237.054.633.09c.397.04.95.061 1.596.034.56-.024 1.189-.108 1.841-.229.164.076.334.143.52.188.471.113.92.167 1.375.167.576 0 1.16-.111 1.766-.3.35.09.693.164 1.013.198.484.053.91.055 1.222.034.31-.019.504-.06.504-.06s-.065-.187-.21-.46a7.15 7.15 0 00-.663-1.03l-.06-.069a2.2 2.2 0 00.221-.84 11.749 11.749 0 002.198-1.7c.283-.278.433-.47.433-.47s-.236-.052-.631-.089z"
    />
    <path
      fill={color}
      d="M156.47 124.916a14.99 14.99 0 001.828-.53c.105.042.21.087.322.121.74.237 1.368.347 1.98.347.655 0 1.286-.135 1.916-.401.709.252 1.396.46 2.01.594.705.153 1.315.24 1.755.277.436.04.702.032.702.032s-.143-.223-.425-.56c-.28-.341-.696-.795-1.222-1.288-.285-.267-.609-.54-.955-.815.123-.38.13-.716.108-.919a2.347 2.347 0 00-.243-.823c.204-.18.41-.36.588-.535a12.6 12.6 0 001.106-1.246 5.65 5.65 0 00.38-.544s-.252-.013-.663.02c-.415.028-.987.1-1.649.239a14.45 14.45 0 00-1.631.464c-.118-.048-.235-.097-.36-.136-1.56-.488-2.726-.456-3.92.072a16.805 16.805 0 00-1.795-.522 14.053 14.053 0 00-1.753-.278c-.437-.041-.702-.035-.702-.035s.144.223.426.56a14.222 14.222 0 002.013 1.967c-.143.403-.15.764-.129.976.026.266.104.526.222.773a12.757 12.757 0 00-1.839 1.907c-.254.327-.381.543-.381.543s.252.013.662-.02c.416-.027.988-.102 1.65-.24z"
    />
    <path
      fill={color}
      d="M153.764 108.517c-.236.306-.356.513-.356.513s.237.028.624.02c.389-.003.928-.042 1.549-.138a12.49 12.49 0 001.652-.397c.137.054.275.105.42.146.67.187 1.236.274 1.78.274.634 0 1.239-.125 1.834-.367.642.199 1.266.36 1.825.455.659.11 1.23.16 1.64.172.41.015.659-.009.659-.009s-.134-.212-.395-.527a12.323 12.323 0 00-1.918-1.862c.061-.236.085-.481.06-.734a2.252 2.252 0 00-.185-.678c.16-.14.327-.28.467-.418.448-.44.801-.848 1.036-1.157.237-.306.357-.511.357-.511s-.237-.027-.623-.02c-.389.005-.927.045-1.548.142-.457.073-.962.198-1.48.347a4.131 4.131 0 00-.448-.154c-1.447-.404-2.529-.34-3.614.108a14.235 14.235 0 00-1.665-.407c-.66-.11-1.23-.162-1.64-.174-.41-.015-.658.007-.658.007s.134.21.396.525c.259.319.647.74 1.136 1.195.193.18.417.362.642.544a2.09 2.09 0 00-.08.79c.02.216.08.424.163.626a10.9 10.9 0 00-.595.53c-.447.44-.8.849-1.035 1.159z"
    />
    <path
      fill={color}
      d="M153.085 94.376c-.221.29-.335.488-.335.488s.224.04.589.053c.367.017.874.007 1.46-.056.463-.05.978-.157 1.507-.292.16.065.32.122.483.164.61.153 1.124.224 1.625.224.61 0 1.19-.115 1.763-.336.583.155 1.15.278 1.66.34.619.075 1.155.095 1.54.085.385-.006.62-.042.62-.042s-.125-.202-.368-.5a11.14 11.14 0 00-1.061-1.121c-.196-.181-.419-.365-.65-.547.036-.19.05-.385.03-.587a2.122 2.122 0 00-.136-.548c.122-.106.255-.213.364-.32a9.8 9.8 0 00.973-1.088c.222-.289.335-.486.335-.486s-.222-.039-.587-.05a10.009 10.009 0 00-1.459.057c-.42.046-.881.139-1.355.255-.169-.07-.34-.13-.51-.173-1.358-.342-2.372-.282-3.405.128a12.382 12.382 0 00-1.502-.3 10.997 10.997 0 00-1.54-.087c-.384.006-.618.04-.618.04s.124.2.369.498c.242.3.605.695 1.062 1.12.155.143.337.288.514.432a2.036 2.036 0 00-.04.643c.017.176.06.341.115.501-.163.139-.33.278-.471.415-.42.412-.751.797-.972 1.09z"
    />
    <path
      fill={color}
      d="M155.43 143.163c-.271.352-.409.583-.409.583s.27-.005.71-.066a14.545 14.545 0 001.762-.362c.614-.163 1.3-.405 2.005-.692.082.034.161.072.248.103.821.288 1.515.422 2.182.422.694 0 1.361-.151 2.025-.45.785.318 1.545.585 2.224.77.758.207 1.411.337 1.883.405.469.073.754.083.754.083s-.157-.238-.461-.603a16.61 16.61 0 00-2.484-2.399c.195-.473.198-.896.175-1.137a2.53 2.53 0 00-.34-1.01 14.71 14.71 0 001.932-2.04c.273-.352.411-.582.411-.582s-.268.006-.708.068c-.442.06-1.054.176-1.762.364-.553.147-1.167.364-1.798.611-.086-.036-.17-.076-.26-.107-1.692-.596-2.948-.573-4.241.035a20.09 20.09 0 00-1.978-.677 17.098 17.098 0 00-1.882-.407c-.468-.072-.754-.084-.754-.084s.157.238.463.601c.302.367.754.857 1.324 1.399.292.276.617.56.969.85-.218.494-.224.94-.2 1.188.03.33.14.648.302.947-.328.283-.633.564-.902.836-.514.522-.92.997-1.19 1.351z"
    />
    <path
      fill={color}
      d="M170.432 164.934c-.33-.4-.82-.94-1.44-1.54-.412-.4-.9-.818-1.418-1.242.27-.542.303-1.048.273-1.385a2.73 2.73 0 00-.45-1.244c.335-.301.644-.599.92-.886.556-.575.994-1.093 1.287-1.48.296-.382.445-.63.445-.63s-.288.028-.76.128c-.475.097-1.132.266-1.892.517-.609.202-1.283.48-1.976.793-.05-.02-.097-.048-.147-.068-1.841-.738-3.213-.73-4.635-.015a24.05 24.05 0 00-2.186-.87 20.185 20.185 0 00-2.03-.567c-.506-.11-.813-.145-.813-.145s.173.257.505.653c.331.4.823.939 1.442 1.539.354.341.761.699 1.195 1.06a2.585 2.585 0 00-.3 1.438c.038.422.183.822.413 1.194-.404.355-.782.707-1.108 1.046a16.86 16.86 0 00-1.285 1.482c-.295.382-.444.631-.444.631s.289-.026.762-.126a17.125 17.125 0 001.892-.514 20.583 20.583 0 002.215-.9c.046.02.086.042.132.06.92.368 1.696.538 2.44.538.737 0 1.447-.173 2.156-.516.873.402 1.716.745 2.468.995a19.56 19.56 0 002.032.564c.506.11.813.144.813.144s-.173-.257-.506-.654z"
    />
    <g>
      <path
        fill={color}
        d="M135.253 103.44c.26-.308.394-.515.394-.515s-.245-.03-.649-.025c-.405.001-.967.037-1.617.13-.495.072-1.046.2-1.61.356a3.748 3.748 0 00-.464-.163c-1.36-.383-2.465-.327-3.595.114a12.825 12.825 0 00-1.51-.388 11.36 11.36 0 00-1.562-.179c-.39-.017-.63.004-.63.004s.118.21.354.521c.234.316.586.733 1.033 1.186.15.15.326.304.496.457a2.179 2.179 0 00-.053 1.366c-.287.22-.554.44-.793.657-.487.442-.872.851-1.132 1.161-.26.307-.394.515-.394.515s.244.028.647.023c.404-.002.966-.04 1.616-.133.548-.08 1.159-.223 1.79-.403.14.06.285.11.437.153a6.315 6.315 0 001.737.264c.63 0 1.24-.121 1.852-.357.585.19 1.156.347 1.67.438.627.113 1.17.164 1.564.177.392.016.631-.005.631-.005s-.118-.21-.353-.523a11.153 11.153 0 00-1.032-1.188c-.19-.191-.407-.386-.63-.58.094-.279.137-.574.11-.881a2.323 2.323 0 00-.095-.471c.232-.183.459-.366.658-.546.486-.442.872-.853 1.13-1.165z"
      />
      <path
        fill={color}
        d="M135.703 89.969c.243-.29.369-.488.369-.488s-.23-.04-.61-.055c-.379-.018-.908-.01-1.52.051-.453.046-.955.142-1.471.262a3.376 3.376 0 00-.527-.184c-1.273-.324-2.313-.268-3.378.13a11.18 11.18 0 00-1.373-.288c-.59-.077-1.102-.1-1.473-.093-.368.004-.594.038-.594.038s.111.2.332.495c.218.298.55.691.97 1.112.117.12.26.238.393.357a2.132 2.132 0 00-.088.773c.008.105.031.21.056.312-.238.182-.467.366-.669.548-.455.413-.815.8-1.057 1.094-.242.29-.368.49-.368.49s.231.04.61.056c.38.019.909.011 1.52-.049.504-.05 1.065-.159 1.643-.3a3.6 3.6 0 00.489.166 6.416 6.416 0 001.576.212h.001a5.14 5.14 0 001.785-.326c.532.148 1.05.267 1.52.328a9.98 9.98 0 001.473.09c.369-.004.595-.04.595-.04s-.11-.2-.331-.496a9.913 9.913 0 00-.97-1.114c-.153-.154-.334-.31-.512-.466.066-.248.09-.494.07-.717-.01-.12-.034-.24-.064-.357.189-.149.38-.298.545-.447.455-.415.816-.8 1.058-1.094z"
      />
      <path
        fill={color}
        d="M135.075 80.627c-.122-.122-.269-.244-.408-.367.04-.197.05-.39.036-.57-.008-.095-.03-.186-.05-.278.152-.122.312-.243.447-.366.428-.387.767-.752.994-1.032.228-.276.345-.467.345-.467s-.218-.05-.574-.08a9.54 9.54 0 00-1.433-.017c-.418.024-.88.095-1.356.188a3.223 3.223 0 00-.563-.195c-1.209-.278-2.194-.222-3.208.144a9.792 9.792 0 00-1.236-.203 9.215 9.215 0 00-1.393-.02 4.38 4.38 0 00-.563.067s.104.192.313.473c.206.284.518.655.914 1.05.09.09.201.18.3.27-.05.221-.068.44-.05.64.006.07.025.137.04.206a9.378 9.378 0 00-.562.458 9.51 9.51 0 00-.991 1.035c-.229.277-.345.468-.345.468s.217.05.575.082a9.5 9.5 0 001.434.02c.462-.027.975-.107 1.503-.218.172.073.348.136.535.18a6.436 6.436 0 001.46.18c.583 0 1.14-.118 1.696-.314a9.75 9.75 0 001.408.243 9.19 9.19 0 001.394.017c.35-.023.564-.068.564-.068s-.104-.193-.312-.473a9.061 9.061 0 00-.914-1.053z"
      />
      <path
        fill={color}
        d="M134.746 118.747c.28-.327.425-.546.425-.546s-.26-.014-.69.015a13.77 13.77 0 00-1.728.231c-.545.106-1.153.275-1.779.475a4.4 4.4 0 00-.384-.147c-1.454-.462-2.652-.434-3.88.075a14.91 14.91 0 00-1.636-.504 12.925 12.925 0 00-1.664-.284c-.417-.042-.672-.037-.672-.037s.128.22.38.555c.25.336.626.784 1.106 1.275.188.193.406.39.628.588-.16.356-.232.74-.199 1.14.016.192.062.377.122.557a13.68 13.68 0 00-2.168 2.039c-.28.327-.424.546-.424.546s.262.015.692-.013a13.714 13.714 0 001.728-.229 16.21 16.21 0 001.982-.537c.115.048.23.095.353.134.69.218 1.316.324 1.915.324.655 0 1.292-.13 1.939-.39.644.242 1.27.445 1.833.575.668.155 1.247.242 1.665.281.416.042.671.035.671.035s-.126-.221-.378-.555a12.929 12.929 0 00-1.877-2.001c.139-.336.2-.696.17-1.069a2.415 2.415 0 00-.142-.614c.287-.225.558-.45.798-.669.521-.48.936-.92 1.214-1.25z"
      />
      <path
        fill={color}
        d="M134.16 136.286c.303-.353.46-.585.46-.585s-.28.003-.74.062c-.464.056-1.108.168-1.854.352-.6.148-1.268.367-1.958.62-.095-.041-.185-.085-.286-.121-1.569-.564-2.86-.548-4.204.042a17.867 17.867 0 00-1.796-.654 14.973 14.973 0 00-1.778-.413c-.444-.074-.716-.087-.716-.087s.137.234.41.594a14.93 14.93 0 001.975 2.142 2.558 2.558 0 00-.295 1.351c.02.259.083.507.177.745a17.54 17.54 0 00-1.139.952c-.564.522-1.012.999-1.312 1.356-.303.353-.46.586-.46.586s.281-.002.742-.06a16.075 16.075 0 001.854-.35c.669-.164 1.417-.41 2.19-.704.087.037.17.078.26.11.764.273 1.457.406 2.12.406.69 0 1.364-.147 2.05-.44.711.307 1.403.568 2.024.749.713.207 1.332.34 1.78.41.444.074.716.086.716.086s-.137-.236-.408-.596c-.269-.364-.673-.85-1.187-1.387-.284-.296-.609-.601-.958-.91a2.543 2.543 0 00.265-1.299 2.546 2.546 0 00-.195-.794 17.364 17.364 0 002.264-2.163z"
      />
    </g>
    <g>
      <path
        fill={color}
        d="M106.528 89.129a12.88 12.88 0 00-1.606.27 3.371 3.371 0 00-.513-.184c-1.22-.315-2.284-.258-3.367.133a9.826 9.826 0 00-1.236-.27 9.453 9.453 0 00-1.406-.098c-.354.003-.572.035-.572.035s.097.198.296.491c.196.296.495.685.877 1.103.08.087.18.176.268.263a2.157 2.157 0 00-.143.938c.003.04.015.077.02.116a11.508 11.508 0 00-2.012 1.754c-.264.291-.402.49-.402.49s.238.04.63.058c.395.02.944.013 1.58-.045.538-.05 1.14-.16 1.76-.305.161.07.329.133.508.179a6.206 6.206 0 001.533.202c.612 0 1.206-.11 1.804-.32.482.14.953.254 1.383.313a9.155 9.155 0 001.407.094c.353-.003.572-.037.572-.037s-.097-.2-.295-.492a9.375 9.375 0 00-.875-1.106c-.113-.122-.249-.246-.376-.37.096-.274.144-.565.123-.87-.004-.058-.02-.114-.027-.17a11.46 11.46 0 001.882-1.667c.263-.292.4-.491.4-.491s-.238-.042-.631-.06a11.936 11.936 0 00-1.582.046z"
      />
      <path
        fill={color}
        d="M108.568 77.147c-.45.024-.95.097-1.466.193a2.98 2.98 0 00-.558-.196c-1.158-.274-2.154-.22-3.202.144a9.065 9.065 0 00-1.113-.19 8.405 8.405 0 00-1.334-.022c-.336.02-.542.063-.542.063s.092.19.28.47c.185.28.468.65.831 1.042.056.06.126.12.185.179-.077.247-.113.51-.092.784 0 .01.003.02.004.03-.269.19-.52.38-.745.572-.457.388-.822.755-1.067 1.038-.246.277-.375.47-.375.47s.225.052.595.085c.372.037.888.055 1.487.025a11.527 11.527 0 001.62-.222c.17.075.345.14.533.185.499.117.963.173 1.418.173.583 0 1.153-.115 1.732-.308a9.06 9.06 0 001.274.228c.533.047.997.045 1.335.02.335-.021.543-.066.543-.066s-.092-.19-.279-.47a8.406 8.406 0 00-.831-1.045c-.084-.092-.188-.182-.281-.273.064-.232.09-.477.072-.73-.002-.031-.012-.06-.015-.089.221-.16.437-.32.627-.481.458-.388.822-.754 1.068-1.035.246-.277.375-.467.375-.467s-.225-.052-.595-.084a10.08 10.08 0 00-1.484-.023z"
      />
      <path
        fill={color}
        d="M112.01 67.083c.231-.265.35-.45.35-.45s-.211-.058-.56-.105a9.306 9.306 0 00-1.403-.079c-.413.006-.87.057-1.34.129a2.942 2.942 0 00-.61-.21c-1.12-.236-2.07-.18-3.055.156a7.977 7.977 0 00-.996-.123 7.887 7.887 0 00-1.269.038 3.945 3.945 0 00-.515.088s.088.184.265.45c.177.27.445.623.79.995.036.038.08.075.117.112-.052.2-.072.41-.058.625a9.18 9.18 0 00-1.636 1.476c-.231.266-.35.452-.35.452s.211.06.561.107c.351.052.839.089 1.405.081a10.05 10.05 0 001.49-.152c.178.08.366.15.566.192.46.097.888.144 1.307.144.571 0 1.126-.106 1.693-.29.401.078.793.138 1.151.154.507.023.95 0 1.27-.04.318-.037.515-.089.515-.089s-.086-.184-.264-.451a7.688 7.688 0 00-.79-.996c-.062-.068-.142-.136-.211-.203.043-.194.064-.394.048-.6l-.003-.018c.184-.135.368-.269.528-.405.43-.37.773-.72 1.003-.988z"
      />
      <path
        fill={color}
        d="M104.225 102.648c-.536.073-1.132.204-1.748.365a3.448 3.448 0 00-.467-.174c-1.28-.37-2.398-.32-3.57.114a11.54 11.54 0 00-1.368-.373c-.595-.114-1.111-.17-1.487-.185-.373-.019-.603.001-.603.001s.102.208.311.517c.208.313.523.726.929 1.175.107.119.24.24.36.362a2.244 2.244 0 00-.21 1.121c.005.075.023.147.035.22a12.873 12.873 0 00-2.24 1.935c-.283.309-.43.517-.43.517s.254.03.673.029c.421 0 1.007-.033 1.687-.123a15.22 15.22 0 001.933-.412c.14.061.288.114.442.159.593.168 1.145.25 1.687.25.63 0 1.246-.118 1.876-.347.527.18 1.042.33 1.51.42.594.113 1.111.167 1.487.182.374.018.605-.002.605-.002s-.102-.208-.312-.518a10.189 10.189 0 00-.927-1.177c-.143-.16-.313-.32-.479-.481.142-.331.21-.689.183-1.059-.007-.09-.026-.178-.042-.264a13.702 13.702 0 002.091-1.833c.283-.308.432-.516.432-.516s-.254-.03-.673-.026a13.037 13.037 0 00-1.685.123z"
      />
      <path
        fill={color}
        d="M103.413 117.784c-.451.023-1.078.091-1.806.221-.588.107-1.245.278-1.923.482a3.817 3.817 0 00-.39-.153c-1.386-.448-2.583-.426-3.863.075a13.133 13.133 0 00-1.469-.48 11.72 11.72 0 00-1.575-.29c-.396-.044-.64-.04-.64-.04s.11.217.332.548c.22.334.556.777.987 1.263.142.16.313.326.476.49a2.402 2.402 0 00-.303 1.331c.007.118.028.233.053.348a14.774 14.774 0 00-2.498 2.145c-.306.329-.466.55-.466.55s.272.016.72-.01c.45-.022 1.078-.09 1.808-.22.649-.117 1.378-.307 2.133-.544.116.051.234.1.36.14a6.024 6.024 0 001.868.316c.652 0 1.297-.13 1.962-.384.579.23 1.145.425 1.659.554.63.157 1.179.247 1.577.288.397.045.641.04.641.04s-.108-.219-.33-.55c-.221-.334-.555-.778-.986-1.265a11.84 11.84 0 00-.606-.623 2.413 2.413 0 00.209-1.67c.362-.26.701-.52 1.003-.774a14.98 14.98 0 001.321-1.25c.307-.33.467-.548.467-.548s-.273-.017-.721.01z"
      />
    </g>
    <g>
      <path
        fill={color}
        d="M83.39 78.472c.487-.39.876-.757 1.14-1.04.263-.278.402-.47.402-.47s-.23-.054-.614-.088c-.384-.039-.92-.058-1.542-.03-.481.023-1.02.098-1.574.198a2.898 2.898 0 00-.552-.196 5.566 5.566 0 00-3.19.14 8.042 8.042 0 00-.994-.176 7.792 7.792 0 00-1.277-.028c-.321.019-.522.06-.522.06s.079.19.245.466a7.9 7.9 0 00.749 1.035c.024.029.057.058.082.088-.105.256-.16.531-.16.817a10.925 10.925 0 00-2.082 1.703c-.265.278-.404.47-.404.47s.231.052.614.087c.384.038.918.056 1.54.026a12.43 12.43 0 001.73-.225c.169.078.346.145.538.192.48.113.933.168 1.385.168.584 0 1.162-.115 1.75-.307.399.1.789.18 1.148.216.509.05.954.05 1.278.027.322-.02.523-.062.523-.062s-.079-.19-.245-.466a7.712 7.712 0 00-.747-1.038c-.051-.06-.117-.12-.172-.181.096-.252.145-.52.14-.798.294-.197.566-.393.811-.588z"
      />
      <path
        fill={color}
        d="M79.197 88.795c-.526.044-1.114.146-1.721.276a3.042 3.042 0 00-.508-.19c-1.176-.309-2.241-.256-3.36.13a8.951 8.951 0 00-1.103-.254 8.698 8.698 0 00-1.342-.103c-.338.001-.548.032-.548.032s.082.196.257.486c.173.293.439.678.784 1.094.044.053.102.107.15.16-.16.327-.239.686-.226 1.057a12.216 12.216 0 00-2.295 1.851c-.285.293-.434.493-.434.493s.245.043.653.061c.409.023.978.02 1.642-.037.575-.048 1.221-.16 1.89-.312a3 3 0 00.483.176 5.918 5.918 0 001.523.206c.603 0 1.203-.109 1.823-.317a9.1 9.1 0 001.237.295 8.537 8.537 0 001.343.1c.339-.002.55-.034.55-.034s-.082-.196-.257-.486a8.529 8.529 0 00-.783-1.096c-.074-.088-.167-.179-.249-.269a2.23 2.23 0 00.2-1.049 12.719 12.719 0 002.15-1.758c.284-.292.433-.491.433-.491s-.245-.043-.652-.061c-.407-.021-.976-.017-1.64.04z"
      />
      <path
        fill={color}
        d="M75.157 102.265c-.573.072-1.214.206-1.878.371a3.431 3.431 0 00-.46-.177c-1.24-.364-2.366-.316-3.569.111a10.422 10.422 0 00-1.213-.347 9.48 9.48 0 00-1.412-.19c-.357-.02-.578-.004-.578-.004s.086.205.27.511c.181.31.46.719.823 1.164.072.086.161.176.239.263-.23.4-.35.845-.324 1.307 0 .011.004.022.004.033a15.85 15.85 0 00-1.224.856c-.561.444-1.01.855-1.315 1.17-.306.31-.468.518-.468.518s.262.033.698.032c.436.003 1.047-.028 1.756-.116.63-.08 1.338-.227 2.072-.418.137.062.28.116.432.161.577.167 1.12.25 1.664.25.622 0 1.245-.118 1.892-.343.471.17.933.312 1.358.4a9.458 9.458 0 001.413.188c.357.02.579.001.579.001s-.087-.205-.27-.512c-.18-.31-.459-.72-.822-1.166-.102-.124-.227-.253-.343-.38.21-.386.318-.813.294-1.253-.001-.027-.009-.053-.012-.078a14.08 14.08 0 002.379-1.923c.306-.309.468-.517.468-.517s-.262-.032-.697-.03c-.437-.002-1.047.03-1.756.118z"
      />
    </g>
    <path
      fill={color}
      d="M53.654 88.424a13.327 13.327 0 00-1.703.034c-.557.045-1.184.147-1.832.28a2.963 2.963 0 00-.496-.189c-1.13-.304-2.175-.262-3.365.124a8.083 8.083 0 00-.966-.232 7.875 7.875 0 00-1.278-.11 4.089 4.089 0 00-.527.028s.069.194.22.48c.148.29.38.672.688 1.082.016.02.036.04.05.06-.2.332-.32.696-.341 1.076-.463.275-.9.553-1.272.83-.554.415-1 .804-1.3 1.1-.304.295-.465.495-.465.495s.253.045.675.065c.423.024 1.013.023 1.704-.031.609-.049 1.296-.161 2.01-.316.149.067.303.128.47.173a5.71 5.71 0 001.505.207c.596 0 1.2-.106 1.84-.313.378.118.749.217 1.093.273.507.082.953.11 1.28.108.324 0 .527-.03.527-.03s-.067-.194-.217-.48a7.704 7.704 0 00-.689-1.085c-.04-.053-.092-.106-.134-.16.192-.332.302-.697.315-1.075a13.187 13.187 0 002.418-1.839c.303-.293.465-.492.465-.492s-.254-.043-.675-.063z"
    />
    <path
      fill={color}
      d="M33.075 76.287c-.409-.04-.981-.062-1.65-.037-.538.021-1.144.097-1.77.202a2.684 2.684 0 00-.53-.197c-1.061-.265-2.06-.222-3.213.132a6.536 6.536 0 00-1.902-.178c-.298.015-.485.053-.485.053s.053.184.177.454c.107.238.278.546.49.873-.182.278-.31.582-.364.899-.473.263-.92.532-1.299.803-.544.39-.982.759-1.28 1.042-.3.28-.46.473-.46.473s.244.055.653.092c.41.041.983.064 1.651.04a14.52 14.52 0 001.937-.23c.154.073.318.138.507.183.465.115.912.172 1.369.172.578 0 1.168-.112 1.782-.298.305.08.604.146.886.18.461.054.869.06 1.168.04.298-.017.486-.056.486-.056s-.052-.184-.177-.455a6.604 6.604 0 00-.551-.976c.173-.278.295-.578.34-.891.414-.238.808-.478 1.147-.72.544-.39.983-.757 1.282-1.04.3-.278.459-.471.459-.471s-.245-.052-.653-.09z"
    />
  </svg>
);

MattressIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

MattressIcon.defaultProps = {
  size: 16,
  color: '#FFF',
};

export default MattressIcon;
