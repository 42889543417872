import TYPES from './types';

export default [
  {
    type: TYPES.Row,
    children: [
      { key: 'sLibelle', className: 'resource-title' },
      {
        type: TYPES.Row,
        className: 'resource-price',
        children: [
          { key: 'priceData.0.sPrixHT', className: 'resource-price--value' },
          {
            type: TYPES.Raw,
            className: 'resource-price--currency',
            children: ['€'],
          },
          {
            type: TYPES.Raw,
            className: 'resource-price--ht',
            children: ['Hors taxes'],
          },
        ],
      },
    ],
  },
  {
    type: TYPES.Row,
    children: [
      {
        type: TYPES.Col,
        className: 'resource-info',
        children: [
          {
            type: TYPES.Raw,
            className: 'resource-info--title',
            children: ['Informations'],
          },
        ],
      },
      {
        type: TYPES.Col,
        className: 'resource-stock',
        children: [
          {
            type: TYPES.Stock,
          },
        ],
      },
    ],
  },
  {
    type: TYPES.Row,
    className: 'resource-parents',
    children: [
      {
        type: TYPES.Col,
        className: 'resource-parents--aisle',
        children: [
          {
            type: TYPES.Raw,
            className: 'resource-title--secondary',
            children: ['Rayon'],
          },
          {
            type: TYPES.Resource,
            key: 'sRayon',
            field: 'sLibelle',
            resourceType: 'aisles',
          },
        ],
      },
      {
        type: TYPES.Col,
        className: 'resource-parents--family',
        children: [
          {
            type: TYPES.Raw,
            className: 'resource-title--secondary',
            children: ['Famille'],
          },
          {
            type: TYPES.Resource,
            key: 'sFamille',
            field: 'sLibelle',
            resourceType: 'families',
          },
        ],
      },
    ],
  },
  {
    type: TYPES.Row,
    children: [
      {
        type: TYPES.Col,
        className: 'resource-upload--image',
        children: [
          {
            type: TYPES.Raw,
            className: 'resource-title--secondary',
            children: ['Photo mise en avant'],
          },
          {
            type: TYPES.Raw,
            className: 'resource-upload--subtitle',
            children: [
              'Privilégiez une image avec le produit sur fond blanc ou transparent.',
            ],
          },
          { type: TYPES.ImageUpload },
        ],
      },
    ],
  },
];
