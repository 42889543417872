import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ArrowLeftIcon from '../../../assets/img/ArrowLeftIcon';
import PrinterIcon from '../../../assets/img/PrinterIcon';
import { usePageTitle } from '../../hooks';
import { CART_ACTIONS, ORDER_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors, Sizing } from '../../styles/vars';
import { parsePriceString } from '../../utils/misc';
import { connect } from '../../utils/redux';
import Button from '../elements/Button';
import CartCheckout from '../elements/CartCheckout';
import CartList from '../elements/CartList';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const OrderConfirmationWrapper = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 0 40px;
  align-items: flex-start;
  grid-column: 1 / span 12;

  h2 {
    @media print {
      margin: 30px 0;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  align-items: stretch;
  grid-column: 1 / span 12;
  width: 100%;
  margin-bottom: ${Sizing.gutterWidth};

  ${props =>
    props.printHidden &&
    css`
      @media print {
        display: none;
      }
    `}

  a, button {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const OrderFieldsContainer = styled.div`
  grid-column: 1 / span 8;

  @media print {
    grid-column: 1 / span 12;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || '20px'};

  @media print {
    margin-bottom: 20px;
    margin-top: ${props => (props.marginTop ? 30 : 0)}px;
  }

  .left {
    margin-right: 40px;

    @media print {
      margin-right: 0;
    }
  }

  p {
    margin: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.ref {
      margin-top: 10px;

      @media print {
        display: inline-block;
        margin-top: 0;
      }
    }

    @media print {
      &:not(:first-child) {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 0.5ch;
        }
      }
    }
  }
`;

const OrderConfirmation = ({
  cartState,
  orderState,
  usersState,
  clearCart,
  setOrderComment,
}) => {
  const { orderInfo, invalidOrderInfo } = orderState;

  const { sOrderReference: reference, sOrderComment } = orderInfo;
  const { sOrderReference: invalidReference } = invalidOrderInfo;

  usePageTitle(
    reference ? `Intention de commande ${reference}` : 'Intention de commande',
  );

  const { present } = cartState;
  const { products, invalidProducts } = present || {
    products: {},
    invalidProducts: {},
  };

  const productsArr = Object.values(products || {});
  const invalidProductsArr = Object.values(invalidProducts || {});

  const [itemDelays, setItemDelays] = useState(
    productsArr.reduce((acc, prod) => {
      const { sCode } = prod;

      acc[sCode] = { count: 0, loading: false };

      return acc;
    }, {}),
  );

  const isLoading = Object.values(itemDelays).some(d => d.loading);
  const longestDelay = Object.values(itemDelays).reduce(
    (acc, d) =>
      Math.max(acc, typeof d.count === 'object' ? d.count?.numeric : d.count),
    0,
  );

  const { currentUser } = usersState;
  const { priceMode } = currentUser;

  const getProductPriceByMode = (product, count) => {
    switch (priceMode) {
      case 'TTC': {
        const {
          sPromoTTC,
          sBestPromoQuantity,
          sBestPromoTTC,
          sBestPrixQuantity,
          sBestPrixTTC,
          sPrixTTC,
        } = product.getPrice(count);

        return {
          promo: sPromoTTC,
          bestPromoQuantity: sBestPromoQuantity,
          bestPromo: sBestPromoTTC,
          bestPrixQuantity: sBestPrixQuantity,
          bestPrix: sBestPrixTTC,
          prix: sPrixTTC,
        };
      }

      case 'HT':
      default: {
        const {
          sPromoHT,
          sBestPromoQuantity,
          sBestPromoHT,
          sBestPrixQuantity,
          sBestPrixHT,
          sPrixHT,
        } = product.getPrice(count);

        return {
          promo: sPromoHT,
          bestPromoQuantity: sBestPromoQuantity,
          bestPromo: sBestPromoHT,
          bestPrixQuantity: sBestPrixQuantity,
          bestPrix: sBestPrixHT,
          prix: sPrixHT,
        };
      }
    }
  };

  const total = productsArr.reduce((acc, product) => {
    const { count } = product;
    const {
      promo,
      bestPromoQuantity,
      bestPromo,
      bestPrixQuantity,
      bestPrix,
      prix,
    } = getProductPriceByMode(product, count);

    let value = 0;

    if (promo) {
      if (count >= bestPromoQuantity) {
        value = (parsePriceString(bestPromo) || 0) * count;
      } else {
        value = (parsePriceString(promo) || 0) * count;
      }
    } else if (count >= bestPrixQuantity) {
      value = (parsePriceString(bestPrix) || 0) * count;
    } else {
      value = (parsePriceString(prix) || 0) * count;
    }

    return acc + value;
  }, 0);

  const handlePropagateState = ({ product, delay, status }) => {
    const { sCode } = product;

    setItemDelays({
      ...itemDelays,
      [sCode]: {
        count: delay,
        loading: status === Status.LOADING,
      },
    });
  };

  // Clear order confirmation state on exit (it's only temporary data)
  useEffect(
    () => () => {
      clearCart();
      setOrderComment('');
    },
    [],
  );

  const renderOrderContents = () => {
    if (
      (!productsArr || productsArr.length === 0) &&
      (!invalidProductsArr || invalidProductsArr.length === 0)
    ) {
      return (
        <>
          <OrderFieldsContainer>
            <Message left>
              <p>Désolé, aucune intention de commande n&apos;a été trouvée.</p>
            </Message>
          </OrderFieldsContainer>

          <Row>
            <Button fromComponent={Link} to={Paths.Cart()} background="white">
              <ArrowLeftIcon />
              <span>Retour au panier</span>
            </Button>
          </Row>
        </>
      );
    }

    return (
      <>
        <OrderFieldsContainer>
          {productsArr && productsArr.length > 0 && (
            <>
              <Message>
                <div className="left">
                  <p>Nous vous remercions de votre commande.</p>

                  {reference && (
                    <>
                      <p>La référence de votre commande est : </p>

                      <p
                        className="ref"
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                          __html: reference || '(référence inconnue)',
                        }}
                      />
                    </>
                  )}
                </div>
              </Message>

              {sOrderComment && (
                <Message marginBottom="40px">
                  <div className="left">
                    <p style={{ fontWeight: 'bold' }}>
                      Commentaire de commande&nbsp;:&nbsp;
                    </p>

                    <p className="ref">{sOrderComment}</p>
                  </div>
                </Message>
              )}

              <CartList
                products={productsArr}
                handlePropagateState={handlePropagateState}
                compact
                isStatic
              />
            </>
          )}

          {invalidProductsArr && invalidProductsArr.length > 0 && (
            <>
              <Message marginTop={60}>
                <div className="left">
                  <p>Votre commande pour les produits non-référencés : </p>

                  {invalidReference && (
                    <p
                      className="ref"
                      /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{ __html: invalidReference }}
                    />
                  )}
                </div>
              </Message>

              <CartList
                products={invalidProductsArr}
                compact
                isStatic
                invalid
              />
            </>
          )}
        </OrderFieldsContainer>

        <CartCheckout
          total={total}
          priceMode={priceMode}
          isLoading={isLoading}
          longestDelay={longestDelay}
          isStatic
        />

        <Row centered printHidden>
          <Button fromComponent={Link} to={Paths.Cart()} background="white">
            <ArrowLeftIcon />
            <span>Retour au panier</span>
          </Button>

          <Button
            fromComponent="a"
            background={Colors.primary}
            color={Colors.white}
            onClick={() => window.print()}
          >
            <PrinterIcon size={17} />
            <span>Imprimer</span>
          </Button>
        </Row>
      </>
    );
  };

  return (
    <OrderConfirmationWrapper>
      <PageTitle fromComponent="h2">Intention de commande</PageTitle>

      {renderOrderContents()}
    </OrderConfirmationWrapper>
  );
};

OrderConfirmation.propTypes = {
  cartState: PropTypes.objectOf(PropTypes.any).isRequired,
  orderState: PropTypes.objectOf(PropTypes.any).isRequired,
  usersState: PropTypes.shape({
    currentUser: PropTypes.objectOf(PropTypes.any),
  }).isRequired,

  clearCart: PropTypes.func.isRequired,
  setOrderComment: PropTypes.func.isRequired,
};

OrderConfirmation.defaultProps = {};

export const ConnectedCart = connect(
  state => ({
    cartState: state.cart,
    orderState: state.order,
    usersState: state.users,
  }),
  {
    ...CART_ACTIONS,
    ...ORDER_ACTIONS,
  },
)(OrderConfirmation);

export default {
  path: Paths.OrderConfirmation(),
  name: 'Intention de commande',
  component: ConnectedCart,
};
