import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { usePageTitle } from '../../hooks';
import { PENDING_ORDER_ACTIONS } from '../../store/actions';
import { Status } from '../../store/models/europrisme';
import { Colors } from '../../styles/vars';
import { connect } from '../../utils/redux';
import {
  OrderDetailsPanel,
  OrderDetailsPanelTrack,
  OrderListButton,
  OrderListElement,
  OrdersListGrid,
  OrdersListWapper,
} from '../elements/AccountLists';
import BlockPlaceholder from '../elements/BlockPlaceholder';
import OrderDetails from '../elements/OrderDetails';
import PageTitle from '../elements/PageTitle';
import Paths from './paths';

const PlaceholderData = Array(12)
  .fill()
  .map((_, index) => index);

const PendingOrdersList = ({
  status,
  pendingOrders,
  getPendingOrders,
  match,
}) => {
  usePageTitle('Intentions de commande en attente de validation');

  const history = useHistory();

  const { params } = match;
  const { orderID: selected } = params;

  useEffect(() => {
    getPendingOrders();
  }, []);

  const toggleSelection = code => {
    if (selected === code) {
      history.push(Paths.PendingOrdersList({ orderID: '' }));
    } else {
      history.push(Paths.PendingOrdersList({ orderID: code }));
    }
  };

  const renderList = () => {
    if (status === Status.LOADING) {
      return PlaceholderData.map(key => (
        <BlockPlaceholder key={key} height={55} />
      ));
    }

    const sortedOrders = [...Object.values(pendingOrders)].sort(
      (left, right) => {
        if (left.date.isBefore(right.date)) return 1;
        if (left.date.isAfter(right.date)) return -1;

        return `${right?.code}`.localeCompare(`${left?.code}`);
      },
    );

    const getColorForOrderStatus = orderStatus =>
      ({
        Livré: Colors.lightBlue,
        Terminé: Colors.lightGreen,
        'Facturé partiellement': Colors.lightYellow,
        Annulée: Colors.lightRed,
      }[orderStatus]);

    return sortedOrders.map(
      ({ code, formattedDate, orderStatus, cancelled }) => {
        const orderStatusMessage = cancelled ? 'Annulée' : orderStatus;

        return (
          <OrderListElement
            key={code}
            selected={selected === code}
            hasSelection={selected}
            className="order-block"
          >
            <OrderListButton
              onClick={() => toggleSelection(code)}
              selected={selected === code}
              hasSelection={selected}
            >
              <header>
                <h3>Dossier n°{code}</h3>
                <h4>{formattedDate}</h4>

                {orderStatus && (
                  <div
                    className="pill"
                    style={{
                      backgroundColor: getColorForOrderStatus(
                        orderStatusMessage,
                      ),
                    }}
                  >
                    {orderStatusMessage}
                  </div>
                )}
              </header>
            </OrderListButton>
          </OrderListElement>
        );
      },
    );
  };

  return (
    <OrdersListWapper>
      <PageTitle>Intentions de commande en attente de validation</PageTitle>

      <OrdersListGrid>{renderList()}</OrdersListGrid>
      <OrderDetailsPanelTrack>
        <OrderDetailsPanel selected={selected}>
          <div className="contents">
            {selected && <OrderDetails code={selected} type="pendingOrder" />}
          </div>
        </OrderDetailsPanel>
      </OrderDetailsPanelTrack>
    </OrdersListWapper>
  );
};

PendingOrdersList.propTypes = {
  status: PropTypes.oneOf(Object.values(Status)).isRequired,
  pendingOrders: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  getPendingOrders: PropTypes.func.isRequired,

  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

PendingOrdersList.defaultProps = {
  pendingOrders: [],
};

export const ConnectedPendingOrdersList = connect(
  state => ({ ...state.pendingOrders }),
  {
    ...PENDING_ORDER_ACTIONS,
  },
)(withRouter(PendingOrdersList));

export default {
  path: Paths.PendingOrdersList(),
  name: 'Mes commandes en cours',
  component: ConnectedPendingOrdersList,
};
