import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Colors, Sizing } from '../../styles/vars';
import ConfirmEmptyCart from '../elements/ConfirmEmptyCart';
import Paths from '../routes/paths';
import Footer from '../sections/Footer';
import Header from '../sections/Header';

const GridWrapper = styled.main`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: ${Sizing.wrapperWidth};
  min-height: calc(100vh - ${Sizing.headerHeight});
  margin: 0 auto;
  padding: 0 ${Sizing.gutterWidth};

  background-color: ${Colors.white};

  z-index: 1;

  @media print {
    background: none;
  }

  @media screen and (max-width: ${Sizing.mobileWidth}) {
    padding: 0 16px;
    padding-top: ${Sizing.mobileHeaderHeight};

    overflow-x: hidden;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 100%;

    background-color: inherit;

    pointer-events: none;
    z-index: -1;
  }

  ${props =>
    props.isAdmin &&
    css`
      min-height: 100vh;
      padding-top: ${Sizing.headerHeight};

      &::before {
        background-color: ${Colors.lightGrey};
      }
    `}
`;

const GridContainer = styled.section`
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 0 ${Sizing.gutterWidth};
  align-items: flex-start;

  ${props =>
    props.isAdmin &&
    css`
      background-color: ${Colors.lightGrey};
    `}

  @media screen and (max-width: ${Sizing.wrapperWidth}) {
    gap: 0 16px;
  }
`;

const GlobalLayout = ({ component: Component, route, children }) => {
  const currentUser = useSelector(state => state.users.currentUser);

  const isAdmin = route.match.path === Paths.Admin();

  return (
    <>
      <Header isAdmin={isAdmin} currentUser={currentUser} />

      <ConfirmEmptyCart />

      <GridWrapper isAdmin={isAdmin}>
        <GridContainer isAdmin={isAdmin}>
          {Component && route ? (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <Component {...route} />
          ) : (
            children
          )}
        </GridContainer>
      </GridWrapper>
      {!isAdmin && <Footer />}
    </>
  );
};

GlobalLayout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  route: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

GlobalLayout.defaultProps = {
  component: null,
  route: null,
  children: null,
};

export const withGlobalLayout = component => route => (
  <GlobalLayout component={component} route={route} />
);

export default GlobalLayout;
