import PropTypes from 'prop-types';
import React from 'react';

const AlertOctagonIcon = ({ size, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={style}
    >
      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
      <line x1="12" y1="8" x2="12" y2="12" />
      <line x1="12" y1="16" x2="12.01" y2="16" />
    </svg>
  );
};

AlertOctagonIcon.propTypes = {
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

AlertOctagonIcon.defaultProps = {
  size: 15,
  style: {},
};

export default AlertOctagonIcon;
