/* eslint-disable react/no-array-index-key */

import React from 'react';

import ProductDelay from '../components/elements/ProductDelay';
import ResourceField from '../components/elements/ResourceField';
import ResourceImageUpload from '../components/elements/ResourceImageUpload';
import ResourceFields from './admin-fields';
import Types from './admin-fields/types';
import appConfig from './app-config';

export const RESOURCE_FIELDS = ResourceFields;

const renderFields = (resource, fields, parentId = 0) => {
  return fields.map((field, idx) => {
    const {
      type,
      className,
      key,
      children,
      resourceType,
      field: resourceField,
    } = field;

    const fieldClass = (type || 'default_unnamed_type').toLowerCase();
    const kID = `resource-${fieldClass}--${parentId}--${idx}`;

    const deepGetValue = () => {
      let current = resource;

      key.split('.').forEach(segment => {
        if (current && segment in current) {
          current = current[segment];
        } else {
          current = null;
        }
      });

      return current;
    };

    switch (type) {
      case Types.Row:
      case Types.Col: {
        if (!children) return null;

        return (
          <div className={`${fieldClass} ${className || ''}`} key={kID}>
            {renderFields(resource, children, idx)}
          </div>
        );
      }
      case Types.Raw: {
        return (
          <div className={`field field-raw ${className || ''}`} key={kID}>
            {children}
          </div>
        );
      }
      case Types.ImageUpload: {
        return (
          resource.sCode && (
            <ResourceImageUpload
              category="IMG"
              type={resourceType}
              code={resource.sCode}
              key={kID}
            />
          )
        );
      }
      case Types.Resource: {
        return resource[key] ? (
          <ResourceField
            type={resourceType}
            code={resource[key]}
            field={resourceField}
            key={kID}
          />
        ) : (
          <div key={kID}>...</div>
        );
      }
      case Types.Stock: {
        return appConfig.get('settings.useProductDelay') ? (
          <ProductDelay
            product={resource}
            quantity={1}
            className={className}
            key={kID}
          />
        ) : (
          <div className="delay dummy" key={kID} />
        );
      }
      default: {
        return (
          <div className={`field ${className || ''}`} key={kID}>
            {deepGetValue()}
          </div>
        );
      }
    }
  });
};

export default renderFields;
